body.faq {

    #breadcrumbs {
        .breadcrumb {
            &:nth-of-type(2):not(.current) {
                display: none;
            }
        }
    }

    .wrapper_moncompte .wrapper_moncompte_main {
        margin: 0 auto;

        @media only screen and (max-width: 1680px) {
            padding: 0 32px;
        }

    }

    .wrapper_moncompte .nav_moncompte {
        margin: 2rem 0 5rem;

        h1.title {
            display: block;
            font-size: 65 / 16 * 1rem;
            line-height: 1.2;
            text-align: center;
            font-family: $secondaryFont;

            @media only screen and (max-width: 1680px) {
                font-size: 60px;
            }
            @media only screen and (max-width: 1520px) {
                    font-size: 55px;
            }

            @media only screen and (max-width: 1370px) {
                font-size: 50px;
            }

        }

        .account_name{
            margin: .5rem 0 0 0;
            text-align: center;

            span{
                font-family: $primaryFont;
                font-size: 18 / 16 * 1rem;
                line-height: 1;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: 600;

                @media only screen and (max-width: 1680px) {
                    font-size: 1rem;
                }

                @media only screen and (max-width: 1370px) {
                    font-size: .9rem;
                }
			}
		}
    }

    .site_search {
        width: 40%;
        max-width: 530px;
        margin: 2rem auto;
        position: relative;
    
        .w-input-container {
            input {
                border-color: #989898;
                &::-webkit-input-placeholder {
                    font-style: normal;
                }
                &::-moz-placeholder {
                    font-style: normal;
                }
                &:-ms-input-placeholder {
                    font-style: normal;
                }
                &:-moz-placeholder {
                    font-style: normal;
                }
            }

            &:after {
                content: '';
                display: block;
                width: 70px;
                height: 9px;
                background: url('../img/search-arrow.png');
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-position: 50% 50%;
                position: absolute;
                right: 1.5rem;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    
        .search_results {
            display: none;
            border: 1px solid #000;
            border-top: none;
            background: #fff;
            position: absolute;
            top: 95%;
            width: 100%;
            z-index: 2;
            padding: 0 1em;
            font-size: .7rem;
            letter-spacing: .07rem;
            transition: all 0.2s ease-out;
            max-height: 30rem;
            overflow-y: scroll;
    
            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-top: 1px solid #aaa;
                cursor: pointer;
                padding: 1rem 0;
    
                &:first-of-type {
                    border: none;
                }
            }
        }
    }
    

    .for_mob  {
        display: none;
    }

    .title-block {
        display: none;
    }

    .faq_content {
        margin-bottom: 3em;
        .theme_global {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 1280px;
            max-width: 100%;
            visibility: hidden;
            height: 0;

            @media screen and (max-width: 1200px) {
                justify-content: space-around;
            }

            &.actif {
                visibility: visible;
                height: auto;
                margin: 3rem auto 4rem;
            }

            .theme {
                width: 32%;
                max-width: 373px;
                height: 200px;
                background-color: $white;
                margin: 2.7% 0;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;

                @media screen and (max-width: 1200px) {
                    margin: 1% 0;
                }

                span, h2 {
                    font-family: $secondaryFont;
                    font-size: 30 / 16 * 1rem;
                    line-height: 1.2;
                    max-width: 240px;
                    color: $primaryColor;
                    font-weight: 600;
                    text-transform: lowercase;

                    @media only screen and (max-width: 1680px) {
                        font-size: 1.75rem;
                    }

                    @media only screen and (max-width: 1370px) {
                        font-size: 1.65rem;
                    }

                    @media only screen and (max-width: 1024px) {
                        font-size: 1.5rem;
                    }

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

            }

        }

        .theme_page {
            display: flex;
            flex-direction: column;
            height: 0;
            overflow: hidden;
            opacity: 0;
            transition: opacity 0.5s ease-in;
            width: 100%;
            max-width: 1024px;
            margin: 0 auto;

            &.actif {
                height: auto;
                opacity: 1;
                margin: 3rem auto 4rem;
            }
            .theme_menu {
                display: flex;
                width: 100%;

                ul{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 1280px;
                    max-width: 100%;
        
                    @media screen and (max-width: 1200px) {
                        justify-content: space-around;
                    }
                }

                .theme {
                    width: 32%;
                    max-width: 373px;
                    height: 200px;
                    background-color: $white;
                    margin: 2.7% 0;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
    
                    @media screen and (max-width: 1200px) {
                        margin: 1% 0;
                    }
    
                    span, h2 {
                        font-family: $secondaryFont;
                        font-size: 30 / 16 * 1rem;
                        line-height: 1.2;
                        max-width: 240px;
                        color: $primaryColor;
                        font-weight: 600;
                        text-transform: lowercase;
    
                        @media only screen and (max-width: 1680px) {
                            font-size: 1.75rem;
                        }
    
                        @media only screen and (max-width: 1370px) {
                            font-size: 1.65rem;
                        }
    
                        @media only screen and (max-width: 1024px) {
                            font-size: 1.5rem;
                        }
    
                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
    
                }
            }
            .theme_detail .faq_title,
            .theme_detail .faq_question {
                cursor: pointer;
            }

            .theme_detail {
	            width: 100%;
                margin: 0 auto 1.5rem;
                display: flex;
                flex-direction: column;

	            .faq_element{
	                .faq_title {
                        display: none;
	                    font-family: $secondaryFont;
                        font-size: 30 / 16 * 1rem;
                        line-height: 1.2;
	                    font-weight: 600;
	                    color: $primaryColor;
	                    padding: 0 0 2rem;
	                    width: 100%;
	                    position: relative;
                        text-align: center;
                        text-transform: lowercase;

                        @media only screen and (max-width: 1680px) {
                                font-size: 1.75rem;
                        }

                        @media only screen and (max-width: 1370px) {
                            font-size: 1.5rem;
                        }

                        &::first-letter {
                            text-transform: uppercase;
                        }

			            strong{
			            	font-weight: 400;
			            	padding-right: 4px;
			            }
	                }

	                &.active{
	                	.faq_title{
                            display: block;

	                		&:after{
	                			transform: rotate(90deg);
	                		}
	                	}
	                }
	            }
                .faq_questions {
                    .faq_question {
                        font-family: $primaryFont;
                        font-size: 13 / 16 * 1rem;
                        line-height: 1.3;
                        font-style: normal;
                        font-weight: 600;
                        color: $black;
                        padding: 1.5rem;
                        position: relative;
                        background-color: $white;
                        margin: 0 0 1.5rem;
                        text-transform: uppercase;

                        @media screen and (max-width: 820px) {
                            padding: 1.5rem 3rem 1.5rem 1.5rem;
                        }

                    	&.active{
                            border: 0;
                            &:after {
                                transform: rotate(45deg);
                            }
                        }
                        
                        &:after {
		                    content: "";
		                    position: absolute;
		                    top: 0;
                            bottom: 0;
                            margin: auto 0;
                            right: 20px;
                            width: 16px;
                            height: 16px;
                            background: url(../img/close_faq.png) no-repeat 50% 50%;
                            transition: transform .3s ease-out;
                        }
                    }

                    .faq_reponse {
	                    font-family: $primaryFont;
	                    font-size: 13 / 16 * 1rem;
                        line-height: 1.7;
                        letter-spacing: .5px;
	                    font-weight: 600;
	                    color: $primaryColor;
	                    padding: 1rem 1.5rem 2rem;
                        position: relative;
                        margin-top: -.8rem;

	                    br:last-child{
	                    	display: none;
	                    }

	                    a {
                            color: $black;
                            text-underline-position: under;
	                    }

	                    ul {
                            margin: 1rem 0;
	                    	li{
	                    		list-style-type: square;
	                    	}
                        }
                        
                        /* strong {
                            font-weight: 600;
                        } */
                    }
                }
            }
        }
    }

    .bloc_question {
        margin: 0 0 8rem;
    }

    /* .faq-retour-cat {
        margin: 0 0 1rem;
        a {
            font-size: 11 / 16 * 1rem;
            line-height: 1;
            color: $secondaryColor;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 1px;

            span.arrow-left {
                display: inline-block;
                width: 4px;
                height: 8px;
                margin-right: 1.2em;
                background: url("../img/icons/arrow_l.png") 50% 50% no-repeat;
            }
        }
    } */
    
}