.giftcard_index {

    #breadcrumbs {
        display: none;
    }

    .main_wrapper {
        background: url('../img/ecadeau.jpg') no-repeat;
        background-size: contain;
        width: 100%;

        

        .pageGiftCard {
            text-align: center;
            background: $white;
            height: 850px;

            border-bottom-left-radius: 100% 100%;
            border-bottom-right-radius: 100% 100%;
            padding-top: 60px;
            width: 972px;
            max-width: 95%;
            text-align: center;
            margin: 80px auto 150px;
            display: flex;
            flex-direction: column;

            
            @media only screen and (max-width: 960px) {
                padding: 60px 2rem 2rem;
            }

            .pageGiftCard__header {
                order: 1;

                .headerTitles {
                    display: none;
                    &.is-hidden {
                        display: none;
                    }

                    &.is-shown {
                        display: block;
                    }

                    h1, .h1Style {
                        font-family: $secondaryFont;
                        font-size: 65px;
                        margin-bottom: 1.5rem;

                        @media only screen and (max-width: 1680px) {
                                font-size: 60px;
                        }
                        @media only screen and (max-width: 1520px) {
                            font-size: 55px;
                        }
                        @media only screen and (max-width: 1370px) {
                            font-size: 50px;
                        }
                    }

                    h2, .h2Style {
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        font-size: 1rem;

                        @media only screen and (max-width: 1680px) {
                            font-size: .9rem;
                        }

                        @media only screen and (max-width: 1370px) {
                            font-size: .85rem;
                        }
                    }
                }

                #showAmount {
                    display: none;

                    button.showAmountBtn {
                        width: 350px;
                        height: 50px;
                        margin: 2rem auto 0;

                        &.is-hidden {
                            display: none;
                        }

                    }
                }
            }

            .pageGiftCard__main {

                &.is-hidden {
                    display: none;
                }

                &.pageGiftCard__main--amountinfos {

                    h3, .h3Style {
                        display: none;
                    }

                    h2, .h2Style {
                        font-family: $secondaryFont;
                        font-size: 65px;
                        margin-bottom: 1.4rem;
                        font-weight: bold;

                        @media only screen and (max-width: 1680px) {
                            font-size: 60px;
                        }
                        @media only screen and (max-width: 1520px) {
                            font-size: 55px;
                        }

                        @media only screen and (max-width: 1370px) {
                            font-size: 50px;
                        }
                    }

                    small {
                        font-size: .8rem;

                        @include ie() {
                            & +.btn_container {
                                display: none !important;
                            }
                        }
                    }

                    #showAmount.btn_container {
                        margin: 1.8rem auto 80px;
                        display: block !important;

                        .is-shown {
                            display: block !important;
                        }

                        .button {
                            background: $black;
                            color: $white;
                            border: none;
                            width: 250px;
                            height: 50px;
                            margin: 0 auto;
                        }

                        &.return .button {
                            width: 120px;
                            background: $white;
                            color: $black;
                            border: 1px solid $black;
                        }
                    }

                    form#formGiftCard {
                        width: 500px;
                        margin: 1.8rem auto 0;

                        p.giftcard_desc {
                            display: none;
                        }

                        .w-form-line {
                            position: relative;

                            .w-input-container {

                                #cardCode {
                                    height: 50px;
                                    padding-top: 0;
                                    background: transparent;
                                }

                                p.w-input-label {
                                    display: none;
                                }
                            }

                            .w-submit {
                                position: absolute;
                                right: 3%;
                                top: 0;
                                width: auto;

                                button.w-submit-button {
                                    background: transparent url('../img/search-arrow.png') center right no-repeat;
                                    width: 70px;
                                    height: 50px;
                                    border: none;

                                    span {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .giftcard_amount {
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            font-size: .95rem;

                            strong {
                                font-weight: 600;
                            }
                        }
                    }
                }

                &.pageGiftCard__main--infos {
                    order: 2;

                    &.is_hidden {
                        display: none;
                    }

                    #showAmount {
                        display: block !important;
                    }
                }



                .pageGiftCard__bloc {
                    margin: .7rem auto 1rem;

                    h3, .h3Style {
                        font-family: $secondaryFont;
                        font-size: 4rem;
                        margin-bottom: 1.4rem;
                        font-weight: bold;
                        display: none;
                    }

                    h1, .h1Style {
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        font-size: 1rem;
                        margin-bottom: .5rem;
                        display: none;

                        @media only screen and (max-width: 1680px) {
                            font-size: .9rem;
                        }

                        @media only screen and (max-width: 1370px) {
                            font-size: .85rem;
                        }
                    }

                    small {
                        font-size: .8rem;
                    }

                    ul.infosList {
                        display: flex;
                        margin: 5rem auto 3rem;
                        justify-content: space-between;
                        max-width: 750px;

                        li {
                            max-width: 25%;
                            h2, .infosListHn {
                                font-size: .8rem;
                                font-weight: normal;
                                position: relative;
                                max-width: 207px;
                                line-height: 1.5;
                                letter-spacing: 1px;

                                &:before {
                                    top: -3rem;
                                    left: 40%;
                                    content: "";
                                    position: absolute;
                                    background: url('../img/ecadeau_circle.jpg') no-repeat;
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                    }

                    #openOrderGiftCard {
                        max-width: 175px;
                        margin: 0 auto 1rem;
                    }

                    p.giftcard_notice {
                        display: block !important;
                        font-size: .65rem;
                        letter-spacing: 1px;
                        line-height: 2;
                        margin: .7rem auto;

                        &:nth-of-type(2) {
                            margin-top: 0;
                        }
                    }

                    a.cgvLink {
                        font-size: .8rem;
                        text-decoration: underline;
                        letter-spacing: 1px;
                        display: block;
                    }
                }

                .giftCard_btn {
                    margin: 0 auto;

                    #openOrderGiftCard {
                        max-width: 175px;
                        margin: 0 auto;
                    }

                    .giftcard_question {
                        display: block !important;
                        text-transform: uppercase;
                        font-weight: 600;
                        margin: 4.5rem auto 0;
                        font-size: 1rem;
                        letter-spacing: 2px;

                        @media only screen and (max-width: 1680px) {
                            font-size: .9rem;
                        }

                        @media only screen and (max-width: 1370px) {
                            font-size: .85rem;
                        }
                    }

                    #showAmount .showAmountBtn {
                        margin: 2rem auto 0;
                        width: 350px;
                        background-color: #FFF;
                        color: #000;
                    }
                }
            }

            .pageGiftCard__asideImg {
                display: none;
            }
        }

        /* Lightbox Order Gift Card */
        #lightboxOrderGiftCard {
            width: 900px;
            height: 540px;
            background: $secondaryColor;
            padding: 60px 80px;

            @media screen and (max-width: 960px) {
                position: absolute;
                left: 50%;
                top: 50%;
                right: auto;
                bottom: auto;
            }

            @media screen and (max-width: 920px) {
                width: 94vw;
            }

            &.en {
                form#orderGiftCardForm #step1 .giftDetails {
                    background: url('../img/carte_cadeau/giftcard_en.jpg') no-repeat right center;
                    background-size: contain;
                }
            }


            .close {
                position: absolute;
                top: 1.5rem;
                right: 1.5rem;
                background: url('../img/close.png') no-repeat;
                width: 11px;
                height: 11px;
                cursor: pointer;
            }

            form#orderGiftCardForm {

                .btn_container {
                    margin: 2rem auto;

                    button {
                        width: 160px;
                        height: 50px;
                        margin: auto;

                        &:hover {
                            color: $black;
                        }

                        &.button.dark:hover {
                            color: $white;
                        }
                    }
                }

                #step1 {
                    padding: 0 95px;

                    @media screen and (max-width: 920px) {
                        max-width: 570px;
                        margin: 0 auto;
                        padding: 0;
                    }

                    .giftDetails {
                        background: url('../img/carte_cadeau/giftcard.jpg') no-repeat right center;
                        background-size: contain;
                        height: 295px;

                        .btn_container {
                            margin: 33% auto 0;
                        }
                    }

                    #sendMethods {
                        display: none;
                    }

                    h2, .h2Style {
                        font-family: $secondaryFont;
                        font-weight: bold;
                        display: none;
                    }

                    #chooseBalance {

                        label.selectBalanceLabel {
                            font-family: $secondaryFont;
                            text-transform: none;
                            font-size: 2.3rem;
                            font-weight: bold;
                            max-width: 60%;
                            display: block;

                            @media only screen and (max-width: 1680px) {
                                font-size: 1.875rem;
                            }

                            @media only screen and (max-width: 1370px) {
                                font-size: 1.65rem;
                            }
                        }

                        input#selectBalance {
                            border: 1px solid $fadeTxt;
                            width: 300px;
                            height: 50px;
                            margin: 2rem 0 .5rem;
                            padding-left: .5rem;
                            background: transparent;
                            -moz-appearance: textfield;

                            &.inputErr {
                                border: 1px solid $redStock;
                            }

                            &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                margin: 0;
                            }

                            &::placeholder {
                                font-style: normal;
                            }
                        }

                        span.min_amount_gift {
                            display: block !important;
                            color: $fadeTxt;
                            font-weight: 600;
                            font-style: italic;
                            letter-spacing: 1px;
                            font-size: .7rem;
                        }
                    }
                }

                #step2 {
                    display: none;

                    .h3Style {
                        margin-top: 1rem;
                    }

                    .giftDetails {
                        text-align: center;

                        .h2Style {
                            display: block !important;
                            font-family: $secondaryFont;
                            text-transform: none;
                            font-size: 2.3rem;
                            font-weight: bold;
                            margin-bottom: 1.5rem;

                            @media only screen and (max-width: 1680px) {
                                font-size: 1.875rem;
                            }

                            @media only screen and (max-width: 1370px) {
                                font-size: 1.65rem;
                            }

                            &:first-child() {
                                display: none !important;
                            }
                        }

                        h3, .h3Style {
                            font-weight: 500;
                            color: $darkTxt;
                        }

                        .formPerso {
                            display: flex;
                            justify-content: space-between;
                            margin: .5rem 0 1.5rem;

                            input {
                                border: 1px solid $fadeTxt;
                                width: 48%;
                                max-width: 354px;
                                height: 50px;
                                margin-top: 1rem;
                                padding-left: .5rem;
                                background: transparent;

                                &::placeholder {
                                    font-style: normal;
                                }
                            }
                        }

                        .formText textarea {
                            height: 125px;
                            resize: none;
                            border: 1px solid $fadeTxt;
                            background: transparent;
                            overflow: auto;
                            font-size: .9rem;

                            &::placeholder {
                                font-style: normal;
                            }
                        }
                    }

                    .btn_container {
                        display: flex;
                        justify-content: space-between;
                        max-width: 345px;

                        button {
                            background: transparent;

                            &.button.dark {
                                background: $black;

                                &:hover {
                                    color: $white;
                                }
                            }

                            &:hover {
                                color: $black;
                            }
                        }
                    }

                }

                #step3 {
                    display: none;

                    .giftDetails {
                        text-align: center;

                        .infos {

                            .h2Style {
                                display: block !important;
                                font-family: $secondaryFont;
                                text-transform: none;
                                font-size: 2.3rem;
                                font-weight: bold;

                                @media only screen and (max-width: 1680px) {
                                    font-size: 1.875rem;
                                }

                                @media only screen and (max-width: 1370px) {
                                    font-size: 1.65rem;
                                }
                            }

                            ul {

                                &.stepsList {
                                    display: flex;
                                    justify-content: space-around;
                                    margin: 6rem 0 2.5rem;

                                    li {
                                        position: relative;
                                        max-width: 25%;

                                        p {
                                            position: absolute;
                                            background: $tertiaryColor;
                                            border-radius: 50%;
                                            color: $white;
                                            width: 30px;
                                            min-height: 30px;
                                            top: -3rem;
                                            left: 50%;
                                            transform: translateX(-50%);
                                            font-size: 1.2rem;
                                            font-family: $secondaryFont;
                                            line-height: 30px;
                                            text-align: center;

                                            @media only screen and (max-width: 1680px) {
                                                font-size: 1rem;
                                            }

                                            @media only screen and (max-width: 1370px) {
                                                font-size: .9rem;
                                            }
                                        }

                                        h3, .h3Style {
                                            font-weight: normal;
                                            font-size: .77rem;
                                            letter-spacing: 1px;
                                            line-height: 1.5;
                                            font-weight: 500;
                                            background: none;
                                            width: 100%;
                                            position: initial;
                                            text-align: center;
                                            transform: none;
                                            font-family: $primaryFont;
                                            font-size: .75rem;
                                            color: #676767;
                                        }

                                        h4, .h4Style {
                                            display: none;
                                            font-weight: bold;
                                        }
                                    }
                                }

                                &.resumeList {
                                    text-align: left;

                                    li {
                                        display: flex;
                                        justify-content: space-between;
                                        margin-bottom: .8rem;
                                        h3.item, .h3Style.item {
                                            text-transform: uppercase;
                                            font-weight: 600;
                                            width: 25%;
                                            letter-spacing: 1px;
                                        }
                                        .h3Style{
                                            width: 23%;
                                            font-weight: 600;
                                            text-transform: uppercase;
                                            letter-spacing: 2.1px;
                                            font-size: 0.73rem;
                                            font-family: $primaryFont;
                                            & + .h3Style{
                                                font-size: 0.75rem;
                                                display: none;
                                                & + p{
                                                    width: 64.5%;
                                                }
                                            }
                                        }

                                        p {
                                            width: 65%;
                                        }

                                        small {
                                            CURSOR: POINTER;
                                            font-size: .8rem;
                                            letter-spacing: 1px;
                                            font-weight: 500;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }

                        .btn_container {
                            display: flex;
                            justify-content: space-between;
                            max-width: 487px;

                            button {
                                background: transparent;
                                width: 220px;

                                &.button.dark {
                                    background: $black;
                                    margin: 0 1.45rem;
                                    &:hover {
                                        color: $white;
                                    }
                                }


                            }
                        }
                    }

                }
            }

        }
    }
}

//Affichage de la date d'expiration
body.giftcard_index{

    &.en{
        .w-giftcard-form .giftcard_validity_en{
            display: block !important;
            margin-top: 1rem;
            letter-spacing: 1px;
        }
    }

    &.fr{
        .w-giftcard-form .giftcard_validity_fr{
            display: block !important;
            margin-top: 1rem;
            letter-spacing: 1px;
        }
    }
}