body.contact_faq {
    #breadcrumbs {
        display: none;
    }

    textarea {
        overflow: hidden;
    }

    .w-radio-input {
        .w-input-element {
            + .w-input-label { 
                &::before {
                    box-shadow: none;
                    background: none;
                }

                &::after {
                    width: 13px;
                    height: 13px;
                }
            }

            &:checked {
                + .w-input-label {
                    &::after {
                        transform: scale(1);
                    }
                }
            }
        }
    }

    .contactForm {
        position: relative;
        padding: 5rem 0 8.5rem;

        #block_img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .contactForm_wrap {
            width: 975px;
            min-height: 849px;
            max-width: 95%;
            margin: 0 auto;
            background-color: $white;
            padding-top: 5rem;
            border-bottom-left-radius: 100% 100%;
            border-bottom-right-radius: 100% 100%;

            .titre_contact {
                font-family: $secondaryFont;
                font-size: 65px;
                line-height: 1.3;
                text-align: center;

                @media only screen and (max-width: 1680px) {
                    font-size: 60px;
                }
                @media only screen and (max-width: 1520px) {
                    font-size: 55px;
                }

                @media only screen and (max-width: 1370px) {
                    font-size: 50px;
                }

            }

            .sous_text {
                font-family: $primaryFont;
                font-size: 13 / 16 * 1rem;
                line-height: 1.3;
                text-align: center;
                margin-bottom: 1.5rem;

                a {
                    text-decoration: underline;
                }
            }
        }
    }

    .wrapperContentFaqForm {

        .wrap_content {
            width: 530px;
            max-width: 100%;
            margin: 0 auto;
        }

        .nos-adresses {
            display: none;
        }

        .champs_requis {
            font-family: 'Arial', sans-serif;
            font-size: 12 / 16 * 1rem;
            line-height: 1.3;
            color: #989898;
            font-style: italic;
            margin-left: .8rem;
        }
    }

    #form_contact1 {
        a {
            span {
                text-decoration: underline;
            }
        }

        .w-form-line {
            &#divContactNumCommande {
                display: none;
            }

            &:nth-of-type(5) {
                margin-bottom: .2rem;
                &.w-has-error {
                    margin-bottom: 1.3rem;
                }
            }
        }

        .file-input-row,
        .file-list {
            display: none;
        }

        .w-fieldset-label {
            font-family: $primaryFont;
            font-size: 13 / 16 * 1rem;
            line-height: 1.3;
            color: $primaryColor;
            margin: 0 auto 1rem;
        }

        .w-lines-group {
            margin-top: 1rem;

            &:after { 
                content: "."; 
                visibility: hidden; 
                display: block; 
                height: 0; 
                clear: both;
            }

            .w-form-line {
                width: 50%;
                padding-left: 2rem;
                float: left;

                [lang="en"] & {
                    padding-left: 2.8rem;
                }
            }

            .w-line-label,
            .w-radio-group .w-input-label {
                font-family: $primaryFont;
                font-size: 13 / 16 * 1rem;
                line-height: 1;
                color: $primaryColor;
            }

            .w-input-note {
                text-align: center;
            }

            .optinLightboxContact{
                display: none;
            }
        }

        .w-form-line.w-submit {
            max-width: 226px;
            margin: 0 auto;

            button.w-submit-button {
                height: 48px;
            }
        }

        .w-dropdown {
            height: 48px;

            &:after {
                border-top-color: #989898;
                border-left-width: .45em;
                border-right-width: .45em;
                border-top-width: .5em;
            }
        }
    }

    #recaptcha {
        justify-content: center;
    }
}