.publication_index {
    box-sizing: border-box;

    img {
        width: 100%;
        height: auto;
    }

    #site_global_wrap{
        .publication_page{
            padding-bottom: 8em;

            .title {
                font-family: $secondaryFont;
                font-size: 65px;
                letter-spacing: -.025em;
                color: $darkColor;
                text-align: center;
                line-height: 4.6875rem;
                margin-bottom: 1.5rem;
                margin-top: 2rem;

                &::first-letter {
                    text-transform: capitalize;
                }

                @media only screen and (max-width: 1680px) {
                    font-size: 60px;
                }

                @media only screen and (max-width: 1520px) {
                    font-size: 55px;
                }

                @media only screen and (max-width: 1370px) {
                    font-size: 50px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            .mes_publications {
                h1.title{
                    font-size: 66px;
                }
                .wrapper_menu_satellite {
                    margin-bottom: 2.6875rem;
                    nav {
                        width: 100%;
                        margin: auto;

                        ul {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            li {
                                display: inline-block;
                                margin-right: 1.875rem;

                                a {
                                    span {
                                        font-family: $primaryFont;
                                        font-size: 0.8125rem;
                                        letter-spacing: 0.05em;
                                        color: $darkColor;
                                        display: inline-block;
                                        padding-bottom: 1px;
                                        border-bottom: 1px solid transparent;
                                        transition: all ease-in-out .5s;

                                        &:first-letter {
                                            text-transform: uppercase;
                                        }
                                    }

                                    &.active, &:hover {
                                        span {
                                            font-weight: 600;
                                            border-color: $darkColor;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .wrapper_satellite_main {
                    width: 100%;

                    .columns_container {
                        position: relative;

                        .columns {
                            width: 100%;
                            max-width: 1680px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            position: relative;
                            margin: auto;

                            @media only screen and (max-width: 1720px) {
                                padding: 0 20px;
                            }

                            &:after {
                                content: "";
                                flex: auto;
                            }

                            .article {

                                width: 507px;
                                max-width: 30.1785%;
                                padding: 4.0625rem 0;
                                margin-right: 4%;
                                position: relative;
                                z-index: 1;

                                @media only screen and (max-width: 1370px) {
                                    padding: 0 0 4.0625rem 0;
                                }

                                &:nth-child(3n){
                                    margin-right: 0%;
                                }

                                img {
                                    display: inline-block;
                                    margin-bottom: 0.975rem;
                                }

                                .txt_publication {
                                    h2.article_title, .article_categ {
                                        font-size: 1.8rem;
                                        letter-spacing: -.025em;
                                        color: $darkColor;
                                        font-family: $secondaryFont;
                                        margin-bottom: 1.2rem;
                                        font-weight: 600;

                                        @media only screen and (max-width: 1680px) {
                                            font-size: 1.75rem;
                                        }

                                        @media only screen and (max-width: 1370px) {
                                            font-size: 1.5rem;
                                        }

                                        &::first-letter {
                                            text-transform: capitalize;
                                        }
                                    }

                                    p,
                                    div.article_title {
                                        font-family: $primaryFont;
                                        font-size: .8125rem;
                                        letter-spacing: .05em;
                                        line-height: 21px;
                                        text-align: justify;

                                        &.cta {
                                            font-size: 14px;
                                            letter-spacing: .05em;
                                            position: relative;
                                            padding-right: 90px;
                                            float: left;
                                            margin-top: 20px;

                                            &:before {
                                                content: '';
                                                width: 70px;
                                                background: url(../svg/arrow_btn_black.svg) center center no-repeat;
                                                background-size: contain;
                                                position: absolute;
                                                height: 9px;
                                                top: 0;
                                                bottom: 0;
                                                margin: auto;
                                                right: 0;
                                            }
                                        }
                                    }

                                    div.article_title {
                                        opacity: 0;
                                    }

                                    .cta_link {
                                        font-size: 14px;
                                        letter-spacing: .05em;
                                        position: relative;
                                        padding-right: 90px;
                                        float: left;
                                        margin-top: 20px;

                                        &:before {
                                            content: '';
                                            width: 70px;
                                            background: url(../svg/arrow_btn_black.svg) center center no-repeat;
                                            background-size: contain;
                                            position: absolute;
                                            height: 9px;
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;
                                            right: 0;
                                        }
                                    }
                                    .article_subtitle.cta{
                                        margin-top: 18px;
                                    }
                                }
                            }
                        }

                         &:nth-of-type(odd) {
                            &:before {
                                content:'';
                                position: absolute;
                                width: 100%;
                                height: 59.5%;
                                background-color: #FFF;
                                z-index: 0;
                                bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    
}

.publication_details  {
    .page_wrapper {
        padding-bottom: 12rem;

        .publication_page {

            padding-bottom: 4rem;

            .in_publication {
                .title {
                    font-family: $secondaryFont;
                    font-size: 4.0625rem;
                    letter-spacing: -.025em;
                    color: $darkColor;
                    text-align: center;
                    line-height: 4.6875rem;
                    margin-bottom: 1.5rem;
                    margin-top: 2rem;
                    min-height: 150px;

                    &::first-letter {
                        text-transform: capitalize;
                    }

                    @media only screen and (max-width: 1370px) {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }

                .wrapper_img.full {
                    width: 100%;
                    max-width: 1280px;
                    margin: 0 auto 110px auto;
                    position: relative;
                    z-index: 1;

                    @media only screen and (max-width: 1320px) {
                        padding: 0 20px;
                    }

                    img {
                        display: inline-block;
                    }
                }

                .publication_detail {
                    position: relative;
                    width: 100%;
                    padding-top: 8.125rem;
                    padding-bottom: 8.125rem;
                    margin-bottom: 100px;
                    background-color: $white;

                    @media only screen and (max-width: 1370px) {
                        padding-top: 2rem;
                        padding-bottom: 2rem;
                    }

                    .contents_1 {
                        width: 100%;
                        max-width: 1280px;
                        margin: 0 auto;
                        position: relative;
                        z-index: 1;

                        @media only screen and (max-width: 1320px) {
                            padding: 0 20px;
                            margin: 0 auto;
                        }

                        h2 {
                            font-family: $secondaryFont;
                            font-size: 1.875rem;
                            letter-spacing: -.025em;
                            color: $darkColor;
                            text-align: center;
                            margin-bottom: 1.5625rem;
                        }

                        p,
                        .txt {
                            font-family: $primaryFont;
                            font-size: .8125rem;
                            letter-spacing: .05em;
                            line-height: 21px;
                            text-align: left;
                            margin-bottom: 1.25rem;
                        }

                        .wrapper_img_container {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            width: 100%;

                            .wrapper_img {
                                width: 100%;
                                margin: 5.5rem 0;
                                box-sizing: border-box;

                                @media only screen and (max-width: 1370px) {
                                    margin: 5rem 0;
                                }

                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }

                            .bloc_video{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 0;
                                width: 100%;
                                padding-bottom: calc((1080 / 1920) * 100%);
                                position: relative;
                                margin-top: 5rem;

                                iframe{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

                .link_publication_article {

                    @media only screen and (max-width: 1680px) {
                        padding: 0 23px;
                    }

                    h3 {
                        font-family: $secondaryFont;
                        font-size: 1.875rem;
                        letter-spacing: -.025em;
                        color: $darkColor;
                        text-align: center;
                        margin-bottom: 1.5625rem; 
                    }

                    .wrap_pics {
                        width: 1680px;
                        max-width: 100%;
                        margin: auto;
                        display: flex;
                        justify-content: flex-start;

                        a {
                            width: 30.178%;
                            max-width: 507px;

                            &:nth-child(2){
                                margin: 0 3.2%;
                            }

                            .title_publication_article {
                                .title {
                                    font-family: $secondaryFont;
                                    font-weight: 600;
                                    font-size: 1.875rem;
                                    letter-spacing: -.025em;
                                    line-height: 1;
                                    color: $darkColor;
                                    text-align: left;
                                    margin: 1.5625rem 0; 
                                    min-height: 0;
                                }

                                .descrip {
                                    font-family: $primaryFont;
                                    font-size: 0.8125rem;
                                    letter-spacing: 0.05em;
                                    line-height: 21px;
                                    color: $darkColor;
                                    text-align: justify;
                                }

                                .cta_link {
                                    font-size: 14px;
                                    letter-spacing: .05em;
                                    position: relative;
                                    padding-right: 90px;
                                    float: left;
                                    margin-top: 20px;

                                    &:before {
                                        content: '';
                                        width: 70px;
                                        background: url(../svg/arrow_btn_black.svg) center center no-repeat;
                                        background-size: contain;
                                        position: absolute;
                                        height: 9px;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto;
                                        right: 0;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}