/*Extenders Rayons*/
.textStyles{
	font-family: $primaryFont;
	font-weight: 400;
	font-size: 13px;
	letter-spacing: 0.65px;
	color: $primaryColor;
	text-decoration: none;

	@media screen and (max-width: 1580px) {
		font-size: 11.5px;
		letter-spacing: 0.55px;
	}
}

.arrowB{
	background: url('../svg/arrow.svg');
	background-repeat: no-repeat;
	background-size: 100%;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		background-size: 175%;
	}
}
/*End Extenders*/

.top_bar{
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1680px;
	margin: 32px auto 42px;

	#breadcrumbs{
		width: auto;
		max-width: none;
		margin: 0;
	}

	.go_back{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;

		@media screen and (max-width: 1720px) {
			padding: 0 0 0 32px;
		}

		&:before{
			content: " ";
			display: block;
			background: url('../img/ariane_ar.png') center center no-repeat;
			width: 10px;
			height: 5px;
			transform: rotate(180deg);
		}

		span{
			@extend .textStyles;
			margin-left: 10px;
		}
	}

	.wrap_actions{
		display: flex;
		justify-content: flex-end;

		@media screen and (max-width: 1720px) {
			padding: 0 32px 0 0;
		}

		#viewChanger{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 50px;

			span{
				@extend .textStyles;
				text-decoration: none;
				cursor: pointer;

				&.actif{
					cursor: default;
					text-decoration: underline;
					text-underline-offset: 2px;
				}
			}

			.sep{
				display: block;
				background: $primaryColor;
				width: 1px;
				height: 10px;
				margin: 0 8px;
			}
		}

		#trigger_filtre{
			display: flex;
			align-items: center;
			justify-content: center;
			@extend .textStyles;
			cursor: pointer;
			
			&:after{
				content: " ";
				display: block;
				@extend .arrowB;
				background-position: 0 20%;
				width: 70px;
				height: 8px;
				margin-left: 18px;
					
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					background-position: 50% 20%;
					background-size: 600%;
				}
			}
		}
	}
}

#rayon_sticky_block{
	display: none;
	background: #fbf4ec;
	width: 100%;
	height: auto;
	position: fixed;
	top: 113px;
	left: 0;
	z-index: 10;
	padding: 80px 32px;

	@media screen and (max-width: 1370px) {
		top: 93px;
	}

	@media screen and (max-width: 1024px) {
		top: 132px;
	}

	&.reposition{
		top: 80px;

		@media screen and (max-width: 1370px) {
			top: 60px;
		}

		@media screen and (max-width: 1024px) {
			top: 101px;
		}
	}

	@media only screen and (max-width: 850px) {
		&.reposition_resp{
			.filter_innerwrap{
				form{
					.filter_wrapper{
						&:not(:last-child){
							margin-right: 2.948vw;
						}
					}
				}

				.wrap_filter_action{
					margin-top: 30px;
				}
			}
		}
	}

	.filter_innerwrap{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: 1190px;
		margin: 0 auto;

		form{
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;
			position: relative;

			.close{
				display: flex;
				align-items: center;
				justify-content: center;
				background: url('../img/close.png') center center no-repeat;
				width: 12px;
				height: 12px;
				cursor: pointer;
				position: absolute;
				right: 0;
				top: -40px;
			}

			.filter_wrapper{
				&:not(:last-child){
					margin-right: 4.948vw;
				}

				.filter_name{
					font-family: $secondaryFont;
					font-weight: 600;
					font-size: 25px;
					letter-spacing: 0.4px;
					margin-bottom: 24px;
				}

				&#filter_trier,
				&#filter_caract{
					.selected_options{
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: flex-start;

						.tri_price{
							@extend .textStyles;
							font-weight: 500;

							&:not(:last-of-type){
								margin-bottom: 8px;
							}

							&.actif{
								font-weight: 600;
								text-decoration: underline;
							}
						}
						
						.form_itm{
							&.charact{
								&:not(:last-of-type){
									margin-bottom: 8px;
								}
							}
						}

						/*Start recherche + Charact*/
						label{
							margin-right: 0;
							@extend .textStyles;

							&:not(:last-of-type){
								margin-bottom: 8px;
							}
						}

						[type=radio]:checked,
						[type=radio]:not(:checked),
						[type=checkbox]:checked,
						[type=checkbox]:not(:checked){
							& + label{
								&:before,
								&:after{
									display: none;
								}
							}
						}
						
						[type=radio]:checked,
						[type=checkbox]:checked{
							& + label{
								font-weight: 600;
								text-decoration: underline;
							}
						}
						/*End recherche*/
					}
				}

				&#filter_sizes,
				&#filter_color{
					.selected_options{
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;
						flex-wrap: wrap;
						width: 100%;
						max-width: 282px;

						.form_itm{
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 7px;
							margin-bottom: 8px;

							&:nth-of-type(6n){
								margin-right: 0;
							}

							[type=checkbox]:checked,
							[type=checkbox]:not(:checked){
								& + label{
									display: flex;
									align-items: center;
									justify-content: center;
									font-weight: 500;
									width: 41px;
									height: 41px;
									border-radius: 50%;
									border: solid 1px transparent;
									margin: 0;
									transition: all 0.4s ease-in-out;

									&:before,
									&:after{
										display: none;
										margin: 0;
									}
								}
							}

							[type=checkbox]:checked{
								& + label{
									border: solid 1px #9a9a9a;
								}
							}
						}
					}
				}

				&#filter_color{
					.selected_options{
						.form_itm{
							[type=checkbox]:checked,
							[type=checkbox]:not(:checked){
								& + label{
									span{
										background-size: contain;
										width: 16px;
										height: 16px;
										border-radius: 50%;
									}
									
									&[title="Blanc"],
									&[title="BLANC"],
									&[title="White"],
									&[title="WHITE"],
									&[title="Ecru"],
									&[title="ECRU"],
									&[title="Nude"],
									&[title="NUDE"]{
										span{
											border: solid 1px #dddddd;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	
		.wrap_filter_action{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			margin-top: 80px;
	
			.form_submit{
				max-width: 250px;
				height: 50px;
	
				&:not(:last-child){
					margin-right: 32px;
				}
			}
		}
	}

	&.show{
		display: flex;
	}
}

/*SINCE header is no more sticky on scroll rayons*/
#site_head_wrap{
	&.sticky{
		& + .main_wrapper{
			#rayon_sticky_block{
				top: 0;

				/*@media screen and (max-width: 1360px) {
					top: 101px!important;
				}*/
			}
		}
	}
}

.wrap_redirects{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	&.no_result{
		margin-top: 2.4rem;
	}

	.title{
		font-family: $secondaryFont;
		font-weight: 600;
		font-size: 65px;
		line-height: 65px;
		letter-spacing: 1.625px;
		margin-bottom: 26px;

		@media only screen and (max-width: 1680px) {
            font-size: 60px;
        }

        @media only screen and (max-width: 1370px) {
            font-size: 50px;
        }
	}

	/*Start recherche*/
	.search_recap{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 44.063vw;
		margin: 70px auto 45px;
		border-bottom: solid 1px #000000;
		position: relative;

		@media screen and (max-width: 1360px) {
			margin: 88px auto 45px;
		}

		.recap{
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
			width: 100%;
			position: relative;
			padding-bottom: 16px;

			@media screen and (max-width: 1370px) {
				padding-bottom: 4px;
			}

			.search_term{
				font-family: $secondaryFont;
				font-weight: 600;
				font-size: 2.604vw;
				line-height: 1.563vw;
				letter-spacing: 0.065vw;

				&:first-letter{
					text-transform: uppercase;
				}
			}

			.nbr_result{
				@extend .textStyles;
				font-size: 18px;
				line-height: 18px;
				letter-spacing: 1.8px;
				margin-left: 7px;
			}
		}

		.reset{
			display: flex;
			align-items: center;
			justify-content: center;
			background: url('../img/close_search.png') center center no-repeat;
			width: 16px;
			height: 16px;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -15px;
			cursor: pointer;
		}

	}
	/*End recherche*/

	.bloc_sub_categ{
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% - 64px);

		.sub_categ,
		.bloc_itm .itm_name{
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
	
			&:not(:last-child){
				margin-right: 33px;
			}

			h3 {
				@extend .textStyles;
				cursor: pointer;
			}

			.sub_cat_name{
				@extend .textStyles;
				cursor: pointer;

				h2{
					@extend .textStyles;
					cursor: pointer;
				}
	
				&.left_menu_select{
					font-weight: 600;
					text-decoration: underline;
					text-underline-offset: 2px;
					cursor: default;

					h2,
					h3{
						font-weight: 600;
						text-decoration: underline;
						text-underline-offset: 2px;
						cursor: default; 
					}
				}
			}

			&.btn_select,
			&:hover{
				font-weight: 600;
				text-decoration: underline;
				text-underline-offset: 2px;

				h2,
				h3,
				.item{
					font-weight: 600;
					text-decoration: underline;
					text-underline-offset: 2px;
				}
			}

			&.btn_select{
				cursor: default;

				h2,
				h3,
				.item{
					cursor: default;
				}
			}
		}

		
		.bloc_itm{
			display: flex;
			align-items: center;
			justify-content: center;

			/*Start recherche*/
			&.in_search{
				display: flex;

				.itm_name{
					@extend .textStyles;
					cursor: pointer;
	
					&:not(:last-child){
						margin-right: 33px;
					}

					[type=checkbox]:checked{
						& + .item{
							font-weight: 600;
							text-decoration: underline;
							text-underline-offset: 2px;
							cursor: default;

							h2,
							h3{
								font-weight: 600;
								cursor: default;
							}
						}
					}
				}
			}
			/*End recherche*/
		}
	}

	& + .item{
		&.push{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			max-width: 1280px;
			margin: 40px auto 32px;

			.block_push{
				display: flex;
			}
		}
	}
}

#list_item{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin: 27px auto;

	@media screen and (max-width: 1720px) {
		padding: 0 32px;
	}

	.item{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-bottom: 5.313vw;

		.imgWrapper{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			position: relative;

			.swiper-slide-off,
			.swiper-slide-on{
				width: 100%;
				height: 100%;

				& > div{
					width: 100%;
					height: 100%;
				}
			}
			
			.block_lnk,
			.block_push,
			.wrap_video{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 0;
				position: relative;

				.imgProd,
				iframe{
					background-color: $bgColor;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
				}
			}

			.productSwiper,
			.productVisualMulti{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				/*Recheche w-swiper*/
				&.w-swiper-container{
					margin: 0 auto;
					position: relative;
					overflow: hidden;
					list-style: none;
					padding: 0;
					z-index: 1;

					.w-swiper-wrapper{
						display: flex;
						width: 100%;
						height: 100%;
						position: relative;
						z-index: 1;
						scroll-snap-type: x mandatory;
						overflow-x: scroll;
						overflow-y: hidden;
						scrollbar-width: none;
						-ms-overflow-style: none;

						&::-webkit-scrollbar {
						display: none;
						}

						.w-swiper-slide{
							flex-shrink: 0;
							width: 100%;
							height: 100%;
							position: relative;
						}

						div{
							scroll-snap-align: center;

							/*.imgProd{
								left: unset!important;
								height: unset!important;
								margin-top: 100%!important;
								position:unset!important;
							}*/
						}
					}
				}
				/*End recheche w-swiper*/

				.rollover_rayonsNext,
				.rollover_rayonsPrev{
					display: flex;
					align-items: center;
					justify-content: center;
					@extend .arrowB;
					background-position: 0% -5%;
					width: 3.646vw;
					height: 1.563vw;
					position: absolute;
					top: 50%;
					margin-top: -0.781vw;
					z-index: 3;
					cursor: default;
					opacity: 0;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					transition: all 0.4s ease-in-out;
					
					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						background-position: 50% -5%;
					}

					&:not(.swiper-button-disabled){
						cursor: pointer;
					}
				}

				.rollover_rayonsPrev{
					left: 16px;
					transform: rotate(180deg);
				}

				.rollover_rayonsNext{
					right: 16px;
				}
		
				&.no_stock{
					.swiper-wrapper{
						opacity: 0.3;
					}
				}
			}
		}

		.bloc_descrip{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;
			margin-top: 16px;
			position: relative;

			.block_lnk{
				width: calc(100% - 200px);

				.wrapper_description{
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;

					.item_des{
						display: inline-block;
						text-transform: lowercase;

						&:first-letter{
							text-transform: uppercase;
						}
					}

					.item_title{
						@extend .textStyles;
						width: 97%;
					}

					.item_price{
						margin-top: 7px;

						.pricetag{
							@media screen and (max-width: 1580px) {
								font-size: 15px;
								letter-spacing: 0.75px;
							}

							& + .pricetag{
								@media screen and (max-width: 1580px) {
									font-size: 13px;
									letter-spacing: 0.65px;
								}
							}
						}
					}
				}
			}

			/*Style achat express v2 starts*/
			.wrap_rolloverproduit{
				width: 100%;

				form{
					width: 100%;

					.rollover_left{
						&.achat_express{
							.productColorFieldset,
							.productSizeFieldset{
								.prod_listes{
									width: 100%;

									.choices_list {
										.simplebar-wrapper {
											.simplebar-content {
												display: flex;
												flex-wrap: wrap;
												justify-content: flex-end;
											}
										}
									}

									.titre_choices_list{
										display: none;
									}
								}

								.choices_list{
									[type=radio]:checked+label:before, 
									[type=radio]:not(:checked)+label:before,
									[type=radio]:checked+label:after, 
									[type=radio]:not(:checked)+label:after{
										display: none;
									}
								}
							}

							.productColorFieldset{
								display: flex;
								align-items: center;
								justify-content: flex-end;
								width: 100%;
								max-width: 200px;
								position: absolute;
								right: 0;
								top: 0;

								.choices_list{
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: flex-end;
									flex-wrap: wrap;
									width: 100%;

									.form_itm.color{
										&:nth-of-type(n+9){
											margin-top: 4px;
										}

										&:not(:last-of-type){
											margin-right: 8px;
										}
										
										&:nth-of-type(8n){
											margin-right: 0;
										}

										[type=radio]:checked,
										[type=radio]:not(:checked){
											& + label{
												display: flex;
												align-items: center;
												justify-content: center;
												background-position: center;
												background-size: contain;
												width: 18px;
												height: 18px;
												border-radius: 50%;
												margin: 0;
												border: solid 1px rgba(0,0,0,0.075);
												transition: all 0.4s ease-in-out;

												&[title="Blanc"],
												&[title="BLANC"],
												&[title="White"],
												&[title="WHITE"],
												&[title="Ecru"],
												&[title="ECRU"],
												&[title="Nude"],
												&[title="NUDE"]{
													border: solid 1px #dddddd;
												}
											}
										}

										[type=radio]:checked{
											& + label{
												border: solid 1px #000000;

												&[title="Blanc"],
												&[title="BLANC"],
												&[title="White"],
												&[title="WHITE"],
												&[title="Ecru"],
												&[title="ECRU"],
												&[title="Nude"],
												&[title="NUDE"]{
													border: solid 1px #000000;
												}
											}
										}
									}
								}
							}

							.productSizeFieldset{
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								justify-content: flex-start;
								background-color: rgba(255, 255, 255, 0.9);
								width: 100%;
								position: absolute;
								left: 0;
								bottom: calc(100% + 16px);
								padding: 24px 32px 16px;
								z-index: 5;
								opacity: 0;
								transition: opacity 0.4s ease-in-out;
                                transform: translateZ(0);
                                backface-visibility: hidden;
                                -webkit-backface-visibility: hidden;

								.productErrorFieldset{
									@extend .textStyles;
									font-style: italic;
									font-size: .65rem;
									color: red;
								}

								.choices_list{
									display: flex;
									align-items: center;
									justify-content: flex-start;
									flex-wrap: wrap;
									width: 100%;
									max-width: 12.4vw;

									.form_itm.size{
										margin-bottom: 8px;

										&:not(:last-of-type){
											margin-right: 8px;
										}

										[type=radio]:checked,
										[type=radio]:not(:checked){
											& + label{
												display: flex;
												align-items: center;
												justify-content: center;
												width: 30px;
												height: 30px;
												position: relative;
												font-family: $primaryFont;
												font-weight: 400;
												font-size: 11px;
												letter-spacing: 0.55px;
												margin-right: 0;
	
												&:before{
													content: " ";
													display: flex;
													align-items: center;
													justify-content: center;
													background-color: transparent;
													width: 100%;
													height: 100%;
													border: solid 1px $primaryColor;
													border-radius: 50%;
													position: absolute;
													left: 50%;
													top: 50%;
													margin-left: -16px;
													margin-top: -16px;
													margin-right: 0;
													opacity: 0;
													transition: all 0.4s ease-in-out;
												}
											}

											&.disabled{
												& + label{
													color: #9a9a9a;
												}
											}
										}

										[type=radio]:checked{
											& + label{
												&:before{
													opacity: 1;
												}
											}

											&.disabled{
												& + label{
													color: #9a9a9a;

													&:before{
														border: solid 1px #9a9a9a;
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.btnAddBasketWrapper{
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						background-color: rgba(255, 255, 255, 0.9);
						width: 100%;
						position: absolute;
						left: 0;
						bottom: calc(100% + 16px);
						padding: 24px 32px 16px;
						z-index: 5;
						opacity: 0;
						transition: opacity 0.4s ease-in-out;

						.alert_return{
							display: flex;
							align-items: center;
							justify-content: center;
							background: url('../img/close.png') center center no-repeat;
							width: 11px;
							height: 11px;
							position: absolute;
							top: 24px;
							right: 32px;
							cursor: pointer;

							span{
								font-size: 0
							}
						}

						.alert_stock{
							@extend .textStyles;
							text-align: center;
							width: 100%;
							padding: 0 10px;
							margin-bottom: 15px;
						}

						.bloc_add_alert_form{
							width: 100%;
							max-width: 350px;
							margin: 0 auto;

							.w-input-element{
								padding-right: 72px;
							}

							.form_submit{
								position: absolute;
								top: 0;
								right: 0;
								width: 72px;
								height: 48px;

								button{
									background-color: transparent;
									border-color: transparent;
									font-size: 0;

									span{
										background-image: url('../svg/arrow.svg');
										background-size: 75%;
										background-position: 55% 2%;
										background-repeat: no-repeat;
										width: 72px;
										height: 24px;
										font-size: 0;
									}

									&:hover{
										background-color: transparent;
									}
								}
							}
						}

						.bloc_add_alert_confirmation,
						.bloc_add_alert_erreur{
							width: 100%;
							max-width: 350px;
							
							p{
								@extend .textStyles;
								text-align: center;
							}

							.w-submit-button{
								max-width: 250px;
								height: 48px;
								margin: 15px auto 10px;
							}
						}
					}

					.bloc_add_color{
						width: 100%;
						max-width: 160px;
						height: auto;
						position: absolute;
						right: 32px;
						bottom: calc(100% + 44px);
						z-index: 6;
						opacity: 0;
						transition: opacity 0.4s ease-in-out;

						/*Recherche*/
						.load_product{
							display: none;
						}
						/*End Recherche*/

						.button{
							display: flex;
							align-items: center;
							justify-content: flex-end;
							background: transparent;
							border: none;
							@extend .textStyles;
							text-transform: none;
							height: 100%;

							span{
								display: flex;
								align-items: center;
								justify-content: space-between;
								@extend .textStyles;
								text-transform: none;
								width: 100%;
							}

							&:before{
								display: none;
							}

							&:after{
								content: " ";
								display: block;
								background: url('../svg/icones.svg');
								background-repeat: no-repeat;
								background-size: 800%;
								background-position: 27% 26%;
								width: 20px;
								height: 25px;
								z-index: 2;
							}

							&.loader{
								background: url(../svg/three_dots_dark.svg) transparent no-repeat center center !important;
								background-size: 20%!important;

								@media screen and (min-width:0\0) {
									background: url(../img/three_dots_black.gif) transparent no-repeat center center!important;
									background-size: 25%!important;
								}

								&.loading{
									z-index: 2!important;
								}

								span,
								&:after{
									display: none;
								}
							}
						}

						&.loading{
							.button{
								span{
									display: none;
								}
							}
						}
					}
				}

				&.calibrate{
					form{
						.bloc_add_color{
							bottom: calc(100% + 61px);
						}
					}
				}

				/*Completly out of stock
				&.no_stock{
					form{
						.rollover_left{
							&.achat_express{
								.productSizeFieldset{
									display: none!important;
								}
							}
						}
						
						.bloc_add_color{
							display: none!important;
						}
					}
				}*/
			}
			/*Style achat express v2 ends*/
		}

		.no_stock_wrap{
			text-align: center;
			position: absolute;
			width: 70%;
			left: 50%;
			top: 50%;
			transform: translateX(-50%);
			margin-top: -3.75rem;
			height: 7.5rem;
			z-index: 1;
			text-transform: uppercase;
			line-height: 1.5;
			padding: 1rem;
			transition: all .35s ease-in-out;

			@media screen and (max-width: 1200px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 16rem;
				width: 85%;
				margin-top: -8rem;
			}

			small {
				font-size: .7rem;
				text-transform: none;
			}
		}

		&:hover{
			.imgWrapper{
				.productSwiper,
				.productVisualMulti{
					.rollover_rayonsPrev,
					.rollover_rayonsNext{
						&.swiper-button-disabled{
							opacity: 0.25;
						}

						&:not(.swiper-button-disabled){
							opacity: 1;
						}
					}
				}
			}

			.bloc_descrip{
				.wrap_rolloverproduit{
					form{
						.rollover_left{
							&.achat_express{
								.productSizeFieldset{
									opacity: 1;
								}
							}
						}
						.bloc_add_color,
						.btnAddBasketWrapper{
							opacity: 1;
						}
					}
				}

				.no_stock_wrap{
					opacity: 0.9;
				}
			}
		}
		//AJOUT PRODUIT WISHLIST
		.wishlistBtn.addToWishlistButton {
			position: absolute;
			top: 20px;
			z-index: 2;
			right: 40px;
			opacity: 0;
			span {
				font-size: 0;
			}
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 20px;
				height: 20px;
				background: url(../svg/wishlist.svg) center center no-repeat;
				background-size: contain;
			}
			&.existToWishlistButton::before {
				background: url(../svg/wishlist_exist.svg) center center no-repeat;
				background-size: contain;
			}
		}
	}

	&.bydefault{
		justify-content: center;
		max-width: 66.667vw;

		@media screen and (max-width: 1370px) {
			max-width: 990px;
		}

		@media screen and (max-height: 668px) and (max-width: 1370px) {
			max-width: 880px;
		}

		.item{
			width: calc(89.374% / 2);
			max-width: 572px;

			&:nth-child(odd){
				margin-right: 10.626%;
			}

			.imgWrapper{
				.block_lnk,
				.block_push,
				.wrap_video{
					padding-bottom: calc((812 / 631) * 100%);
				}
			}

			&.push{
				.imgWrapper{
					.block_lnk,
					.block_push,
					.wrap_video{
						img{
							max-width: 460px;
							max-height: 575px;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
		}
	}

	&.byFour{
		max-width: 1680px;

		.item{
			width: calc(85.714% / 4);
			max-width: 360px;

			&.push{
				.imgWrapper{
					.block_lnk,
					.block_push,
					.wrap_video{
						img{
							max-width: 290px;
							max-height: 362px;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
			}

			&:not(:nth-child(4n)){
				margin-right: 4.762%;
			}

			.imgWrapper{
				.eclat_rect{
					width: 26.67%;
				}
	
				.eclat_rond{
					width: 13.89%;
				}

				.block_lnk,
				.block_push,
				.wrap_video{
					padding-bottom: calc((450 / 360) * 100%);
				}
			}

			.bloc_descrip{
				.block_lnk{
					width: calc(100% - 96px);
				}

				.wrap_rolloverproduit{
					form{
						.rollover_left{
							&.achat_express{
								.productColorFieldset{
									max-width: 96px;

									.choices_list{	
										.form_itm.color{
											&:nth-of-type(4n){
												margin-right: 0;
											}

											&:nth-of-type(n+5){
												margin-top: 4px;
											}
										}
									}
								}
							}
						}

						.bloc_add_color{
							max-width: 60px;

							@media screen and (max-width: 1176px) {
								bottom: calc(100% + 61px);
							}
							
							.button{
								span{
									display: none;
								}
								&:after{
									@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
										background-position: 27% 31.5%;
									}
								}

								&.loader{
									background-size: 50%!important;

									&.loading{
										z-index: 2!important;
									}

									span,
									&:after{
										display: none;
									}
								}
							}
						}
					}

					&.calibrate{
						form{
							.bloc_add_color{	
								@media screen and (max-width: 1176px) {
									bottom: calc(100% + 81px);
								}
							}
						}
					}
				}
			}
		}
	}
}

/*Start no results*/
#search_page{
	.no_stock_wrap+.block_lnk {
		opacity: .3;
	}
	
	.top_bar{
		display: none!important;
	}

	.search_details_wrapper{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
		
		.no_result_block{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;

			.title_no_result{
				@extend .textStyles;
				font-weight: 600;
				font-size: 18px;
				letter-spacing: 1.8px;
				text-transform: uppercase;
			}

			.tips_title{
				display: block;
				@extend .textStyles;
				text-align: center;
				margin: 16px 0 19px;
			}

			.go_faq{
				@extend .textStyles;
				text-decoration: underline;
				text-underline-offset: 2px;
			}
		}
	}

	.gondole{
		background-color: $white;
		position: relative;
		margin: 150px 0 235px;
		padding-top: 60px;

		&:after{
			content: " ";
			display: block;
			background: $white url('../img/footer_bg_2.png') center top no-repeat;
			width: 100%;
			height: 8.854vw;
			position: absolute;
			left: 0;
			bottom: -4.427vw;
			transform: rotate(180deg);
			z-index: -1;
		}

		.title_gonodle{
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $secondaryFont;
			font-weight: 600;
			font-size: 65px;
			letter-spacing: -1.625px;
			width: 100%;
			margin-bottom: 75px;
		}

		#list_item{
			justify-content: center;
			margin-top: 0;

			.item{
				margin-bottom: 66px;

				&:last-child{
					&:not(:nth-child(4n)){
						margin-right: 0;
					}
				}

				.bloc_descrip{
					.wrap_rolloverproduit{
						form{
							.bloc_add_color{
								bottom: calc(100% + 44px);
							}
						}
					}
				}
			}
		}

		#searchSwiper{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			max-width: 1680px;
			margin: 0 auto;

			@media screen and (max-width: 1720px) {
				padding: 0 32px;
			}

			.swiper-slide{
				max-width: 360px;
			}

			.item{
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				margin-bottom: 102px;
				max-width: 360px;
		
				.imgWrapper{
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					position: relative;
		
					.swiper-slide-off,
					.swiper-slide-on{
						width: 100%;
		
						& > div{
							width: 100%;
						}
					}
					
					.block_lnk,
					.block_push,
					.wrap_video{
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						height: 0;
						padding-bottom: calc((812 / 631) * 100%);
						position: relative;
		
						.imgProd,
						iframe{
							background-color: $bgColor;
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
						}
					}
		
					.productSwiper,
					.productVisualMulti{
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
		
						/*Recheche w-swiper*/
						&.w-swiper-container{
							margin: 0 auto;
							position: relative;
							overflow: hidden;
							list-style: none;
							padding: 0;
							z-index: 1;
		
							.w-swiper-wrapper{
								display: flex;
								width: 100%;
								height: 100%;
								position: relative;
								z-index: 1;
								scroll-snap-type: x mandatory;
								overflow-x: scroll;
								overflow-y: hidden;
								scrollbar-width: none;
								-ms-overflow-style: none;
		
								&::-webkit-scrollbar {
								display: none;
								}
		
								.w-swiper-slide{
									flex-shrink: 0;
									width: 100%;
									height: 100%;
									position: relative;
								}
		
								div{
									scroll-snap-align: center;
		
									/*.imgProd{
										left: unset!important;
										height: unset!important;
										margin-top: 100%!important;
										position:unset!important;
									}*/
								}
							}
						}
						/*End recheche w-swiper*/
		
						.rollover_rayonsNext,
						.rollover_rayonsPrev{
							display: flex;
							align-items: center;
							justify-content: center;
							@extend .arrowB;
							background-position: 0% -5%;
							width: 3.646vw;
							height: 1.563vw;
							position: absolute;
							top: 50%;
							margin-top: -0.781vw;
							z-index: 3;
							cursor: default;
							opacity: 0;
							-webkit-touch-callout: none;
							-webkit-user-select: none;
							-khtml-user-select: none;
							-moz-user-select: none;
							-ms-user-select: none;
							user-select: none;
							transition: all 0.4s ease-in-out;
							
							@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								background-position: 50% -5%;
							}
		
							&:not(.swiper-button-disabled){
								cursor: pointer;
							}
						}
		
						.rollover_rayonsPrev{
							left: 16px;
							transform: rotate(180deg);
						}
		
						.rollover_rayonsNext{
							right: 16px;
						}
		
						&.no_stock{
							.w-swiper-wrapper{
								opacity: 0.3;
							}
						}
					}
				}
		
				.bloc_descrip{
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					width: 100%;
					margin-top: 16px;
					position: relative;
					
					.block_lnk{
						width: calc(100% - 96px);
		
						.wrapper_description{
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							justify-content: flex-start;

							.item_des{
								display: inline-block;
								text-transform: lowercase;
		
								&:first-letter{
									text-transform: uppercase;
								}
							}
		
							.item_title{
								@extend .textStyles;
								width: 97%;
							}
		
							.item_price{
								margin-top: 7px;

								.pricetag{
									@media screen and (max-width: 1580px) {
										font-size: 15px;
										letter-spacing: 0.75px;
									}
		
									& + .pricetag{
										@media screen and (max-width: 1580px) {
											font-size: 13px;
											letter-spacing: 0.65px;
										}
									}
								}
							}
						}
					}
		
					/*Style achat express v2 starts*/
					.wrap_rolloverproduit{
						width: 100%;
		
						form{
							width: 100%;
		
							.rollover_left{
								&.achat_express{
									.productColorFieldset,
									.productSizeFieldset{
										.prod_listes{
											width: 100%;
		
											.titre_choices_list{
												display: none;
											}
										}
		
										.choices_list{
											[type=radio]:checked+label:before, 
											[type=radio]:not(:checked)+label:before,
											[type=radio]:checked+label:after, 
											[type=radio]:not(:checked)+label:after{
												display: none;
											}
										}
									}
		
									.productColorFieldset{
										display: flex;
										align-items: center;
										justify-content: flex-end;
										width: 100%;
										max-width: 96px;
										position: absolute;
										right: 0;
										top: 0;
		
										.choices_list{
											display: flex;
											align-items: center;
											justify-content: flex-end;
											flex-wrap: wrap;
											width: 100%;
		
											.form_itm.color{
												&:nth-of-type(n+9){
													margin-top: 4px;
												}
		
												&:not(:last-of-type){
													margin-right: 8px;
												}
												
												&:nth-of-type(8n){
													margin-right: 0;
												}

												&:nth-of-type(4n){
													margin-right: 0;
												}
	
												&:nth-of-type(n+5){
													margin-top: 4px;
												}
		
												[type=radio]:checked,
												[type=radio]:not(:checked){
													& + label{
														display: flex;
														align-items: center;
														justify-content: center;
														background-position: center;
														background-size: contain;
														width: 18px;
														height: 18px;
														border-radius: 50%;
														margin: 0;
														border: solid 1px transparent;
														transition: all 0.4s ease-in-out;
		
														&[title="Blanc"],
														&[title="BLANC"],
														&[title="White"],
														&[title="WHITE"],
														&[title="Ecru"],
														&[title="ECRU"],
														&[title="Nude"],
														&[title="NUDE"]{
															border: solid 1px #dddddd;
														}
													}
												}
		
												[type=radio]:checked{
													& + label{
														border: solid 1px #000000;
		
														&[title="Blanc"],
														&[title="BLANC"],
														&[title="White"],
														&[title="WHITE"],
														&[title="Ecru"],
														&[title="ECRU"],
														&[title="Nude"],
														&[title="NUDE"]{
															border: solid 1px #000000;
														}
													}
												}
											}
										}
									}
		
									.productSizeFieldset{
										display: flex;
										flex-direction: column;
										align-items: flex-start;
										justify-content: flex-start;
										background-color: rgba(255, 255, 255, 0.9);
										width: 100%;
										position: absolute;
										left: 0;
										bottom: calc(100% + 16px);
										padding: 24px 32px 16px;
										z-index: 5;
										opacity: 0;
										transition: opacity 0.4s ease-in-out;
		
										.productErrorFieldset{
											@extend .textStyles;
											font-style: italic;
											font-size: .65rem;
											color: red;
										}
		
										.choices_list{
											display: flex;
											align-items: center;
											justify-content: flex-start;
											flex-wrap: wrap;
											width: 100%;
											max-width: 12.4vw;
		
											.form_itm.size{
												margin-bottom: 8px;
		
												&:not(:last-of-type){
													margin-right: 8px;
												}
		
												[type=radio]:checked,
												[type=radio]:not(:checked){
													& + label{
														display: flex;
														align-items: center;
														justify-content: center;
														width: 30px;
														height: 30px;
														position: relative;
														font-family: $primaryFont;
														font-weight: 400;
														font-size: 11px;
														letter-spacing: 0.55px;
														margin-right: 0;
			
														&:before{
															content: " ";
															display: flex;
															align-items: center;
															justify-content: center;
															background-color: transparent;
															width: 100%;
															height: 100%;
															border: solid 1px $primaryColor;
															border-radius: 50%;
															position: absolute;
															left: 50%;
															top: 50%;
															margin-left: -16px;
															margin-top: -16px;
															margin-right: 0;
															opacity: 0;
															transition: all 0.4s ease-in-out;
														}
													}
		
													&.disabled{
														& + label{
															color: #9a9a9a;
														}
													}
												}
		
												[type=radio]:checked{
													& + label{
														&:before{
															opacity: 1;
														}
													}
		
													&.disabled{
														& + label{
															color: #9a9a9a;
		
															&:before{
																border: solid 1px #9a9a9a;
															}
														}
													}
												}
											}
										}
									}
								}
							}
		
							.btnAddBasketWrapper{
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								background-color: rgba(255, 255, 255, 0.9);
								width: 100%;
								position: absolute;
								left: 0;
								bottom: calc(100% + 16px);
								padding: 24px 32px 16px;
								z-index: 5;
								opacity: 0;
								transition: opacity 0.4s ease-in-out;
		
								.alert_return{
									display: flex;
									align-items: center;
									justify-content: center;
									background: url('../img/close.png') center center no-repeat;
									width: 11px;
									height: 11px;
									position: absolute;
									top: 24px;
									right: 32px;
									cursor: pointer;
								}
		
								.alert_stock{
									@extend .textStyles;
									text-align: center;
									width: 100%;
									padding: 0 10px;
									margin-bottom: 15px;
								}
		
								.bloc_add_alert_form{
									width: 100%;
									max-width: 350px;
									margin: 0 auto;
		
									.form_submit{
										width: 50%;
										height: 48px;
		
										button{
											background: $primaryColor;
											border-color: $primaryColor;
											color: $white;
		
											&:hover{
												background: $white;
												color: $primaryColor;
											}
										}
									}
								}
		
								.bloc_add_alert_confirmation,
								.bloc_add_alert_erreur{
									width: 100%;
									max-width: 350px;
									
									p{
										@extend .textStyles;
										text-align: center;
									}
		
									.w-submit-button{
										max-width: 250px;
										height: 48px;
										margin: 15px auto 10px;
									}
								}
							}
		
							.bloc_add_color{
								width: 100%;
								max-width: 20px;
								height: auto;
								position: absolute;
								right: 32px;
								bottom: calc(100% + 44px);
								z-index: 6;
								opacity: 0;
								transition: opacity 0.4s ease-in-out;
		
								.button{
									display: flex;
									align-items: center;
									justify-content: flex-end;
									background: transparent;
									border: none;
									@extend .textStyles;
									text-transform: none;
									height: 100%;
		
									span{
										display: none;
									}
		
									&:before{
										display: none;
									}
		
									&:after{
										content: " ";
										display: block;
										background: url('../svg/icones.svg');
										background-repeat: no-repeat;
										background-size: 800%;
										background-position: 27% 26%;
										width: 20px;
										height: 25px;
										z-index: 2;
									}

									&.loader{
										background-size: 50%!important;

										&.loading{
											z-index: 2!important;
										}

										span,
										&:after{
											display: none;
										}
									}
								}

								&.loading{
									.button{
										span{
											display: none;
										}
									}
								}
							}
						}
		
						&.calibrate{
							form{
								.bloc_add_color{
									bottom: calc(100% + 61px);
								}
							}
						}
		
						/*Completly out of stock
						&.no_stock{
							form{
								.rollover_left{
									&.achat_express{
										.productSizeFieldset{
											display: none!important;
										}
									}
								}
								
								.bloc_add_color{
									display: none!important;
								}
							}
						}*/
					}
					/*Style achat express v2 ends*/
		
					.no_stock_wrap{
						display: flex;
						align-items: center;
						justify-content: center;
						background: $white;
						width: 100%;
						height: 90px;
						font-family: $primaryFont;
						font-weight: 400;
						font-size: 14px;
						letter-spacing: 1.4px;
						text-transform: uppercase;
						text-align: center;
						position: absolute;
						bottom: calc(100% + 16px);
						left: 0;
						z-index: 5;
						opacity: 0;
						transition: all 0.4s ease-in-out;
					}
				}
		
				&:hover{
					.imgWrapper{
						.productSwiper,
						.productVisualMulti{
							.rollover_rayonsPrev,
							.rollover_rayonsNext{
								&.swiper-button-disabled{
									opacity: 0.25;
								}
		
								&:not(.swiper-button-disabled){
									opacity: 1;
								}
							}
						}
					}
		
					.bloc_descrip{
						.wrap_rolloverproduit{
							form{
								.rollover_left{
									&.achat_express{
										.productSizeFieldset{
											opacity: 1;
										}
									}
								}
								
								.bloc_add_color,
								.btnAddBasketWrapper{
									opacity: 1;
								}
							}
						}
		
						.no_stock_wrap{
							opacity: 0.9;
						}
					}
				}
			}
	
			& + .swiper-btn.swiper-button-next{
				top: 50%;
				margin-top: 20px;
				right: 3.5%;

				&.swiper-button-disabled{
					opacity: 0;
				}

				& + .swiper-btn.swiper-button-prev{
					top: 50%;
					margin-top: 20px;
					left: 3.5%;

					&.swiper-button-disabled{
						opacity: 0;
					}
				}
			}
		}
	}
}
/*End no results*/

.pager_wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 150px;

	.see_all_product{
		@extend .textStyles;
		text-decoration: underline;
		margin-right: 32px;
		cursor: pointer;
	}

	.pagination{
		display: flex;
		align-items: center;
		justify-content: flex-start;

		a:not(.vector_sprite),
		span:not(.hellip){
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $primaryFont;
			font-weight: 400;
			font-size: 16px;
			letter-spacing: 0.8px;
			color: $primaryColor;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			border: solid 1px transparent;
			transition: all 0.4s ease-in-out;

			&:not(:last-child){
				margin-right: 10px;
			}

			&.active{
				border: solid 1px #9a9a9a;
			}
		}

		.hellip{
			margin: 0 26px 0 16px;
		}

		.next,
		.prev{
			@extend .arrowB;
			background-position: 0% -5%;
			width: 70px!important;
			height: 30px!important;
			font-size: 0!important;
					
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background-position: 50% -5%;
			}
		}

		.prev{
			transform: rotate(180deg);
		}
	}
}

.txt_bandeau{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $white;
	padding: 80px 32px;
	margin-bottom: 150px;

	.wrap_text_seo{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: 1280px;

		.title{
			@extend .textStyles;
			font-weight: 600;
			font-size: 18px;
			letter-spacing: 1.8px;
			text-transform: uppercase;
			margin-bottom: 23px;
		}

		.txt{
			@extend .textStyles;
			line-height: 21px;
			width: 100%;
			overflow: hidden;
			transition: all 0.4s ease-in-out;
		}

		.txt_trigger{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			@extend .textStyles;
			margin-left: auto;
			margin-top: 43px;
			cursor: pointer;
			
			&:after{
				content: " ";
				display: block;
				@extend .arrowB;
				background-position: 0% 5%;
				width: 70px;
				height: 20px;
					
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					background-size: 250%;
					background-position: 50% 5%;
				}
			}
		}
	}
}

body.category{
	.main_wrapper{
		margin-top: 6.5rem;
	}
}