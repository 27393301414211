/******************************************************************************/
/* GLOBAL STYLES */
/******************************************************************************/
body {
    background-color: $bgColor2;
    padding-top: 80px; /* Because of header fixed position */
    font-family: $primaryFont;
    font-size: 12px;
    min-height: 100vh;
    z-index: 30;
    box-sizing: border-box;
    width: 100%;
    max-width: 1920px;
    min-width: 767px;
    margin: auto;

    @media only screen and (max-width: 1720px) {
        padding-top: 80px; /* Because of header fixed position */
    }

    @media only screen and (max-width: 1370px) {
        padding-top: 60px; /* Because of header fixed position */
    }

    @media only screen and (max-width: 1024px) {
        padding-top: 100px;
    }

    a {
        text-decoration: none;
    }

    img {
        width: 100%;
        height: auto;
    }

    input {
        &:focus,
        &.active {
            outline: none;
        }
    }

    &.menu_hidden {
        padding-top: 82px; /* Because menu does not display on all pages */
        .main_menu_wrapper {
            display: none;
        }
    }

    &.bandeauDelivery {
        padding-top: 112px;

        @media only screen and (max-width: 1720px) {
            padding-top: 112px;
        }

        @media only screen and (max-width: 1370px) {
            padding-top: 92px;
        }

        @media only screen and (max-width: 1170px) {
            padding-top: 133px;
        }

        @media only screen and (max-width: 1023px) {
            padding-top: 130px;
        }

        &.menu_hidden {
            padding-top: 102px; /* Because menu does not display on all pages */
        }

        @media only screen and (max-width: 1170px) {
            .sticky #form_recherche .wrap_search_field {
                top: 100px;
            }
        }

        header:not(.sticky) {
            .head_top {
                .header {
                    .block_top_links.right {
                        .block_top_item {
                            &.my_basket {
                                #show_top_cart {
                                    top: 112px;

                                    @media only screen and (max-width: 1370px) {
                                        top: 92px;
                                    }

                                    @media only screen and (max-width: 1170px) {
                                        top: 133px;
                                    }
                                }
                            }

                            &.my_account {
                                #topLogin {
                                    top: 112px;

                                    @media only screen and (max-width: 1370px) {
                                        top: 92px;
                                    }

                                    @media only screen and (max-width: 1024px) {
                                        top: 132px;
                                    }
                                }
                            }
                        }
                    }

                    .menu_top {
                        .main_menu_wrapper .main_menu_contents {
                            .main_menu_itm {
                                &:nth-child(n + 6) {
                                    .submenu_wrapper {
                                        top: 113px;
                                    }
                                }

                                @media only screen and (max-width: 1870px) {
                                    &:nth-child(n + 5) {
                                        .submenu_wrapper {
                                            top: 112px;
                                        }
                                    }
                                }

                                @media only screen and (max-width: 1560px) {
                                    &:nth-child(n + 4) {
                                        .submenu_wrapper {
                                            top: 112px;
                                        }
                                    }
                                }

                                @media only screen and (max-width: 1370px) {
                                    &:nth-child(n + 3) {
                                        .submenu_wrapper {
                                            top: 92px;
                                        }
                                    }
                                }

                                @media only screen and (max-width: 1370px) {
                                    &:nth-child(n + 2) {
                                        .submenu_wrapper {
                                            top: 92px;
                                        }
                                    }
                                }

                                @media only screen and (max-width: 1370px) {
                                    .submenu_wrapper {
                                        top: 60px;
                                    }
                                }

                                @media only screen and (max-width: 1170px) {
                                    .submenu_wrapper {
                                        top: 133px !important;
                                    }

                                    &:nth-child(n + 2) {
                                        .submenu_wrapper {
                                            top: 133px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 1024px) {
                        .menu_top {
                            top: 87px;

                            .main_menu_wrapper .main_menu_contents {
                                .main_menu_itm {
                                    &:before {
                                        bottom: 10px;
                                    }

                                    .submenu_wrapper {
                                        position: fixed;
                                        top: 133px;
                                    }

                                    &:nth-child(n + 2) {
                                        .submenu_wrapper {
                                            top: 133px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 1170px) {
                        .menu_top {
                            top: 87px !important;
                            justify-content: center;
                        }

                        .block_top_links.right {
                            .block_top_item.search {
                                .wrap_search_field {
                                    top: 133px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 1170px) {
            .menu_top {
                justify-content: center;
                padding: 0;
            }
        }

        .menu_top {
            .main_menu_wrapper {
                .main_menu_contents {
                    .main_menu_itm {
                        @media only screen and (max-width: 1160px) and (min-width: 1024px) {
                            &:nth-child(n + 4) {
                                position: inherit;

                                .submenu_wrapper {
                                    right: 20px;
                                    left: inherit;
                                    top: 92px;
                                }
                            }
                        }

                        @media only screen and (max-width: 1170px) {
                            .submenu_wrapper {
                                top: 100px !important;
                            }
                        }

                        @media only screen and (max-width: 1024px) {
                            &:nth-child(n + 3) {
                                position: inherit;

                                .submenu_wrapper {
                                    position: fixed !important;
                                    right: 20px;
                                    left: inherit;
                                    top: 133px !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        &:not(.category) header.sticky {
            .menu_top {
                .main_menu_wrapper {
                    .main_menu_contents {
                        .main_menu_itm {
                            @media only screen and (max-width: 1160px) and (min-width: 1024px) {
                                &:nth-child(n + 4) {
                                    position: inherit;

                                    .submenu_wrapper {
                                        right: 20px;
                                        left: inherit;
                                        top: 60px;
                                    }
                                }
                            }

                            @media only screen and (max-width: 1024px) {
                                .submenu_wrapper {
                                    top: 100px !important;
                                }
                            }

                            /*@media only screen and (max-width: 1470px) and (min-width: 1024px) {
                                &:nth-child(n + 3) {
                                    position: inherit;
                                    .submenu_wrapper{
                                        right: 20px;
                                        left: inherit;
                                        top: 80px;
                                    }
                                }
                            }*/
                            /*@media only screen and (max-width: 1250px) {
                                &:nth-child(n + 3) {
                                    position: inherit;
                                    .submenu_wrapper{
                                        position: fixed !important;
                                        right: 20px;
                                        left: inherit;
                                        top: 101px !important;
                                    }
                                }
                            }*/
                        }
                    }
                }
            }
        }
    }

    &:not(.bandeauDelivery) {
         @media only screen and (max-width: 1170px) {
            #form_recherche .wrap_search_field {
                top: 100px;
            }
        }
    }

    @media only screen and (max-width: 1024px) {
        &:not(.bandeauDelivery) header.sticky {
            .head_top {
                .header {
                    .menu_top {
                        .main_menu_wrapper {
                            .main_menu_contents {
                                .main_menu_itm {
                                    .submenu_wrapper {
                                        top: 100px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.category {
        overflow-x: hidden; //For rollover panier + acc

        header.sticky {
            .head_top {
                .header {
                    .block_top_links {
                        &.right {
                            .block_top_item.my_basket {
                                #show_top_cart {
                                    position: fixed !important;
                                    top: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.search_in {
        overflow-x: hidden !important;
    }
}

.cache {
    display: none;
}

/******************************************************************************/
/* GENERAL CONTENT LAYOUT */
/******************************************************************************/
.main_wrapper {
    width: 100%;
    max-width: 1920px;
    min-width: 990px;
    margin: 0 auto;
    overflow: hidden !important;

    @media only screen and (max-width: 1024px) {
        min-width: inherit;
    }
}

/******************************************************************************/
/* BREADCRUMG */
/******************************************************************************/
body {
    #breadcrumbs {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 1680px;
        margin: 32px auto 0;

        @media screen and (max-width: 1720px) {
            padding: 0 32px;
        }

        nav {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .breadcrumb {
                display: flex;
                align-items: center;
                justify-content: center;

                &:last-child,
                &:only-child {
                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            a,
            span {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-family: $primaryFont;
                font-weight: 400;
                font-size: 11px;
                line-height: 25px;
                letter-spacing: 0.55px;
                color: $primaryColor;
                text-transform: none;
                text-decoration: none;
                transition: all 0.3s ease-in-out;

                @media screen and (max-width: 1580px) {
                    font-size: 10px;
                    letter-spacing: 0.5px;
                }

                &:hover {
                    color: $primaryColor;
                }
            }

            a {
                &:after {
                    content: ' ';
                    display: block;
                    background: url('../img/ariane_ar.png') center center no-repeat;
                    width: 10px;
                    height: 5px;
                    margin: 0 16px;
                }
            }

            .current {
                cursor: default;

                span {
                    color: #000000;
                }
            }
        }
    }

    .zopim {
        right: unset !important;
        left: 0 !important;
    }
}

/******************************************************************************/
/* ECLATS */
/******************************************************************************/
.eclat,
.eclat_rect,
.eclat_rond {
    position: absolute;
    z-index: 5;
    top: 24px;
    right: 24px;

    img {
        width: auto;
        height: 24px;
    }
}

.eclat_rect {
    right: auto;
    left: 24px;

    @media only screen and (max-width: 1380px) {
        top: 10px;
        left: 10px;
    }
}

.eclat_rond {
    top: auto;
    bottom: 24px;
}

/* SHAD */
#shad,
#shad_menu,
#shad_zoom,
#shad_link {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.45);
    overflow-y: auto;
}

/******************************************************************************/
/* GLOBAL SHADES */
/******************************************************************************/
.shad {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.45);
    overflow-y: auto;

    &.actif {
        display: block;
    }

    &#shad_abox {
        z-index: 110;
    }
}

#shad_menu,
#shad_link {
    display: block !important;
    background-color: rgba($black, 0.5);
    opacity: 0;
    z-index: -10;
    margin-top: 80px;
    transition: opacity 0.3s linear, z-index 0s linear 0.3s, margin 0s linear 0.3s;

    &.sticky {
        margin-top: 0;
    }

    &.actif {
        opacity: 1;
        z-index: 6;
        transition: opacity 0.3s linear 0.2s, z-index 0s linear;

        &.rollPanier {
            display: block !important;
            opacity: 1 !important;
            z-index: 10 !important;
            width: calc(100% - 523px);

            &:after {
                content: ' ';
                display: block;
                background: $white;
                width: 523px;
                height: 100%;
                position: fixed;
                top: 58px;
                right: 0;
            }
        }

        &.rollPanierLeft {
            display: block !important;
            opacity: 1 !important;
            z-index: 12 !important;
            width: calc(100% - 523px);
            transform: translateX(523px);
        }

        &.filterOn {
            margin-top: 0;
            z-index: 9;
        }
    }
}

#shad_menu {
    margin-top: 0 !important;
}

/*****************************************************************************
//  Fullpage search loader
// *****************************************************************************/
.fullpage-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55vh;

    span {
        font-family: 'commuters', sans-serif;
        font-weight: 900;
        font-size: 12 / 16 * 1rem;
        color: #3c3c3c;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    &:before {
        display: block;
        width: 2.5em;
        height: 2.5em;
        background: transparent url(../svg/loader_custom.svg) center no-repeat;
        background-size: cover;
        margin-right: 0.75em;
        animation: revolution 1.6s steps(16, start) infinite;
        content: '';
    }
}

/*****************************************************************************
//  Vue.js Transitions
// *****************************************************************************/
.pagefade-enter-active,
.pagefade-leave-active {
    transition: opacity 0.5s linear;
}

.pagefade-enter,
.pagefade-leave-to {
    opacity: 0;
}

[v-cloak] {
    display: none;
}

/*********************************
IE "X" from search input field
*********************************/
input::-ms-clear,
select::-ms-expand,
input::-ms-reveal {
    display: none;
}

/*********************************
Links style
*********************************/
a {
    color: $txtColor;
    text-decoration: none;
}

/*********************************
Tooltip
*********************************/
.tooltip {
    color: #272525;
    display: none;
    font-size: 11px;
    position: absolute;
    text-align: left;
    z-index: 15;

    .content_tooltip {
        min-width: 100px;
        white-space: nowrap;
        background: #f8f7f7;

        #erreur_msg {
            font-family: $secondaryFont;
            font-weight: 400;
            padding: 10px;

            .title_toolltip {
                font-weight: 600;
            }
        }
    }
}

/* FIX global e banner */
div#FreeShippingBanner {
    z-index: 1 !important;
}

.swiper-slide .swiper-lazy-preloader {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    transform: none;
    animation: none;
    z-index: 1;
    background: #fff;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        background-image: url(../svg/cloud.svg);
        background-repeat: no-repeat;
        background-size: 10%;
        background-position: center;
        background-color: transparent !important;
    }
}

/* BUTTON */
button,
.button:not(.home-module-cta) {
    width: 100%;
    height: 50px;
    background-color: $darkColor;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-family: $primaryFont;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & + .loader {
        width: 100%;
        height: 50px;
        background: $darkColor url(../svg/three_dots.svg) center center no-repeat;
        background-size: 45px;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all ease-in-out 0.3s;

        &.loading {
            z-index: 1;
            opacity: 1;
            display: block !important;
        }
    }
}

body.product_page,
body.homepage {
    .page_wrapper {
        .main_wrapper {
            .block_gondole,
            #gondole_aimerez {
                /* TETE de GONDOLE */
                .gondole_wrapper,
                .push-product-module {
                    width: 100%;
                    position: relative;
                    max-width: calc(1682px + 80px);
                    padding: 0 40px;
                    margin: auto;

                    #productAssociationSwiper,
                    .pushProductHomeWrapper_main {
                        width: 100%;
                        max-width: 1682px;
                        margin: auto;
                        display: flex;
                        justify-content: center;

                        .imgWrapper,
                        .pushProductHomeWrapper,
                        .content-wrapper,
                        .item.swiper-slide {
                            position: relative;

                            /*Style achat express v2 starts*/
                            .wrap_rolloverproduit {
                                width: 100%;
                                position: absolute;
                                bottom: 0;
                                background-color: rgba(255, 255, 255, 0.9);
                                z-index: -1;
                                opacity: 0;
                                transition: opacity 0.3s ease-in-out;

                                form {
                                    width: 100%;

                                    .rollover_left {
                                        &.achat_express {
                                            .productSizeFieldset {
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-start;
                                                width: 100%;
                                                padding: 24px 32px;
                                                z-index: 2;

                                                .choices_list {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: flex-start;
                                                    flex-wrap: wrap;
                                                    width: calc(100% - 25px);
                                                    max-width: 235px;

                                                    .form_itm.size {
                                                        //6e tailles passe a la ligne
                                                        &:nth-of-type(n + 6) {
                                                            margin-top: 8px;
                                                        }

                                                        &:not(:last-of-type) {
                                                            margin-right: 8px;
                                                        }

                                                        [type='radio']:checked,
                                                        [type='radio']:not(
                                                                :checked
                                                            ) {
                                                            & + label {
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                width: 30px;
                                                                height: 30px;
                                                                position: relative;
                                                                font-family: $primaryFont;
                                                                font-weight: 400;
                                                                font-size: 11px;
                                                                letter-spacing: 0.55px;
                                                                margin-right: 0;

                                                                &:before {
                                                                    content: ' ';
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: center;
                                                                    background-color: transparent;
                                                                    width: 100%;
                                                                    height: 100%;
                                                                    border: solid 1px $primaryColor;
                                                                    border-radius: 50%;
                                                                    position: absolute;
                                                                    left: 50%;
                                                                    top: 50%;
                                                                    margin-left: -16px;
                                                                    margin-top: -16px;
                                                                    margin-right: 0;
                                                                    opacity: 0;
                                                                    transition: all 0.4s ease-in-out;
                                                                }
                                                            }
                                                        }

                                                        [type='radio']:checked {
                                                            & + label {
                                                                &:before {
                                                                    opacity: 1;
                                                                }
                                                            }
                                                        }

                                                        .disabled + label {
                                                            color: #9a9a9a;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .form_submit {
                                        &.bloc_add_color {
                                            position: absolute;
                                            right: 32px;
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;
                                            height: auto;
                                            max-width: 20px;

                                            .button {
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-end;
                                                background: transparent;
                                                border: none;
                                                @extend .textStyles;
                                                font-weight: 500;
                                                text-transform: none;

                                                span {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    @extend .textStyles;
                                                    font-weight: 500;
                                                    text-transform: none;
                                                    width: 100%;
                                                    display: none;
                                                    font-size: 0 !important;
                                                }

                                                &:before {
                                                    display: none;
                                                }

                                                &:after {
                                                    content: ' ';
                                                    display: block;
                                                    background: url('../svg/icones.svg');
                                                    background-repeat: no-repeat;
                                                    background-size: 800%;
                                                    background-position: 27% 26%;
                                                    width: 20px;
                                                    height: 25px;
                                                    z-index: 2;

                                                    /* FOR IE :( */
                                                    @media screen and (min-width: 0\0) {
                                                        background-position: 27% 32%;
                                                    }
                                                }

                                                &.loader {
                                                    opacity: 0 !important;
                                                }
                                            }
                                        }
                                    }

                                    .bloc_add_alert_confirmation {
                                        padding: 1rem;
                                    }
                                }

                                /* alert Stock*/
                                .btnAddBasketWrapper {
                                    position: relative;

                                    .alert_stock {
                                        padding: 0.75rem 0;
                                        max-width: 90%;
                                        margin: auto;
                                        text-align: center;
                                    }

                                    .bloc_add_alert_form {
                                        position: relative;
                                        max-width: 90%;
                                        margin: 0.5rem auto;

                                        .w-form-line {
                                            .w-input-container {
                                                .w-input {
                                                    .w-input-element {
                                                    }
                                                }
                                            }
                                        }

                                        .form_submit {
                                            max-width: 80px;
                                            width: 100%;
                                            height: 46px;
                                            position: absolute;
                                            right: 1px;
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;
                                            padding: 0 1rem;
                                            background-color: #fff;

                                            button {
                                                background-color: inherit;
                                                border: none;
                                                font-size: 0;
                                                display: inline-block;
                                                width: 100%;
                                                height: 46px;
                                                background-size: contain;
                                                background-repeat: no-repeat;
                                                background-position: center center;
                                                background-image: url(../svg/arrow_btn_black.svg);
                                                background-color: transparent;
                                                border: none;

                                                span {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }

                                    bloc_add_alert_confirmation {
                                        padding: 1rem;
                                    }

                                    .alert_return {
                                        position: absolute;
                                        top: 10px;
                                        right: 10px;
                                    }
                                }

                                &.calibrate {
                                    form {
                                        .form_submit {
                                            &.bloc_add_color {
                                                bottom: calc(100% + 64px);
                                            }
                                        }
                                    }
                                }
                            }

                            /*Style achat express v2 ends*/
                            &:hover {
                                .wrap_rolloverproduit {
                                    opacity: 1;
                                    z-index: 5;
                                }
                            }

                            .bloc_descrip {
                                .wrapper_description {
                                    flex-direction: column;
                                    width: calc(100% - 130px);
                                }

                                .wrap_rolloverproduit {
                                    bottom: 56px;
                                    z-index: 6;
                                    opacity: 1;

                                    .rollover_left.achat_express {
                                        .productColorFieldset {
                                            display: flex !important;
                                            position: absolute;
                                            top: calc(100% + 20px);
                                            right: 3px;
                                            width: 130px;
                                        }

                                        .input_check.disabled + label {
                                            color: #9a9a9a;
                                        }
                                    }
                                }
                            }
                        }

                        &.small_swiper {
                            @media only screen and (max-width: 1360px) {
                                max-width: 760px;
                            }
                        }

                        &.no_swiper {
                            & + .swiper-button-next {
                                display: none;

                                & + .swiper-button-prev {
                                    display: none;
                                }
                            }
                        }

                        /* PRODUIT INDISPONIBLE */
                        .productVisualMulti {
                            &.no_stock {
                                .swiper-slide {
                                    img {
                                        opacity: 0.3;
                                    }
                                }
                            }
                        }

                        .wrap_rolloverproduit {
                            &.no_stock {
                                opacity: 1 !important;
                                z-index: 5 !important;

                                .no_stock_wrap {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    height: 90px;
                                    font-family: $primaryFont;
                                    font-weight: 400;
                                    font-size: 14px;
                                    letter-spacing: 0.05em;
                                    text-transform: uppercase;
                                    text-align: center;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    z-index: 5;
                                    opacity: 1;
                                    transition: all 0.4s ease-in-out;
                                }
                            }
                        }
                    }

                    &.small_swiper {
                        max-width: inherit;

                        @media only screen and (max-width: 1360px) {
                            max-width: 960px;

                            #productAssociationSwiper,
                            .pushProductHomeWrapper_main {
                                max-width: 760px;
                            }
                        }
                    }

                    .wrapper_description {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        margin-top: 11px;

                        .title_n_price_wrapper {
                            width: 100%;
                            max-width: calc(100% - 105px);
                        }

                        .item_title {
                            font-size: 13px;
                            font-family: $primaryFont;
                            letter-spacing: 0.05em;
                            color: $darkColor;
                            font-weight: 400;
                        }

                        .item_price {
                            margin-top: 7px;
                            order: 2;

                            .pricetag {
                                color: $darkColor;
                                font-size: 16px;
                                letter-spacing: 0.05em;

                                & + .pricetag {
                                    color: #757472;
                                    font-size: 14px;
                                    margin-right: 7px;
                                    padding: 0 3px;
                                    position: relative;

                                    @media only screen and (max-width: 1360px) {
                                        font-size: 12px;
                                    }

                                    .currency {
                                        padding-left: 0.15rem;
                                    }

                                    &:before {
                                        content: '';
                                        height: 1px;
                                        background-color: #757472;
                                        width: 100%;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                    }
                                }
                            }

                            & + .item_price {
                                display: none;
                            }
                        }

                        .name {
                            width: calc(100% - 130px);
                            order: 1;

                            .name {
                                width: 100%;
                            }

                            .item_title,
                            h2 {
                                font-size: 13px;
                                font-family: $primaryFont;
                                font-weight: 200;
                                display: inline-flex;
                                color: $darkColor;
                                position: relative;
                            }
                        }
                    }

                    .productColorFieldset,
                    .productSizeFieldset {
                        .prod_listes {
                            .titre_choices_list {
                                display: none;
                            }
                        }

                        .choices_list {
                            [type='radio']:checked + label:before,
                            [type='radio']:not(:checked) + label:before,
                            [type='radio']:checked + label:after,
                            [type='radio']:not(:checked) + label:after {
                                display: none;
                            }
                        }
                    }

                    .productColorFieldset {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width: 100%;
                        max-width: 125px;

                        .prod_listes {
                            .titre_choices_list {
                                display: none;
                            }
                        }

                        .choices_list {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            flex-wrap: wrap;
                            width: 100%;

                            .form_itm.color {
                                margin: 0 0 5px 8px;

                                [type='radio']:checked,
                                [type='radio']:not(:checked) {
                                    & + label {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 18px;
                                        height: 18px;
                                        border-radius: 50%;
                                        margin: 0;
                                        border: solid 1px rgba(0, 0, 0, 0.075);
                                        transition: all 0.4s ease-in-out;
                                        background-size: contain;

                                        &[title='Blanc'],
                                        &[title='White'],
                                        &[title='Ecru'] {
                                            border: solid 1px #dddddd;
                                        }
                                    }
                                }

                                [type='radio']:checked {
                                    & + label {
                                        border: solid 1px #000000;

                                        &[title='Blanc'],
                                        &[title='White'],
                                        &[title='Ecru'] {
                                            border: solid 1px #000000;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#chatbot-insert {
    display: none !important;
}

#tete_gondole,
#gondole_aimerez,
.searchSwiper_container {
    .swiper-button-next:not(.rollover_rayonsNext),
    .swiper-button-prev:not(.rollover_rayonsPrev) {
        background: url(../svg/icones.svg) no-repeat;
        background-size: 500% auto;
        width: 1.2rem;
        height: 1.2rem;
        background-position: 75% 25%;
        top: -14%;
    }
}

body.bandeauDelivery{
    position: unset !important;
}

.rollover-pic-duo img {
    opacity: 0;
    transition: opacity ease-in 0.3s;

    &:hover {
        opacity: 1;
    }
}