/***@   lafiancee guide_taille page **/
.satellite_guide_taille {
	overflow-x: auto !important;
	#appContainer {
		.page_wrapper {
			.main_wrapper {

				/***@  On page responsive  **/
				@media only screen and (max-width: 1200px) {
					.page_satellite {
						width: 95% !important;
					}
				}

				.page_satellite {
					margin: auto;
					width: 80%;
					position: relative;

					.satellite_title{
						margin-bottom: 2.4rem;

						h1{
							font-size: 65px;
							padding: 45px 0 10px 0;
							font-weight: 700;
							text-align: center;
							font-family: $secondaryFont;

							@media only screen and (max-width: 1680px) {
							    font-size: 60px;
							}

							@media only screen and (max-width: 1370px) {
							    font-size: 50px;
							}

						}
						p{
							font-size: 0.8125rem;
							letter-spacing: 0.65px;
							text-align: center;
							font-family: $primaryFont;
						}
					}

					.satellite_content {
							display: flex;
							flex-direction: row;
							justify-content:center;
							margin-bottom: 8.5rem;
							color:#1b1c1e;

						.satellite_content_left {
							padding-right: 40px;

							.satellite_content_left_img {
								max-width: 630px;
							}
						}

						.satellite_content_right {
							display: flex;
							flex-direction: column;
							justify-content: center;
							margin:-0.25rem 0 0 -1.5rem;

							.sizeguide_content{
								display:flex;
								justify-content:space-around;

								.left_side_content{
									margin:0 2rem 0.85rem 0;
									width: 50%;
									.sizeguide_txt{
                                      margin-bottom: 0.8rem;
                                      font-size:0.8125rem;
                                      font-weight:600;
                                      text-transform: uppercase;
									}

									.block_text{
									   padding-bottom: 2.5rem;
									   line-height: 24px;
									   font-size:0.8125rem;
									   width:94%;

									  h3{
									 	font-weight: 600;
									 	font-size: 0.8125rem;
									 	letter-spacing: 1.25px;
									 }

									}
								}
							}

							.right_side_content{
								width:37%!important;
								margin-top: 2.6rem;
							    .satellite_content_right_img{
							 		max-width: 173px;
							 	}
							 }

							/***@   Table in page guide_taille **/
							table.sizeguide_tbl {
								width: 90%;
								border-collapse: collapse;
								border: 1px solid #b9b9b9;
								margin:1.5rem 0 0 1.5rem;
								text-align: center;
								color: #636260;

								.sizeguide_tbl_caption{
									text-align: justify;
									text-transform:uppercase;
									padding-bottom: 1rem;
									font-weight: 600;
									color: #1b1c1e;
									@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
									   margin-right:32rem!important;
									}
								}

								td {
									border-collapse: collapse;
									border: 1px solid #b9b9b9;
									padding: 5px 12px;
									height: 2.435em;
									width: 10em;
									font-family: $primaryFont;
									font-size:0.8125rem;
								}
								th {
									border-collapse: collapse;
									border: 1px solid #b9b9b9;
									padding: 5px 14px;
									font-size:0.8125rem;
									line-height: 20px;
									font-weight:400;
									color: black;
									height: 4.25em;
									width: 10em;
								}
							}
						}
					}
				}
			}
		}
	}
}