/******************************************************************************/
/* SITE COLORS */
/******************************************************************************/
$bgColor1:   	 #fdfdfd;

$white:      	 #FFFFFF;
$black:      	 #000000;

$greyTxt:    	 #EFEFEF;
$grey:       	 #bfbfbf;
$greyLine:   	 #c4c3c3;
$greylight:      #f8f8f8;

$bleuTxt:		 #000000;
$bleuBorder:     #EFEFEF;

$fadeGreyline:	 #e4e0dd;

$primaryColor:   #1b1c1e;
$secondaryColor: #fbf4ec;
$tertiaryColor:  #23574a;


$borderColor:  	 #f3f3f3;

$btnColor:       #000000;
$txtColor:		 #000000;

$titleColor:     #000000;
$darkTxt:    	 #414042;
$fadeTxt:   	 #888c8d;

$greenbg: 	 	 #31574A;
$validGreen: 	 #42cd02;
$redStock: 		 #ff0000;

$green:          #1dac36;
$bgColor2: 		 #fbf4ec;

$darkColor: 	 #333333;

$textForm: 		 #202020;
$bgColor: 		 #FBF4EC;

$reassuBg: #f3f3f3;