body.store_locator{
    .wrapper_store{
        @media screen and (max-width: 1463px){
            padding: 0 2.5%;
        }
    }
    .locator_heading{
        width: 100%;
        max-width: 1430px;
        margin: auto;
        margin-bottom: 2.25rem;
        h1 span{
            display: block;
            width: 100%;
            text-align: center;
            font-size: 65px;
            font-family: $secondaryFont;

            @media only screen and (max-width: 1680px) {
                    font-size: 60px;
            }


            @media only screen and (max-width: 1370px) {
                font-size: 50px;
            }
        }
    }
    form.storeloc_form{
        width: 100%;
        max-width: 1430px;
        margin: auto;
        margin-bottom: 2.35rem;
        .wrap_fields{
            display: flex;
            justify-content: center;
        }
        .field_address{
            display: flex;
            width: 100%;
            max-width: 42.25rem;
            margin-right: 0.875rem;

            @media screen and (max-width: 1115px) and (min-width: 800px) and (orientation: portrait){
                max-width: 29.25rem;
            }
            input{
                height: 3rem;
                border: 1px solid black;
                width: 100%;
                @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
                    background-color: transparent;
                }
            }
            .w-input-container{
                display: inline-block;
                width: 100%;
                max-width: 12.925rem;
                position: relative;
                &:after{
                    content: "\25b6\fe0e";
                    position: absolute;
                    top: 0.7rem;
                    right: 0.2rem;
                    font-size: 1rem;
                    color: darken($grey,5%);
                    transform: rotate(90deg);
                }
                label.w-input.w-dropdown{
                    height: 3rem;
                    &:after{
                        content: none;
                    }
                }
                #search_pays{
                    border: 1px solid $black;
                    background-color: transparent;
                    height: 3rem;
                    color: darken($grey,5%);
                }
            }
            #search_cp{
                width: 100%;
                max-width: 29rem;
                margin-left: 0.7rem;
                border: 1px solid #d9d9d9;
                @media screen and (max-width: 1115px) and (min-width: 800px) and (orientation: portrait){
                    max-width: 15rem;
                }
            }
        }
        div.ou{
            display: none;
        }
        button.recherche_btn{
            width: 14.1rem;
            height: 3rem;
            background-color: $black;
            border: 1px solid $black;
            transition: 0.15s ease-in-out;
            margin-right: 0.875rem;
            span{
                color: $white;
                font-size: 14px;
                letter-spacing: 1.4px;

                @media only screen and (max-width: 1370px) {
                    font-size: 13px;
                    letter-spacing: 1.3px;
                }

            }
            &:hover{
                background-color: transparent;

                span{
                    color: $black;
                }
            }
        }
        button.loc_btn{
            width: 14.2rem;
            height: 3rem;
            background-color: transparent;
            border: 1px solid $black;
            transition: 0.15s ease-in-out;
            span{
                color: $black;
                font-size: 14px;
                letter-spacing: 1.4px;

                @media only screen and (max-width: 1370px) {
                    font-size: 13px;
                    letter-spacing: 1.3px;
                }

            }
            &:hover{
                background-color: $black;

                span{
                    color: $white;
                }
            }
        }
    }
    .wrapper_retailers_map{
        width: 100%;
        max-width: 1430px;
        height: 31rem;
        margin: 0 auto 8.8rem;
        display: flex;
        overflow: hidden;
        .liste_recherche_distributeur {
            display: inline-block;
            width: 46%;
            padding: 2rem 3.5rem 1rem;
            z-index: 1;
            background-color: white;

            @media screen and (max-width: 920px) {
                padding: 2rem 2.5rem 1rem;
            }

            .resultat_recherche {
                color: $primaryColor;
                margin-bottom: 17px;
                h3, .result_title {
                    font-family: $secondaryFont;
                    font-weight: 700;
                    margin-right: 5px;
                    font-size: 1.85rem;
                    letter-spacing: -0.75px;
                    width: 100%;
                    display: block;
                }
                span{
                    width: 100%;
                    display: block;
                }
                #nb_magasins{
                    font-family: $primaryFont;
                    font-size: 0.95rem;
                    font-weight: 100;
                    margin-top: 0.25rem;
                    text-transform: uppercase;
                }
            }
            .liste_distributeur_wrapper {
                height: 350px;
                .liste_distributeur {
                    padding-right: 15px;
                    .elem_liste_distributeur {
                        color: $black;
                        cursor: pointer;
                        padding-bottom: 25px;
                        position: relative;
                        &:after{
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 10rem;
                            height: 1px;
                            background-color: lighten($black, 40%);

                            @media only screen and (max-width: 840px) {
                                width: 8rem;
                            }
                        }
                        .nom_store h2{
                            font-family: $primaryFont;
                            font-size: 13px;
                            letter-spacing: 0.25px;
                            font-weight: 600;
                            margin-bottom: 5px;
                            text-transform: capitalize;
                            margin-top: 0.3rem;
                        }
                        .detail_store {
                            font-family: $primaryFont;
                            font-weight: 100;
                            font-size: .7rem;
                            margin-top: 0.15rem;
                            line-height: 1.8;

                            @media screen and (max-width: 920px) {
                                font-size: .65rem;
                            }
                            
                            .pays_store{
                                opacity: 0;
                            }
                            .horaire_title,
                            .horaire{
                                display: none;
                            }
                        }
                        &:nth-last-child(1) {
                            margin-bottom: 0;
                        }
                        .store_more{
                            margin-top: 1.01rem;
                            a{
                                text-decoration: underline;
                                font-size: 0.81rem;
                                letter-spacing: 0.5px;
                            }
                        }
                    }
                }
            }
            #scrollContainer{
                .simplebar-track.simplebar-vertical{
                    width: 16px;
                    background-color: transparent;
                    cursor: pointer;
                    &:after{
                        content: '';
                        width: 1px;
                        height: 100%;
                        background-color: $black;
                        position: absolute;
                        left: 7px;
                        cursor: pointer;
                    }
                }
                .simplebar-scrollbar{
                    width: 16px;
                    height: 16px;
                    border-radius: 1px;
                    background-color: transparent;
                    transform: translateX(-97%);
                    cursor: pointer;
                    &::before {
                        background: $black;
                        border-radius: 100%;
                        width: 12px;
                        height: 12px;
                        left: 2px;
                        cursor: pointer;
                    }
                }
            }
        }
        #retailers_map {
            width: 54%;
            height: 500px;
            padding-bottom: 35%;
            margin-bottom: 170px;
            .gm-style {
                .gm-style .gm-style-iw-c {
                    border-radius: 0;
                    width: 350px;
                    height: 180px;
                    padding: 40px;
                    
                    .info_popup {                                           
                        font-family: Times New Roman;                                           
                        font-size: 14px;
                        .name a {
                            letter-spacing: 1.5px;
                            text-transform: uppercase;
                            font-weight: bold;
                            font-family: Proximanova,serif;                                             
                        }

                        .adresse {
                            font-family: Times New Roman;
                            margin: 0.75rem 0 0.9rem;
                        }

                        .cta_row a {                                                
                            padding-right: 1.5rem;
                            font-size: 15px;
                            color: #ABABAB;
                            text-decoration: underline;

                        }
                    }
                }

                div[aria-label="Map"] {
                    div {
                        &:last-of-type {
                            min-width: 50px;
                        }
                    }
                }
            }
            iframe + div{
                border: none !important;
            }
        }
    }
}
body.satellite_store_details{
    #breadcrumbs {
        .breadcrumb {
            a {
                display: inline-block;
                text-transform: none;
                &:first-letter {
                    text-transform: uppercase;
                }

                &:after{
                    display: inline-block;
                }
            }

            &.current span {
                display: inline-block;
                text-transform: none;
                
                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }

    .wrapper_moncompte.masgasin_pages{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1680px;
        margin: auto;
        margin-bottom: 9.35rem;
        @media screen and (max-width: 1720px){
            padding: 0 1.5rem;
        }
        .locator_heading{
            width: 100%;
            max-width: 1430px;
            margin: auto;
            margin-bottom: 2.25rem;
            margin-top: 1.3rem;
            .title {
                span{
                    font-size: 65px;
                    font-family: $secondaryFont;
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-weight: 600;

                    & + span{
                        font-weight: 400;
                        font-size: 1rem;
                        font-family: $primaryFont;

                        h1 {
                            font-weight: 400;
                            font-size: 1rem;
                            font-family: $primaryFont;
                        }
                    }

                    @media only screen and (max-width: 1680px) {
                            font-size: 60px;
                    }
                    @media only screen and (max-width: 1520px) {
                            font-size: 55px;
                    }

                    @media only screen and (max-width: 1370px) {
                        font-size: 50px;
                    }
                }
            }
        }
    }
    #distribute_section_a{
        width: 33.5%;
        background: white;
        padding: 5.1% 3.85%;
        .store_direction.btn_store_container{
            margin-top: 1.12rem;
            a, h2{
                background: transparent;
                height: auto;
                width: auto;
                text-transform: initial;
                text-decoration: underline;
                color: $black;
                display: block;
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
        .horraires_ctn{
            h2{
                margin-top: 1.55rem;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 0.78rem;
                letter-spacing: 0.75px;
            }
        }
        .bloc_store_det{
            margin-top: 1.55rem;
            table{
                width: 100% !important;
                tr{
                    display: flex;
                    width: 100%;
                    td{
                        width: 60%;
                        line-height: 1.35rem;
                        &:nth-child(2){
                            width: 100%;
                            text-align: right;
                        }
                    }
                }
            }
            .store_close{
                display: none;
            }
            .store_open,
            .annonce_gov{
                font-family: $primaryFont;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 1.5;
                margin-top: 1rem;
                display: block;

                &:first-letter{
                    text-transform: uppercase;
                }
            }
        }
        .distribute_info{
            .distribute_title .store_name_title{
                font-family: $primaryFont;
                font-size: 0.81rem;
                letter-spacing: 1.25px;
                font-weight: 600;
                text-transform: capitalize;
                margin-top: 0.3rem;
                margin-bottom: 0.68rem;
            }
            .distribute_adress{
                p{
                    font-family: $primaryFont;
                    font-weight: 100;
                    font-size: .725rem;
                    line-height: 1.8;
                    &.contact_itm{
                        font-size: 0.82rem;
                    }
                    &.mail_boutik{
                        font-size: .82rem;
                    }
                }
                .adrr_info.mail{
                    font-size: .8rem;
                    letter-spacing: 0.5px;

                    @media only screen and (max-width: 1100px) {
                        letter-spacing: 0px;
                    }

                    @media only screen and (max-width: 960px) {
                        word-wrap: break-word;
                    }
                }
            }
        }
    }
    #distribute_section_b{
        width: 66.5%;
        #store_detail_map{
            /*padding-bottom: 51.8%;*/
        }
        .store_direction.btn_store_container{
            display: none;
        }
    }
    .descrip_avis_ctn{
        display: none;
    }
    .store_img{
        width: 100%;
        max-width: 1680px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media screen and (max-width: 1720px){
            padding: 0 1.5rem;
        }
        .boutik_img{
            padding-bottom: 5rem;
            &:nth-child(3n+1),
            &:nth-child(3n+3){
                width: 24.19%;
            }
            &:nth-child(3n+2){
                width: 42.143%;
            }
        }
    }
}

#retailers_map .info_popup {
    line-height: 1.4;

    .name {
        font-weight: 600;
    }

    .coord {
        margin-top: .5rem;
        text-transform: capitalize;
    }
}