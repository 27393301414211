body.product_page {
    padding-top: 81px;

    @media only screen and (max-width: 1360px) {
        padding-top: 100px;
    }

    &.bandeauDelivery {
        padding-top: 113px;

        @media only screen and (max-width: 1370px) {
            padding-top: 93px;
        }

        @media only screen and (max-width: 1024px) {
            padding-top: 133px;
        }
    }

    .page_wrapper {
        .main_wrapper {

            @media only screen and (max-width: 1024px) {
                min-width: inherit;
            }

            .page_produit {
                position: relative;
                .ariane_wrapper {
                    width: 100%;
                    max-width: 1682px;
                    position: relative;
                    margin: auto;

                     #breadcrumbs {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 43.5%;
                        padding: 0 !important;

                        @media only screen and (min-width: 2000px) {
                            width: 48.5%;
                        }

                        @media only screen and (max-height: 900px) and (max-width: 2000px){
                            width: 48.5%;
                        }

                        nav {
                            flex-wrap: wrap;
                        }

                        @media only screen and (max-width: 1560px) {
                            margin-top: 16px;
                             width: 40.5%;
                        }

                        @media only screen and (max-width: 1160px) {
                            width: 50.5%;
                        }

                        @media only screen and (max-width: 1024px) {
                            width: 50%;
                        }

                    }
                }
               
                .product_main_wrapper {
                   width: 100%;
                    padding-bottom: 145px;

                    @media only screen and (max-width: 1370px) {
                        padding-bottom: 60px;
                    }

                    .prod_top_wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        max-width: 1682px;
                        margin: auto;

                        @media only screen and (max-width: 1720px) {
                            padding: 0 32px;
                        }

                        .prod_btn_retour_wrapper {
                            width: 52.5%;

                            @media only screen and (min-width: 2000px) {
                                width: 47.5%;
                            }

                            @media only screen and (max-height: 900px) and (max-width: 2000px){
                                width: 47.5%;
                            }

                            @media only screen and (max-width: 1680px) {
                                width: 57.5%;
                            }

                            @media only screen and (max-width: 1370px) {
                                width: 56%;
                            }

                            @media only screen and (max-width: 1160px) {
                             width: 43%;
                            }

                            @media only screen and (max-width: 1024px) {
                                width: 47%;
                            }

                            .product_left_image {
                                display: flex;
                                justify-content: flex-start;
                                width: 100%;

                                @media only screen and (max-width: 1160px) {
                                    flex-wrap: wrap;
                                }

                                .main_vis {
                                    width: 100%;
                                    max-width: 606px;
                                    height: 788px;
                                    margin: 0 24px 0 0;
                                    max-height: calc(100vh - 120px);

                                    @media only screen and (min-width: 2000px) {
                                        max-width: 504px;
                                    }

                                    @media only screen and (max-width: 1440px) {
                                        max-width: 504px;
                                    }

                                    @media only screen and (max-height: 900px) and (max-width: 2000px){
                                        max-width: 504px;
                                        height: 649px;
                                    }

                                    @media only screen and (max-height: 705px) and (max-width: 2000px){
                                        max-width: 440px;
                                    }

                                    @media only screen and (max-height: 660px) and (max-width: 2000px){
                                        max-width: 420px;
                                    }

                                    @media only screen and (max-height: 600px) and (max-width: 2000px){
                                        max-width: 370px;
                                    }

                                    @media only screen and (max-height: 600px) and (max-width: 1440px){
                                        max-width: 380px;
                                    }

                                    @media only screen and (max-width: 1370px) {
                                        height: 650px;
                                        margin: 0 34px 0 0;
                                    }

                                    @media only screen and (max-width: 1160px) {
                                        height: 615px;
                                        margin: 0 0 30px 0;
                                        max-width: 100%;
                                        width: 100%;
                                     }

                                     @media only screen and (max-width: 1024px) {
                                        height: 570px;
                                     }

                                     @media only screen and (max-width: 960px) {
                                        height: 495px;
                                     }

                                     @media only screen and (max-width: 850px) {
                                        height: 425px;
                                     }

                                     .productBigPictures {
                                        .swiper-slide {
                                            overflow: hidden;
                                            
                                            img {

                                                &:hover {
                                                    cursor: url('../img/zoom.svg'), auto;
                                                } 
                                            }

                                            .block_vid {
                                                display: flex;
                                                align-items: flex-start;
                                                justify-content: flex-start;
                                                position: relative;
                                                width: 100%;
                                                height: 0;
                                                padding-bottom: calc((780 / 606) * 100%);

                                                iframe {
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                    width: 100%;
                                                    height: 100%;
                                                }
                                            }
                                        }
                                     }

                                    .pdt_icon_btm {
                                        position: absolute;
                                        top: 23px;
                                        left: 23px;
                                        z-index: 10;

                                        @media only screen and (max-width: 1360px) {
                                            top: 6%;
                                            left: 5%;
                                        }

                                        img {
                                            width: auto;
                                            height: 24px;
                                        }

                                        
                                        .eclat_rect_place {

                                            img {
                                                height: 24px;
                                                width: auto;
                                            }
                                        }
                                    }

                                    .eclatProd {

                                        position: absolute;
                                        bottom: 23px;
                                        right: 23px;
                                        z-index: 10;

                                        img {
                                            width: auto;
                                            height: 22px;
                                        }

                                        @media only screen and (max-width: 1023px) {
                                            bottom: 60px
                                        }
                                    }
                                }

                                .more_vis {
                                    width: 22%;
                                    height: 788px;
                                    overflow: hidden;
                                    position: relative;
                                    max-height: calc(100vh - 120px);

                                    .swiper-slide {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-image: inherit !important;
                                    }

                                    @media only screen and (max-height: 900px) and (max-width: 2000px){
                                        height: 649px;
                                    }

                                    @media only screen and (max-width: 1370px) {
                                        width: 24%;
                                        height: 650px;
                                    }

                                    @media only screen and (max-width: 1260px) {
                                        width: 20%;
                                    }

                                    @media only screen and (max-width: 1160px) {
                                        width: 100%;
                                        height: auto;
                                        padding: 0 32px;
                                        margin-bottom: 60px;
                                    }
                                   
                                    #visual_scroller {
                                        width: 139px;
                                        max-width: 100%;
                                        margin: 0;
                                         max-height: calc(100vh - 120px);

                                         @media only screen and (max-width: 1440px) {
                                             width: 111px;
                                         }

                                        @media only screen and (max-height: 668px) and (max-width: 1440px){
                                            width: 131px;
                                        }

                                        @media only screen and (max-height: 600px) and (max-width: 1440px){
                                            width: 121px;
                                        }

                                        @media only screen and (max-width: 1420px) {
                                            width: 92px;
                                        }

                                        @media only screen and (max-width: 1366px) {
                                            width: 112px;
                                        }

                                        @media only screen and (max-height: 767px) and (max-width: 1366px){
                                            width: 93px;
                                        }

                                        @media only screen and (max-width: 1300px) {
                                            width: 86px;
                                        }

                                        @media only screen and (max-width: 1160px) {
                                            width: 100%;
                                        }

                                        .swiper-slide {
                                            display: flex;
                                            
                                            .content_img_visu {
                                                .video_holder {
                                                    display: flex;
                                                    position: relative;

                                                    &:before {
                                                        content:'';
                                                        position: absolute;
                                                        top: 0;
                                                        bottom: 0;
                                                        right: 0;
                                                        left: 0;
                                                        margin: auto;
                                                        background: rgba(0,0,0,0.45) url(../img/play.png) center center no-repeat;
                                                        background-size: auto;
                                                    }

                                                }
                                            }

                                            &.swiper-slide-thumb-active {
                                                img {
                                                    border: 1px solid #000;
                                                    display: block;
                                                }
                                            }
                                        }
                                    }

                                    .thumbPrev,
                                    .thumbNext {
                                        position: absolute;
                                        top: 55px;
                                        right: -35px;
                                        height: 10px;
                                        width: 78px;
                                        z-index: 5;
                                        background: transparent url(../svg/arrow_btn_black.svg) center center no-repeat;
                                        background-size: contain;
                                        transform: rotate(-90deg);
                                        cursor: pointer;

                                        &.swiper-button-disabled {
                                            cursor: default;
                                            opacity: .5;
                                        }

                                        @media only screen and (max-width: 1160px) {
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;
                                            left: 0;
                                            right: inherit;
                                            transform: rotate(180deg);
                                        }
                                    }

                                    .thumbNext {
                                        transform: rotate(90deg);
                                        top: inherit;
                                        bottom: 55px;

                                        @media only screen and (max-width: 1160px) {
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;
                                            left: inherit;
                                            right: 0;
                                            transform: rotate(0);
                                        }

                                    }
                                }
                            }
                        }

                        .product_info_wrapper {
                            width: 43.5%;
                            padding-bottom: 5rem;
                            margin-top: 120px;

                            @media only screen and (min-width: 2000px) {
                                width: 48.5%;
                            }

                            @media only screen and (max-height: 900px) and (max-width: 2000px){
                                width: 48.5%;
                            }

                            @media only screen and (max-width: 1560px) {
                                margin-top: 90px;
                                 width: 40.5%;
                            }

                            @media only screen and (max-width: 1370px) {
                                margin-top: 70px;
                            }

                            @media only screen and (max-width: 1160px) {
                                width: 50.5%;
                            }

                            @media only screen and (max-width: 1024px) {
                                 width: 50%;
                            }

                            #breadcrumbs {
                                margin-bottom: 65px;
                                width: 100%;

                                .breadcrumbs {
                                    flex-wrap: wrap;
                                }

                                @media only screen and (max-width: 1560px) {
                                    margin-bottom: 25px;
                                    padding: 0;
                                }

                            }

                            .product_info {
                                width: 100%;

                                .item_name {

                                    margin-bottom:  2rem;
                                    position: relative;

                                    @media only screen and (max-width: 1560px) {
                                        margin-bottom:  1rem;
                                    }

                                    #itm_name {
                                        font-size: 40px;
                                        font-family: $secondaryFont;
                                        letter-spacing: -0.025em;
                                        color: $darkColor;
                                        margin-bottom: .5rem;
                                        width: calc(100% - 40px);
                                        line-height: 1;
                                        text-transform: lowercase;
		
                                        &:first-letter{
                                            text-transform: uppercase;
                                        }

                                        @media only screen and (max-width: 1560px) {
                                            font-size: 38px;
                                        }

                                        @media only screen and (max-width: 1460px) {
                                            font-size: 35px;
                                        }

                                        @media only screen and (max-width: 1370px) {
                                            font-size: 28px;
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            font-size: 24px;
                                        }

                                        &:first-letter {
                                            text-transform: uppercase;
                                        }


                                        small {
                                            display: inline-block;
                                            font-size: 16px;
                                            text-transform: lowercase;
                                            font-family: $primaryFont;
                                            font-weight: 400;
                                            letter-spacing: 0;
		
                                            &:first-letter{
                                                text-transform: uppercase;
                                            }

                                            @media only screen and (max-width: 1560px) {
                                                font-size: 15px;
                                            }

                                            @media only screen and (max-width: 1460px) {
                                                font-size: 14px;
                                            }

                                            @media only screen and (max-width: 1370px) {
                                                font-size: 13px;
                                            }

                                            @media only screen and (max-width: 1024px) {
                                                font-size: 12px;
                                            }
                                        }
                                    }

                                    @media only screen and (max-width: 1370px) {
                                        margin-bottom:  1.25rem;
                                    }

                                }

                                .item_price_wrapper {
                                    margin-bottom: 40px;

                                    @media only screen and (max-width: 1560px) {
                                         margin-bottom: 20px;
                                    }

                                    .item_price {
                                        .price_tag {
                                            font-family: $primaryFont;
                                            font-size: 22px;
                                            letter-spacing: 0.05em;
                                            font-weight: 600;
                                            color: $darkColor;

                                            @media only screen and (max-width: 1560px) {
                                                font-size: 21px;
                                            }

                                            @media only screen and (max-width: 1460px) {
                                                font-size: 20px;
                                            }

                                            @media only screen and (max-width: 1370px) {
                                                font-size: 18px;
                                            }

                                            @media only screen and (max-width: 1024px) {
                                                font-size: 16px;
                                            }

                                            &+ .price_tag {
                                                color: #757472;
                                                font-size: 18px;
                                                margin-right: 23px;
                                                padding: 0 9px;
                                                position: relative;

                                                @media only screen and (max-width: 1560px) {
                                                    font-size: 17px;
                                                }

                                                @media only screen and (max-width: 1460px) {
                                                    font-size: 16px;
                                                }

                                                @media only screen and (max-width: 1370px) {
                                                    font-size: 15px;
                                                }

                                                @media only screen and (max-width: 1024px) {
                                                    font-size: 14px;
                                                }

                                                .currency {
                                                    padding-left: .35rem;
                                                }

                                                &:before {
                                                    content: '';
                                                    height: 2px;
                                                    background-color: #757472;
                                                    width: 100%;
                                                    position: absolute;
                                                    top: 0;
                                                    bottom: 0;
                                                    left: 0;
                                                    right: 0;
                                                    margin: auto;
                                                }
                                            }
                                        }
                                    }
                                }

                                .itm_form_group {

                                    &#product_selects {
                                        .ligne_form {
                                            &#ligne_couleur {
                                                height: auto;
                                                max-height: 128px;
                                                margin-bottom: 2rem;
                                                width: 100%;
                                               

                                                @media only screen and (max-width: 1560px) {
                                                    margin-bottom: 1.25rem;
                                                }

                                                 @media only screen and (max-width: 1370px) {
                                                    height: 92px;
                                                    margin-bottom: 1rem;
                                                 }


                                                .prod_listes  {
                                                    width: 100%;

                                                    .dropp {
                                                        width:100%;
                                                        height: 122px;
                                                        margin: auto;
                                                        background: transparent;
                                                        position: relative;

                                                        @media only screen and (max-width: 1560px) {
                                                            height: 112px;
                                                        }

                                                        @media only screen and (max-width: 1370px) {
                                                            height: 92px;
                                                        }

                                                        .drop_btn_big {
                                                            position: absolute;
                                                            height: 122px;
                                                            left: 0;
                                                            top: 0;
                                                            width: 100%;
                                                            z-index: 10;
                                                            cursor: pointer;
                                                            background-color: transparent;

                                                            @media only screen and (max-width: 1560px) {
                                                                height: 112px;
                                                            }

                                                            @media only screen and (max-width: 1370px) {
                                                                height: 92px;
                                                            }

                                                            .form_itm.color {
                                                                opacity: 0;
                                                            }

                                                            &.opened {
                                                                border-bottom: 0 solid #989898;
                                                                z-index: -1;

                                                                &+.dropp-header {
                                                                    .js-visible {
                                                                        .form_itm.color {
                                                                            &.active {
                                                                                margin-bottom: 0;
                                                                                border-bottom: 1px solid #9d9894;

                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                        }

                                                        .no_dropdown {
                                                            cursor: default;
                                                        }

                                                        .dropp-header {
                                                            display: block;
                                                            width: 100%;
                                                            height: 100%;
                                                            position: relative;
                                                        }

                                                        .dropp-header__btn {
                                                            display: block;
                                                            transition: all .3s ease-in-out;
                                                            position: absolute;
                                                            right: 30px;
                                                            top: 35px;
                                                            height: 50px;
                                                            width: 12px;
                                                            z-index: 5;
                                                            background: transparent url(../svg/fp_color_btn.svg) center center no-repeat;
                                                            background-size: contain;
                                                            cursor: pointer;

                                                            @media only screen and (max-width: 1560px) {
                                                                top: 30px;
                                                            }

                                                            @media only screen and (max-width: 1370px) {
                                                                right: 15px;
                                                                top: 21px;
                                                            }


                                                            &:hover {

                                                            }

                                                            &.drop_button {
                                                                transition: all .3s ease-in-out;
                                                            }
                                                        }
                                                    }

                                                    label.color_title, .titre_couleur {
                                                        font-size: 0;
                                                        display: none;
                                                    }

                                                    .choices_list {
                                                        border: 1px solid #989898;
                                                        padding: 0 23px 23px 23px;
                                                        background-color: $bgColor2;
                                                        position: relative;
                                                        z-index: 5;
                                                        height: 122px;
                                                        overflow: auto;
                                                        transition: all .5s ease-in-out;
                                                        display: flex;
                                                        flex-direction: column;
                                                        max-height: 120px;
                                                        
                                                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                                                        scrollbar-width: none;  /* Firefox */

                                                        &::-webkit-scrollbar { 
                                                            display: none;  /* Safari and Chrome */
                                                        }

                                                        @media only screen and (max-width: 1560px) {
                                                            height: 112px;
                                                            max-height: 110px;
                                                        }

                                                        @media only screen and (max-width: 1370px) {
                                                            height: 92px;
                                                            max-height: 92px;
                                                            padding:  0 15px 0;
                                                        }

                                                        .simplebar-wrapper {
                                                                transition: all .5s ease-in-out;

                                                            .simplebar-content-wrapper {

                                                                .simplebar-content {
                                                                    display: flex;
                                                                    flex-direction: column;
                                                                    padding-bottom: 0 !important;
                                                                    transition: all .5s ease-in-out;
                                                                }
                                                            }
                                                        }

                                                        .simplebar-track {
                                                            opacity: 0;
                                                        }


                                                        .form_itm.color  {
                                                            position: relative;
                                                            order: 2;
                                                            padding: 20px 0;
                                                            border-bottom: 1px solid #9d9894;
                                                            opacity: 0;

                                                            @media only screen and (max-width: 1560px) {
                                                                padding: 15px 0;
                                                            }

                                                            &:last-of-type, &:only-child {
                                                                margin-bottom: 0;
                                                                border-bottom: none;
                                                                padding-bottom: 0;
                                                            }

                                                            &.active {
                                                                order: 1;

                                                                &:last-of-type {
                                                                    margin-bottom: 25px;
                                                                }
                                                            }

                                                            input {

                                                                position: absolute;
                                                                top: 0;
                                                                right: 0;
                                                                left: 0;
                                                                bottom: 0;
                                                                margin: auto;
                                                                z-index: 0;
                                                                cursor: pointer;
                                                                opacity: 0;
                                                                height: 100%;
                                                                width: 100%;

                                                                &+label {
                                                                    position: relative;
                                                                    width: 80px;
                                                                    height: 80px;
                                                                    border: 1px solid rgba(0,0,0,0.05);
                                                                    background-size: cover;
                                                                    cursor: pointer;
                                                                    align-items: center;
                                                                    z-index: -1;

                                                                    @media only screen and (max-width: 1370px) {
                                                                        width: 60px;
                                                                        height: 60px;
                                                                    }

                                                                    &:before, &:after {
                                                                        display: none;
                                                                    }

                                                                    .colorName {
                                                                        position: relative;
                                                                        left: 115px;
                                                                        width: 100%;
                                                                        font-size: 14px;
                                                                        font-family: $primaryFont;
                                                                        letter-spacing: 0.05em;
                                                                        white-space: nowrap;
                                                                        text-transform: lowercase;
                                                                        line-height: 74px;
                                                                        height: 100%;

                                                                        &::first-letter {
                                                                            text-transform: uppercase;
                                                                        }

                                                                        @media only screen and (max-width: 1460px) {
                                                                            font-size: 13px;
                                                                        }

                                                                        @media only screen and (max-width: 1370px) {
                                                                            line-height: 60px;
                                                                        }

                                                                        @media only screen and (max-width: 1024px) {
                                                                            font-size: 12px;
                                                                        }
                                                                    }

                                                                    
                                                                }

                                                                &:checked {

                                                                    &+label {
                                                                        border: 1px solid rgba(0,0,0,0.1);
                                                                        &:after {
                                                                            opacity: 1;
                                                                            width: 100%;
                                                                            height: 1px;
                                                                            background: rgba(0,0,0,0.1);
                                                                            position: absolute;
                                                                            bottom: 0;
                                                                            left: 0;
                                                                            top: inherit;
                                                                            z-index: 5;
                                                                            display: block;
                                        
                                                                        }
                                                                    }
                                                                }

                                                            }

                                                        }

                                                        &.actif {
                                                            max-height: inherit;
                                                            height: auto;

                                                             .form_itm.color  {
                                                                input {
                                                                    z-index: 5;
                                                                }
                                                            }
                                                        }

                                                        &.js-visible:not(.no_dropdown_list) {
                                                            transition: height .3s ease-in-out .5s;
                                                            display: flex;
                                                            flex-direction: column;
                                                            position: absolute;
                                                            bottom: 0;
                                                            right: 0;
                                                            height: auto;
                                                            max-height: 364px;
                                                            z-index: 4;
                                                            box-shadow: 0px 1px 0px rgba(0,0,0,.1);
                                                            width: 100%;
                                                            background: $bgColor2 url(../svg/fp_color_btn.svg) 96% 88% no-repeat;
                                                            background-size: 1.45%;

                                                            @media only screen and (max-width: 1560px) {
                                                                background-size: 1.7%;
                                                                max-height: 334px;
                                                            }

                                                            @media only screen and (max-width: 1460px) {
                                                                max-height: 322px;
                                                            }

                                                            @media only screen and (max-width: 1370px) {
                                                                max-height: 262px;
                                                            }

                                                            .flex_container {
                                                                display: none;
                                                            }

                                                            .simplebar-track {
                                                                opacity: 1;
                                                            }


                                                            .form_itm.color {

                                                                padding: 20px 0;

                                                                @media only screen and (max-width: 1560px) {
                                                                    padding: 15px 0;
                                                                }

                                                                @media only screen and (max-width: 1460px) {
                                                                    padding: 13px 0;
                                                                }


                                                                &.active {
                                                                    &:last-of-type {
                                                                        margin-bottom: 25px;
                                                                    }
                                                                }

                                                                &:nth-of-type(1) {

                                                                    &.active {
                                                                        border-top: none;
                                                                    }
                                                                }
                                                            }

                                                            & + .dropp-header__btn{
                                                                display: none;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &#ligne_pointure {
                                               .prod_listes {
                                                    &.size_wrapper {

                                                        display: flex;
                                                        justify-content: space-between;
                                                        align-items: center;
                                                        margin-bottom: 2rem;

                                                        @media only screen and (max-width: 1370px) {
                                                            margin-bottom: 1rem;
                                                        }


                                                        .size_title {
                                                            display: none;
                                                        }

                                                        .choices_list {
                                                            display: flex;
                                                            justify-content: flex-start;
                                                            align-items: center;
                                                            flex-wrap: wrap;
                                                            width: calc(100% - 130px);
                                                            padding-left: 5px;

                                                            .form_itm.size {
                                                                position: relative;
                                                                width: 50px;
                                                                height: 50px;
                                                                text-align: center;
                                                                margin-right: 7px;

                                                                input {
                                                                    position: absolute;
                                                                    top: 0;
                                                                    bottom: 0;
                                                                    right: 0;
                                                                    left: 0;
                                                                    margin: auto;
                                                                    width: 50px;
                                                                    height: 50px;
                                                                    opacity: 0;

                                                                    &+label {
                                                                        font-size: 16px;
                                                                        letter-spacing: 0.05em;
                                                                        text-align: center;
                                                                        position: absolute;
                                                                        top: 0;
                                                                        right: 0;
                                                                        left: 0;
                                                                        bottom: 0;
                                                                        margin: auto;
                                                                        width: 50px;
                                                                        height: 50px;
                                                                        line-height: 50px;
                                                                        display: block;
                                                                        text-align: center;

                                                                        @media only screen and (max-width: 1560px) {
                                                                            font-size: 15px;
                                                                        }

                                                                        @media only screen and (max-width: 1460px) {
                                                                            font-size: 14px;
                                                                        }

                                                                        @media only screen and (max-width: 1024px) {
                                                                            font-size: 13px;
                                                                        }

                                                                        &:before {
                                                                            content:'';
                                                                            position: absolute;
                                                                            top: 0;
                                                                            right: 0;
                                                                            bottom: 0;
                                                                            bottom: 0;
                                                                            margin: auto;
                                                                            width: 50px;
                                                                            height: 50px;
                                                                            background-color: transparent;
                                                                            border: 1px solid transparent;

                                                                        }

                                                                    }

                                                                    &:checked {
                                                                        &+label {
                                                                            &:before {
                                                                                border: 1px solid #989898;
                                                                            }

                                                                            &:after {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                    }

                                                                    &.disabled {
                                                                        &+label {
                                                                            text-decoration: line-through;
                                                                        }

                                                                        &:checked {
                                                                            &+label {
                                                                                text-decoration: none;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                @media only screen and (max-width: 1560px) {
                                                                    width: 40px;
                                                                    height: 40px;

                                                                    input {
                                                                        width: 40px;
                                                                        height: 40px;

                                                                        &+label {
                                                                            font-size: 13px;
                                                                            width: 40px;
                                                                            height: 40px;
                                                                            line-height: 40px;

                                                                            &:before {
                                                                                width: 40px;
                                                                                height: 40px;
                                                                            }

                                                                        }

                                                                    }
                                                                }



                                                            }
                                                        }

                                                        .block_sizeguide {
                                                            .sizeguide {
                                                                font-size: 13px;
                                                                color: $darkColor;
                                                                font-family: $primaryFont;
                                                                font-weight: 600;
                                                                letter-spacing: 0.05em;
                                                                display: inline-block;
                                                                border-bottom: 1px solid #777;

                                                                @media only screen and (max-width: 1460px) {
                                                                    font-size: 12.5px;
                                                                }

                                                                @media only screen and (max-width: 1370px) {
                                                                    font-size: 12px;
                                                                }

                                                                @media only screen and (max-width: 1024px) {
                                                                    font-size: 11px;
                                                                }
                                                            }
                                                        }
                                                    }
                                               }

                                               .disabledBtnTooltip {
                                                    top: 118%;
                                                    border: none;
                                                    background-color: $bgColor2;
                                                    padding: 0;
                                                    min-width: inherit;

                                                    @media only screen and (max-width: 1370px) {
                                                        top: 95%;
                                                    }


                                                    &:before {
                                                        display: none;
                                                    }

                                                    .disabledBtnInfos {
                                                        font-size: 11px;
                                                        color: #ff3439;
                                                        letter-spacing: 0.05em;
                                                        font-weight: 600;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &#product_addcart {

                                        margin-bottom: 2rem;

                                        .prod_btn_wrapper {
                                            display: flex;
                                            justify-content: space-between;
                                            flex-wrap: wrap;
                                            position: relative;

                                            .form_submit {
                                                height: 72px;

                                                @media only screen and (max-width: 1370px) {
                                                    height: 62px;
                                                }

                                            }

                                            #sizeerror {
                                                position: absolute;
                                                top: -1.35rem;
                                                font-size: 11px;
                                                color: #ff3439;
                                                letter-spacing: 0.05em;
                                                font-weight: 600;
                                                display: none;

                                                @media only screen and (max-width: 1370px) {
                                                    font-size: 10px;
                                                }
                                            }

                                            #bloc_add_basket {
                                                max-width: calc(50% - 1rem);
                                                margin-right: 2rem;

                                                .button {
                                                    background-color: $darkColor;
                                                    border: 1px solid $darkColor;
                                                    letter-spacing: 0.1em;
                                                    color: #FFF;
                                                    font-weight: 600;
                                                    font-size: 14px;
                                                    font-family: $primaryFont;
                                                    opacity: 1;

                                                    @media only screen and (max-width: 1560px) {
                                                        font-size: 12px;
                                                    }

                                                    @media only screen and (max-width: 1300px) {
                                                         font-size: 10px;
                                                    }
                                                }

                                                .loader {
                                                    height: 72px;
                                                    background-size: 15% !important;

                                                    &.loading {
                                                        background-size: 15% !important;
                                                    }

                                                     @media only screen and (max-width: 1370px) {
                                                        height: 62px;
                                                     }
                                                }



                                                &.inactiv {
                                                    opacity: .85;
                                                }

                                                &.active_btn {
                                                    .button {
                                                        opacity: 1;
                                                        background-color: $primaryColor;
                                                        border-color: $primaryColor;
                                                    }
                                                }
                                            }

                                            #bloc_add_alert {
                                                max-width: calc(50% - 1rem);
                                                margin-right: 2rem;

                                                #btn_add_alert {
                                                    background-color: #ff3439;
                                                    color: #FFF;
                                                    letter-spacing: 0.1em;
                                                    font-size: 14px;
                                                    font-family: $primaryFont;
                                                    opacity: 1;
                                                    border-color: #ff3439;

                                                    @media only screen and (max-width: 1560px) {
                                                        font-size: 12px;
                                                    }
                                                }


                                                /* Alert stock */

                                                #keep_alive_stock {
                                                    .w-alertestock-form {
                                                        width: 100%;
                                                        height: 72px;
                                                        position: relative;

                                                        @media only screen and (max-width: 1370px) {
                                                            height: 62px;
                                                        }

                                                        .w-form-line {

                                                            .w-input-container {
                                                                .w-input {
                                                                    #mail_alerte_stock {
                                                                        border: 1px solid red;
                                                                        height: 72px;
                                                                        padding-right: 32%;

                                                                        @media only screen and (max-width: 1370px) {
                                                                            height: 62px;
                                                                        }

                                                                        &:-internal-autofill-selected {
                                                                            box-shadow: inset 0 0 0 3.125em $secondaryColor;
                                                                        }
                                                                    }

                                                                    .w-input-label {
                                                                        color: $darkColor;
                                                                        font-weight: 500;
                                                                    }

                                                                    &.w-focused {
                                                                        #mail_alerte_stock {
                                                                            &:-internal-autofill-selected {
                                                                                box-shadow: inset 0 0 0 3.125em $secondaryColor;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            &.w-submit {

                                                                max-width: 110px;
                                                                width: 32%;
                                                                height: 50px;
                                                                position: absolute;
                                                                right: 1px;
                                                                top: 0;
                                                                bottom: 0;
                                                                margin: auto;
                                                                padding: 0 1rem 0 0;

                                                                .w-submit-button {
                                                                    font-size: 0;
                                                                    display: inline-block;
                                                                    width: 100%;
                                                                    height: 50px;
                                                                    background-size: contain;
                                                                    background-repeat: no-repeat;
                                                                    background-position: center center;
                                                                    background-image: url(../svg/arrow_btn_black.svg);
                                                                    background-color: transparent;
                                                                    border: none;

                                                                    span {
                                                                        display: none;
                                                                    }

                                                                    &+.w-loader {
                                                                        &.loading {
                                                                            display: none;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .bloc_add_alert_confirmation {
                                                    padding: 1rem;

                                                    p {
                                                        position: relative;
                                                        top: -1.25rem;
                                                    }

                                                    .w-submit-button {
                                                        background-color: none;
                                                        position: absolute;
                                                        z-index: 5;
                                                        top: 0;
                                                        right: 0;
                                                        background-color: transparent;
                                                        cursor: pointer;
                                                        opacity: 1;
                                                        transition: opacity 0.3s ease-in-out;
                                                        font-size: 0;
                                                        background: url(../img/close.png) center center no-repeat;
                                                        width: 12px;
                                                        height: 12px;
                                                        display: block;
                                                        border: none;

                                                        span {
                                                            display: none;
                                                        }
                                                    }
                                                }

                                            }

                                            #bloc_reserve_instore {
                                                width: 100%;
                                                max-width: calc(50% - 1rem);

                                                #btn_resa_shop {
                                                    background-color: $bgColor2;
                                                    letter-spacing: 0.1em;
                                                    color: $darkColor;
                                                    font-weight: 600;
                                                    font-size: 14px;
                                                    font-family: $primaryFont;
                                                    opacity: 1;

                                                    span{
                                                        font-weight: 600;
                                                    }

                                                    @media only screen and (max-width: 1560px) {
                                                        font-size: 12px;

                                                        span {
                                                             font-size: 12px;
                                                        }
                                                    }

                                                    @media only screen and (max-width: 1300px) {
                                                        font-size: 10px;

                                                        span {
                                                             font-size: 10px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                #product_selects {
                                    width: 100%;
                                }
                            }

                            .product_feats_wrapper {

                                margin-bottom:  15px;

                                .thumbnav {
                                    display: flex;

                                    .thumb {
                                        margin-right: 45px;
                                        margin-bottom: 22px;

                                        @media only screen and (max-width: 1370px) {
                                            margin-right: 40px;
                                        }

                                        @media only screen and (max-width: 1260px) {
                                            margin-right: 30px;
                                        }

                                        @media only screen and (max-width: 1160px) {
                                            margin-right: 15px;
                                        }

                                        &:last-child {
                                            margin: 0;
                                        }

                                        a, h2 {
                                            font-size: 16px;
                                            letter-spacing: .1em;
                                            color: $darkColor;
                                            display: inline-block;
                                            text-transform: uppercase;
                                            font-weight: 400;

                                            &.current {
                                                color: $darkColor;
                                                font-weight: 900;

                                                h2 {
                                                   font-weight: 600; 
                                                }
                                            }


                                            @media only screen and (max-width: 1560px) {
                                                font-size: 14px;
                                            }

                                            @media only screen and (max-width: 1160px) {
                                                font-size: 11px;
                                            }
                                        }



                                    }
                                }

                                .thumbcnt {
                                    padding: 0 0;
                                    background: transparent;

                                    .thumbslide {
                                        display: none;

                                        .itm_spec {
                                            width: 100%;
                                            text-align: left;
                                            font-size: 13px;
                                            letter-spacing: 0.05em;
                                            line-height: 21px;
                                            font-family: $primaryFont;

                                            li, span, a {
                                                font-size: 13px;
                                                letter-spacing: 0.05em;
                                                line-height: 21px;
                                                font-family: $primaryFont;

                                                @media only screen and (max-width: 1370px) {
                                                    font-size: 11px;
                                                }
                                            }

                                            td {
                                                border: 1px solid #ccc;
                                                padding: 10px;
                                                width: 50%;

                                                &:last-child {
                                                    text-align: center;
                                                }
                                            }
                                        }


                                        p {
                                            margin-bottom: .5rem;
                                        }


                                        &.current {
                                            display: block !important;
                                        }

                                        .thumbsilde_title {
                                            font-size: 14px;
                                            letter-spacing: 0.15em;
                                            font-family: $secondaryFont;
                                            color: #272525;
                                            font-weight: 600;
                                            margin: 15px 0 5px;
                                            text-transform: uppercase;

                                            @media only screen and (max-width: 1460px) {
                                                font-size: 13px;
                                            }

                                            @media only screen and (max-width: 1370px) {
                                                font-size: 12px;
                                            }
                                        }

                                        .contents {
                                        }

                                        p , p span, li, li a, li span {
                                            text-align: left;
                                            font-size: 13px;
                                            letter-spacing: 0.05em;
                                            line-height: 21px;
                                            font-family: $primaryFont;

                                            &.ref {
                                                font-family: $primaryFont;
                                                font-size: 13px;
                                                letter-spacing: 0.015em;
                                                text-transform: uppercase;
                                                font-weight: 400;
                                                line-height: 1;
                                            }

                                            @media only screen and (max-width: 1370px) {
                                                font-size: 11px;
                                            }
                                        }

                                        li {
                                            list-style-type: none;
                                            position: relative;

                                            &:before {
                                                content:"-";
                                                font-size: 13px;
                                                width: 10px;
                                                height: 10px;
                                                left: 0;
                                                top: 10px;
                                                margin-right: 5px;

                                                @media only screen and (max-width: 1370px) {
                                                    font-size: 11px;
                                                }
                                            }
                                        }
                                    }
                                }

                                .international_shipping {
                                    margin-top: .85rem;
                                }
                            }

                            .share_wrapper {
                                .itm_spec {
                                    h3 {
                                        display: inline-block;
                                        width: auto;
                                    }
                                    img {
                                        height: auto;
                                        width: auto;
                                        max-height: 30px;
                                    }
                                }
                            }


                            // CTA wishlist
                            #addToWishlistButtonContainer {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                right: 0;
                                top: 10px;


                                .btnWishlist  {
                                    width: 100%;
                                    height: 100%;
                                    background-color: transparent;
                                    border: none;
                                    position: relative;

                                    &:before {
                                        content:"";
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        margin: auto;
                                        width: 20px;
                                        height: 20px;
                                        background: url(../svg/wishlist.svg) center center no-repeat;
                                        background-size: contain;
                                    }

                                    &.existToWishlistButton {
                                        &:before {
                                            background: url(../svg/wishlist_exist.svg) center center no-repeat;
                                            background-size: contain;
                                        }
                                    }
                                }
                            }


                        }

                        #bloc_acces_fiche_produit {
                            display: none;
                        }
                    }
                }

                #gondole_derniers_articles_vus {
                    display: none;
                }

                #show_aimerez {
                    text-align: center;
                    margin-bottom: 90px;
                    font-size: 65px;
                    font-family: $secondaryFont;
                    color: $darkColor;
                    letter-spacing: -0.025em;

                     @media only screen and (max-width: 1680px) {
                        font-size: 60px;
                    }

                    @media only screen and (max-width: 1370px) {
                        margin-bottom: 55px;
                        font-size: 50px;
                    }
                }

                #show_aimerez, #gondole_aimerez {

                    padding: 0 32px;

                    &.product_associations_wrapper {
                        background: #FFF url(../img/fp_bg.jpg) bottom center no-repeat;
                        padding: 3.5rem 32px 5rem;
                        margin-bottom: 8rem;
                    }

                    .gondole_wrapper {
                        margin-bottom: 2.5rem;
                        #productAssociationSwiper {
                            max-width: 1260px;
                            overflow: hidden;

                            .swiper-wrapper {
                                .item {
                                    .imgWrapper {
                                        position: relative;

                                        .productBigPictures {

                                           .swiper-slide-on {
                                                opacity: 1;
                                                &.cache {
                                                    opacity: 0;
                                                    transition: opacity 1s ease-in-out .5s;
                                                }
                                           }
                                        }

                                        .eclat_rect {
                                            img {
                                                max-height: 24px;

                                                @media only screen and (max-width: 1370px) {
                                                    max-height: 22px;
                                                }
                                            }
                                        }

                                        .item_title {
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            width: 100%;
                                        }

                                        .no_stock_wrap {
                                            text-align: center;
                                            position: absolute;
                                            width: 100%;
                                            bottom: 0;
                                            top: 0;
                                            height: 7rem;
                                            margin: auto;
                                            z-index: 0;
                                            text-transform: uppercase;
                                            line-height: 1.5;
                                            padding: 1rem;
                                            display: block !important;
                                            transition: all .35s ease-in-out;

                                            small {
                                                font-size: .7rem;
                                                text-transform: none;
                                            }
                                        }
                                    }

                                    .productVisualMulti {
                                        .rollover_rayonsNext,
                                        .rollover_rayonsPrev {
                                            cursor: pointer;
                                            z-index: 50;
                                            opacity: 0;
                                            padding: 1rem;

                                            @media only screen and (max-width: 1360px) {
                                                width: 45px;
                                            }

                                        }

                                        .rollover_rayonsNext {
                                            right: 12px;
                                        }

                                        .rollover_rayonsPrev {
                                            left: 12px;
                                        }
                                    }

                                    &:hover {
                                        .rollover_rayonsNext,
                                        .rollover_rayonsPrev {
                                            opacity: 1;

                                            &.swiper-button-disabled {
                                                opacity: .5;
                                            }
                                        }
                                    }

                                    &.no_stock {
                                        .imgWrapper {
                                            .productVisualMulti {
                                                img {
                                                    opacity: .3;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .productVisualMulti {
                                .no_stock, &+.no_stock {
                                    .no_stock_wrap {
                                        text-align: center;
                                        position: absolute;
                                        width: 100%;
                                        bottom: 0;
                                        top: 0;
                                        height: 7rem;
                                        margin: auto;
                                        z-index: 0;
                                        text-transform: uppercase;
                                        line-height: 1.5;
                                        padding: 1rem;
                                        display: block !important;
                                        transition: all .35s ease-in-out;

                                        small {
                                            font-size: .7rem;
                                            text-transform: none;
                                        }
                                    }

                                    .wrap_rolloverproduit {
                                        form {
                                            .expressAlertStock {
                                                .bloc_add_alert_form {
                                                    input {
                                                        padding-right: 80px;

                                                        &:-webkit-autofill,
                                                        &:-webkit-autofill:hover, 
                                                        &:-webkit-autofill:focus, 
                                                        &:-webkit-autofill:active {
                                                            -webkit-box-shadow: 0 0 0 30px #FFF inset !important;
                                                            color: $darkColor !important;
                                                            -webkit-animation-fill-mode: both;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }

                            @media only screen and (max-width: 1360px) {
                                margin: auto;
                            }

                            & > .swiper-wrapper {

                                .swiper-slide {
                                    max-width: 100%;
                                }

                                & > .item.swiper-slide {
                                    position: relative;
                                    margin-right: 55px;
                                    max-width: calc(100% / 3 - 36px);

                                    @media only screen and (max-width: 1360px) {
                                       max-width: calc(100% / 3 - 20px);
                                    }

                                    @media only screen and (max-width: 1300px) {
                                       max-width: calc(100% / 2 - 20px);
                                    }

                                    .wrap_video {
                                        position: relative;
                                        height: 100%;

                                        iframe {
                                            max-width: 100%;
                                            position: relative;
                                            top: 0;
                                            left: 0;
                                        }

                                        img {
                                            opacity: 0;
                                        }
                                    }


                                    .pas-button-next,
                                    .pas-button-prev {
                                        opacity: 0; 
                                        margin: auto 0;
                                        height: 35px;
                                        transition: opacity 0.35s ease-in-out;
                                        z-index: 30;
                                        cursor: pointer;
                                        position: absolute;
                                        width: 71px;
                                        background: url(../svg/arrow_btn_black.svg) center center no-repeat;
                                        background-size: contain;
                                        position: absolute;
                                        height: 9px;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto;
                                        right: 0;

                                        &.swiper-button-disabled {
                                            opacity: 0;
                                        }

                                        @media only screen and (max-width: 1370px) {
                                            width: 45px;
                                        }
                                    }

                                    .pas-button-next {
                                        right: 16px;
                                    }

                                    .pas-button-prev {
                                        left: 16px;
                                        right: auto;
                                        transform: rotate(180deg);
                                    }

                                    .ecalt_rect_place {
                                        position: absolute;
                                        top: 15px;
                                        left: 15px;
                                        z-index: 5;

                                        img {
                                            width: auto;
                                            height: 22px;
                                        }

                                        @media only screen and (max-width: 1380px) {
                                            top: 10px;
                                            left: 10px;
                                        }
                                    }

                                    .eclat_rond {
                                        bottom: 10px;
                                        right: 10px;

                                        img {
                                            max-height: 22px;
                                        }

                                        @media only screen and (max-width: 1023px) {
                                            bottom: 65px;
                                        }
                                    }



                                    &:hover{

                                        .pas-button-next,
                                        .pas-button-prev {
                                            opacity: 1;

                                            &.swiper-button-disabled {
                                                opacity: .35;
                                                cursor: auto;
                                                pointer-events: none;
                                            }

                                        }
                                    }

                                }
                            }
                        }

                        /* To delete ?? */ 
                        .swiper-button-next.btn_nxt,
                        .swiper-button-prev.btn_prv {
                            &.swiper-button-disabled {
                                opacity: 0;
                            }
                        }

                        @media only screen and (max-width: 1360px) {
                            max-width: 960px;
                        }

                        @media only screen and (max-width: 1023px) {
                             max-width: 890px;
                        }

                        @media only screen and (max-width: 960px) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    #abox {
        width: 501px;
        height: 348px;
        overflow: hidden;

        .header_lightbox {
            margin-top: 1.25rem;
        }

        .box_wishlist {
            .wrapper_btn {
                margin-top: 45px;
            }
        }

        &:before {
            content: '';
            position: absolute;
            width: 600px;
            height: 300px;
            background-color: #FFF;
            border-radius: 100%;
            left: -45px;
            margin: auto;
            top: 68%;
            z-index: -1;
        }
    }
    #thumbs_scroller .has_video .video_holder {
        display: flex;
        position: relative;

        &:before {
            content:'';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            background: rgba(0,0,0,0.45) url(../img/play.png) center center no-repeat;
            background-size: auto;
        }

    }

}

#gondole_aimerez {
    
    @media screen and (max-width: 1400px) {
        .wrapper_description {
            width: calc(100% - 90px) !important;
        }
        .productColorFieldset {
            width: 85px !important;
            padding: 0;
        }
    }

    .wrap_rolloverproduit {
        background-color: transparent !important;
        top: calc(100% - 100px) !important;
        width: 100%;
        height: 3rem !important;
        position: absolute !important;
        bottom: auto !important;

        form {
            .rollover_left.achat_express {
                display: block !important;

                .addToWishlistButton span {
                    display: none;
                }
            }
        }


        .btnAddBasketWrapper.expressAlertStock {
            opacity: 0;
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: rgba(255,255,255,.85);
        }
    }

    .expressAlertStock {
        position: absolute !important;
    }
    
    .productSizeFieldset {
        position: absolute;
        bottom: 0;
        background-color: transparent;

        .productErrorFieldset{
            position: absolute;
            top: .5rem;
            font-size: .65rem;
            color: red;
        }
    }

    .form_submit.bloc_add_color {
        height: 78px !important;
        bottom: 0 !important;
        top: unset !important;
    }

    .productSizeFieldset,
    .form_submit.bloc_add_color {
        z-index: -1 !important;
        opacity: 0 !important;
        transition: all .3s;
    }

    .item.assoc,
    .item.reload_slide_colorchange {

        .productVisualMulti {
            .productBigPictures {
            }
        }

        &:hover {
            .productSizeFieldset {
                background-color: $white;
            }
    
            .productSizeFieldset,
            .form_submit.bloc_add_color {
                z-index: 6 !important;
                opacity: 1 !important;

                .w-submit-button {
                    & + .loader {
                        &.loading {
                            opacity: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .productColorFieldset {
        top: 100% !important;

        .prod_listes  {
            width: 100%;
            height: 100%;
            padding: 11px 0;

            .simplebar-content {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
            }
        }
    }

    .item {
        &:hover {
            .wrap_rolloverproduit {
                .btnAddBasketWrapper.expressAlertStock {
                    opacity: 1;
                }
            }
        }
    }
}

#e-resaBox{
    .steps_num{
        .step_num{
            text-transform: initial !important;
        }
    }
    .steps{
        #step3{
            .w-form-line.optin p.w-input-label span{
                width: 100%;
            }
        }
    }
}