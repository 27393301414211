body.cart #breadcrumbs{
    display: none;

    @media screen and (max-height: 668px) and (max-width: 1370px) {
        margin: 12px auto 0;
        padding: 0;
    }
}

// Sticky bloc de droite (devrait être simplement ".sticky")
.tunnel_sticky {
    position: relative;
}

// Etapier
.tunnel_track_pusher {
    padding: 4.2rem 0 7.3rem;

    @include queriesWshop (1199px, max) {
        padding: 3.5rem 0 5rem;
    }

    .tunnel_track_wrapper {
        max-width: 620px;
        margin: auto;

        .tunnel_track {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 25px;
            margin: 0 auto;

            @include queriesWshop (1300px, max) {
                justify-content: space-between;
            }

            .tunnel_step {
                display: flex;
                color: $black;
                margin-right: 2.6rem;

                @include queriesWshop (1350px, max) {
                    margin-right: 2rem;
                }

                @include queriesWshop (1199px, max) {
                    margin-right: .9rem;
                }

                &:last-child {
                    margin-right: 0;
                }

                span {
                    line-height: .9;
                    font-size: .75rem;
                    font-weight: 500;
                    letter-spacing: .5px;
                }

                &.on {
                    color: $black;
                    span {
                        border-bottom: 1px solid $black;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

#outsite_ue_notice {
    max-width: 1209px;
    width: 91.6%;
    padding-bottom: 2rem;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.cart,
.checkout {
    .main_wrapper {
        padding-bottom: 50px;

        & > div {
            max-width: calc(1285px + 2rem);
            width: 100%;
            padding: 0 2rem;
            margin: 0 auto;
        }
    }

    .wrapper_panier {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        max-width: 1285px;
        margin: 0 0 40px;

        .cart_main_table .cart_product_line {
            display: flex;
            flex-direction: column;
            position: relative;
            max-width: 222px;
            margin: 2rem 9% 0 0;

            @include queriesWshop(1330px, max) {
                margin-right: 3%;

                &:nth-of-type(3n+1) {
                    margin-right: 3%;
                }
            }

            @include queriesWshop(1199px, max) {
                margin-right: 12% !important;
            }

            @include queriesWshop(1100px, max) {
                margin-right: 8% !important;
            }

            @media screen and (max-height: 668px) and (max-width: 1370px) {
                margin: 1rem 9% 0 0;
            }

            &:nth-of-type(3n+1) {
                margin-right: 0;
            }

            &:first-child {
                margin-top: 1rem;
            }

            .product_quantity_change {
                opacity: 0;
            }

            .cart_product_desc {
                display: flex;
                flex-direction: column;
                position: relative;
                flex-grow: 1;
                margin-top:  .75rem;
                position: relative;

                .wrapper_cart_product_desc {
                    min-height: 100px;
                }

                .wrap_titre_prix {
                    display: flex;
                    flex-direction: column;

                    a {
                        min-height: 38px;

                        @media screen and (max-height: 668px) and (max-width: 1370px) {
                            min-height: unset;
                        }
                    }

                    .cart_product_brand {
                        display: none;
                    }

                    .cart_product_title {
                        font-size: .8rem;
                        font-weight: 500;
                        color: $black;
                        font-family: $primaryFont;
                        letter-spacing: .5px;
                        position: relative;
                        display: inline;
                        min-height: 38px;

                        &:after {
                            content: " - ";
                        }
                    }

                    .cart_product_subtitle {
                        font-size: .8rem;
                        font-weight: 500;
                        color: $black;
                        font-family: $primaryFont;
                        letter-spacing: .5px;
                        text-transform: none;
                        display: inline;
                    }

                    .wrapper_cart_product_price {
                        margin: 0.5rem 0 1rem;
                        display: flex;

                        @media screen and (max-height: 668px) and (max-width: 1370px) {
                            margin: .5rem 0;
                        }

                        .pricetag {
                            font-weight: 600;
                            font-size: .75rem;
                            font-family: $primaryFont;

                            &+.pricetag {
                                margin-left: 1rem;
                                text-decoration: line-through;

                                &:after {
                                    display: none;
                                }

                                span.price {
                                    position: relative;

                                    &:after {
                                        position: absolute;
                                        content: '';
                                        width: 100%;
                                        background: $black;
                                        height: 1px;
                                        top: 50%;
                                        left: 0;
                                    }
                                }

                            }
                        }
                    }
                }

                .cart_product_modify {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 0.5rem;
                    margin-bottom: 3px;
                    flex-direction: column;

                    a {
                        color: $black;
                        text-decoration: underline;
                        font-size: .8rem;
                        margin-bottom: .6rem;
                        font-weight: 500;
                        letter-spacing: .75px;
                    }
                }

                .cart_product_sizecol {
                    font-size: 0.75rem;
                    font-weight: 500;
                    letter-spacing: .5px;
                    padding: 3px ​0;

                    span.color_bullet {
                        display: none !important;
                    }

                    label {
                        font-size: 0.75rem;
                        font-weight: 500;
                        letter-spacing: .5px;
                    }

                    &.quantity {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin: .3rem 0 1rem;

                        .product_quantity_change {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin-left: 5px;
                            opacity: 0;
                            transition: opacity 0.5s ease-in-out;

                            form.qte_selector {
                                position: relative;

                                .qteWrapper {
                                    display: flex;

                                    .change_qte {
                                        cursor: pointer;
                                        background: transparent;
                                        height: 20px;
                                    }

                                    .selected_qte {
                                        width: 20px;
                                        height: 20px;
                                        text-align: center;
                                        line-height: 1;
                                        border: none;
                                        font-size: .75rem;
                                        font-weight: 500;
                                        font-family: $primaryFont;
                                        padding: 0;
                                    }
                                }

                                .load_qte {
                                    width: 20px;
                                }
                            }
                        }
                    }

                    &.color {
                        margin: .3rem 0 0;
                    }
                }

                /*&.reposition_qty{
                    .cart_product_modify{
                        .product_quantity_change{
                            bottom: 42.5%;

                            @media screen and (max-height: 668px) and (max-width: 1370px) {
                                bottom: 44%;
                            }
                        }
                    }
                }

                &.reposition_qty_spe{
                    .cart_product_modify{
                        .product_quantity_change{
                            bottom: 46.5%;
                        }
                    }
                }*/
            }
        }

        .left_side,
        .bank_left_side {
            width: 70%;
            font-size: .95rem;

            @include queriesWshop(1199px, max) {
                margin-right: 2rem;
                width: 60%;
            }

            .cart_main_table {
                display: flex;
                flex-wrap: wrap;

                .cart_donation {
                    .cart_product_desc {
                        .wrapper_cart_product_desc {
                            min-height: auto;
                        }

                        .cart_product_modify {
                            .product_mod,
                            .product_quantity_change {
                                display: none;
                            }
                        }
                    }
                }

                &.wishlist .cart_product_line {
                    margin: 2rem 9% 0 0;

                    &:nth-of-type(3n) {
                        margin-right: 0;

                        @include queriesWshop(1366px, max) {
                            margin-right: 9%;
                        }
                    }

                    .cart_product_modify a.button {
                        background: transparent;
                        height: auto;
                        text-transform: none;
                        justify-content: flex-start;
                    }

                    .tunnel_wish_nostock {
                        display: none;
                    }

                    &.no_stock {
                        .cart_product_pic {
                            a {
                                img {
                                    opacity: .35;
                                }
                            }
                        }

                        .cart_product_desc {
                            .wrapper_cart_product_desc {
                                .cart_product_modify {
                                    .tunnel_wish_nostock {
                                        position: absolute;
                                        width: 100%;
                                        text-align: center;
                                        top: -94%;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.coupnCode {
                .bloc_paiement {
                    padding: 2rem;

                    h2 {
                        font-weight: bold;
                        text-transform: uppercase;
                        margin: -.5rem auto 0;
                        text-align: center;
                        width: 39%;
                        font-size: 1rem;
                        font-family: $primaryFont;
                        letter-spacing: .5px;
                        position: absolute;
                        transform: translateX(-50%);
                        left: 50%;
                        letter-spacing: 2px;
                        margin-top: -2.5rem;


                        &:before {
                            content:'';
                            background-color: $secondaryColor;
                            position: absolute;
                            top: 0.45rem;
                            left: 0;
                            right: 0;
                            margin: auto;
                            height: 1.5rem;
                            width: 100%;
                            z-index: -1;
                        }

                        @media only screen and (max-width: 1520px) {
                                font-size: .9rem;
                        }
                        @media only screen and (max-width: 1370px) {
                            font-size: .85rem;
                        }

                        @include queriesWshop(1199px, max) {
                            width: 50%;
                        }
                    }

                    .freeOrderCouponTxt {
                        font-size: .8rem;
                        margin-bottom: 1.25rem;
                        text-align: center;
                    }

                    p.c_both {
                        margin: 0.5rem 0 1rem;
                        line-height: 20px;
                        font-size: .9rem;
                        span {
                            display: block;
                            margin-bottom: .5rem;
                        }
                    }

                    .btn_container{
                        height: auto;

                        .button {
                            width: 180px;
                            height: auto;
                            text-align: center;
                            margin: auto;
                            border: none;
                            background-color: transparent;
                            font-size: .8rem;
                            text-transform: none;
                            letter-spacing: .5px;
                            text-decoration: underline;

                            &:hover {
                                background-color: transparent;
                                color: $darkColor;
                            }
                        }
                    }

                    &:hover {
                        h2 {
                            &:before {
                                background-color: #FFF;
                            }
                        }
                    }
                }
            }

            .txt_recommencer_paiement {
                margin-bottom: 2.5rem;
            }

            .cart_main_title,
            .title_basket_tunnel {
                font-size: 1.8rem;
                font-family: $secondaryFont;
                font-weight: bold;
                line-height: 1.5;
                @media only screen and (max-width: 1520px) {
                        font-size: 1.75rem;
                }
                @media only screen and (max-width: 1370px) {
                    font-size: 1.65rem;
                }


                &.wishlist {
                    margin-top: 3rem;

                    span.nb_products {
                        margin-left: .3rem;
                    }

                }
            }

            .empty_cart {
                margin: 1.8rem 0;
            }

            span.nb_products {
                color: $black;
                text-transform: uppercase;
                font-family: $primaryFont;
                letter-spacing: 2px;
                font-weight: 500;
                font-size: .97rem;
                display: block;
            }

            .precoBasket {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 20px;

                span {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    background-color: $fadeTxt;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                }

                .precoBasketIntro {
                    line-height: 1.5;
                }
            }

            .dropdown_cart {
                font-size: 0.9rem;
                width: 100%;

                a.dropdown_cart_link h3 {
                    display: none;
                }

                p.precoSubtitle {
                    display: none;
                    font-weight: bold;
                    font-family: $secondaryFont;
                    font-size: .95rem;
                }

                .precoDeliveryDate {
                    color: $black;
                    line-height: 1.6;
                    font-size: .8rem;
                    font-weight: 500;
                    margin: -1.25rem 0 0 9.5rem;

                    /*span {
                        display: block;
                    }*/
                }
            }

            .line_ssTotal {
                display: none;
            }

            .itm_back_btn {
                display: none;
            }

            .bloc_livraison,
            .wrapper_bloc_banque,
            .bloc_paiement  {
                width: 100%;
                border: 1px solid $fadeTxt;
                position: relative;
                color: $primaryColor;
                margin-bottom: 3rem;
                padding: 0;
                transition: all .2s ease-out;

                .checkbox,
                .radio {
                    border: 1px solid $fadeTxt;
                    border-radius: 50%;
                    background-color: $secondaryColor;
                    position: absolute;
                    left: -11px;
                    top: 48%;
                    width: 20px;
                    height: 20px;
                    transform: translate(0,-50%);
                    transition: all .2s ease-out;

                    &:after {
                        content:"";
                        display: block;
                        border-radius: 50%;
                        width: 13px;
                        height: 13px;
                        background-color: transparent;
                        transition: all .2s ease-out;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        top: 50%;

                        @include ie() {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                &.active,
                &.actif,
                &:hover {
                    border-color: $black;
                    border-width: 1px;
                    background: $white;
                    position: relative;

                    .checkbox,
                    .radio {
                        border-color: $black;

                        &:after {
                            background-color: $black;

                            @include ie() {
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }

                    h2.bank_title {
                        margin: -1.5rem auto 0;
                    }

                    h2.bank_title:after, .title_type_livraison:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        background: $white;
                        height: 12px;
                        z-index: -1;
                        transform: translateX(-50%);
                        left: 50%;
                        top: 8px;
                    }
                }

                .adresse_change,
                .wrapper_locate_relais {
                    padding: 0 2.25rem;

                    .w-input-container .w-dropdown {

                        &:after {
                            content: "";
                            background-image: url(../svg/icones.svg);
                            background-size: 500% auto;
                            background-position: 25% 0;
                            width: 25px;
                            height: 25px;
                            position: absolute;
                            right: 1rem;
                            bottom: 0;
                            margin: auto;
                            border: none;
                        }

                        select.w-input-element {
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            position: relative;
                        }
                    }
                }

                .wrapper_locate_relais {

                    .w-input-container {
                        &:after {
                            content: "";
                            background-image: url(../svg/icones.svg);
                            background-size: 500% auto;
                            background-position: 25% 0;
                            width: 25px;
                            height: 25px;
                            position: absolute;
                            right: 1rem;
                            bottom: 0;
                            top: 0;
                            margin: auto;
                            border: none;
                        }

                        .img_error, .valid_form{
                            max-width: 19px;
                            right: 2.5rem;
                        }
                    }

                    .displayed_result {
                        padding: 1.5rem 0;
                        display: flex;
                        justify-content: space-between;
                        font-family: $secondaryFont;

                        .list-right {
                            padding-right: 36px;
                        }

                        .distance {
                            display: none;
                        }

                        .kp {
                            margin-top: 1rem;
                            border-bottom: 1px solid $tertiaryColor;
                        }

                        .kpname {
                            display: block;
                            text-transform: capitalize;
                            font-weight: bold;
                            margin-bottom: 7px;
                            font-size: .75rem;
                            letter-spacing: .5px;
                            font-family: $primaryFont;
                        }

                        .address {
                            font-size: .75rem;
                            text-transform: lowercase;
                            font-family: $primaryFont;
                            font-weight: 600;
                            letter-spacing: .5px;
                            line-height: 1.5;


                            .city {
                                text-transform: capitalize;
                            }
                        }

                        .btn_container {
                            margin: 1.1rem 0;
                            height: auto;

                            .relayLink {
                                font-size: .75rem;
                                text-decoration: underline;
                                font-family: $primaryFont;
                                letter-spacing: .5px;
                                font-weight: 600;
                            }
                        }

                        .map_canvas {
                            width: 62%;
                            height: auto;

                            .gm-style .gm-style-iw-c {

                                .details a {
                                    font-size: .5rem;
                                }

                            }

                            .info_popup {

                                .name {
                                    text-transform: capitalize;
                                    font-weight: bold;
                                    font-size: .85rem;
                                    max-width: 95%;
                                }

                                .horaire_title {
                                    font-weight: bold;
                                }

                            }
                        }

                        .sidebar-right {
                            width: 35%;
                            max-height: 450px;
                            height: 450px;

                            .os-scrollbar-vertical {
                                right: 3px;

                                .os-scrollbar-track {
                                    background: $grey;
                                    width: 1px;

                                    .os-scrollbar-handle {
                                        width: 1px;
                                        position: relative;
                                        background: rgba(0,0,0,1);

                                        &:before {
                                            background: $black;
                                            content: "";
                                            position: absolute;
                                            top: 100%;
                                            width: 8px;
                                            height: 8px;
                                            left: -4px;
                                            border-radius: 50%;
                                        }
                                    }
                                }
                            }
                        }

                        .lb-v-scrollbar {
                            width: 1px;
                        }

                        .lb-v-scrollbar-slider {
                            width: 1px;
                            background: $grey;
                        }
                    }
                }

                .title_type_livraison,
                .bank_title {
                    font-weight: bold;
                    text-transform: uppercase;
                    margin: -.5rem auto 0;
                    text-align: center;
                    background-color: $secondaryColor;
                    width: 39%;
                    font-size: 1rem;
                    font-family: $primaryFont;
                    letter-spacing: .5px;
                    position: absolute;
                    transform: translateX(-50%);
                    left: 50%;
                    letter-spacing: 2px;

                    @media only screen and (max-width: 1520px) {
                            font-size: .9rem;
                    }
                    @media only screen and (max-width: 1370px) {
                        font-size: .85rem;
                    }
                    @media only screen and (max-width: 850px) {
                        font-size: .75rem;
                    }

                    @include queriesWshop(1199px, max) {
                        width: 50%;
                    }
                }

                .bank_title {
                    margin: -1.5rem auto 0;
                }

                .transporteur_info {
                    text-align: center;
                    font-size: .85rem;
                    line-height: 1.8;
                    font-family: $primaryFont !important;
                    font-weight: 500 !important;
                    margin-top: 1.2rem;
                    letter-spacing: .5px;
                }

                .choix_delivery {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: .5rem 1.5rem 1rem 3rem;

                    .button.dark {
                        line-height: 2;
                        color: $black;
                        font-size: .8rem;
                        background: none;
                        text-align: left;
                        text-transform: none;
                        justify-content: flex-start;
                        letter-spacing: .5px;
                        font-weight: 500;
                    }

                    .fdp_offered{
                        text-transform: uppercase;
                    }

                    .price_tag{
                        font-size: 1.2rem;
                    }

                    .transporteur_delay {
                        font-weight: bold;
                        font-size: .75rem;
                        color: $black;
                        margin-top: .5rem;
                        letter-spacing: .5px;
                    }

                    a.show_modif_info {
                        position: relative;
                        cursor: pointer;
                        font-size: 0.85rem;
                        text-decoration: underline;
                    }

                    .left_delivery {
                        width: 60%;
                        position: relative;

                        p {
                            font-size: .85rem;
                            line-height: 1.5;
                            color: $primaryColor;
                            font-weight: 500;
                            &.shop_name {
                                text-transform: uppercase;

                                i { display: none;}
                            }

                            &.civilite {
                                font-weight: bold;
                            }

                            &.adresse {
                                font-weight: 500;
                            }
                        }

                        input[type=radio] {
                            display: none;
                        }
                    }

                    .right_delivery {
                        .price_tag, span {
                            font-family: $primaryFont;
                            font-weight: bold;
                            font-size: 1.2rem;
                            .currency {
                                margin-left: 3px;
                            }
                        }
                    }
                }

                .civilite,
                .shop_name,
                .delivery_form_title {
                    font-family: $primaryFont;
                    font-weight: 600;
                }

                .transporteur_info {
                    text-transform: uppercase;
                    font-size: 1rem;

                    @media only screen and (max-width: 1370px) {
                        font-size: .9rem;
                    }
                     @media only screen and (max-width: 1360px) {
                        font-size: .85rem;
                    }
                }

                .civilite,
                .shop_name {
                    font-size: .85rem;
                }

                .delivery_form_title {
                    text-align: center;
                    font-size: .75rem;
                    margin: 0.5rem 0;
                    text-transform: uppercase;
                    letter-spacing: .5px;
                    font-weight: bold;
                }

                &.paypal .bank_title {
                    width: 22%;
                    margin-top: -1.5rem;
                }
            }


            .bloc_livraison {
                min-height: 195px;
                transition: none;

                &.choix_delivery_relais {

                    &.transporteur_sogep {

                        p.title_type_livraison {
                            width: 42%;

                            @include queriesWshop(1199px, max) {
                                width: 50%;
                            }
                        }
                    }

                    &.transporteur_Livraison {

                        .left_delivery .shop_name {
                            font-weight: bold;
                            text-transform: none;
                        }
                    }
                }

                &.transporteur_Colissimo .title_type_livraison {
                    width: 60%;

                    @include queriesWshop(1199px, max) {
                        width: 75%;
                    }
                }
            }

            .wrapper_form_adresse.tunnel_form form#adresseForm {

                .w-form-line {

                    &.w-submit {
                        max-width: 100%;
                    }

                    .w-dropdown:after {
                        display: none;
                    }
                }

                .elem_global.delivery {

                    p.more_details {
                        font-family: $primaryFont;
                        text-transform: uppercase;
                        font-size: .8rem;
                        margin-bottom: 1rem;
                    }

                    @include ie() {
                        .w-form-line.w-has-tel .w-input.w-tel-input .selected-flag {
                            height: 96%;
                            margin-left: .1rem;
                            background-color: transparent;
                        }
                    }
                }

                .w-input-group .w-input-container .w-visibility-toggle {
                    background-size: 200%;
                    height: 1.5rem;

                }
            }

            //Bank
            .wrapper_bloc_banque {

                &.alias_list_wrapper {
                    padding: 0 2rem 2rem;
                    position: relative;

                    &:before {
                        content:'';
                        width: 20px;
                        height: 20px;
                        border: 1px solid $grey;
                        border-radius: 50%;
                        border: 1px solid #888c8d;
                        background-color: black;
                        box-shadow: inset 0 0 0 20px $secondaryColor;
                        position: absolute;
                        left: -10px;
                        top: 50%;
                        transform: translate(0,-50%);
                        transition: all .2s ease-out;
                        box-sizing: border-box;
                    }

                    &.actif,
                    &:hover {
                        &:before {
                            border: 1px solid black;
                            box-shadow: inset 0 0 0 2px $secondaryColor;
                        }
                    }

                    h2.bank_title {
                        margin: -.5rem auto 0;
                    }

                    .bank_confiance img {
                        width: 47px;
                        vertical-align: -.2rem;
                    }

                    .alias_list {
                        font-size: 13px;

                        @media only screen and (max-width: 1370px) {
                            font-size: 12.5px;
                        }
                         @media only screen and (max-width: 1360px) {
                            font-size: 12px;
                        }

                        button {
                            width: auto;
                            height: auto;
                            text-transform: initial;
                        }
                    }
                }

                .title_wrapper_fold,
                .cards-wrapper,
                #flex {
                    padding: 0 2rem;
                }

                .cards-wrapper {
                    display: flex;
                    align-items: center;
                    margin: 1rem 0;

                    img {
                        vertical-align: top;
                        width: auto;
                        height: 1.5rem;

                        + img {
                            margin-left: 0.3rem;
                        }
                    }
                }

                .bank_subtitle,
                .total_transaction,
                .alias_list {
                    font-size: 1rem;
                }

                .total_transaction {
                    margin-bottom: 2rem;

                    span {
                        font-size: .8rem;
                        font-weight: 600;
                        letter-spacing: 1px;
                        text-transform: uppercase;

                        &.pricetag {
                            font-weight: bold;
                        }
                    }
                }

                .bank_subtitle, .bank_confiance {
                    margin: 1rem 0;
                    padding-top: .8rem;
                    font-size: .8rem;
                    font-weight: 600;
                    letter-spacing: .5px;

                    strong {
                        font-weight: 500;
                    }

                    span img {
                        width: 47px;
                        vertical-align: -.2rem;
                    }
                }

                &.paypal {

                    cursor: pointer;

                    .bank_title span {
                        position: relative;
                        margin-left: -5rem;

                        &::after {
                            content: "";
                            background-image: url(../img/paypal-logo.png);
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 80px;
                            height: 22px;
                            position: absolute;
                            left: 2.8rem;

                            @include queriesWshop(1520px, max) {
                                left: 2.4rem;
                            }
                        }
                    }

                    .bloc_bank_contents {
                        display: block;
                        text-align: center;
                    }

                    .bank_subtitle {
                        color: $black;
                        font-size: .8rem;
                        text-decoration: underline;
                        padding: 1.2rem 0 0.5rem;

                        span {
                            display: none;
                        }
                    }

                    #inputPAYPAL {
                        visibility: hidden;
                        opacity: 0;
                        height: 0;
                    }
                }

                &.bancontact {
                    .bloc_bank_contents  {
                        display: block;
                        text-align: center;
                        text-decoration: underline;
                    }   
        
                    .bank_title span {
                        position: relative;
                        margin-left: -5rem;

                        &::after {
                            content: "";
                            background-image: url(../banque/logo/bancontact-logo.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 70px;
                            height: 50px;
                            position: absolute;
                            left: 2.8rem;
                            top: -75%;
                        }
                    }

                    .bank_subtitle {
                        color: $black;
                        font-size: .8rem;
                        text-decoration: underline;
                        padding: 1.2rem 0 0.5rem;
                        cursor: pointer;

                        span {
                            display: none;
                        }
                    }

                    #inputBANCONTACT {
                        display: none;
                    }
                }
            }

            /* LIGHTBOX PRODUIT OFFERT DANS NOTRE SÉLECTION */
            #selection-offer-content #wrap_lion form.js-form-selection-offer {

                .color_list, .size_list {
                    .color  + label, .size  + label {
                        &:before , &:after {
                            display: none;
                        }
                    }

                    .swiper-slide {
                        margin: 0 auto;
                    }

                    width: 50%;
                    margin: 0 auto 0 0;
                }
            }
        }

        .right_side,
        .bank_right_side {
            width: 28%;

            @include queriesWshop(1199px, max) {
                width: 40%;
            }

            .main_table {
                background: $white;
                padding-top: 28px;
                margin-bottom: 25px;
                display: flex;
                flex-direction: column;

                .cart_product_line {
                    margin: 2.2rem 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    .cart_product_pic {
                        width: 105px;
                        min-width: 105px;
                    }

                    .cart_product_desc {
                        margin-left: 1.3rem;

                        .wrap_titre_prix {
                            .cart_product_title {
                                font-size: 1rem;

                                @media only screen and (max-width: 1370px) {
                                    font-size: .9rem;
                                }
                                 @media only screen and (max-width: 1360px) {
                                    font-size: .85rem;
                                }
                            }

                            .wrapper_cart_product_price {
                                margin: .3rem 0 .5rem;
                                .pricetag {
                                    font-weight: 600;
                                    font-size: 1rem;

                                    &+.pricetag {
                                        margin-right: 1rem;

                                        &:after {
                                            display: none;
                                        }

                                        span.price {
                                            position: relative;

                                            &:after {
                                                position: absolute;
                                                content: '';
                                                width: 100%;
                                                background: $black;
                                                height: 1px;
                                                top: 50%;
                                                left: 0;
                                            }
                                        }

                                    }
                                }
                            }
                        }

                        .cart_product_sizecol {
                            font-size: .9rem;

                            span.color_bullet {
                                display: none !important;

                            }
                        }

                        .cart_product_modify {
                            margin: 0 2rem .6rem 0;

                        }

                        &.reposition_qty{
                            .cart_product_modify{
                                .product_quantity_change{
                                    bottom: 42.5%;
                                }
                            }
                        }

                        &.reposition_qty_spe{
                            .cart_product_modify{
                                .product_quantity_change{
                                    bottom: 46.5%;
                                }
                            }
                        }
                    }
                }

                .bill_line {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 85%;
                    margin: auto;
                    font-size: .8rem;
                    padding: 4px 0;
                    font-weight: 600;
                    letter-spacing: .5px;

                    &:last-of-type {
                        padding: 5px 0 0;
                        margin-bottom: 1.5rem;
                    }

                    &.empty p {
                        width: 100%;
                        text-align: center;
                    }

                    &.total_produit {

                        &.toggle,
                        &.toggle label {
                            cursor: pointer;
                        }

                        &.toggle label {
                            position: relative;
                            text-decoration: underline;

                        }

                        &.toggle.open label {

                            &:after {
                                transform: rotate(180deg);
                            }
                        }

                    }

                    &.total_produit_dropdown {
                        display: none;
                        width: 83%;
                        margin: auto;
                        padding: 0;

                        .cart_product_pic {
                            width: 130px;
                            min-width: 130px;
                        }

                        .cart_donation .cart_product_desc .cart_product_modify {
                            .product_mod,
                            .product_quantity_change {
                                display: none!important;
                            }
                        }
                    }

                    &.discount {
                        border-bottom: 1px solid $fadeTxt;
                        padding-bottom: 1.5rem;

                        .pricetag {
                            text-transform: uppercase;
                        }

                        @media only screen and (max-width: 850px) {
                            .price{
                                &.pack_liv{
                                    .pricetag{
                                        font-size: 0.65rem;
                                    }
                                }
                            }
                        }
                    }

                    &.used_cp {

                        .code_promo {
                            a {
                                text-decoration: none;
                            }
                        }
                    }

                    &.coupon {
                        padding: 10px 0 0;

                        form#formPromo.w-coupon-form {

                            .w-form-line.w-submit {

                                span {
                                    font-weight: 600;
                                }
                            }

                            input {
                                border-color: $fadeTxt;
                            }
                        }
                    }

                    &.total {
                        padding-top: 1.5rem;

                        label, .pricetag {
                            font-weight: 600;
                        }
                    }

                    .price p.pricetag {
                        font-family: $primaryFont;
                        font-size: .8rem;
                        font-weight: 600;
                        letter-spacing: .5px;
                    }

                    label[for="isDonation"] {
                        display: none;

                        + small {
                           display: none; 
                        }
                    }
                }

                .upselling.price {
                    box-sizing: border-box;
                    background: $white;
                    padding:  1.2rem 1rem 1rem;
                    font-weight: 600;
                    order: 6;
                    position: relative;
                    background: $tertiaryColor;
                    min-height:  75px;
                    border-top: 25px solid $secondaryColor;

                    p {
                        text-align: center;
                        font-size: .7rem;
                        font-family: $primaryFont;
                        margin: 0 auto 1rem;
                        color: $white;
                        font-weight: 500;
                        letter-spacing: .5px;
                    }

                    progress {
                        width: 100%;
                        appearance: none;
                        height: 2px;
                        border-radius: 5px;
                        vertical-align: top;
                        border: 0;
                    }
                    // Chrome + Safari
                    progress::-webkit-progress-bar {
                        /* Bar rules */
                        background-color: $fadeTxt;
                    }
                    progress::-webkit-progress-value {
                        /* Value rules */
                        background-color: $white;
                    }
                    // Firefox
                    progress::-moz-progress-bar {
                        /* style rules */
                        background-color: $primaryColor;
                    }
                }

                .formPanier_container {
                    margin-top: 1.5rem;
                    border-top: 25px solid $secondaryColor;


                    form#formPanier .form_submit {
                        height: 50px;

                        button span {
                            font-size: .85rem;
                            font-weight: 500;
                        }
                    }

                    .form_submit {
                        height: 50px;

                        button.dark+.loader {
                            background-size: 15% !important;
                        }
                    }

                    .checkCgv{
                        background: #fbf4ec;
                        padding-bottom: 1.5rem;

                        .w-checkbox-input{
                            height: auto;

                            .w-input-element{
                                & + .w-input-label{

                                    &:before{
                                        margin-top: 0.25em;
                                    }
                                }
                            }

                            .shipment_cgv_link{
                                display: flex!important;
                                align-items: flex-start;
                                font-family: $primaryFont;
                                font-size: 12 / 16 * 1rem;
                                line-height: 1.5;
                                color: #000;
                                text-align: justify;

                                span{
                                    width: 95%;
                                }

                                a{
                                    text-decoration: underline;
                                }
                            }
                        }

                        & + #err_cgv_compulsory{
                            background: #fbf4ec;
                            font-size: 0.75rem;
                            color: red;
                            text-align: center;
                            padding-bottom: 1.5rem;
                        }
                    }
                }

                .donation {
                    text-align: center;
                    margin-top: 1.5rem;
                    background-color: $bgColor;
                    padding-top: 25px;
                    font-size: 14px;
                    font-weight: 700;
                    text-decoration: underline;
                    cursor: pointer;

                    +.formPanier_container {
                        border-top: 25px solid $bgColor;
                        margin-top: 0;
                    }
                }
            }

            //adresses rappel
            .wrapper_payment {
                width: 100%;
                margin: auto;
                position: relative;
                padding: 0 1.5rem 1.5rem 4.5rem;

                .payment_addr_wrapper {
                    margin: 1.7rem 0;
                    position: relative;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:before {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 9px;
                        background: url('../img/arrow_blk.jpg') no-repeat;
                        position: absolute;
                        left: -2.5rem;
                        top: .2rem;
                    }

                    .title {
                        font-family: $primaryFont;
                        font-weight: bold;
                        font-size: .8rem;
                        letter-spacing: .5px;
                    }

                    .address {
                        font-size: .8rem;
                        line-height: 1.7;
                        text-transform: none;
                        font-weight: 500;
                    }

                    .address_mod {
                        text-decoration: underline;
                        font-size: .8rem;
                        margin-top: .5rem;
                        display: block;
                        font-weight: 600;
                        letter-spacing: .5px;
                    }
                }
            }

            .bill_line.total_produit_dropdown .cart_product_line {
                display: flex;
                margin-top: 1.8rem;
                position: relative;

                .cart_product_pic {
                    min-width: 130px;
                    height: 160px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .cart_product_desc {
                    margin: .5rem 0 0 .5rem;

                    .wrapper_cart_product_desc {

                        p.cart_product_brand {
                            display: none;
                        }

                        p.cart_product_title {
                            font-size: .8rem;
                            font-weight: 500;
                            color: $black;
                            font-family: $primaryFont;
                            letter-spacing: .5px;
                            position: relative;
                            display: inline;

                            &:after {
                                content: " - ";
                            }
                        }

                        p.cart_product_subtitle {
                            font-size: .8rem;
                            font-weight: 500;
                            color: $black;
                            font-family: $primaryFont;
                            letter-spacing: .5px;
                            text-transform: none;
                            display: inline;
                        }

                        .wrapper_cart_product_price {
                            margin: .5rem 0 1rem;
                            display: flex;

                            .pricetag {
                                font-weight: 600;
                                font-size: .75rem;
                                font-family: $primaryFont;

                                &+.pricetag {
                                    margin-left: 1rem;
                                }

                            }
                        }

                        .cart_product_sizecol {
                            font-size: 0.75rem;
                            font-weight: 500;
                            letter-spacing: .5px;
                            padding: 3px ​0;

                            span.color_bullet {
                                display: none !important;
                            }

                            label {
                                font-size: 0.75rem;
                                font-weight: 500;
                                letter-spacing: .5px;
                            }

                            &.quantity {
                                margin: .3rem 0 1rem;
                            }

                            &.color {
                                margin: .3rem 0 0;
                            }
                        }
                    }

                    &.reposition_qty{
                        .cart_product_modify{
                            .product_quantity_change{
                                bottom: 42.5%;
                            }
                        }
                    }

                    &.reposition_qty_spe{
                        .cart_product_modify{
                            .product_quantity_change{
                                bottom: 46.5%;
                            }
                        }
                    }
                }


                .cart_product_modify {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 0.5rem;
                    margin-bottom: 3px;
                    flex-direction: column;

                    a {
                        color: $black;
                        text-decoration: underline;
                        font-size: .8rem;
                        margin-bottom: .6rem;
                        font-weight: 500;
                        letter-spacing: .75px;

                        &.product_del {
                            position: absolute;
                            top: 0;
                            right: -1rem;
                            background: url('../svg/close.svg') center center;
                            background-size: 100%;
                            width: 11px;
                            height: 11px;

                            span {
                                display: none;
                            }
                        }
                    }

                    /* RECAP de la commande */
                    .product_quantity_change {
                        position: relative;
                        bottom: 58px;
                        right: -95px;
                        width: 67px;

                        form.qte_selector {
                            position: relative;

                            .qteWrapper {
                                display: flex;
                                align-items: baseline;

                                .change_qte {
                                    cursor: pointer;
                                    background: transparent;
                                    height: 20px;
                                }

                                .selected_qte {
                                    width: 30px;
                                    height: 20px;
                                    text-align: center;
                                    line-height: 1;
                                    border: none;
                                }
                            }

                            .load_qte {
                                position: absolute;
                                top: -20px;
                                left: 34%;
                                width: 20px;
                            }
                        }
                    }
                }
            }


            //recap commande
            .recap_cart_title {
                text-align: center;
                font-size: 2rem;
                font-family: $secondaryFont;
                color: $black;
                margin-bottom: 20px;
                line-height: 1.5;
                text-transform: uppercase;
                letter-spacing: .5px;
                font-weight: bold;
                text-transform: none;

                /*@media only screen and (max-width: 1680px) {
                    font-size: 1.85rem;
                }
                @media only screen and (max-width: 1520px) {
                    font-size: 1.75rem;
                }
                @media only screen and (max-width: 1370px) {
                    font-size: 1.65rem;
                }*/


                span {
                    font-family: $primaryFont;
                    display: block;
                    font-weight: 600;
                    font-size: 1rem;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
            }

            //bloc_question
            .bloc_question {

                .faq_theme_wrapper{
                    padding-bottom: 1.5625rem /* 25px -> 1.5625rem */;
                }

                .retour_rallonge {
                    display: none;
                }

                .title_question,
                #title_other_question {
                    text-transform: uppercase;
                    font-size: .82rem;
                    font-family: $primaryFont;
                    margin: 2rem 0 0.8rem;
                    font-weight: bold;
                    letter-spacing: .5px;
                }

                .title_question {
                    margin: 0 0 0.8rem;
                }

                .questions_frequentes {
                    margin: 2rem 0 0;

                    #other_question_1 p.label_question {
                        font-size: .8rem;
                        font-weight: 500;
                        letter-spacing: .5px;
                    }

                    #other_question_2, #other_question_3 {
                        display: none;
                    }

                }

                .faq {
                    margin-bottom: 5px;

                    .intitule_question {
                        line-height: 1.2;
                        cursor: pointer;
                        font-size: .8rem;
                        font-weight: 500;

                        span {
                            position: relative;
                            &:after {
                                content: "";
                                display: block;
                                position: absolute;
                                left: 0;
                                width: 0;
                                height: 1px;
                                background-color: $black;
                                -webkit-transition: all .2s ease-out;
                                transition: all .2s ease-out;
                            }
                        }
                        &.actif {
                            font-weight: bold;
                        }
                    }

                    .texte_question  {
                        margin: 1rem 0;
                        font-size: .8rem;
                        line-height: 1.5;
                        white-space: normal;

                        h4 {
                            text-align: left;
                        }

                        h4, p {
                            font-size: .8rem;
                        }

                        a {
                            text-decoration: underline;
                        }

                        ul {
                            list-style: disc;
                            margin-left: 2rem;
                        }
                    }
                }

                .questions_frequentes {

                    #other_question_3 {
                        a {
                            text-decoration: underline;
                            span {
                                text-transform: lowercase;
                            }
                        }
                    }
                }
            }

            //bloc_secur
            .top {
                padding: 28px 0;

                .right_bloc_btm_paiement {

                    .title {
                        display: flex;
                        align-items: center;
                        padding-top: 30px;
                        margin-bottom: 2px;
                        line-height: 1.1;
                        font-size: 1.3rem;
                        color: $black;
                    }

                    .tunel_paiement_right_description {
                        margin: auto;
                        text-align: left;
                        font-size: .85rem;
                        line-height: 1.6;
                        letter-spacing: .5px;
                        font-weight: 600;

                        img {
                            width: 47px;
                        }
                    }
                }
            }

        }

        .wrapper_mes_adresses {
            width: 100%;
            padding: 0 2rem;

            .conteneur_adresse{
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                margin-top: 1.45rem;
                justify-content: flex-start;
                .adresse{
                    background-color: $white;
                    width: 27.3%;
                    font-family: $primaryFont;
                    font-size: 0.75rem;
                    letter-spacing: 1px;
                    line-height: 1.37rem;
                    font-weight: 600;
                    padding: 2.8% 0 2.3%;
                    margin-left: 3%;
                    margin-right: 3%;
                    &:nth-child(3n+1) {
                        margin-top: 5%;
                    }
                    &:nth-child(1) {
                        margin-top: 0;
                    }
                    .addr_label{
                        text-transform: uppercase;
                        font-size: 0.969rem;
                        font-weight: 600;
                        letter-spacing: 1.5px;
                        margin-bottom: 0.3rem;
                    }
                    .addr_titre{
                        font-family: $secondaryFont;
                        font-size: 1.9rem;
                        font-weight: 700;
                        text-transform: initial;
                        letter-spacing: 0;
                        margin-bottom: 0.6rem;

                        @media only screen and (max-width: 1680px) {
                            font-size: 1.85rem;
                        }
                        @media only screen and (max-width: 1520px) {
                            font-size: 1.75rem;
                        }
                        @media only screen and (max-width: 1370px) {
                            font-size: 1.65rem;
                        }

                    }
                    .addr_type{
                        padding: 0 0 2.5%;
                    }
                }
                .modlinks_wrapper{
                    margin-top: 1.3rem;
                    a{
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
            }

            .form_submit{
                width: 100%;
                a.button{
                    width: 100%;
                    max-width: 18.8rem;
                    background-color: $bgColor;
                    margin: 0 auto;
                    border: 1px solid grey;
                    color: black;
                    font-weight: 600;
                    font-size: 0.83rem;
                    letter-spacing: 1px;
                }
            }
        }

        .edit_adresse {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            form#adresseForm .elem_global.delivery {
                margin-bottom: 1rem;
            }
        }
    }

    #donation_wrapper {
        max-width: 820.7px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        margin-top: 40px;
        width: 65.5%;

        @media only screen and (max-width: 1199px) {
            width: 58.5%;
        }

        #donation_cards {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: stretch;
            column-gap: 10px;
                
            .container_donation {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: $white;
                min-height: 370px;
                width: calc((100% - 10px) / 2);
                padding: 20px;
                column-gap: 10px;

                .container_logo_donation,
                .default,
                &.basket,
                &.rose_up {
                    background-image: url(../img/don.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 127px;
                    height: 100%;

                    &.basket {
                        background-image: url(../img/tunnel_basket.jpg);
                    }

                    &.rose_up {
                        background-image: url(../img/tunnel_rose_up.jpg);
                    }
                }

                .title_donation,
                .subtitle_donation,
                .learn_more_donation {
                    font-size: 14px;
                    line-height: 23px;
                    margin-bottom: 5px;
                    color: $primaryColor;
                }

                .title_donation {
                    font-weight: 700;
                    text-transform: uppercase;
                }

                .learn_more_donation {
                    text-decoration: underline;
                    margin-bottom: 15px;
                    cursor: pointer;
                }

                #bloc_add_basket {
                    width: 200px;
                    height: 50px;

                    button {
                        background-color: $black;
                        color: $white;
                    }
                }
            }

            #desc_donation {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: fixed;
                background-color: $bgColor;
                height: auto;
                max-width: 828px;
                min-width: 500px;
                z-index: 200;
                overflow: hidden;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -56px;
                    left: -4%;
                    right: 0;
                    height: 140px;
                    background-color: $white;
                    border-top-left-radius: 100% 264px;
                    border-top-right-radius: 100% 264px;
                    z-index: -1;
                    width: 108%;
                }

                .don_lightbox {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                    padding: 50px;

                    .btn_close {
                        position: absolute;
                        top: 30px;
                        right: 30px;
                        background: url('../svg/close.svg') center center;
                        background-size: 100%;
                        width: 11px;
                        height: 11px;
                        cursor: pointer;
                    }

                    .title_donation_lightbox {
                        font-size: 40px;
                        font-weight: 600;
                        letter-spacing: -1px;
                        font-family: $secondaryFont;
                        margin-bottom: 10px;
                        color: $primaryColor;
                    }

                    .desc_txt_donation {
                        font-size: 14px;
                        line-height: 23px;
                        color: $primaryColor;
                    }

                    #bloc_add_basket {
                        margin-top: 41px;
                        width: 230px;

                        .btn_container {
                            height: 50px;
                            background-color: $black;
                            color: $white;
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .container_donation_texte {
            .donation_intro {
                font-size: 17px;
                font-weight: 600;
                margin-bottom: 10px;
                color: $primaryColor;
            }

            .donation_content{
                font-size: 14px;
                line-height: 23px;
                color: $primaryColor;
            }
        }
    }

    .push_container {
        margin-top: 2rem;
    }

    //dragNdrop
    .dropper {
        border: 1px dashed transparent;
        transition: all .2s ease-out;

        &.drop_hover {
            border-color: $fadeTxt;
            background-color: $greylight;
        }
	}

	.dragHandle {
		display: none;

    }

    .dragOverlay {
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .7);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transition: all .2s ease-out;

        span {
            color: $white;
            text-transform: uppercase;
            font-weight: 600;
            font-family: Arial;
        }
    }


	.draggableElem {
		border: 1px dashed transparent;
        transition: all .2s ease-out;

        &.dragHover {
            border-color: $fadeTxt;

            .dragOverlay {
                height: 100%;
            }
        }

        &[draggable=true] {
            border-color: transparent;

            .dragHandle {
                cursor: grabbing;
            }
        }
	}

    .wrapper_form_adresse {

        #adresseForm {
            max-width: 825px;

            .w-form-line .w-radio-group {
                .w-checkbox-input,
                .w-radio-input {

                    & + & {
                        margin-left: 2em;
                    }

                    .w-input-element {
                        + .w-input-label {
                            &::before {
                                width: 17px;
                                height: 17px;
                                box-shadow: none;
                                background: $secondaryColor;
                            }
                        }

                        &:checked + .w-input-label::before {
                            background-color: $primaryColor;
                            box-shadow: inset 0 0 0 0.2em $secondaryColor;

                            /* safari + IE */
                            @supports (-webkit-appearance:none) {
                                box-shadow: inset 0 0 0 3px $secondaryColor;
                            }

                            @include ie() {
                                box-shadow: inset 0 0 0 3px $secondaryColor;
                            }
                        }
                    }
                }
            }

            /*.w-input-group.accountCreate .w-form-line .w-checkbox-input .w-input-element {

                + .w-input-label {
                    &::before {
                        width: 17px;
                        height: 17px;
                        box-shadow: none;
                        background: $secondaryColor;
                    }
                }

                &:checked + .w-input-label::before {
                    background-color: $primaryColor ;
                    box-shadow: inset 0 0 0 0.2em $secondaryColor;
                }
            }*/

            .w-group-label {
                font-family: $secondaryFont;
                font-size: 1.8rem;
                text-transform: none;
                color: $black;
                letter-spacing: .5px;
                margin-bottom: 1rem;
            }

            .choix_type_adresse {
                display: flex;
                flex-wrap: wrap;
            }

            .type_adresse {
                margin: 0 1.5rem 1rem 0;

                p {
                    font-family: $primaryFont;
                    font-size: .8rem;
                    line-height: 1.3;
                    color: $primaryColor;
                    text-transform: uppercase;
                    cursor: pointer;
                    font-weight: 600;
                    letter-spacing: 1.5px;
                    transition: opacity .2s ease-in;

                    span {
                        display: none;
                    }
                }

                &.actif {
                    p {
                        font-weight:bold;
                    }
                }

                &.ami {
                    display: none;
                }
            }

            .w-submit-button {
                height: 50px;
                font-weight: 500;
            }

            .w-form-line img.valid_form {
                width: 20px;
            }
        }
    }

    &.step_2_0 #cart_total .bill_line {
        font-weight: 500;

        &.total_produit_dropdown .cart_product_line {
            display: flex;
            margin-top: 1.8rem;
            position: relative;

            .cart_product_pic {
                min-width: 130px;
                height: 160px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .cart_product_desc {
                margin: .5rem 0 0 .5rem;

                .wrapper_cart_product_desc {

                    p.cart_product_brand {
                        display: none;
                    }

                    p.cart_product_title {
                        font-size: .8rem;
                        font-weight: 500;
                        color: $black;
                        font-family: $primaryFont;
                        letter-spacing: .5px;
                        position: relative;
                        display: inline;

                        &:after {
                            content: " - ";
                        }
                    }

                    p.cart_product_subtitle {
                        font-size: .8rem;
                        font-weight: 500;
                        color: $black;
                        font-family: $primaryFont;
                        letter-spacing: .5px;
                        text-transform: none;
                        display: inline;
                    }

                    .wrapper_cart_product_price {
                        margin: .5rem 0 1rem;
                        display: flex;

                        .pricetag {
                            font-weight: 600;
                            font-size: .75rem;
                            font-family: $primaryFont;

                            &+.pricetag {
                                margin-left: 1rem;
                            }

                        }
                    }

                    .cart_product_sizecol {
                        font-size: 0.75rem;
                        font-weight: 500;
                        letter-spacing: .5px;
                        padding: 3px ​0;

                        span.color_bullet {
                            display: none !important;
                        }

                        label {
                            font-size: 0.75rem;
                            font-weight: 500;
                            letter-spacing: .5px;
                        }

                        &.quantity {
                            margin: .3rem 0 1rem;
                        }

                        &.color {
                            margin: .3rem 0 0;
                        }
                    }
                }

                &.reposition_qty{
                    .cart_product_modify{
                        .product_quantity_change{
                            bottom: 42.5%;
                        }
                    }
                }

                &.reposition_qty_spe{
                    .cart_product_modify{
                        .product_quantity_change{
                            bottom: 46.5%;
                        }
                    }
                }
            }


            .cart_product_modify {
                display: flex;
                justify-content: flex-start;
                margin-top: 0.5rem;
                margin-bottom: 3px;
                flex-direction: column;

                a {
                    color: $black;
                    text-decoration: underline;
                    font-size: .8rem;
                    margin-bottom: .6rem;
                    font-weight: 500;
                    letter-spacing: .75px;

                    &.product_del {
                        position: absolute;
                        top: 0;
                        right: -1rem;
                        background: url('../svg/close.svg') center center;
                        background-size: 100%;
                        width: 11px;
                        height: 11px;

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}


body.body_login.in_tunnel .main_wrapper {

    .wrapper_tunnel {
        position: relative;

        .tunnel_track_pusher {
            padding: 3.5rem 0 25px;
            position: absolute;
            top: 18%;
            left: 50%;
            z-index: 2;
            transform: translateX(-50%);
            min-width: 520px;

            @include queriesWshop(1500px, max) {

                @supports (-webkit-hyphens:none) {
                    top: 15%;
                    padding: 1.5rem 0 25px;
                }
            }
        }
    }

    .newlogin_page {

        .login_wrapper {
            top: 20%;
            padding-top: 3.5rem;

            @include queriesWshop(1500px, max) {

                @supports (-webkit-hyphens:none) {
                    top: 15%;
                }
            }

            .login_bloc {

                .login_form_wrapper {

                    .title {

                        h3 {
                            font-family: $secondaryFont;
                            font-size: 2.4rem;
                            text-transform: none;
                            letter-spacing: 0;
                            margin: 2rem auto .5rem;

                            @media only screen and (max-width: 1680px) {
                                font-size: 2.25rem;
                            }
                            @media only screen and (max-width: 1520px) {
                                    font-size: 2.2rem;
                            }
                            @media only screen and (max-width: 1370px) {
                                font-size: 2rem;
                            }


                            @include queriesWshop(1500px, max) {

                                @supports (-webkit-hyphens:none) {
                                    margin: 0 auto .5rem;
                                }
                            }
                        }

                        p.subtitle {
                            font-size: .75rem !important;
                            font-weight: 600;
                            margin: 0 auto;
                            line-height: 1.8;
                            letter-spacing: 1px;
                        }
                    }

                    #js-signUpForm.w-newlogin-form, .w-lostpass-form {
                        width: 65%;
                        position: relative;
                        margin: 0 auto;
                        border-bottom: 1px solid $grey;
                        padding-bottom: 5px;

                        .intro {
                            margin: 2rem auto 0;
                            font-size: 0.75rem;
                            color: $fadeTxt;
                            letter-spacing: 1px;
                        }

                        .deja_compte {
                            margin: 1rem 0 0;
                            line-height: 1.5;
                        }

                        input[type=email].w-input-element {
                            border-color: $grey;
                        }

                        .w-fieldset-label {
                            font-family: $primaryFont;
                            font-size: .8rem;
                            letter-spacing: .5px;
                            font-weight: 500;
                            margin: 0 auto 1.2rem;
                            width: 100%;
                        }

                        .w-lines-group .optin_container.column {
                            flex-direction: row;
                            max-width: 390px;
                            margin: 0 auto;

                            .w-form-line {
                                width: 93%;
                                .w-line-label {
                                    color: $black;
                                    font-size: 1rem;
                                    font-family: $primaryFont;
                                    font-size: .8rem;
                                    letter-spacing: .5px;
                                    font-weight: 500;
                                    min-width: 70px;
                                }

                                .w-radio-input+.w-radio-input {
                                    margin-left: 1rem;
                                }

                            }

                            .knowMoreOptin {
                                position: absolute;
                                bottom: -2.5rem;

                                @include ie() {
                                    transform: translateX(-50%);
                                    left: 50%;
                                }

                                a {
                                    font-size: .8rem;
                                    letter-spacing: 1px;
                                    font-weight: 500;
                                    color: $black;
                                }
                            }
                        }

                        .w-submit {
                            margin: 2.6rem auto 1.8rem;
                            max-width: 300px;

                            .w-submit-button {
                                width: 100%;

                                span {
                                    font-size: .8rem;
                                    letter-spacing: 1px;
                                    font-weight: 500;
                                }
                            }
                        }

                        .w-pwd-reset {
                            font-size: .8rem;
                            letter-spacing: 1px;
                            font-weight: 500;
                            color: $black;
                        }

                        p.mdpSentEmail {
                            font-size: .8rem;
                            letter-spacing: .5px;
                            font-weight: 500;
                        }
                    }
                }

                .title {

                    h4 {
                        font-family: $primaryFont;
                        font-weight: bold;
                        font-size: 1rem;
                        letter-spacing: 1px;

                        @media only screen and (max-width: 1370px) {
                            font-size: .9rem;
                        }
                         @media only screen and (max-width: 1360px) {
                            font-size: .85rem;
                        }
                    }

                    p.subtitle {
                        font-size: .95rem;

                        @media only screen and (max-width: 1370px) {
                            font-size: .875rem;
                        }
                         @media only screen and (max-width: 1360px) {
                            font-size: .8rem;
                        }
                    }
                }

                @include queriesWshop(1500px, max) {

                    @supports (-webkit-hyphens:none) {

                        &:last-of-type {
                            padding: 0
                        }
                    }
                }


            }
        }
    }
}

.safari.portrait.tabletwide{
    body.body_login{
        .main_wrapper{
            .newlogin_page{
                .login_wrapper{
                    top: 15%;
                }
            }
        }
    }
}


/* Details de livraison*/
#main_cart_wrapper.wrapper_panier {
    max-width: 1275px;
    margin: 0 auto 40px;
    box-sizing: content-box;

    .left_side {
        width: 65.5%;

        .bloc_pack_illimitee {
            display: none;
        }
    }

    .right_side {
        width: 32.45%;

        @include queriesWshop(1199px, max) {
            width: 43%;
        }

        #cart_total.main_table {

            .total_produit_dropdown .cart_product_line {
                display: flex;
                margin-top: 2rem;
                position: relative;

                .cart_product_pic {
                    min-width: 130px;
                    height: 160px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .cart_product_desc {
                    margin: .5rem 0 0 .5rem;

                    .wrapper_cart_product_desc {

                        p.cart_product_brand {
                            display: none;
                        }

                        p.cart_product_title {
                            font-size: .8rem;
                            font-weight: 500;
                            color: $black;
                            font-family: $primaryFont;
                            letter-spacing: .5px;
                            position: relative;
                            display: inline;

                            &:after {
                                content: " - ";
                            }
                        }

                        p.cart_product_subtitle {
                            font-size: .8rem;
                            font-weight: 500;
                            color: $black;
                            font-family: $primaryFont;
                            letter-spacing: .5px;
                            text-transform: none;
                            display: inline;
                        }

                        .wrapper_cart_product_price {
                            margin: .5rem 0 1rem;
                            display: flex;

                            .pricetag {
                                font-weight: 600;
                                font-size: .75rem;
                                font-family: $primaryFont;

                                &+.pricetag {
                                    margin-left: 1rem;
                                }

                            }
                        }

                        .cart_product_sizecol {
                            font-size: 0.75rem;
                            font-weight: 500;
                            letter-spacing: .5px;
                            padding: 3px ​0;

                            span.color_bullet {
                                display: none !important;
                            }

                            label {
                                font-size: 0.75rem;
                                font-weight: 500;
                                letter-spacing: .5px;
                            }

                            &.quantity {
                                margin: .3rem 0 1rem;
                            }

                            &.color {
                                margin: .3rem 0 0;
                            }
                        }
                    }

                    &.reposition_qty{
                        .cart_product_modify{
                            .product_quantity_change{
                                bottom: 42.5%;
                            }
                        }
                    }

                    &.reposition_qty_spe{
                        .cart_product_modify{
                            .product_quantity_change{
                                bottom: 46.5%;
                            }
                        }
                    }
                }

                .cart_product_modify {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 0.5rem;
                    margin-bottom: 3px;
                    flex-direction: column;

                    a {
                        color: $black;
                        text-decoration: underline;
                        font-size: .8rem;
                        margin-bottom: .6rem;
                        font-weight: 500;
                        letter-spacing: .75px;

                        &.product_del {
                            position: absolute;
                            top: 0;
                            right: -1rem;
                            background: url('../svg/close.svg') center center;
                            background-size: 100%;
                            width: 11px;
                            height: 11px;

                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
            .error_text{
                position: relative;
                top: 1rem;
                display: flex;
                margin-right: -3.35rem;
                img{
                    width: 1.5rem;
                    height: 1.5rem;
                    position: relative;
                    top: 0;
                    display: none;
                }
                #bloc_info_offert{
                    &.tooltip {
                        display: none !important;
                    }
                }
                #error_cart_cdpromo{
                    font-size: 0.65rem;
                    letter-spacing: 0.1px;
                    margin-left: -3px;
                    font-weight: 400;
                }
            }
        }
    }
}

body.checkout{
    .error_text{
        position: relative;
        top: 1rem;
        display: flex;
        margin-right: -3.35rem;
        img{
            width: 1.5rem;
            height: 1.5rem;
            position: relative;
            top: 0;
            display: none;
        }
        #bloc_info_offert{
            &.tooltip {
                display: none !important;
            }
        }
        #error_cart_cdpromo{
            font-size: 0.65rem;
            letter-spacing: 0.1px;
            margin-left: -3px;
            font-weight: 400;
            display: none;
        }
    }
}

body.cart.step_2{
    .error_text {
        margin-right: -2.35rem !important;
        display: none;
    }

    #error_cart_cdpromo {
        display: none;
    }

    #adresseForm {
        .w-form-line.w-has-tel {
            margin-bottom: 2.8rem;
        }
    }
}

.right-container {
    display: none;
}

/* PAGE PAIEMENT */
body.checkout.in_tunnel {

    .wrapper_basket_content {

        .bank_left_side {
            width: 65.5%;
            display: flex;
            flex-direction: column;

            #bloc_virement {

                padding: 0 2rem 2rem;

                h2.bank_title {
                    margin: -.5rem auto;
                }

                .bank_choix_paiement_logo {
                    display: none;
                }

                p.bank_subtitle {
                    text-align: center;
                    cursor: pointer;
                    text-decoration: underline;

                    &.hide {
                        font-size: 0;
                    }

                    span {
                        display: none;
                    }
                }

                .tunel_virement_detail {
                    display: none;

                    &.active {
                        display: block;
                    }

                    #wrapper_virement {

                        table {
                            width: 100%;
                            margin: 1rem 0;

                            th {
                                vertical-align: top;
                            }

                            td {
                                text-align: center;
                            }

                            &.virement_details_adresse td {
                                text-align: left;
                            }

                            &.tab_virement_detail_compte {
                                margin-top: 0;
                            }
                        }

                        .bank_choix_btn_virement {
                            width: 100%;
                            max-width: 15.875rem;
                            margin: 1rem auto;
                            background-color: $black;
                            font-family: Montserrat,sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            letter-spacing: 1.4px;
                            height: 50px;
                            color: $white;
                            line-height: 50px;
                            text-align: center;
                            text-transform: uppercase;
                            cursor: pointer;

                            @media only screen and (max-width: 1680px) {
                                font-size: 13.5px;
                            }
                            @media only screen and (max-width: 1520px) {
                                font-size: 13px;
                            }
                            @media only screen and (max-width: 1370px) {
                                font-size: 12px;
                            }

                        }

                        #bt_valider {
                            display: none;
                        }

                        form {
                            text-align: left !important;
                        }
                    }

                }
            }

            .wrapper_bloc_banque.gift_card {

                .radio {
                    display: block !important;
                    border: 1px solid $fadeTxt;
                    border-radius: 50%;
                    background-color: $secondaryColor;
                    position: absolute;
                    left: -11px;
                    top: 48%;
                    width: 20px;
                    height: 20px;
                    transform: translate(0,-50%);
                    transition: all .2s ease-out;

                    &:after {
                        content:"";
                        display: block;
                        border-radius: 50%;
                        width: 13px;
                        height: 13px;
                        background-color: transparent;
                        transition: all .2s ease-out;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        top: 50%;

                        @include ie() {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                &.active,
                &.actif,
                &:hover {
                    border-color: $black;
                    border-width: 1px;
                    background: $white;
                    position: relative;

                    .checkbox,
                    .radio {
                        border-color: $black;

                        &:after {
                            background-color: $black;

                            @include ie() {
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                }

                #formGiftCard {
                    padding: 0 3rem;

                    .giftcard_desc {
                        margin: 3rem auto .2rem;
                    }

                    .giftcard-buttons-container {
                        margin: 1.875rem 0;

                        .w-submit-button {
                            background: $black;
                            width: 250px;
                            height: 50px;

                            span {
                                text-decoration: none;
                                color: $white;
                                text-transform: uppercase;
                            }
                        }

                    }

                    #cardCode p.w-input-label {
                        display: none;
                    }
                }

                .gift_card_title {
                    top: 1rem;
                    width: 55%;

                    @include queriesWshop(1250px, max) {
                        width: 70%;
                    }
                }

                .w-submit-button span {
                    font-size: .8rem;
                    letter-spacing: 0;
                }
            }

            .w-submit-button {
                margin: 0 auto;
                background: transparent;
                border: none;

                span {
                    color: $black;
                    text-transform: none;
                    text-decoration: underline;
                }
            }

            .wrapper_bloc_banque.paypal {
                .bloc_bank_contents {
                    .bank_subtitle.loader_paypal {
                        display: block;

                         &::before {
                            background-size: auto 25%;
                            margin-top: 8px;
                            width: 80%;
                        }
                    }
                }
            }

            #hipay_applepay_bloc {
                padding: 1.75rem;

                .title_wrapper_fold {
                    margin-top: -1.2rem;

                    .bank_title {
                        top: 17px;
                        width: 26%;
                    }

                    .total_transaction {
                        display: none;
                    }
                }

                #hipay-apple-pay-button {
                    position: relative;
                    max-width: 23rem;
                    height: 1rem;
                    padding: 0;
                    overflow: hidden;
                    margin: 1.3rem auto 0;

                    &:before,
                    &:after {
                        display: none;
                        content: none;
                    }

                    &:after {
                        left: inherit;
                        right: 0;
                    }

                    iframe {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 100%;
                        height: 2rem;
                    }
                }
            }
        }

        

        .bank_right_side {
            width: 29%;

            .main_table {
                padding: 30px 0 0;

                h3.recap_cart_title {
                    text-align: center;
                    font-size: 2rem;
                    font-family: $secondaryFont;
                    color: $black;
                    margin-bottom: 20px;
                    line-height: 1.5;
                    text-transform: uppercase;
                    letter-spacing: .5px;
                    font-weight: bold;
                    text-transform: none;
                    order: 1;

                    /*@media only screen and (max-width: 1680px) {
                        font-size: 1.9rem;
                    }
                    @media only screen and (max-width: 1520px) {
                            font-size: 1.85rem;
                    }
                    @media only screen and (max-width: 1370px) {
                        font-size: 1.75rem;
                    }*/


                    span {
                        font-family: $primaryFont;
                        display: block;
                        font-weight: 600;
                        font-size: 1rem;
                        text-transform: uppercase;
                        letter-spacing: 2px;

                        /*@media only screen and (max-width: 1370px) {
                            font-size: .9rem;
                        }
                         @media only screen and (max-width: 1360px) {
                            font-size: .85rem;
                        }*/
                    }
                }

                .wrapper_payment {
                    order: 0;
                    padding: 0 1.5rem 0 4.5rem;
                }

                .bill_line {

                    &.total_produit {
                        order: 2;
                    }

                    &.total_produit_dropdown {
                        order: 3;
                    }

                    &.unlimited {
                        order: 4;
                    }

                    &.discount {
                        order: 5;
                    }

                    &.total {
                        order: 5;
                        padding: 1.5rem 0;
                    }

                }

                .formPanier_container {
                    border: 0;
                    margin: 0;
                }

                .upselling.price {
                    border-top: 32px solid $secondaryColor;
                }
            }

            .top {

                .recap_cart_title {
                    font-family: $primaryFont;
                    font-size: 1rem;
                    text-transform: uppercase;

                    @include queriesWshop(1370px, max) {
                        font-size: .9rem;
                    }

                    span {
                        display: inline;
                        font-weight: bold;

                        @include queriesWshop(1370px, max) {
                            font-size: .9rem;
                        }
                    }
                }
            }
        }
    }

}


/* PAGE FELICITATION TUNNEL */
body.checkout.bankThanks {

    .tunnel_track_pusher {
        padding: 4.2rem 0;
    }

    .thanksWrapper .bankThanksTop {
        max-width: 1200px;

        h1 {
            color: $primaryColor;
            text-align: center;
            flex-wrap: wrap;
            justify-content: center;
            font-family: $secondaryFont;
            font-weight: bold;
            font-size: 2.4rem;
            text-transform: none;
            letter-spacing: 0;
            margin: 0 0 1rem;

            @media only screen and (max-width: 1680px) {
                    font-size: 2.2rem;
            }
            @media only screen and (max-width: 1520px) {
                    font-size: 2.1rem;
            }
            @media only screen and (max-width: 1370px) {
                font-size: 2rem;
            }


            &:before {
                width: 27px;
                height: 27px;
            }

            span {
                font-family: $primaryFont;
                font-weight: 600;
                font-size: .8rem;
                text-transform: none;
                letter-spacing: .5px;
                margin: 2rem 0 1rem;
                line-height: 1.5;
            }
        }

        .tunel_virement_detail {
            text-align: center;

            .bloc_section_virement_bancaire {
                font-family: $primaryFont;
                font-weight: 600;
                font-size: .8rem;
                text-transform: none;
                letter-spacing: .5px;
                margin: 1rem 0;
                line-height: 1.5;

                .virement_sub_title {
                    font-weight: bold;
                }

                table {
                    width: 100%;
                    max-width: 600px;
                    margin: 0 auto;
                }

                &.bank_infos table {

                    &.virement_details_adresse {
                        text-align: left;
                        width: 600px;
                        margin: 0 auto;

                        tr {
                            line-height: 2;

                            th {
                            vertical-align: top;
                            }
                        }
                    }
                }

                &.account_infos table {

                    th {
                        width: 180px;
                        text-align: left;
                    }

                    td {
                        text-align: left;
                    }

                }
            }

            .virement_nimero_commande {
                font-family: $primaryFont;
                font-weight: 600;
                font-size: .8rem;
                text-transform: none;
                letter-spacing: .5px;
                margin: 1rem 0;
                line-height: 1.5;
            }
        }

        #around_me_map {
            height: 450px;
            margin: 0 auto 3rem;
        }
    }

    .thanksWrapper .thanksRecap {
        max-width: 1200px;
        margin: 0 auto 4rem;

        .thanksLeft {
            width: 42%;

            .thanksLeftWrapper {
                display: flex;
                flex-direction: column;

                .w-newpass-form {
                    width: 96%;

                    .w-form-line .w-input.w-password-input {
                        position: relative;

                        .w-visibility-toggle {
                            position: absolute;
                            content: '';
                            background: url('../svg/icons.svg') no-repeat;
                            @include bgIconCoord(16, 8, 5);
                            background-size: 900% auto;
                            width: 1.8rem;
                            height: 1rem;
                            right: 1rem;
                            top: 1rem;
                            opacity: 1;
                            padding: 0;
                            transform: none;
                        }
                    }
                }

                .thanksTrust {
                    order: 5;
                    margin: 0;
                    font-family: $primaryFont;

                    strong {
                        font-size: .75rem;
                        letter-spacing: .5px;
                    }
                }

                .seeOrderEvo, .createPwdTxt {
                    font-size: .75rem;
                    letter-spacing: .5px;
                    font-weighT: 600;
                    line-height: 1.8;
                }
            }

            p:not(.w-input-label) {
                font-size: 1rem;
                color: $primaryColor;
                margin-bottom: 0;

                strong {
                    font-size: 1rem;
                }

                a {
                    text-decoration: none;
                    color: $primaryColor;
                }

                &.createPwdTxt {
                    margin-bottom: 1rem;
                }
            }

            form#clientForm {
                margin-bottom: 1rem;
            }

            .bankThanksBtn {
                max-width: 250px;
                height: 50px;
                background: $black;
                display: block;
                font-size: .85rem;
                font-weight: 500;
                text-transform: uppercase;
                margin: 1.5rem 0;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    letter-spacing: 1px;
                }
            }
        }
    }

    .thanksRight {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 41%;
        padding: 40px 2rem;
        background-color: $white;

        .thanksRightWrapper {
            width: 100%;
        }

        .txt_thanks_transaction {
            display: block;
        }

        p {
            font-size: .8rem;
            font-weight: 500;
            margin-bottom: .5rem;

            label {
                white-space: nowrap;
                font-weight: 500;
                font-size: .8rem;
                letter-spacing: .5px;



                &:after {
                    content: " :";
                    margin-right: 5px;
                }
            }

            span {
                white-space: nowrap;
                text-align: left;
                font-weight: bold;
                font-size: .75rem;
            }

            &.txt_thanks_transaction {
                text-transform: none;
                margin-bottom: 0;
                margin-top: 30px;
                font-size: .8rem;
                letter-spacing: .5px;

            }
        }
    }

    .gm-style {

        .gm-style-iw-c {
            box-shadow: none;
            border-radius: 0;
            width: 350px;
            top: 50px;
            left: -150px;
            padding: 12px!important;

            .gm-style-iw-d{
                max-height: 279px!important;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: .8rem;
                right: 2rem;
                background: url('../img/pin_dark.png') no-repeat;
                background-size: 100%;
                width: 24px;
                height: 33px;
                display: block;
            }
        }

        .gm-style-iw-t::after {
            display: none;
        }

        .gm-style-iw {

            button.gm-ui-hover-effect {
                display: none !important;
            }

            .gm-style-iw-d{
                .infoWindowContent {
                    font-weight: 400;
                    font-size: .85rem;
                    letter-spacing: 1px;
                    line-height: 1.5;
                }
            }
        }
    }
}
body.cart.step_2 #map_canvas{
    .gm-style-iw-d{
        .infoWindowContent {
            font-weight: 400;
            font-size: .85rem;
            letter-spacing: 1px;
            line-height: 1.5;
        }
        .info_popup div.details{
            .button.secondary.dark{
                height: 2rem;
            }
        }
    }
}

/* Cacher le header sur les page tunnels */

body.in_tunnel {

    #header_mention_id {
        display: none !important;
    }

    .head_top {
        background: $secondaryColor;
        border-bottom: 0;

        .header {
            justify-content: center;
            height: 132px;

            .block_top_links {

                &.logo {
                    width: 250px;
                    align-self: center;
                }

                &.menu_top, &.right {
                    display: none;
                }
            }
        }
    }
}

.bloc_section_virement_bancaire {
    .montant_trans span{
        font-weight: 600;
    }
}

.step_1 .tunnel_track_pusher {
    padding: 2rem 0 4rem;

    @media screen and (max-height: 768px) and (max-width: 1370px) {
        padding: 2rem 0;
    }
}

body.en.checkout .wrapper_panier .bank_left_side .wrapper_bloc_banque.paypal .bank_title span::after {
    left: 2rem;

    @include queriesWshop(1520px, max) {
        left: 1.7rem;
    }
}

body.en.step_1 .tunnel_track_pusher {
    padding: 2rem 0 3.5rem;

    @media only screen and (max-width: 1460px) {
        padding: 2rem 0 3rem;
    }
}