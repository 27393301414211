@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.reveal-anim-elem {
    opacity: 0;
    transition: opacity .5s ease-in .5s;
    &.elem-revealed {
        opacity: 1;
    }
}

.rayon_lookbook {

    .top_bar{
        @media only screen and (max-width: 1370px) {
            margin-bottom: 0;
        }
    }

    .wrap_redirects {
        margin-bottom: 6rem;

        @media only screen and (max-width: 1370px) {
            margin-bottom: 0;
        }

        .title{
            @media only screen and (max-width: 1370px) {
                margin-bottom: 0;
            }
        }
    }

    #list_item {
        &.items_wrapper {
            .item_look {

                h2 {
                    font-family: $primaryFont;
                    font-size: 18 / 16 * 1rem;
                    font-weight: 900;
                    line-height: 1.2;
                    letter-spacing: .5px;
                    text-transform: uppercase;
                    margin: 5rem 0 1rem;

                    @media screen and (max-width: 1460px) {
                        font-size: 16 / 16 * 1rem;
                        margin: 3rem 0 1rem;
                    }

                    @media screen and (max-width: 1180px) {
                        font-size: 14 / 16 * 1rem;
                    }

                    &:empty {
                        display: none;
                    }
                }

                p {
                    font-family: $primaryFont;
                    font-size: 13 / 16 * 1rem;
                    font-weight: 600;
                    line-height: 1.5;
                    text-align: justify;

                    @media screen and (max-width: 1460px) {
                        font-size: 10 / 16 * 1rem;
                    }

                    @media screen and (max-width: 1180px) {
                        font-size: 8 / 16 * 1rem;
                    }

                    &:empty {
                        display: none;
                    }

                    a {
                        display: block;
                        margin: 1.3rem 0;
                        cursor: pointer;

                        @media screen and (max-width: 1460px) {
                            margin: 1rem 0;
                        }

                        &:after {
                            content: '';
                            width: 70px;
                            height: 9px;
                            background: url('../svg/arrow_btn_black.svg') no-repeat 50% 50%;
                            background-size: contain;
                            display: inline-block;
                            margin-left: 1rem;

                            @media screen and (max-width: 1495px) {
                                width: 50px;
                                height: 7px;
                            }
                        }
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: bottom;
                }

                &:nth-of-type(10n + 1) {
                    width: 28%;
                    max-width: 474px;
                    margin-right: 4.75%;
                }

                &:nth-of-type(10n + 2) {
                    width: 41%;
                    max-width: 686px;
                    position: relative;

                    &:after {
                        content: '';
                        width: 67vw;
                        max-width: 1280px;
                        height: 81%;
                        background-color: $white;
                        position: absolute;
                        top: 9%;
                        left: 50%;
                        z-index: -1;
                        border-radius: 0 0 50% 50%;
                        transform: translateX(-50%);
                    }

                    img {
                        max-width: 150px;
                    }
                }

                &:nth-of-type(10n + 3) {
                    width: 41%;
                    max-width: 686px;
                    margin-left: -41%;
                    margin-top: 450px;
                    max-height: 400px;
                    overflow-y: hidden;

                    @media screen and (max-width: 1460px) {
                        margin-top: 390px;
                    }
                }

                &:nth-of-type(10n + 4) {
                    width: 21.5%;
                    max-width: 362px;
                    margin-left: 4.75%;
                    margin-top: 9%;
                }

                &:nth-of-type(10n + 5) {
                    width: 100%;
                    max-width: 100%;
                    margin-top: 7%;
                    margin-bottom: 7%;
                    max-height: 600px;
                    overflow-y: hidden;
                }

                &:nth-of-type(10n + 6) {
                    width: 28%;
                    max-width: 474px;
                    margin-right: 4.75%;
                    position: relative;

                    &:after {
                        content: '';
                        width: 100vw;
                        height: 400px;
                        background-color: $white;
                        position: absolute;
                        top: 40%;
                        left: 0;
                        z-index: -1;
                        transform: translateX(-6vw);

                        @media screen and (max-width: 1460px) {
                            height: 350px;
                            transform: translateX(-3vw);
                        }

                        @media screen and (max-width: 1280px) {
                            height: 320px;
                        }
    
                        @media screen and (max-width: 1180px) {
                            height: 290px;
                        }

                        @media screen and (max-width: 1024px) {
                            height: 240px;
                        }
                    }
                }

                &:nth-of-type(10n + 7) {
                    width: 21.5%;
                    max-width: 362px;
                    margin-top: 12%;
                }

                &:nth-of-type(10n + 8) {
                    width: 41%;
                    max-width: 686px;
                    margin-left: 4.75%;
                }

                &:nth-of-type(10n + 9),
                &:nth-of-type(10n + 10) {
                    width: 48%;
                    max-width: 800px;
                    margin-top: 7%;
                    margin-bottom: 7%;
                    max-height: 1000px;
                    overflow-y: hidden;

                    @media only screen and (max-width: 1023px) {
                        overflow-y: inherit;
                    }
                }

                &:nth-of-type(10n + 9) {
                    margin-right: 2%;
                }

                &:nth-of-type(10n + 10) {
                    margin-left: 2%;
                }

                &.paysage{
                    .item_look_contents{
                        @media only screen and (max-width: 920px) {
                            overflow: hidden!important;
                        }
                    }
                }

            }
        }

        &.bydefault {
            max-width: 1680px;
        }
    }

    .item_look_contents {
        position: relative;

        .tag {
            display: block;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: $white;
            position: absolute;
            opacity: 0;
            animation: fadeIn .3s linear .5s 1 alternate forwards;

            &:hover,
            &:focus {
                .tag_tooltip {
                    opacity: 1;
                }
            }

            &:after {
                content: "";
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: $black;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
            }

            .tag_tooltip {
                z-index: 1;
                opacity: 0;
                position: absolute;
                left: 50%;
                bottom: 150%;
                transform: translateX(-50%);
                background: #fff;
                padding: 1.2rem;
                width: 12rem;
                transition: opacity .2s ease-in-out;
                text-transform: capitalize;

                strong {
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 0.85rem;
                    line-height: 1.4;

                    &:first-of-type {
                        &:after {
                            content: "-";
                            padding: 0 0 0 4px;
                        }
                    }
                }

                .price_wrapper {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: flex-end;
                    margin: 0.5rem 0;

                    .pricetag {
                        font-size: .85rem;
                        & + .pricetag {
                            font-size: .7rem;
                        }
                    }
                }
            }
        }
    }
}


.lightbox#lightbox_achat_express {
    width: 100%;
    max-width: 900px;
    height: 605px;
    padding: 2rem;
    background-color: $secondaryColor;

    @media screen and (max-width: 960px) {
        width: 97%;
    }

    .form_submit .button+.loader {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background-size: 20%!important;
       }
    }

    .swiper-slide.has_video {
        .block_vid {
            position: relative;
            padding-bottom: 126%;
            height: 0;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    #gondole_derniers_articles_vus,
    #gondole_aimerez,
    .ariane_wrapper {
        display: none;
    }

    #content_achat_express {
        .prod_top_wrapper {
            display: flex;
        }

        .prod_btn_retour_wrapper {
            width: 420px;

            #visual_scroller_wrapper {
                display: none;
            }

            #buyNowSwiper {
                width: 420px;

                @media screen and (max-width: 820px) {
                    width: 369px;
                }
            }
        }

        .product_info_wrapper {
            width: 50%;
            padding-left: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .product_feats_wrapper,
            .share_wrapper,
            #breadcrumbs {
                display: none;
            }
        }

        .pdt_icon_btm {
            position: absolute;
            top: 1rem;
            left: 1rem;
            z-index: 1;
        }

        .eclatProd {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            z-index: 1;
        }
    }

    #buyNowSwiper {
        .swiper-slide {
            background-image: none !important;
        }

        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }
    }

    .item_name {
        display: flex;
        flex-direction: column;
        position: relative;

        h1 {
            font-family: $secondaryFont;
            font-size: 40 / 16 * 1rem;
            font-weight: 600;
            line-height: 32 / 16 * 1rem;
            color: $primaryColor;
            text-transform: lowercase;
            margin-bottom: 1.5rem;
            display: inline-block;
            width: 95%;
		
            &:first-letter{
                text-transform: uppercase;
            }

            @media screen and (max-width: 820px) {
                font-size: 1.45rem;
                line-height: 1.45rem;
            }
        }

        small {
            font-family: $primaryFont;
            font-size: 1rem;
            line-height: 1.2;
            color: $primaryColor;
            font-weight: 400;
            text-transform: lowercase;
            margin-bottom: 0.3rem;
            display: inline-block;
		
            &:first-letter{
                text-transform: uppercase;
            }

            @media screen and (max-width: 820px) {
                font-size: 0.75rem;
                margin-bottom: 1rem;
                width: 90%;
            }
        }
    }

    .item_price {
        .price_tag {
            font-family: $primaryFont;
            font-size: 22 / 16 * 1rem;
            line-height: 1;
            color: $primaryColor;
            font-weight: 600;

            & + .price_tag {
                text-decoration: line-through;
                margin-right: 1rem;
                font-size: 18 / 16 * 1rem;
                color: #757270;
            }
        }
    }

    #ligne_couleur {
        margin: 2rem 0;
        .dropp {
            width:100%;
            height: 123px;
            margin: auto;
            background: transparent;
            position: relative;
            
            .drop_btn_big {
                position: absolute;
                height: 123px;
                left: 0;
                top: 0;
                width: 100%;
                z-index: 10;
                cursor: pointer;
                background-color: transparent;

                &.opened {
                    z-index: -1;
                }
            }
            
            .no_dropdown {
                cursor: default;
            }
            
            .dropp-header {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
            }

            .dropp-header__btn {
                display: block;
                transition: all .3s ease-in-out;
                position: absolute;
                right: 30px;
                top: 35px;
                height: 50px;
                width: 12px;
                z-index: 5;
                background: transparent url(../svg/fp_color_btn.svg) center center no-repeat;
                background-size: contain;
                cursor: pointer;

                &.drop_button {
                    transition: all .3s ease-in-out;
                }
            }
        }

        label.color_title, .titre_couleur {
            font-size: 0;
            display: none;
        }

        .choices_list {
            border: 1px solid #989898;
            background-color: $bgColor2;
            position: relative;
            z-index: 5;
            height: 123px;
            overflow: hidden;
            transition: all .35s ease-in-out;
            display: flex;
            flex-direction: column;
            max-height: 123px;

            .simplebar-content {
                display: flex;
                flex-direction: column;
            }

            .form_itm.color  {
                position: relative;
                order: 2;
                padding: 0 25px;

                &:last-of-type, &:only-child {
                    margin-bottom: 0;
                    border-bottom: none;
                    padding-bottom: 0;
                }

                &.active {
                    order: 1;
                    padding-top: 0;

                    &:last-of-type {
                        margin-bottom: 25px;
                    }
                }

                input {

                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: -1;
                    cursor: pointer;
                    opacity: 0;
                    height: 100%;
                    width: 100%;

                    &[type=radio]:not(:checked)+label {
                        margin: 0;
                    }

                    &+label {
                        position: relative;
                        cursor: pointer;
                        align-items: center;
                        padding: 23px 0;
                        border-bottom: 1px solid #9d9894;
                        
                        &:before, &:after {
                            display: none;
                        }
                        
                        span {
                            width: 60px;
                            height: 75px;
                            border: 1px solid rgba(0,0,0,0.05);
                            background-size: cover;
                            display: inline-block;
                        }

                        .colorName {
                            margin-left: 2rem;
                            font-weight: 600;
                            font-size: 14px;
                            font-family: $primaryFont;
                            letter-spacing: 0.05em;
                            white-space: nowrap;
                            text-transform: lowercase;
                            &::first-letter {
                                text-transform: uppercase;
                            }

                            @media screen and (max-width: 820px) {
                                margin-left: 1.5rem;
                                font-size: 12px;
                            }
                        }

                        
                    }

                    &:checked {

                        &+label {
                            margin: 0;
                        }
                    }

                }
            }

            &.actif {
                max-height: inherit;
                height: auto;

                 .form_itm.color  {
                    input {
                        z-index: 5;
                    }
                }
            }

            &.js-visible {
                transition: height .3s ease-in-out .5s;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                right: 0;
                height: auto;
                max-height: 300px;
                z-index: 4;
                box-shadow: 0px 1px 0px rgba(0,0,0,.1);
                width: 100%;
                overflow-y: auto;

                .flex_container {
                    display: none;
                }


                .form_itm.color {
                    &.active {
                        padding-top: 0;

                        &:last-of-type {
                            margin-bottom: 25px;
                        }
                    }
                }
            }
        }

    }

    #ligne_pointure {
        .prod_listes {
             &.size_wrapper {

                 display: flex;
                 justify-content: space-between;
                 align-items: center;
                 margin-bottom: 2rem;

                 .size_title {
                     display: none;
                 }

                 .choices_list {
                     display: flex;
                     justify-content: flex-start;
                     align-items: center;
                     flex-wrap: wrap;
                     width: calc(100% - 130px);
                     padding-left: 5px;

                     .form_itm.size {
                         position: relative;
                         width: 32px;
                         height: 32px;
                         text-align: center;
                         margin-right: 7px;

                         input {
                             position: absolute;
                             top: 0;
                             bottom: 0;
                             right: 0;
                             left: 0;
                             margin: auto;
                             width: inherit;
                             height: inherit;
                             opacity: 0;

                             &+label {
                                 font-weight: 600;
                                 font-size: 10px;
                                 letter-spacing: 0.05em;
                                 text-align: center;
                                 position: absolute;
                                 top: 0;
                                 right: 0;
                                 left: 0;
                                 bottom: 0;
                                 margin: auto;
                                 width: inherit;
                                 height: inherit;
                                 line-height: 32px;
                                 display: block;
                                 text-align: center;

                                 &:before {
                                     content:'';
                                     position: absolute;
                                     top: 0;
                                     right: 0;
                                     bottom: 0;
                                     bottom: 0;
                                     margin: auto;
                                     width: inherit;
                                     height: inherit;
                                     background-color: transparent;
                                     border: 1px solid transparent;

                                 }

                             }

                             &:checked {
                                 &+label {
                                     &:before {
                                         border: 1px solid #989898;
                                     }

                                     &:after {
                                         display: none;
                                     }
                                 }
                             }

                             &.disabled {
                                 &+label {
                                     text-decoration: line-through;
                                 }
                             }
                         }


                     }
                 }

                 .block_sizeguide {
                     .sizeguide {
                         font-weight: 600;
                         font-size: 13px;
                         color: $darkColor;
                         font-family: $primaryFont;
                         letter-spacing: .5px;
                         display: inline-block;
                         border-bottom: 1px solid #777;
                     }
                 }
             }
        }

        .disabledBtnTooltip {
             top: 120%;
             border: none;
             background-color: transparent;
             padding: 0;
             min-width: inherit;
             display: none;

             &:before {
                 display: none;
             }

             .disabledBtnInfos {
                 font-size: 11px;
                 color: #ff3439;
                 letter-spacing: 0.05em;
                 font-weight: 600;
             }
         }
     }

    // CTA wishlist
    #addToWishlistButtonContainer {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        top: 20px;


        .btnWishlist  {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            position: relative;

            &:before {
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 20px;
                height: 20px;
                background: url(../svg/wishlist.svg) center center no-repeat;
                background-size: contain;
            }

            &.existToWishlistButton {
                &:before {
                    background: url(../svg/wishlist_exist.svg) center center no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    #product_addcart {
        .prod_btn_wrapper {
            position: relative;
            margin: 1rem auto 0;
            max-width: 230px;

            #sizeerror {
                position: absolute;
                top: -1.35rem;
                font-size: 11px;
                color: #ff3439;
                letter-spacing: 0.05em;
                font-weight: 600;
                display: none;
        
                &.show {
                    display: block !important;
        
                    span {
                        text-transform: lowercase;
                    }
                }
            }
        }
    }

    #bloc_add_basket { 
        margin: 0 0 1rem;
               
        .btnAddBasket {
            background-color: $primaryColor;
            color: $white;
        }
    }

    #bloc_reserve_instore {
        display: none !important;
    }

    #bloc_acces_fiche_produit {
        text-align: center;
        
        a {
            display: inline-block;
            font-family: $primaryFont;
            font-size: 13 / 16 * 1rem;
            line-height: 1;
            color: $primaryColor;
            font-weight: 600;
            margin: .4rem 0 0;
            text-decoration: underline;
            text-underline-position: under;
        }

    }

}