#copyright {
    width: 100%;
    max-width: 1920px;
    margin: auto;
    padding-top: 82px;
    background: #FFF url(../img/footer_bg_2.png) center top no-repeat;

    @media only screen and (max-width: 1720px) {
        padding: 82px 0 0;
    }

    @media only screen and (max-width: 1360px) {
        padding: 15px 0 0;
    }


    .reassurance {
        width: 100%;
        max-width: 1680px;
        padding: 0 0 50px 0;
        border-bottom: 1px solid $bgColor2;
        margin: auto;

        .content {
            width: 100%;
            max-width: 1210px;
            margin: auto;
            display: flex;
            justify-content: space-between;

            @media only screen and (max-width: 1680px) {
                max-width: 1100px;
            }

            @media only screen and (max-width: 1370px) {
                max-width: 900px;
            }

            @media only screen and (max-width: 1360px) {
                max-width: 800px;
            }

            .reassu_item  {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                span {
                    font-size: 13px;
                    font-family: $primaryFont;
                    color: $darkColor;
                    letter-spacing: 0.05em;
                    display: inline-block;

                    &.ico {
                        height: 32px;
                        width: 100%;
                        margin: 0 auto 20px;
                        background-size: contain;
                        background-position: center center;
                        background-repeat: no-repeat;
                    }
                }

                &.secured_payment {
                    .ico {
                        background-image: url(../svg/paiement_securise.svg);
                    }
                }

                &.expedition_24 {
                    .ico {
                        background-image: url(../svg/expedition_24.svg);
                    }
                }

                &.livraison_gratuite_magasin {
                    .ico {
                        background-image: url(../svg/livraison_gratuite_magasin.svg);
                    }
                }

                &.sc_mail {
                    .ico {
                        background-image: url(../img/sc.png);
                        background-size: contain;
                        width: 40px;
                        height: 32px;
                    }
                }
            }

            @media only screen and (max-width: 1360px) {
                flex-wrap: wrap;

                .reassu_item {
                    width: 50%;
                    margin-top: 40px;
                }
            }
        }
    }

    .map{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 40px 0 35px;

        @media only screen and (max-width: 1380px) {
        padding: 0 20px;
        }

        .content{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            max-width: 1680px;

            @media only screen and (max-width: 1720px) {
                padding: 0 20px;
            }


            .menu_footer {
                display: flex;
            }

            .satellites{
                display: flex;
                align-items: flex-start;
                justify-content: center;

                .navlist{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-right: 105px;

                    @media only screen and (max-width: 1380px) {
                        margin-right: 80px;
                    }



                    .title{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-family: $primaryFont;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 14px;
                        letter-spacing: 0.05em;
                        color: $darkColor;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                        white-space: nowrap;
                        text-align: left;
                        min-width: 100px;
                    }

                    li{
                        font-family: $primaryFont;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 25px;
                        letter-spacing: 0.05em;
                        color: $darkColor;
                        text-transform: none;
                        text-align: left;
                        white-space: nowrap;

                        &:last-child{
                            margin-bottom: 0;
                        }

                        &.cpt{
                            text-transform: capitalize;
                        }
                    }

                    @media only screen and (max-width: 1360px) {
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            #listes{
                tr{
                    display: flex;
                    align-items: flex-start;

                    td{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin-right: 5.5vw;

                        @media only screen and (max-width: 800px) {
                            margin-right: 35px;
                        }

                        h2, .title {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            font-family: $primaryFont;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 14px;
                            letter-spacing: 0.05em;
                            color: $darkColor;
                            text-transform: uppercase;
                            margin-bottom: 15px;
                            white-space: nowrap;
                            text-align: left;
                            min-width: 100px;
                        }

                        table{
                            tr{
                                td{
                                    margin-right: 0;

                                    h3{
                                        font-family: $primaryFont;
                                        font-weight: 60;
                                        font-size: 13px;
                                        line-height: 25px;
                                        letter-spacing: 0.05em;
                                        color: $darkColor;
                                        text-transform: none;
                                        text-align: left;
                                        white-space: nowrap;
                                    }
                                }

                                &:last-child{
                                    td{
                                        h3{
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .news_n_evaluation {
                display: flex;
                justify-content: space-between;
                width: 100%;
                max-width: 780px;

                #block_news, .signup-module{
                    width: 100%;
                    max-width: 530px;
                    margin-right: 100px;

                    @media only screen and (max-width: 1720px) {
                        margin-right: 50px;
                    }

                    .home-module-body{
                        width: 100%;

                        .home-column {
                            .home-module-title {
                                font-family: $secondaryFont;
                                font-weight: 600;
                                font-size: 40px;
                                line-height: 27px;
                                letter-spacing: -0.025em;
                                text-transform: none;
                                color: $darkColor;
                                width: 100%;
                                text-align: left;
                                display: flex;
                                align-items: flex-end;
                                justify-content: flex-start;
                                margin-bottom: 1.2rem;

                                span {
                                    font-family: $primaryFont;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 1;
                                    letter-spacing: 0.1em;
                                    text-transform: uppercase;
                                    display: inline-block;
                                    padding-left: 25px;
                                }

                                .home-module-text {
                                    font-size: 13px;
                                    font-family: $primaryFont;
                                    text-transform: uppercase;
                                    margin-left: 2rem;
                                    font-weight: 100;
                                    padding-bottom: 7px;
                                }
                            }

                            .w-newsletter-form {
                                width: 100%;
                                height: 50px;

                                .w-input-element:not([type=radio]):not([type=checkbox]), 
                                input[type=email].w-input-element,
                                input {
                                        padding-right: 3.75rem;
                                        border-radius: 0;
                                        height: 50px;
                                        background-color: $tertiaryColor;
                                        color: #FFF !important;

                                        &+.w-input-label {
                                            span {
                                                color: #FFF;
                                                font-family: Arial, sans-serif;
                                                font-size: 14px;
                                            }
                                        }

                                        &::placeholder {
                                            color: #FFF;
                                            opacity: 1;
                                        }

                                        ::-webkit-input-placeholder {
                                            color:red;
                                        }

                                        &::-webkit-input-placeholder {
                                            color: #FFF;
                                            opacity: 1;
                                        }

                                        &::-moz-placeholder {
                                            color: #FFF;
                                            opacity: 1;
                                        }

                                        &:-ms-input-placeholder {
                                            color: #FFF;
                                            opacity: 1;
                                        }

                                        &:-moz-placeholder {
                                            color: #FFF;
                                            opacity: 1;
                                        }

                                        &:-webkit-autofill,
                                        &:-webkit-autofill:hover, 
                                        &:-webkit-autofill:focus, 
                                        &:-webkit-autofill:active {
                                            -webkit-box-shadow: 0 0 0 30px $tertiaryColor inset !important;
                                            color: #FFF !important;
                                            -webkit-animation-fill-mode: both;
                                        }
                                }

                                .w-form-line.w-submit {

                                    max-width: 110px;
                                    width: 100%;
                                    height: 50px;

                                    .w-btn-wrapper{
                                        .w-submit-button {
                                            height: 50px;
                                            border: none;
                                            width: 110px;
                                            background-color: transparent;
                                            padding: 0 20px;

                                            &:not(.w-loader) {
                                                span {
                                                    font-size: 0;
                                                    display: inline-block;
                                                    width: 100%;
                                                    height: 50px;
                                                    background-size: contain;
                                                    background-repeat: no-repeat;
                                                    background-position: center center;
                                                    background-image: url(../svg/arrow_btn.svg);
                                                }
                                            }

                                            &.w-loader {
                                                top: 2px;
                                                height: 46px;
                                                background-color: $tertiaryColor;
                                                opacity: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            .rs_footer {
                                display: flex;
                                align-items: flex-end;
                                justify-content: flex-end;
                                margin-top: 40px;

                                .col_links {
                                    width: 25px;
                                    height: 25px;
                                    margin-left: 30px;
                                    background-size: 500% 100%;
                                    background-image: url(../svg/social_b.svg);
                                    background-repeat: no-repeat;

                                    span {
                                        font-size: 0;
                                    }

                                    &.linkedin {
                                        background-position: 100% 0; 
                                    }
                                    &.youtube {
                                        background-position: 75% 0;
                                        width: 35px;
                                        height: 35px;
                                        position: relative;
                                        top: 7px;
                                    }

                                    &.pinterest {
                                        background-position: 50% 0;
                                        width: 25px;
                                        height: 25px;
                                    }

                                    &.insta {
                                        background-position: 25% 0;
                                        width: 25px;
                                        height: 25px;
                                    }
                                    &.fb {
                                        background-position: 0% 0;
                                        width: 25px;
                                        height: 25px; 
                                    }
                                }

                                @media only screen and (max-width: 1360px) {
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }

                .evaluation{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;

                    @media only screen and (max-width: 1024px) {
                        margin-top: 48px;
                    }

                    .media_title{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-family: $primaryFont;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        letter-spacing: 0.05em;
                        color: #797979;
                        text-transform: capitalize;
                        margin-bottom: 16px;
                        white-space: nowrap;
                    }

                    a{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .wrap_rating{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 5px;

                            .rating_stars{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: url('../img/etoiles_evaluation.png') no-repeat;
                                background-size: 95%;
                                background-position: 0% 0%;
                                width: 70px;
                                height: 11px;

                                &.five{
                                    background-position: 0% 0%;
                                }

                                &.four{
                                    background-position: 0% 20%;
                                }

                                &.three{
                                    background-position: 0% 40%;
                                }

                                &.two{
                                    background-position: 0% 61%;
                                }

                                &.one{
                                    background-position: 0% 81%;
                                }

                                &.zero{
                                    background-position: 0% 102%;
                                }
                            }

                            .evaluation_rating{
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                background: transparent;
                                font-family: $primaryFont;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 15px;
                                letter-spacing: 0.05em;
                                text-transform: uppercase;
                                color: #e57125;
                                margin-right: 10px;
                            }
                        }

                        .nombre_evaluations{
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 14px;
                            letter-spacing: 0;
                            color: $darkColor;
                        }
                    }
                }
            }

            
        }

        @media only screen and (max-width: 1360px) {
            max-width: 775px;
            margin: 40px auto 20px;

            .content {
                flex-wrap: wrap;
                flex-direction: column-reverse;

                .news_n_evaluation {
                    width: 100%;
                    margin-bottom: 80px;
                }


                .menu_footer {
                    display: flex;
                    justify-content: space-between;

                    .SEO {
                        display: inline-flex;
                    }

                    .satellites {
                        display: inline-flex;
                        justify-content: flex-start;
                    }
                }
            }
        }
    }

    .mention_legales_popup {
        width: 100%;
        max-width: 1680px;
        margin: auto;
        text-align: center;

        a {
            font-style: italic;
        }

        @media only screen and (max-width: 1720px) {
            padding: 0 20px;
        }

    }

    .copyright_mention {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 58px;
        margin-top: 20px;
        background-color: $bgColor2;

        a,
        .sep{
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 13px;
            line-height: normal;
            letter-spacing: 0;
            text-transform: none;
            color: $darkColor;

            @media only screen and (max-width: 1360px) {
                font-size: 11.5px;
            }

            @media only screen and (max-width: 1024px) {
                font-size: 10px;
            }

        }

        .sep{
            margin: 0 14px;
        }

        .wShop{
            display: flex;
            align-items: center;
            justify-content: center;
            background: url("../svg/Wshop_G.svg") center center no-repeat;
            width: 55px;
            height: 33px;
            margin-left: 12px;
        }
    }
}