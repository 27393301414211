@import '../../_app/_src/scss/desktop/mixins/typeFace';

$montserrat: (
    normal: (regular, medium, semibold, bold)
);

$abrilfatface: (
    normal: (regular)
);

$avenir: (
    normal: (regular)
);

@include typeFace('Montserrat', $montserrat);
@include typeFace('Abrilfatface', $abrilfatface);
@include typeFace('Avenirltstdroman', $avenir);

// *********************************************************
//  EXEMPLE :
//  font-family: 'Metropolis', sans-serif;
//  font-weight: 400;
// *********************************************************

$primaryFont: 'Montserrat', sans-serif;
$secondaryFont: 'kepler-std-display', serif;
$tertiaryFont: 'Abrilfatface', sans-serif;
$avenirFont: 'Avenirltstdroman', sans-serif;
$zoojaFont: 'zooja-pro', sans-serif;