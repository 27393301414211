// Override from app

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,  // TODO:
input[type=password].w-input-element,   // Get rid
input[type=text].w-input-element,   // of these
textarea.w-input-element {  // override rules
    font-family: Arial, sans-serif;
    border-radius: 0;
    font-size: 14 / 16 * 1rem;

    .w-input:not(.w-dropdown) & {

        &:focus {
            @media screen and (min-width:0\0) {
                box-shadow: none;
                border-width: 2px;
            }

            @supports (-ms-ime-align:auto) {/* Edge 16+ CSS */
                box-shadow: none;
                border-width: 2px;
            }
        }
    }

    .w-has-error:not(.w-dropdown) & {
        @media screen and (min-width:0\0) {
            box-shadow: none;
            border-width: 2px;
        }

        @supports (-ms-ime-align:auto) {/* Edge 16+ CSS */
            box-shadow: none;
            border-width: 2px;
        }
    }

    & + .w-input-label {
        color: #919191;
        font-family: Arial, sans-serif;
    }
}

.w-dropdown {
    border-radius: 0;

    &::after {
        right: 12 / 14 * 1em;
    }
}

.w-checkbox-input,
.w-radio-input {

    & + & {
        margin-left: 2em;
    }

    .w-input-element {
        + .w-input-label {
            &::before {
                width: 17px;
                height: 17px;
            }
        }

        &:checked + .w-input-label::before {
            background-color: $primaryColor ;
        }
    }
}

.w-submit-button,
button.w-submit-button {
    height: 72px;
    background-color: $primaryColor;
    border: 1px solid $primaryColor;
    font-family: $primaryFont;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.4px;

    &:not(.w-loader):hover,
    &:not(.w-loader):focus {
        background-color: $black;
        color: #FFF;
    }

    &.w-loader {
        background: url(../svg/three_dots.svg) $black no-repeat center center !important;
        background-size: 20% !important;

        @media screen and (min-width:0\0) {
            background: url(../img/three_dots.gif) $black no-repeat center center !important;
            background-size: 15% !important;
        }
    }
}

.w-form-line {
    margin-bottom: 25 / 16 * 1rem;

    &.w-submit {
        max-width: 142 / 16 * 1rem;
    }
}

.w-checkbox-input,
.w-radio-input {

    .w-input-element {

        + .w-input-label {
            font-family: Arial, sans-serif;
            font-size: 14 / 16 * 1rem;
            color: $primaryColor;
        }
    }
}

/* Radio button */
.w-radio-input {
    .w-input-element {

        &.gm-err-autocomplete {
            &+ .w-input-label {
                opacity: 0;
            }
        }

        + .w-input-label {
            position: relative;

            &::before {
                border-color: #c8c8c8;
            }

            &::after {
                content: "";
                width: 14px;
                height: 14px;
                position: absolute;
                top: 3px;
                left: 3px;
                background-color: #242424;
                border-radius: 50%;
                transform: scale(0);
                transition: transform .3s ease;
            }
        }
    }
}

//
//  Price range slider
// *********************************************************

/* component style */
.vue-slider-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* rail style */
.vue-slider-rail {
    background-color: #ccc;
    border-radius: 15px;
}

/* process style */
.vue-slider-process {
    background-color: #444;
    border-radius: 15px;
}

/* mark style */
.vue-slider-mark {
    z-index: 4;
    &:first-child .vue-slider-mark-step,
    &:last-child .vue-slider-mark-step {
        display: none;
    }
}

.vue-slider-mark-step {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.16);
}

.vue-slider-mark-label {
    font-size: 14px;
    white-space: nowrap;
}

/* dot style */
.vue-slider-dot-handle {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #444;
    box-sizing: border-box;
    box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
}

.vue-slider-dot-handle-disabled {
    cursor: not-allowed;
    background-color: #ccc;
}

.vue-slider-dot-tooltip-inner {
    font-size: 14px;
    white-space: nowrap;
    padding: 2px 5px;
    min-width: 20px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    border-color: #444;
    background-color: #444;
    box-sizing: content-box;
    &::after {
        content: "";
        position: absolute;
    }
}

.vue-slider-dot-tooltip-inner-top::after {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-top-color: inherit;
}

.vue-slider-dot-tooltip-inner-bottom::after {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-bottom-color: inherit;
}

.vue-slider-dot-tooltip-inner-left::after {
    left: 100%;
    top: 50%;
    transform: translate(0, -50%);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-left-color: inherit;
}

.vue-slider-dot-tooltip-inner-right::after {
    right: 100%;
    top: 50%;
    transform: translate(0, -50%);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-right-color: inherit;
}

.vue-slider-dot-tooltip-wrapper {
    opacity: 0;
    transition: all 0.3s;
}

.vue-slider-dot-tooltip-wrapper-show {
    opacity: 1;
}

//
//  Formulaire de code promo
// *********************************************************

.w-coupon-form {

    .w-form-line {

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=text].w-input-element {
            border-radius: 0;
            background-color: #FFF;
        }

        &.w-submit {

            &, .w-submit-button {
                border-radius: 0;
                background: transparent;
                color: inherit;
            }

            .w-submit-button {
                border: none;
            }
        }
    }
}

.w-newuser-form {
    .w-group-label {
        font-weight: 600;
        color: #444;
        font-family: $primaryFont;
        text-transform: uppercase;
    }
}
