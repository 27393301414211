/*
 * Override SWIPER JS properties 
 * because of to many bugs w/ swiper IN swiper when changing color
 * productAssociationSwiper => productBigPictures
 * didnt find any better way 
*/

/***** TG HOME + CROSS SELL FP *****/
#productAssociationSwiper:not(.no_w_important) {

    &.no_swiper .item:nth-child(4) {
        margin-right: 0 !important;
    }

    .imgProd {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .wrap_rolloverproduit {
        @media only screen and (max-width: 2560px) and (min-width: 1921px){
            bottom: 74px !important;
        }
        @media only screen and (max-width: 1366px) {
            bottom: 76px !important;
        }
        @media only screen and (max-width: 1024px) {
            bottom: 76px !important;
        }
    }

    .productBigPictures {

       /* .swiper-slide, 
        .swiper-slide-off, 
        .swiper-slide-on {
            @media only screen and (max-width: 2560px) and (min-width: 1921px) {
                height: 447px !important;
            }
        
            @media only screen and (max-width: 1540px) {
                height: 342px !important;
            }
        
            @media only screen and (max-width: 1024px) {
                height: 463px !important;
            }
        }*/

        .block_lnk {
            display: block; 
        }

        .swiper-slide-off div {
            height: 100%;
        }
    }

    .item {
        @media only screen and (max-width: 2560px) and (min-width: 1921px) {
            width: 359px; 
        }
    
        @media only screen and (max-width: 1540px) {
            width: 266px; 
        }
    
        @media only screen and (max-width: 1024px) {
            width: 360px; 
        }
    }
}

/***** CROSS SELL FP *****/
.product_page #productAssociationSwiper:not(.no_w_important) {
    .wrap_rolloverproduit {
        @media only screen and (max-width: 1366px) {
            bottom: 76px !important;
        }
        @media only screen and (max-width: 1024px) {
            bottom: 79px !important;
        }
    }

    .productBigPictures {

        .swiper-slide, 
        .swiper-slide-off, 
        .swiper-slide-on {

            /*@media only screen and (max-width: 2560px) and (min-width: 1921px) {
                height: 483px !important;
            }

            @media only screen and (max-width: 1366px) {
                height: 470px !important;
            }
            
            @media only screen and (max-width: 1024px) {
                height: 463px !important;
            }

            @media only screen and (max-width: 860px) {
                height: 385px !important;
            }*/
        }
    }
    
    .item {
        /*@media only screen and (max-width: 2560px) and (min-width: 1921px) {
            width: 375px !important;
        }
        
        @media only screen and (max-width: 1366px) {
            width: 365px !important; 
        }
    
        @media only screen and (max-width: 1024px) {
            width: 360px !important; 
        }

        @media only screen and (max-width: 960px) {
            width: calc(50% - 20px) !important; 
        }*/
    }
}