header {
    position: fixed;
    z-index: 30;
    width: 100%;
    top: 0;
    max-width: 1920px;
    margin: auto;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out 0.5s;

    #header_mention_id {
        width: 100%;
        height: 32px;
        position: relative;
        background-color: #31574a;

        .promo_swiper {
            width: 100%;

            .swiper-wrapper {
                .swiper-slide {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .wrap_preheadertxt {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 32px;
                    }

                    .fixed_slide_preheader {
                        h1,
                        p {
                            color: #fff;
                            font-size: 13px;
                            letter-spacing: 0.05em;
                            font-family: $primaryFont;
                            font-weight: 400;
                            line-height: 32px;
                        }
                    }
                }
            }
        }

        .close_delais {
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255,255,255,.75) url('../img/close.png') center center no-repeat;
            background-size: auto;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 10px;
            top: 0%;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            z-index: 2;
            transition: all ease-in-out 0.3s;
            opacity: 0;
        }

        &:hover {
            .close_delais {
                opacity: 1;
            }
        }
    }

    .head_top {
        background-color: #fff;
        border-bottom: 1px solid #efe9e4;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            width: 100%;
            max-width: 1680px;
            margin: auto;

            @media only screen and (max-width: 1720px) {
                padding: 0 32px;
                height: 80px;
            }

            @media only screen and (max-width: 1370px) {
                height: 60px;
            }

            @media only screen and (max-width: 1170px) {
                height: 55px;

                .menu_top {
                    position: fixed;
                    top: 54px;
                    background-color: #fff;
                    left: 0;
                    width: 100%;
                    z-index: 0;
                    max-height: 46px;
                    max-width: inherit;
                    padding: 0 32px;

                    &.hide_menu {
                        display: none;
                    }

                    .main_menu_wrapper .main_menu_contents {
                        .main_menu_itm {
                            &:before {
                                bottom: 10px;
                            }

                            .submenu_wrapper {
                                position: fixed;
                                top: 100px!important;
                            }
                        }
                    }
                }
            }

            .block_top_links {
                height: 100%;

                &.logo {
                    width: 170px;
                    height: 70px;
                    align-self: flex-start;

                    @media only screen and (max-width: 1580px) {
                        width: 170px;
                    }

                    @media only screen and (max-width: 1370px) {
                        height: 60px;
                    }

                    @media only screen and (max-width: 1380px) {
                        width: 120px;
                    }

                    @media only screen and (max-width: 1024px) {
                        padding-top: 0;
                    }

                    a.logo {
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: url(../svg/logo_site.svg) center center no-repeat;
                        background-size: contain;
                        font-size: 0;

                        h1 {
                            font-size: 0;
                        }
                    }
                }

                &.right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 375px;
                    padding-top: 10px;

                    @media only screen and (max-width: 1024px) {
                        align-items: flex-end;
                        padding-bottom: 2px;
                    }

                    .block_top_item {
                        /*Recherche*/
                        &.search {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 15px;
                            width: 15px;
                            cursor: pointer;
                            margin-right: 40px;

                            @media only screen and (max-width: 1360px) {
                                margin-right: 35px;
                            }

                            #form_recherche {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                cursor: pointer;
                            }

                            .ico {
                                background: url('../svg/search.svg') center center no-repeat;
                                background-size: contain;
                                width: 15px;
                                height: 15px;
                                display: inline-block;
                            }

                            #trigger_recherche {
                                font-size: 0;
                                text-decoration: none;
                                cursor: pointer;

                                @media only screen and (max-width: 1260px) {
                                    font-size: 0;
                                }
                            }

                            .wrap_search_field {
                                display: none;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                background: $bgColor2;
                                width: 100%;
                                min-height: 155px;
                                padding: 55px 0 49px;
                                position: absolute;
                                top: 100%;
                                left: 0;

                                @media only screen and (max-width: 1370px) {
                                    min-height: 145px;
                                    padding: 58px 0 49px;
                                }

                                @media only screen and (max-width: 1360px) {
                                    min-height: 130px;
                                    padding: 24px 0 0;
                                }

                                @media only screen and (max-width: 1024px) {
                                    top: 100px;
                                }

                                .wrap_field {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: center;
                                    width: 100%;
                                    max-width: 44.063vw;
                                    position: relative;

                                    #search_home {
                                        border: none;
                                        border-bottom: solid 1px $darkColor;
                                        padding: 0 30px 0 0;
                                        width: 100%;
                                        font-family: $secondaryFont;
                                        font-weight: 600;
                                        font-size: 2.604vw;
                                        line-height: normal;
                                        letter-spacing: 0.065vw;
                                        color: $darkColor;
                                        background-color: $bgColor2;

                                        &:first-letter {
                                            text-transform: uppercase;
                                        }

                                        &::placeholder {
                                            font-family: $secondaryFont;
                                            font-weight: 600;
                                            font-size: 2.604vw;
                                            line-height: normal;
                                            letter-spacing: 0.065vw;
                                            color: $darkColor;
                                            font-style: normal;
                                            text-transform: none;
                                        }

                                        @media only screen and (max-width: 1370px) {
                                            font-size: 30px;

                                            &::placeholder {
                                                font-size: 30px;
                                            }
                                        }
                                    }

                                    .close_search {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background: url('../svg/close.svg') center center no-repeat;
                                        background-size: contain;
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        right: 0;
                                        top: 50%;
                                        margin-top: -7px;
                                        cursor: pointer;

                                        @media only screen and (max-width: 1370px) {
                                            width: 16px;
                                            height: 16px;
                                        }
                                    }
                                }

                                .results_list {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: center;
                                    width: 100%;
                                    max-width: 44.063vw;

                                    li {
                                        padding: 10px 0;
                                        width: 100%;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        /*Storelocator*/
                        &.storeloc {
                            display: flex;
                            align-items: flex-end;
                            justify-content: center;
                            height: 15px;
                            margin-right: 40px;

                            #trigger_locator {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                font-size: 0;
                                text-decoration: none;

                                .ico {
                                    background: url('../svg/pin.svg') center center no-repeat;
                                    background-size: contain;
                                    width: 15px;
                                    height: 15px;
                                    display: inline-block;
                                }
                            }
                        }

                        &.my_account {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 40px;
                            height: 100%;

                            @media only screen and (max-width: 1024px) {
                                align-items: flex-end;
                            }

                            a {
                                position: relative;
                                line-height: 15px;
                                transition: all 0.3s ease-in-out;

                                &:not(.button) {
                                    &:before {
                                        content: ' ';
                                        display: block;
                                        background: $darkColor;
                                        width: 100%;
                                        height: 1px;
                                        position: absolute;
                                        left: 0;
                                        bottom: -8px;
                                        opacity: 0;
                                        transform: scale(0);
                                        transform-origin: center;
                                        transition: all 0.3s ease-in-out;
                                    }
                                }

                                span {
                                    font-size: 13px;
                                    font-family: $primaryFont;

                                    @media only screen and (max-width: 1580px) {
                                        font-size: 11.5px;
                                    }

                                    @media only screen and (max-width: 1360px) {
                                        font-size: 0;

                                        &.ico {
                                            background: url('../svg/my_account.svg') center center no-repeat;
                                            background-size: contain;
                                            width: 15px;
                                            height: 15px;
                                            display: inline-block;
                                            position: relative;
                                            top: 4px;
                                        }
                                    }

                                    @media only screen and (max-width: 1024px) {
                                        &.ico {
                                            top: 8px;
                                        }
                                    }
                                }
                            }

                            #topLogin {
                                background: $bgColor2;
                                width: 405px;
                                position: absolute;
                                right: 0;
                                top: 80px;
                                padding: 40px;
                                transform: translateX(50vw);
                                transition: all 0.3s ease-in-out;

                                @media only screen and (max-width: 1370px) {
                                    top: 60px;
                                    padding: 20px 40px;
                                }

                                @media only screen and (max-width: 1024px) {
                                    top: 100px;
                                }

                                @media only screen and (max-width: 850px) {
                                    transform: translateX(75vw);
                                }

                                .wrapper_menu_compte_container {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    text-align: center;

                                    .account_name {
                                        font-family: $secondaryFont;
                                        font-weight: 600;
                                        font-size: 40px;
                                        line-height: 1;
                                        letter-spacing: -0.025em;
                                        text-transform: capitalize;
                                        color: $darkColor;
                                        margin-bottom: 25px;
                                        width: 100%;

                                        @media only screen and (max-width: 1680px) {
                                            font-size: 38px;
                                        }

                                        @media only screen and (max-width: 1520px) {
                                            font-size: 35px;
                                        }

                                        @media only screen and (max-width: 1370px) {
                                            font-size: 30px;
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            font-size: 28px;
                                        }

                                        span {
                                            font-family: $primaryFont;
                                            font-weight: 400;
                                            letter-spacing: 0;
                                            font-size: 16px;
                                            display: block;
                                            text-transform: uppercase;
                                            margin-top: 8px;
                                        }
                                    }

                                    .wrapper_menu_compte {
                                        display: block;
                                        width: 100%;

                                        .item {
                                            a {
                                                display: inline-block;
                                                width: auto;
                                                font-family: $primaryFont;
                                                font-weight: 600;
                                                font-size: 13px;
                                                line-height: 14px;
                                                text-align: center;
                                                letter-spacing: 0;
                                                text-transform: none;
                                                color: $darkColor;
                                                margin-bottom: 25px;
                                                position: relative;

                                                &:before {
                                                    display: none;
                                                }

                                                .highlight,
                                                #number_wishlist_top {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    background: $primaryColor;
                                                    width: 16px;
                                                    height: 15px;
                                                    font-family: $secondaryFont;
                                                    font-weight: 600;
                                                    font-size: 10px;
                                                    letter-spacing: 0.25px;
                                                    color: $white;
                                                    margin-left: 8px;
                                                    position: absolute;
                                                    top: 0rem;
                                                    right: -2rem;
                                                }

                                                #number_wishlist_top {
                                                    position: absolute;
                                                    right: -30px;
                                                    top: 0;
                                                }
                                            }

                                            &:last-child {
                                                a {
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
                                    }

                                    .btn_container {
                                        width: 100%;
                                        width: 325px;
                                        height: 50px;
                                        position: relative;
                                        margin-top: 40px;

                                        button {
                                            width: 100%;
                                            height: 50px;
                                            background-color: $darkColor;
                                            color: #fff;
                                            text-transform: uppercase;
                                            font-size: 14px;
                                            font-family: $primaryFont;
                                            cursor: pointer;

                                            & + .loader {
                                                width: 100%;
                                                height: 50px;
                                                background: $darkColor url(../svg/three_dots.svg) center center no-repeat;
                                                background-size: 45px;
                                                position: absolute;
                                                z-index: -1;
                                                top: 0;
                                                left: 0;
                                                opacity: 0;
                                                transition: all ease-in-out 0.3s;

                                                &.loading {
                                                    z-index: 5;
                                                    opacity: 1;
                                                }
                                            }
                                        }

                                        &.loading {
                                            button {
                                                & + .loader {
                                                    z-index: 5;
                                                    opacity: 1;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &:hover,
                            &.hover {
                                a {
                                    &:before {
                                        transform: scale(1);
                                        opacity: 1;
                                    }
                                }

                                #topLogin {
                                    transform: translateX(0);
                                }
                            }
                        }

                        &.my_basket {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;

                            @media only screen and (max-width: 1024px) {
                                align-items: flex-end;
                            }

                            a {
                                position: relative;
                                line-height: 15px;
                                transition: all 0.3s ease-in-out;

                                &:not(.button) {
                                    &:before {
                                        content: ' ';
                                        display: block;
                                        background: $darkColor;
                                        width: 100%;
                                        height: 1px;
                                        position: absolute;
                                        left: 0;
                                        bottom: -8px;
                                        opacity: 0;
                                        transform: scale(0);
                                        transform-origin: center;
                                        transition: all 0.3s ease-in-out;
                                    }
                                }

                                .rolloverTxt {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 15px;
                                    position: relative;
                                    font-size: 13px;

                                    @media only screen and (max-width: 1580px) {
                                        font-size: 11.5px;
                                    }

                                    span:not(.ico) {
                                        font-family: $primaryFont;
                                        font-weight: 400;
                                        font-size: 8px;
                                        line-height: 1;
                                        color: #fff;
                                        border-radius: 100%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 14px;
                                        width: 14px;
                                        background-color: $tertiaryColor;
                                        position: absolute;
                                        right: -7px;
                                        top: -7px;
                                    }

                                    @media only screen and (max-width: 1360px) {
                                        .ico {
                                            background: url('../svg/cart.svg') center center no-repeat;
                                            background-size: contain;
                                            width: 15px;
                                            height: 15px;
                                            display: inline-block;
                                            position: relative;
                                            top: 1px;
                                            left: inherit;
                                            right: inherit;
                                        }

                                        font-size: 0;
                                    }
                                }
                            }

                            #show_top_cart {
                                background: $bgColor2;
                                width: 405px;
                                position: absolute;
                                right: 0;
                                top: 80px;
                                padding: 40px 30px 40px 40px;
                                transform: translateX(50vw);
                                transition: all 0.3s ease-in-out;
                                z-index: 30;

                                @media only screen and (max-width: 1370px) {
                                    top: 60px;
                                    padding: 20px 30px 40px 20px;
                                }

                                @media only screen and (max-width: 1170px) {
                                    top: 100px;
                                }

                                @media only screen and (max-width: 850px) {
                                    transform: translateX(75vw);
                                }

                                &.mention_livraison_on {
                                    height: 650px;

                                    @media only screen and (max-height: 770px) {
                                        height: 570px;
                                    }

                                    @media only screen and (max-height: 770px) and (max-width: 1370px) {
                                        height: 550px;
                                    }
                                }

                                &.noprod {
                                    .no_product {
                                        text-align: center;
                                        text-transform: uppercase;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        color: $darkColor;
                                        margin-bottom: 40px;

                                        @media only screen and (max-width: 1680px) {
                                            font-size: 15px;
                                        }

                                        @media only screen and (max-width: 1520px) {
                                            font-size: 14px;
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            font-size: 13px;
                                        }
                                    }
                                }

                                .title_panier {
                                    font-family: $secondaryFont;
                                    font-weight: 600;
                                    font-size: 40px;
                                    line-height: 1;
                                    letter-spacing: -0.025em;
                                    text-transform: none;
                                    color: $darkColor;
                                    margin-bottom: 3px;
                                    width: 100%;
                                    text-align: center;

                                    @media only screen and (max-width: 1680px) {
                                        font-size: 38px;
                                    }

                                    @media only screen and (max-width: 1520px) {
                                        font-size: 35px;
                                    }

                                    @media only screen and (max-width: 1370px) {
                                        font-size: 30px;
                                    }

                                    @media only screen and (max-width: 1024px) {
                                        font-size: 28px;
                                    }
                                }

                                .panier_subtitle {
                                    text-align: center;
                                    text-transform: uppercase;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    color: $darkColor;
                                    margin-bottom: 36px;

                                    @media only screen and (max-width: 1680px) {
                                        font-size: 15px;
                                    }

                                    @media only screen and (max-width: 1520px) {
                                        font-size: 14.5px;
                                    }

                                    @media only screen and (max-width: 1370px) {
                                        font-size: 14px;
                                    }

                                    @media only screen and (max-width: 1024px) {
                                        font-size: 13px;
                                    }
                                }

                                #cart_list {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    width: 100%;
                                    padding-right: 10px;
                                    height: 250px;
                                    overflow: hidden;
                                    overflow: auto;

                                    @media only screen and (max-height: 770px) {
                                        height: 180px;
                                    }

                                    -ms-overflow-style: none;  /* Internet Explorer 10+ */
                                    scrollbar-width: none;  /* Firefox */

                                    &::-webkit-scrollbar { 
                                        display: none;  /* Safari and Chrome */
                                    }

                                    .simplebar-track.simplebar-vertical {
                                        cursor: pointer;
                                    }

                                    li{
                                        display: flex;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        width: 100%;
                                        margin-bottom: 23px;

                                        .cart_link{
                                            display: flex;
                                            align-items: flex-start;
                                            justify-content: flex-start;
                                            width: 130px;
                                            height: auto;
                                            margin-right: 15px;

                                            &:before {
                                                display: none;
                                            }

                                            .cart_thumb_holder {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;

                                                img {
                                                    width: 100%;
                                                    height: auto;
                                                }
                                            }
                                        }

                                        .cart_detail_box {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                            justify-content: flex-start;
                                            width: calc(100% - 126px);

                                            .cart_donation {
                                                .modify_prod,
                                                .sub_quantity {
                                                    display: none;
                                                }
                                            }

                                            .remove_prod {
                                                font-size: 0;
                                                background: url('../img/close.png') center center no-repeat;
                                                width: 11px;
                                                height: 11px;
                                                margin-left: auto;
                                                cursor: pointer;

                                                &:before {
                                                    display: none;
                                                }
                                            }

                                            .cart_product {
                                                display: flex;
                                                flex-direction: column;
                                                align-items: flex-start;
                                                justify-content: flex-start;
                                                width: 100%;
                                                position: relative;

                                                .wrap_top_info {
                                                    display: flex;
                                                    flex-direction: column;
                                                    align-items: flex-start;
                                                    justify-content: flex-start;
                                                    width: 100%;
                                                    margin-bottom: 2px;

                                                    .title {
                                                        display: flex;
                                                        align-items: flex-start;
                                                        justify-content: flex-start;
                                                        font-family: $primaryFont;
                                                        font-weight: 500;
                                                        font-size: 13px;
                                                        letter-spacing: 0.05em;
                                                        color: $darkColor;
                                                        text-transform: capitalize;
                                                    }

                                                    .sub_title {
                                                        display: block;
                                                        font-family: $primaryFont;
                                                        font-weight: 500;
                                                        font-size: 13px;
                                                        letter-spacing: 0;
                                                        color: $darkColor;
                                                        text-transform: lowercase;
                                                        text-align: left;
                                                        margin: 3px 0 4px 0;

                                                        &:first-letter {
                                                            text-transform: uppercase;
                                                        }
                                                    }

                                                    .wrapper_price {
                                                        .pricetag {
                                                            font-size: 13px;
                                                            letter-spacing: 0.05em;
                                                            line-height: 25px;
                                                            font-weight: 600;
                                                        }
                                                    }
                                                }

                                                .sub_taille,
                                                .sub_couleur,
                                                .sub_quantity {
                                                    font-family: $primaryFont;
                                                    font-weight: 500;
                                                    font-size: 12px;
                                                    line-height: 19px;
                                                    letter-spacing: 0.05em;
                                                    color: $darkColor;
                                                    text-transform: capitalize;
                                                }

                                                .sub_couleur {
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    white-space: nowrap;
                                                    width: 140px;
                                                }

                                                .modify_prod {
                                                    font-family: $primaryFont;
                                                    font-weight: 500;
                                                    font-size: 13px;
                                                    line-height: 25px;
                                                    letter-spacing: 0;
                                                    color: $darkColor;
                                                    position: relative;
                                                    cursor: pointer;
                                                    margin-top: 5px;

                                                    &:before {
                                                        content: ' ';
                                                        display: block;
                                                        background: $darkColor;
                                                        width: 100%;
                                                        height: 1px;
                                                        position: absolute;
                                                        right: 0;
                                                        bottom: 5px;
                                                    }
                                                }
                                            }
                                        }

                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }

                                .wrap_tot_panier {
                                    .cart_panier_total {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        width: 100%;
                                        padding: 0 40px;
                                        margin-bottom: 23px;
                                        margin-top: 23px;
                                        background-color: #fff;
                                        height: 60px;
                                        font-weight: 600;

                                        label {
                                            font-family: $primaryFont;
                                            font-weight: 600;
                                            font-size: 13px;
                                            line-height: 1;
                                            color: $darkColor;
                                        }

                                        .pricetag {
                                            font-family: $primaryFont;
                                            font-weight: 600;
                                            font-size: 13px;
                                            line-height: 1;
                                            color: $darkColor;

                                            .no_cents {
                                                display: none;
                                            }
                                        }
                                    }

                                    .form_submit {
                                        width: 100%;

                                        .button {
                                            height: 50px;

                                            &.dark {
                                                color: #fff;

                                                span {
                                                    color: #fff;
                                                }

                                                &:before {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }

                                .mention_livraison {
                                    color: #fff;
                                    background: $tertiaryColor;
                                    width: 100%;
                                    height: 78px;
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    text-align: center;
                                    display: flex;
                                    flex-wrap: wrap;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;

                                    p {
                                        display: inline-block;
                                        width: 100%;
                                        font-family: $primaryFont;
                                        font-weight: 400;
                                        font-size: 12px;
                                        letter-spacing: 0.035em;
                                        text-transform: none;
                                        padding: 0 1rem;

                                        &.free_delvery_bar {
                                            height: 1px;
                                            position: relative;
                                            width: calc(100% - 80px);
                                            background-color: rgba(255,255,255,0.2);
                                            margin: 1rem auto 0;
                                        }
                                    }

                                    progress {
                                        width: calc(100% - 60px);
                                        margin: 1rem auto 0;
                                        height: 2px;
                                        position: relative;
                                        color: #FFF;
                                        background-color: rgba(255,255,255,.2);

                                        &::-webkit-progress-bar {background-color:  rgba(255,255,255,0); width: 100%;}
                                        &::-webkit-progress-value {background-color:  #FFF !important;}
                                        &::-moz-progress-bar {background-color:  #FFF !important;}
                                    }
                                }
                            }

                            &:hover,
                            &.hover{
                                a{
                                    &:before{
                                        transform: scale(1);
                                        opacity: 1;
                                    }
                                }

                                #show_top_cart {
                                    transform: translateX(0);
                                    display: block;
                                }
                            }
                        }
                    }

                    .wrap_lang {
                        .lang_switch {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 40px;
                            width: 15px;
                            height: 15px;
                            position: relative;

                            .lang_itm {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: $primaryFont;
                                width: 15px;
                                height: 15px;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 15px;
                                color: $darkColor;
                                text-transform: uppercase;
                                opacity: 0;

                                &.active {
                                    background: #fff;
                                    font-size: 13px;
                                    color: $darkColor;
                                    position: relative;
                                    z-index: 2;
                                    opacity: 36;
                                }

                                &:not(.active) {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    z-index: 1;
                                    transition: all 0.3s ease-in-out;
                                }

                                &.show {
                                    top: 100%;
                                    opacity: 1;
                                    padding-top: 20px;

                                    &:before {
                                        content: '';
                                        height: 1px;
                                        background-color: $darkColor;
                                        width: 100%;
                                        position: absolute;
                                        top: 7px;
                                    }
                                }
                            }
                        }

                        .block_top_item {
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: 10.5px;
                            line-height: 23px;
                            letter-spacing: 1.6px;
                            color: #919191;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    &.sticky {
        #header_mention_id {
            height: 0;
            z-index: -1;
            opacity: 0;
        }

        .head_top {
            .header {
                height: 60px;

                @media only screen and (max-width: 1024px) {
                    height: 55px;
                }

                .block_top_links.logo {
                    height: 60px;
                }

                .menu_top {
                    .main_menu_wrapper {
                        .main_menu_contents {
                            .main_menu_itm {
                                .submenu_wrapper {
                                    top: 60px;
                                }
                            }
                        }
                    }
                }

                .block_top_links.right {
                    @media only screen and (max-width: 1024px) {
                        padding-bottom: 10px;
                    }

                    .block_top_item.my_account {
                        #topLogin {
                            top: 60px;

                            @media only screen and (max-width: 1024px) {
                                top: 100px;
                            }
                        }
                    }

                    .block_top_item.my_basket {
                        #show_top_cart {
                            top: 60px;

                            @media only screen and (max-width: 1170px) {
                                top: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.category {
    padding-top: 0 !important;

    header {
        .head_top {
            .header {
                @media only screen and (max-width: 1024px) {
                    .menu_top {
                        position: absolute;
                        top: 55px;

                        .main_menu_wrapper .main_menu_contents {
                            .main_menu_itm {
                                &:before {
                                    bottom: 10px;
                                }

                                .submenu_wrapper {
                                    position: fixed;
                                    top: 101px;
                                    max-width: calc(100vw - 64px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .main_wrapper {
        @media only screen and (max-width: 1024px) {
            padding-top: 3rem;
        }
    }
}
