.recrutement_page {
    padding: 3.5rem 1rem 8rem;

    .button {
        max-width: 300px;
        height: 50px;
        margin: 0 auto;
    }

    .form_candidat {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        .flag-container {
            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;
                width: 1px;
                height: 80%;
                background-color: #989898;
            }

        }

        .intl-tel-input.allow-dropdown input[type=text] {
            padding-left: 62px;
        }

        .form_submit {
            max-width: 300px;
            height: 50px;
            margin: 0 auto;
        }

    }

    .input_container {
        margin: 0 0 1.5rem;
        width: 100%;

        &.col3 {
            width: 50%;
            max-width: 365px;
        }
    }

    input[type=text],
    input[type=email],
    input[type=password] {
        height: 48px;
        border: 1px solid #989898;
        padding: 0.5rem 1rem;
        background-color: transparent;

        &::-webkit-input-placeholder {
            font-size: 14 / 16 * 1rem;
            font-style: normal;
        }
        &::-moz-placeholder {
            font-size: 14 / 16 * 1rem;
            font-style: normal;
        }
        &:-ms-input-placeholder {
            font-size: 14 / 16 * 1rem;
            font-style: normal;
        }
        &:-moz-placeholder {
            font-size: 14 / 16 * 1rem;
            font-style: normal;
        }
    }

    input[type=file] {
        display: none;
        width: inherit;
        height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
    }
    
    label.ajout_pj_label {
        font-family: $primaryFont;
        font-size: 14 / 16 * 1rem;
        line-height: 1;
        color: $primaryColor;
        border: 1px solid $primaryColor;
        cursor: pointer;
        padding: 7px 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .file_msg {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: $primaryFont;
        font-size: 11 / 16 * 1rem;
        line-height: 1;
        font-style: italic;
        font-weight: 500;
        color: #989898;
        margin: 0.4rem 0;
    }

    .recrutement_form {
        width: 750px;
        max-width: 100%;
        margin: 0 auto;
    }

    .recrutement_offre {
        width: 1280px;
        max-width: 100%;
        margin: 2.5rem auto 0;
    }

    .recrutement_bloc {
        h1, h2 {
            font-family: $secondaryFont;
            font-size: 65 / 16 * 1rem;
            line-height: 1.3;
            text-align: center;
            margin: 0 0 3.5rem;

            @media only screen and (max-width: 1680px) {
                font-size: 60px;
            }

            @media only screen and (max-width: 1520px) {
                font-size: 55px;
            }

            @media only screen and (max-width: 1370px) {
                font-size: 50px;
            }

        }

        .btn_container {
            margin: 2rem 0 0;

            .btn_postuler {
                background: $primaryColor;
                color: $white;
            }
        }

        #msg_success {
            font-size: 1rem;
            text-align: center;
            margin: 0 0 3rem;
        }

        #msg_suspens {
            font-size: .85rem;
            text-align: center;
            margin: 2rem auto 1rem;
            color: red;
        }
    }

    .joblist {
        display: flex;
        justify-content: space-between;

        li {
            width: 33%;
            max-width: 373px;

            @media only screen and (max-width: 944px) {
                width: 32%;
            }
        }

        .annonce {
            background-color: $white;



            h3 {
                font-family: $secondaryFont;
                font-size: 30 / 16 * 1rem;
                line-height: 1.3;
                text-align: center;
                padding: 3rem;
                min-height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                @media screen and (max-width: 1200px) {
                    min-height: 215px;
                }

                @media only screen and (max-width: 944px) {
                    min-height: 250px;
                    padding: 1.25rem;
                }
            }

            .description {
                display: none;
                padding: 0 2rem 3rem;
                line-height: 1.5;
            }
        }
    }

    .bloc_file {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .bloc_cv,
        .bloc_motivation {
            width: 50%;
            max-width: 365px;
        }

        .btn_container {
            margin-top: 2rem;
        }

        .upload {
            width: 100%;
            position: relative;

            &.inputErr {
                .ajout_pj_label {
                    border-color: red;
                    color: red;
                }

                & + .file_msg {
                    color: red;
                }
            }

            span[data-icon="load"] {
                display: none;
            }
        }
    }
}