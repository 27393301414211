.menu_top {
    width: 100%;
    max-width: calc(100% - 575px);
    display: flex;
    padding: 0 40px;
    box-sizing: border-box;

    @media only screen and (max-width: 1590px) {
        padding: 0 20px 0 5px;
    }

    .main_menu_wrapper {
        width: 100%;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 1380px) {
            display: block;
        }

        .main_menu_contents {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 100%;

            @media only screen and (max-width: 1170px) {
                justify-content: center;
            }

            .main_menu_itm {
                position: relative;
                padding-right: 50px;
                height: 100%;
                display: flex;
                align-items: center;

                @media only screen and (max-width: 1470px) {
                    padding-right: 6%;
                }

                @media only screen and (max-width: 1440px) {
                    padding-right: 2rem;
                }

                @media only screen and (max-width: 1210px) {
                    padding-right: 1.6rem;
                }

                @media only screen and (max-width: 1170px) {
                    padding-right: 3rem;
                }

                @media only screen and (max-width: 960px) {
                    padding-right: 2.8rem;
                }

                @media only screen and (max-width: 850px) {
                    padding-right: 2.3rem;
                }

                @media only screen and (max-width: 820px) {
                    padding-right: 1.2rem;
                }

                @media only screen and (max-width: 800px) {
                    padding-right: 1rem;
                }

                &:last-child {
                    padding-right: 0;

                    &:before {
                        width: 100%;
                    }
                }

                .first_level_item {
                    font-size: 12px;
                    letter-spacing: 0.1em;
                    font-family: $primaryFont;
                    position: relative;
                    padding-top: 21px;
                    text-transform: uppercase;

                    a {
                        white-space: nowrap;
                    }

                    @media only screen and (max-width: 1580px) {
                        font-size: 11px;
                    }

                    @media only screen and (max-width: 1170px) {
                        padding-top: 0;

                        .has_submenu {
                            font-size: 0.8rem;
                        }
                    }

                    &:before {
                        content: ' ';
                        display: block;
                        background: #272525;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        opacity: 0;
                        transform: scale(0);
                        transform-origin: center;
                        transition: all 0.3s ease-in-out;
                    }
                }

                .block_lnk {
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 12px;
                    color: $darkColor;
                    cursor: default;
                    white-space: nowrap;
                    text-transform: uppercase;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 1580px) {
                        font-size: 11px;
                        letter-spacing: 0.05em;
                    }

                    @media only screen and (max-width: 1360px) {
                        font-size: 10px;
                    }
                }

                .submenu_wrapper {
                    display: none;
                    background: $bgColor2;
                    width: 938px;
                    position: absolute;
                    top: 80px;
                    left: -40px;
                    padding: 0 40px 40px;

                    @media only screen and (max-width: 1720px) {
                        top: 80px;
                        padding: 0 30px 20px;
                    }

                    @media only screen and (max-width: 1370px) {
                        top: 60px;
                        left: inherit;
                    }

                    @media only screen and (max-width: 960px) {
                        left: 0 !important;
                        right: 0 !important;
                        margin: auto;
                        max-width: 97%;
                        padding: 0 20px 20px;
                    }

                    &.univers_fiancee {
                        .submenu {
                            display: flex;
                            justify-content: space-between;

                            .menu_visuel_wrapper {
                                width: 100%;
                                max-width: 620px;

                                .menu_visuel {
                                    width: 100%;
                                    display: flex;
                                    justify-content: flex-end;

                                    .visu_menu {
                                        display: flex;
                                        flex-direction: column;

                                        img {
                                            width: 100%;
                                            height: auto;
                                        }

                                        .visuel_title {
                                            font-size: 13px;
                                            font-family: $primaryFont;
                                            color: $darkColor;
                                            margin-top: 23px;
                                            letter-spacing: 0.05em;
                                        }

                                        &:first-child {
                                            width: 230px;

                                            @media only screen and (max-width: 1024px) {
                                                max-width: 210px;
                                            }

                                            @media only screen and (max-width: 960px) {
                                                max-width: 180px;
                                            }

                                            @media only screen and (max-width: 800px) {
                                                max-width: 140px;
                                            }

                                            .visuel_title {
                                                display: none;
                                            }
                                        }

                                        &:nth-child(2) {
                                            width: 351px;
                                            margin-left: 2.75rem;

                                            @media only screen and (max-width: 1024px) {
                                                max-width: 300px;
                                            }

                                            @media only screen and (max-width: 980px) {
                                                max-width: 290px;
                                            }

                                            @media only screen and (max-width: 800px) {
                                                max-width: 220px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .menu_categ_wrapper {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;

                        .ss_menu_categ_wrapper {
                            padding-top: 2rem;

                            .ss_cat {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                width: 200px;
                                margin-right: 40px;

                                &.univers {
                                    margin-top: 40px;

                                    &:first-child {
                                        width: 100px;
                                    }
                                }

                                &:first-child {
                                    width: 200px;
                                    margin-right: 40px;
                                }

                                li {
                                    a {
                                        display: flex;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        font-family: $primaryFont;
                                        font-weight: 600;
                                        font-size: 13px;
                                        line-height: 13px;
                                        letter-spacing: 0.05em;
                                        color: $darkColor;
                                        text-transform: none;
                                        margin-bottom: 25px;
                                        transition: all 0.3s ease-in-out;

                                        &.ss_cat_name {
                                            font-family: $primaryFont;
                                            font-weight: 600;
                                            margin: 0 0 20px;
                                        }

                                        &:hover {
                                            font-weight: 600;
                                        }
                                    }

                                    &:last-child {
                                        a {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }

                            .menu_img {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;

                                a {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-decoration: none;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                }

                                .wrap_discover {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    position: absolute;
                                    left: 0;
                                    bottom: 45px;
                                    width: 100%;

                                    .title {
                                        font-family: $secondaryFont;
                                        font-weight: 600;
                                        font-size: 30px;
                                        line-height: 30px;
                                        letter-spacing: 4.5px;
                                        color: $white;
                                        text-transform: uppercase;
                                        text-align: center;
                                        margin-bottom: 5px;
                                    }

                                    .discover {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        position: relative;
                                        font-family: $secondaryFont;
                                        font-weight: 600;
                                        font-size: 13px;
                                        line-height: 13px;
                                        letter-spacing: 1.95px;
                                        color: $white;
                                        text-transform: uppercase;

                                        &:before {
                                            content: ' ';
                                            display: block;
                                            background: $white;
                                            width: 100%;
                                            height: 1px;
                                            position: absolute;
                                            left: 0;
                                            bottom: 0;
                                        }
                                    }
                                }
                            }

                            .wrap_img {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;

                                .menu_img {
                                    &:first-child {
                                        margin-right: 18px;
                                    }

                                    .title {
                                        font-size: 25px;
                                        line-height: 25px;
                                        letter-spacing: 2.25px;
                                    }
                                }
                            }
                        }

                        .menu_visuel_wrapper {
                            width: 100%;
                            max-width: 620px;

                            .menu_visuel {
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;

                                .visu_menu {
                                    display: flex;
                                    flex-direction: column;

                                    img {
                                        width: 100%;
                                        height: auto;
                                    }

                                    .visuel_title {
                                        font-size: 13px;
                                        font-family: $primaryFont;
                                        color: $darkColor;
                                        margin-top: 23px;
                                        letter-spacing: 0.05em;
                                    }

                                    &:first-child {
                                        width: 230px;

                                        @media only screen and (max-width: 980px) {
                                            width: 180px;
                                        }

                                        @media only screen and (max-width: 800px) {
                                            width: 140px;
                                        }

                                        .visuel_title {
                                            display: none;
                                        }
                                    }

                                    &:nth-child(2) {
                                        width: 351px;
                                        margin-left: 2.75rem;

                                        @media only screen and (max-width: 980px) {
                                            width: 290px;
                                        }

                                        @media only screen and (max-width: 800px) {
                                            width: 220px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    & > .submenu {
                        .ss_menu_categ_wrapper {
                            padding-top: 2rem;

                            .ss_cat {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                width: 200px;
                                margin-right: 40px;

                                li {
                                    a {
                                        display: flex;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        font-family: $primaryFont;
                                        font-weight: 600;
                                        font-size: 13px;
                                        line-height: 13px;
                                        letter-spacing: 0.05em;
                                        color: $darkColor;
                                        text-transform: none;
                                        margin-bottom: 25px;
                                        transition: all 0.3s ease-in-out;

                                        &.ss_cat_name {
                                            font-family: $primaryFont;
                                            font-weight: 600;
                                            margin: 0 0 20px;
                                        }

                                        &:hover {
                                            font-weight: 600;
                                        }
                                    }

                                    &:last-child {
                                        a {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }

                            .menu_img {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;

                                a {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-decoration: none;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                }

                                .wrap_discover {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    position: absolute;
                                    left: 0;
                                    bottom: 45px;
                                    width: 100%;

                                    .title {
                                        font-family: $secondaryFont;
                                        font-weight: 600;
                                        font-size: 30px;
                                        line-height: 30px;
                                        letter-spacing: 4.5px;
                                        color: $white;
                                        text-transform: uppercase;
                                        text-align: center;
                                        margin-bottom: 5px;
                                    }

                                    .discover {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        position: relative;
                                        font-family: $secondaryFont;
                                        font-weight: 600;
                                        font-size: 13px;
                                        line-height: 13px;
                                        letter-spacing: 1.95px;
                                        color: $white;
                                        text-transform: uppercase;

                                        &:before {
                                            content: ' ';
                                            display: block;
                                            background: $white;
                                            width: 100%;
                                            height: 1px;
                                            position: absolute;
                                            left: 0;
                                            bottom: 0;
                                        }
                                    }
                                }
                            }

                            .wrap_img {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;

                                .menu_img {
                                    &:first-child {
                                        margin-right: 18px;
                                    }

                                    .title {
                                        font-size: 25px;
                                        line-height: 25px;
                                        letter-spacing: 2.25px;
                                    }
                                }
                            }
                        }
                    }
                }

                &:nth-child(n + 6) {
                    .submenu_wrapper {
                        right: 50px;
                        left: inherit;
                        position: fixed;
                    }

                    @media only screen and (min-width: 1950px) {
                        .submenu_wrapper {
                            right: auto;
                            left: 49%;
                        }
                    }
                }

                @media only screen and (max-width: 1160px) {
                    &:nth-child(n + 4) {
                        position: inherit;

                        .submenu_wrapper {
                            right: 20px;
                            left: inherit;
                        }
                    }
                }

                @media only screen and (max-width: 1870px) {
                    &:nth-child(n + 5) {
                        .submenu_wrapper {
                            right: 50px;
                            left: inherit;
                            position: fixed;
                        }
                    }
                }

                @media only screen and (max-width: 1560px) {
                    &:nth-child(n + 4) {
                        .submenu_wrapper {
                            right: 50px;
                            left: inherit;
                            position: fixed;
                        }
                    }
                }

                @media only screen and (max-width: 1420px) {
                    &:nth-child(n + 3) {
                        .submenu_wrapper {
                            right: 23px;
                            left: inherit;
                            position: fixed;
                        }
                    }
                }

                @media only screen and (max-width: 1370px) {
                    &:nth-child(n + 2) {
                        .submenu_wrapper {
                            position: fixed;
                            right: 90px;
                        }
                    }
                }

                @media only screen and (max-width: 1310px) {
                    &:nth-child(n + 2) {
                        .submenu_wrapper {
                            right: 23px;
                            left: inherit;
                            position: fixed;
                        }
                    }
                }

                @media only screen and (max-width: 1160px) {
                    .submenu_wrapper {
                        right: 23px;
                        left: inherit;
                        position: fixed;
                    }
                }

                &:hover {
                    .first_level_item,
                    .menu_static_la_marque {
                        &:before {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}