.item_price,
.wrapper_price{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
} 

.pricetag {
    font-family: $primaryFont;
    font-weight: 600;
    color: $txtColor;
    position: relative;
    font-size: 16px;
    letter-spacing: 0.8px;

    @media only screen and (max-width: 1560px) {
        font-size: 15.5px;
    }

    @media only screen and (max-width: 1370px) {
        font-size: 15px;
    }

    @media only screen and (max-width: 1360px) {
        font-size: 14px;
    }

    @media only screen and (max-width: 1024px) {
        font-size: 13.5px;
    }

    .currency {
        margin-left: .1rem;
    }

    + .pricetag {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #757270;
        margin-right: 10px;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #757270;
            position: absolute;
            top: 50%;
            margin-top: -0.5px;
            left: 0;
        }

        @media only screen and (max-width: 1560px) {
            font-size: 13.5px;
        }

        @media only screen and (max-width: 1370px) {
            font-size: 13px;
        }

        @media only screen and (max-width: 1360px) {
            font-size: 12.5px;
        }

        @media only screen and (max-width: 1024px) {
            font-size: 12px;
        }
    }
}

.cents.no_cents{
    display: none!important;
}