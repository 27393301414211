/******************************************************************************/
/* GENERAL LIGHTBOX STYLES */
/******************************************************************************/
.lightbox {
    width: 50%;
    display: none;
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*background: $bgColor2!important;*/
    background: #fbf4ec;
    border-bottom: solid 1px #fbf4ec; // pour retirer le blur du text

    .close_pop {
        position: absolute;
        z-index: 5;
        top: 20px;
        right: 20px;
        background-color: transparent;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        width: 20px;
        height: 20px;

        span {
            font-weight: 600;
            font-size: 0;
            color: #434343;
            background: url(../img/close_picto.png) center center no-repeat;
            width: 12px;
            height: 12px;
            display: block;
        }

        &:hover {
            opacity: 0.75;
        }
    }
    .lightbox_content {
        padding: 3em 2em;
    }

    &#exit_overlay_OVI{
        background: $bgColor2;
        border: 0;
        width: 836px;
        height: 521px;
        transform: none;
        margin-top: -275.5px;
        margin-left: -418px;
        padding: 32px;

        &:not(.lightbox-image){
            height: 518px;
            margin-top: -260.5px;
            padding: 83px 70px 28px 0;
            background-image: url("../img/overlay_bg.jpg");
            background-repeat: no-repeat;
            background-position: left center;
        }

        .close {
            position: absolute;
            z-index: 5;
            top: 20px;
            right: 20px;
            background-color: transparent;
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
            font-size: 0;
            background: url(../img/close.png) center center no-repeat;
            width: 12px;
            height: 12px;
            display: block;

            &:hover {
                opacity: 0.75;
            }
        }

        .wrapper_overlay{
            width: 45.8%;
            float: right;
            text-align: center;

            .pop_title{
                font-family: $secondaryFont;
                font-size: 13px;
                color: $titleColor;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 1px;
                line-height: 1.6rem;

                strong{
                    font-size: 30px;
                    font-weight: 600;
                    letter-spacing: 4.5px;
                }
            }
        }

        .pop_contents{
          .detail{
            font-size: 14px;
            color: $titleColor;
            font-weight: 500;
            padding: 1.2rem 0 1.5rem;
            line-height: 1.3rem;
          }

          .main_msg,
          .overlay_msg_attention{
            margin-bottom: 1rem;
          }
        }

        .pop_txt_listing {
            text-align: center;
            margin: 1rem auto 26px;
            max-width: 350px;
            span{
                font-size: 13px;
                color: $darkColor;
                font-weight: 400;
                border-bottom: 1px solid #919191;
                font-family: $primaryFont;
            }
        }

        form{
            width: 100%;

            .w-form-line{
                margin-bottom: 0;

                .w-input-note{
                    position: relative;
                    left: auto;
                    top: auto;
                    margin: 0;
                }

                &.w-submit{
                    max-width: 240px;
                }
            }

            .gender{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 15px;

                label{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 13px;
                    margin-right: 50px;

                    &:last-child{
                        margin-right: 0;
                    }
                }

                [type=radio] + label:before,
                [type=radio] + label:after{
                    border-radius: 0;
                }

                [type=radio]:checked+label:before,
                [type=radio]:not(:checked)+label:before{
                    margin-right: 14px;
                    border-color: #c5c5c5;
                }
            }

            input{
                height: 48px !important;
                border-color: #989898 !important;

                &+.w-input-label {
                    span {
                        font-size: 14px;
                        font-family: Arial, sans-serif;
                    }
                }
            }

            .w-submit-button,
            button.w-submit-button{
                height: 50px;
                margin-top: 32px;
                font-family: $primaryFont;
                color: #989898;
                font-size: 14px;
                letter-spacing: 0.1em;
                width: 230px;
                color: #FFF;
                background-color: $darkColor;
                border-color: $darkColor;
            }

        }

        .social{
            .footer_lbl{
                display: none;
            }

            .social_links{
                display: flex;
                justify-content: center;
                align-items: center;

                li a{
                    width: 25px;
                    height: 25px;
                    margin-left: 25px;
                    background-size: 500% 100%;
                    background-image: url(../svg/social_b.svg);
                    background-repeat: no-repeat;
                    display: inline-block;


                    &.fb{
                        background-position: 0% 0;
                        width: 25px;
                        height: 25px;
                        margin-left: 0;
                    }
                    &.insta{
                        background-position: 25% 0;
                        width: 25px;
                        height: 25px;
                    }
                    &.tw{
                        background-position: 50% 0;
                    }
                    &.pin{
                        background-position: 50% 0;
                        width: 25px;
                        height: 25px;
                    }
                    &.social_lnk.In {
                        background-position: 100% 0;
                    }
                    &.Yt {
                        background-position: 75% 0;
                        width: 32px;
                        height: 32px;
                        position: relative;
                        top: 2px;
                    }
                }
            }
        }

        &.lightbox-image{
            .pop_contents{
                &.lightbox-image{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .wrapper_img{
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img{
                            width: 305px;
                            height: 460px;

                            @include queriesWshop(1024px, max) {
                                height: 460px;
                            }
                        }
                    }

                    .wrapper_newspopup_home{
                        width: calc(100% - 347px);

                        .content_newspopup{
                            width: 350px;
                            margin: 0 auto;

                            .pop_txt_listing{

                                margin-bottom: 26px;

                                p,
                                .detail{
                                    font-size: 13px;
                                    color: $darkColor;
                                    font-weight: 400;
                                    padding: 0;
                                    line-height: 1.3rem;
                                }
                            }
                        }
                    }
                }

                .title_popup_inscription_home{
                    font-family: $secondaryFont;
                    font-size: 40px;
                    color: $darkColor;
                    font-weight: 600;
                    text-transform: lowercase;
                    letter-spacing: -0.025em;
                    line-height: 1.6rem;
                    text-align: center;

                    &:first-letter {
                        text-transform: uppercase;
                    }

                    small{
                        font-size: 16px;
                        font-weight: 400;
                        font-family: $primaryFont;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                    }
                }
            }
        }

        #conf_inscription_news {
            .form_submit {
                button {
                    height: 50px;
                }
            }
        }
    }

    &#abox{
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        width: 501px;
        height: 240px;
        padding: 50px 15px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transform: none;
        z-index:120;
        background-color: $bgColor2;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            width: 600px;
            height: 300px;
            background-color: #FFF;
            border-radius: 100%;
            left: -50px;
            margin: auto;
            top: 68%;
            z-index: -1;
        }

        .close_pop {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;
        }

        .header_lightbox {
            .txt_alert {
                padding: 0;
                font-size: 16px;
                font-family: $primaryFont;
            }
        }

        .form_submit {
            max-width: 250px;
            margin: auto;

            button {
                background: #242424;
                border: 1px solid #242424;
                color: #fff;
                cursor: pointer;
                transition: all .2s ease-out;

                &:hover {
                    background: #fff;
                    color: #242424;
                }
            }
        }


        .box_wishlist{
            .light_title{
                font-family: $secondaryFont;
                font-weight: 600;
                font-size: 40px;
                line-height: 1;
                letter-spacing: -0.025em;
                text-transform: none;
                color: $darkColor;
                margin-bottom: 20px;
            }

            p{
                font-size: 13px;
                line-height: 20px;
                color: $primaryColor;
                margin: 0 auto 0;
                font-weight: 600;
                font-family: $primaryFont;
            }

            > .btn_container {
                margin: 0 auto;
                margin-top: 45px;
                width: 240px;
                .button {
                    background-color: #272525;
                }
            }

            .wrapper_btn {
                margin-top: 23px;
                .button {
                    &.primary {
                        background-color: #272525;
                        color: #fff;
                        width: 240px;
                        height: 50px;
                        display: block;
                        line-height: 50px;
                        font-size: 14px;
                        text-transform: uppercase;
                        margin: auto;
                        letter-spacing: 0.1em;
                        font-family: $primaryFont;

                    }

                    &.special {
                        display: none;
                    }
                }
            }
        }

        .form_submit {
            margin-top: 2rem;
        }

        .wrap_btn_abox{
            display: flex;
            align-items: center;
            justify-content: center;

            .btn_container{
                width: auto;
                height: 41px;

                &.btn_cnx{
                    margin-left: 21px;
                }

                .button {

                height: 41px;
                padding: 0 30px;
                border-color: rgba($primaryColor, 0.2);

                    span {
                        text-transform: none;
                        font-size: 17px;
                        font-weight: 100;
                        font-style: italic;
                    }
                }
            }
        }

        &.wishlistpopup {

            padding:  50px;

            .light_title{

                margin-top: 0.5rem;

                .txt_alert {

                    font-size: 17px;
                    font-family: $primaryFont;
                    color: $primaryColor;
                    font-weight: 100;
                    line-height: 25px;
                    letter-spacing: 0.025em;

                    .light_title {
                        font-size: 17px;
                        font-family: $primaryFont;
                        color: $primaryColor;
                        font-weight: 100;
                        line-height: 25px;
                        letter-spacing: 0.025em;
                    }
                }
            }

            .wrap_btn_abox {
                display: flex;
                justify-content: center;
                margin-top: 1rem;

                .btn_container {
                    margin: 10px;
                    button {
                        min-width: 120px;
                    }
                }

                .form_submit {
                    max-width: 120px;
                    margin: 10px;

                    button {
                        width: 120px;
                    }
                }
            }
        }
    }

    &#sendfriendbox{
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        width: 657px;
        height: auto;
        max-height: 765px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 44px;

        @media only screen and (max-width: 1310px) {
            height: auto;
            margin: auto;
            top: 25px;
            left: 0;
            right: 0;
            max-height: 90vh;
            transform: none;
            overflow: hidden;

            #sendfriendform {
                #content_sendfriendbox {
                    overflow: auto;
                    max-height: 85vh;
                    padding: 0 15px;
                    -ms-overflow-style: none;  /* Internet Explorer 10+ */
                    scrollbar-width: none;  /* Firefox */

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }

            .lb-wrap {
                -ms-overflow-style: none;  /* Internet Explorer 10+ */
                scrollbar-width: none;  /* Firefox */

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .lb-v-scrollbar {
                transform: scaleY(.75);
                width: 2px;
                cursor: pointer;
            }

            .lb-v-scrollbar-slider {
                width: 2px;
            }
        }



        .popup_title{
            font-family: $secondaryFont;
            font-size: 16px;
            letter-spacing: 0.15em;
            line-height: 22px;
            text-transform: uppercase;
            font-weight: 600;
            color: #272525;
            margin: 52px 0 12px;

            @media only screen and (max-width: 1310px) {
                font-size: 23px;
                line-height: 20px;
                margin: 30px 0 8px;
            }
        }

        #sendfriend_prodinfos {
            font-size: 14.5px;
            letter-spacing: 0;
            color: #272525;
            margin: 10px 0 20px;
        }

        .popup_subtitle {
            display: block;
            font-family: $primaryFont;
            font-size: 17px;
            font-weight: 100;
            line-height: 20px;
            color: $primaryColor;
            text-align: center;
            vertical-align: top;
            margin-bottom: 25px;

            @media only screen and (max-width: 1310px) {
                font-size: 15px;
                margin-bottom: 18px;
            }
        }

        #title_destinataire{
            display: block;
            font-family: $secondaryFont;
            font-size: 12px;
            font-weight: 600;
            line-height: 30px;
            color: #272525;
            text-align: left;
            text-transform: uppercase;
            vertical-align: top;
            letter-spacing: 0.150em;

            @media only screen and (max-width: 1310px) {
                font-size: 15px;
                line-height: 20px;
            }
        }

        label {
            span {
                font-size: 14px;
                font-family: $secondaryFont;
                color: #272525;
                line-height: normal;
                font-weight: 600;
            }
        }

        #sendfriend_form_froms {
            #second_sendfriend_form_froms, #fourth_sendfriend_form_froms {
                margin-bottom: 15px;
                display: inline-block;
                width: 100%;
            }
        }

        #sendfriend_alert_mail {
            .msg_poperror {
                max-width: 450px;
                margin: 1rem auto;
            }

            .form_submit {
                margin:0 auto 33px;
                max-width: 190px;
            }
        }

        #second_sendfriend_form_to,
        #second_sendfriend_form_froms,
        #fourth_sendfriend_form_froms{
            height: 48px;

            @media only screen and (max-width: 1310px) {
                height: 38px;
            }

            input {
                padding: 0 15px;

                &::-webkit-input-placeholder { /* Edge */
                  color: #919191;
                  font-style: normal;
                  font-size: 14px;
                  font-family: $primaryFont;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: #919191;
                  font-style: normal;
                  font-size: 14px;
                  font-family: $primaryFont;
                }

                &::placeholder {
                  color: #919191;
                  font-style: normal;
                  font-size: 14px;
                  font-family: $primaryFont;
                }
            }

            .g-recaptcha {
                margin-bottom: 20px;
            }
        }

        .plusieur_add{
            display: block;
            font-size: 13px;
            line-height: 20px;
            margin: 5px 0 19px;
            color: #272525;
            letter-spacing: 0;
            font-weight: 400;
            text-align: left;
            font-family: $primaryFont;
        }

        #sixth_sendfriend_form_to{
            display: block;
            height: 115px;
            margin-bottom: 15px;

            @media only screen and (max-width: 1310px) {
                height: 90px;
            }

            textarea{
                padding: 15px;

                &::-webkit-input-placeholder { /* Edge */
                  color: #919191;
                  font-style: normal;
                  font-size: 14px;
                  font-family: $primaryFont;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: #919191;
                  font-style: normal;
                  font-size: 14px;
                  font-family: $primaryFont;
                }

                &::placeholder {
                  color: #919191;
                  font-style: normal;
                  font-size: 14px;
                  font-family: $primaryFont;
                }

            }
        }

        #title_de_la_part{
            display: block;
            font-family: $secondaryFont;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            color: #272525;
            text-align: left;
            vertical-align: top;
            text-transform: uppercase;
            letter-spacing: 0.150em;

             @media only screen and (max-width: 1310px) {
                font-size: 15px;
                line-height: 20px;
                height: 22px;
             }
        }

        #copySendCheck{
            [type=checkbox]:checked+label:before,
            [type=checkbox]:not(:checked)+label:before{
                width: 14px;
                height: 14px;
            }

            [type=checkbox]:checked+label:after,
            [type=checkbox]:not(:checked)+label:after{
                width: 12px;
                height: 12px;
            }

            span{
                font-size: 13px;
                line-height: 18px;
                color: $primaryColor;
            }
        }



        .g-recaptcha {
            margin-top: 10px;
             transform: scale(0.775);
        }

        #sendfriend_form_buttons{
            max-width: 240px;
            margin: 10px auto 20px;
            height: 50px;

            button.primary {
                background-color:  #272526;
                span {
                    font-size: 12px;
                    letter-spacing: 0.15em;
                    color: $white;
                    text-transform: uppercase;
                    font-family: $secondaryFont;
                    font-weight: 600;

                }
            }

            .loader{
                &.loading{
                    z-index: 2;
                }
            }

        }

        #product_sendfriend_nok {
            display: flex !important;
            justify-content: center;
            align-items: center;
        }
    }

     &#mention_legales_pop_up {
        padding: 2rem;
        width: 650px;

        #content_mention_legales_pop_up {

            #fermer_mention_legales_pop_up {
                .btn_retour {
                    position: absolute;
                    top: 15px;
                    right:  15px;
                    font-size: 0;
                    text-decoration: none;
                    width: 11px;
                    height: 11px;
                    background: url(../img/close.png) center center no-repeat;
                }

            }

            .pop_mention_title {
                font-size: 18px;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 2rem;
                letter-spacing: 0.1em;
                color: $darkColor;
                text-align: center;
            }

            .pop_mention_detail {
                font-size: 16px;
                color: $darkColor;
                line-height: 18px;
                text-align: center;

            }

        }
    }

/*Sizeguide Pop up style*/

    &#sizeguideBox{
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        width: 100%;
        max-width: 840px;
        height: 730px;

        @media only screen and (max-width: 840px) {
            width: 97%;
            height: 570px;
        }

        body.category {
            height: 630px;
        }

        .tablesWrapper {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            max-height: 475px;
            padding: 3rem;

            @media only screen and (max-width: 840px) {
                height: 570px;
                max-height: 570px;
            }

            #wrap_itable{
                width: 100%;
            }

            .guide_intro{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                h1, .h1style {
                    font-size: 2.5rem;
                    padding: 12px 0 25px 0;
                    font-weight: 600;
                    text-align: center;
                    font-family: $secondaryFont;
                }
                 p{
                    font-size: 0.8125rem;
                    font-family: $primaryFont;
                    line-height: 20px;
                    letter-spacing: 0.65px;
                    text-align: justify;
                    width: 60%;
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                       width:55%;
                    }

                    @media only screen and (max-width: 840px) {
                        font-size: 0.75rem;
                    }
               }
            }
            .sizeguide_txt{
                margin-top: 1.5rem;
                font-weight: 600;
                font-size: 0.8125rem;
                letter-spacing: 0.65px;
                text-transform: uppercase;
                text-align: justify;
                margin:2rem 0 0.5rem 0;

                @media only screen and (max-width: 840px) {
                    font-size: 0.75rem;
                }
            }
            .guide_wrapper {
                width: 100%;
                max-width: 765px;
                height: 415px;
                overflow: hidden;
                margin: 0 auto;

                @media only screen and (max-width: 840px) {
                    height: 305px;
                }

                .sizeguide_content{
                    display: flex;
                    justify-content: flex-start;
                    .left_side_content{
                        width: calc(100% - 200px);
                        max-width: 399px;

                        .block_text{
                           padding-bottom: 2.5rem;
                           text-align:justify;
                           line-height: 24px;
                           font-size:0.8125rem;

                           @media only screen and (max-width: 840px) {
                               font-size: 0.75rem;
                           }
                          h3, .h3style{
                            font-weight: 600;
                            font-size: 0.8125rem;
                            letter-spacing: 1.25px;

                            @media only screen and (max-width: 840px) {
                                font-size: 0.75rem;
                            }
                          }
                        }
                    }
                    .right_side_content{
                        width: 173px;
                        margin:1.25rem 0 1rem 5vw;

                        img{
                            display: block;
                            width: 100%;
                        }
                    }
                }
                table.sizeguide_tbl {
                  width: 89%;
                  border-collapse: collapse;
                  border: 1px solid #b9b9b9;
                  margin:1.5rem 0 0 0;
                  text-align: center;
                  color: #636260;
                  .sizeguide_tbl_caption{
                     padding-bottom: 1rem;
                     font-size:0.8rem;
                     font-weight: 600;
                     letter-spacing:0.65px;
                     text-transform: uppercase;
                     text-align: justify;
                     color: #1b1c1e;
                     @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        margin-right:14.8rem!important;
                     }

                     @media only screen and (max-width: 840px) {
                         font-size: 0.75rem;
                     }
                    }
                 td{
                    border-collapse: collapse;
                    border: 1px solid #b9b9b9;
                    padding: 5px 10px;
                    height: 2.435em;
                    width: 6em;
                    font-family: $primaryFont;
                    font-size:0.8125rem;
                   }
                 th{
                    border-collapse: collapse;
                    border: 1px solid #b9b9b9;
                    padding: 5px 8px;
                    font-size:0.8125rem;
                    line-height: 20px;
                    font-weight:400;
                    color: black;
                    height: 4.25em;
                    width: 13em;
                   }
                }
    }
                .lb-v-scrollbar {
                    width: 7px;
                    right: -3px;

                        &:before {
                            content:"";
                            width: 1px;
                            height: 100%;
                            position: absolute;
                            right: 5px;
                            bottom: 0;
                            background-color:#e6e6e6;
                        }
                    }

                    .lb-v-scrollbar-slider {
                        background: $primaryColor;

                        &:before {
                            content:"";
                            width: 7px;
                            height: 7px;
                            border-radius: 100%;
                            position: absolute;
                            right: -3px;
                            bottom: 0;
                            margin: auto;
                            background-color:$primaryColor;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            background-color: $primaryColor;
                            width: 1px;
                            left: 0;
                            right: 0;
                            margin: auto;
                        }
                    }
                    .lb-v-scrollbar {
                        border: none;
                    }
                    .os-scrollbar-horizontal{
                        display:none;
                     }
                    .os-scrollbar.os-scrollbar-vertical {
                        width: 16px;
                        .os-scrollbar-track.os-scrollbar-track-off {
                            width: 1px;
                            background-color: #e6e6e6;

                            .os-scrollbar-handle {
                                width: 1px;
                                margin: 0 auto;
                                left: 0;
                                right: 0;
                                background-color: $black;

                                &:before {
                                    background-color: $black;
                                    border-radius: 50%;
                                    width: 7px;
                                    height: 7px;
                                    top: auto;
                                    left: -3px;
                                    right: auto;
                                }

                                &:after{
                                    content: " ";
                                    display: block;
                                    background: #242424;
                                    width: 1px;
                                    height: 600px;
                                    position: absolute;
                                    left: 50%;
                                    bottom: 0;
                                }
                            }
                        }
                    }
                /*End Scrollbar*/
            }
        }
        /* End of sizeguide style*/
    &#modboxpromo{
        width: 500px;
        min-height: 150px;
        text-align: center;
        padding: 3rem;

        .texte{
            font-family: $primaryFont;
            font-size: 1rem;
            line-height: 1.2;
            color: $black;
            margin: 0 0 10px;
        }

        .close_pop{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background: url("../img/close_btn.png") center right no-repeat;
            width: 65px;
            height: 10px;
            position: absolute;
            right: 0px;
            top: 70px;
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            letter-spacing: 0.325px;
            color: #242424;
            cursor: pointer;
            outline: none;

            span {
                background-image: none;
                font-size: 0;
                font-family: $secondaryFont;
                text-transform: uppercase;
            }

            @media only screen and (max-width: 1320px) {
                top: 0px;
            }
        }

        .button {
            &.primary {
                background-color: #272525;
                color: #fff;
                width: 240px;
                height: 50px;
                display: block;
                line-height: 50px;
                font-size: 12px;
                text-transform: uppercase;
                margin: auto;
                font-family: $primaryFont;

            }
        }

        .form_submit{
            max-width: 150px;
            margin: 0 auto;
        }

    }

    &#zoomBox{
        width: 100%;
        height: 100%;
        padding: 0;
        transform: none;
        top: 0;
        left: 0;
        overflow-y: auto;
        background-color: #FFF;

        @media only screen and (max-width: 1360px) {
            overflow-y: auto;
        }

        .zoom_main_wrapper {
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;
            width: 68%;
            padding: 0;
            margin: 0 auto;
            position: relative;

             @media only screen and (max-width: 1160px) {
                width: 90%;
             }

        }

        .close_pop{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background: url("../img/close.png") center right no-repeat;
            width: 10px;
            height: 10px;
            position: absolute;
            right: 25px;
            top: 25px;
            cursor: pointer;
            outline: none;

            span {
                display: none;
            }

            @media only screen and (max-width: 1320px) {
                right: 5px;
                top: 5px;
            }
        }

        .thumb_wrapper {
            max-width: 151px;
            height: 788px;
            max-height: 90vh;
            position: relative;
            @media only screen and (max-width: 1370px) {
                max-height: 85vh;
            }

            .thumbsPrev,
            .thumbsNext{
                position: absolute;
                top: 60px;
                right: -60px;
                margin: auto;
                background: transparent url(../svg/arrow_btn_black.svg) center center no-repeat;
                background-size: contain;
                transform: rotate(-90deg);
                height: 10px;
                width: 78px;
                cursor: pointer;

                &:focus{
                    outline: none;
                }

                &.swiper-button-disabled{
                    opacity: 0.25;
                    cursor: default;
                }
            }

            .thumbsPrev{
            }

            .thumbsNext{
                transform: rotate(90deg);
                top: inherit;
                bottom: 60px;
            }
        }

        .thumbs_vis{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 151px;
            height: 788px;
            max-height: 90vh;
            margin-right: 10px;
            position: relative;

            @media only screen and (max-width: 1370px) {
                max-height: 85vh;
                max-width: 110px;
            }

             #thumbs_scroller {
                height: 100%;
                align-items: center;
                justify-content: flex-end;
             }

            .swiper-container{
                height: 100%;

                .swiper-wrapper{

                    .swiper-slide{
                        width: 151px;
                        height: 186px;
                        opacity: 0.9;
                        cursor: pointer;

                        @media only screen and (max-width: 1370px) {
                            width: 110px;
                            height: 138px;
                        }

                        &.swiper-slide-thumb-active {
                            opacity: 1;
                            cursor: default;
                        }

                        img{
                             width: 151px;
                            height: 186px;
                            display: block;

                            @media only screen and (max-width: 1370px) {
                                width: 100%;
                                height: 138px;
                            }
                        }
                    }
                }
            }
        }

        #zoom_box_wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 84%;
            height: auto;
            margin: 0 35px 0 0;

            img{

                position: relative;
                height: auto;
                width: 100%;
            }

            .swiper-lazy-preloader,
            .swiper-slide {
                background-color: #FFF;
            }
        }
    }

    &#popup_savoir_plus{
        background: $white;
        border: 0;
        width: 836px;
        max-height: 520px;
        padding: 2rem;
        transform: none;
        margin-top: -160px;
        margin-left: -418px;

        .close {
            position: absolute;
            z-index: 5;
            top: 20px;
            right: 20px;
            background-color: transparent;
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
            font-size: 0;
            background: url(../img/close.png) center center no-repeat;
            width: 12px;
            height: 12px;
            display: block;

            &:hover {
                opacity: 0.75;
            }
        }

        #title_popup_savoir_plus{
            font-family: $secondaryFont;
            font-size: 13px;
            color: $titleColor;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 1.6rem;
        }

        #content_savoir_plus,
        #bottom_savoir_plus{
            margin-bottom: 1rem;
            line-height: 1.5rem;

            li{
                list-style: inside;
            }
        }
    }

    &#pdvBox {
        width: 950px;
        height: 600px;
        padding: 50px;

        .close_pop {
            font-size: 0;
            width:11px;
            height: 11px;
            background: url(../img/close.png) center center;
            background-size: cover;
        }

        #content_pdvBox {
            .popup_title {
                text-align: left;
                font-size: 25px;
                font-family: $secondaryFont;
                color: $primaryColor;
                text-transform: uppercase;
                letter-spacing: 0.015em;
                font-weight: 600;
                margin-bottom: 1rem;
            }

            .popup_intro {
                text-align: left;
                font-size: 16px;
                font-family: $primaryFont;
                color: $primaryColor;
                letter-spacing: 0;
                font-weight: 400;
            }

            .storeloc_form {

                .zip_input {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 0 15px;

                    .formWrapper {
                        width: 100%;

                        .wrap_fields {

                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .field_address {
                                width: 70%;
                                height: 50px;

                                .search_wrapper {
                                    width: 60%;
                                    display: flex;

                                    .loc_input {
                                        width: calc(100% - 39px);
                                        height: 50px;
                                    }
                                }
                            }

                            .ou {
                                font-size: 14px;
                                letter-spacing: 0.15em;
                                font-family: $secondaryFont;
                                padding: 17px;
                            }

                            .loc_btn {
                                width: 35%;
                                max-width: 240px;
                                background: $primaryColor;
                                color: $white;
                                font-size: 12px;
                                letter-spacing: 0.15em;
                                text-align: center;
                                padding: 0 1em;
                                height: 50px;
                                line-height: 50px;
                                cursor: pointer;
                                text-transform: uppercase;
                                font-family: $secondaryFont;
                            }

                        }
                    }

                    .field {
                        height: 39px;

                        select {
                            border: 1px solid #e6e6e6;
                            box-sizing: border-box;
                            width: 100%;
                        }

                        &.country_field {
                            width: 27%;
                        }


                    }
                }
            }

            .liste_recherche_distributeur {
                padding: 1rem 0;

                .resultat_recherche {
                    border-bottom: 1px solid #e6e6e6;
                    display: flex;
                    justify-content: flex-start;
                    padding-bottom: 1rem;

                    h3 {
                       font-family: $primaryFont;
                       font-size: 16px;
                       font-weight: 100;
                    }

                    #nb_magasins {
                        padding-left: 5px;
                        font-family: $secondaryFont;
                        font-size: 13px;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                    }
                }

                .liste_distributeur_wrapper {
                    height: auto;
                    padding: 1rem 0;

                }

                .liste_distributeur {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    height: auto;
                    overflow-y: auto;
                    padding-top: 1rem;
                    max-height: 270px;

                    .elem_liste_distributeur {
                       width: 33.333%;
                        margin-bottom: 2em;

                        .elem_list_contents {
                            font-size: 13px;
                            font-family: $secondaryFont;
                            text-align: center;

                            .nom_store {
                                font-weight: 600;
                                margin-bottom: 6px;
                                text-transform: uppercase;
                                font-size: 1.05rem;
                            }

                            .ville_store, .pays_store {
                                display: inline;
                            }

                            .content_store {

                                position: relative;
                                font-size: 14.25px;
                                line-height: 22px;
                                font-family: $primaryFont;
                                letter-spacing: 0;
                                color: $primaryColor;

                                .horaire_today{
                                    margin: 10px auto;
                                    display: inline-block;

                                    color: red;

                                    .puce {
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 100%;
                                        display: inline-block;

                                        &.store_open {
                                            background: #3dd125;
                                        }

                                        &.store_close {
                                            background: red;
                                        }

                                    }

                                    .txt_store_open {
                                        font-weight: 500;
                                        color: #3dd125;
                                        padding-left: 3px;
                                        font-size: 13px;
                                        text-transform: uppercase;
                                        letter-spacing: 0.150em;
                                        font-family: $secondaryFont;
                                        & + span {
                                            color: #242424;
                                        }
                                    }
                                }

                                .store_more {
                                    a {
                                        color: #919191;
                                        text-decoration: underline;
                                        font-size: 15px;
                                    }
                                }
                                /*
                                .store_more {
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    right: 0;
                                    margin: auto;

                                    a {
                                        height: 45px;
                                        width: auto;
                                        padding: 1rem;
                                        border: 1px solid rgba(0,0,0,0.25);
                                        line-height: 45px;
                                    }
                                }*/

                            }
                        }

                    }

                    .lb-wrap {

                        -ms-overflow-style: none;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .lb-content {

                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                        }
                    }

                    .lb-v-scrollbar {
                        width: 4px;
                    }


                    .lb-v-scrollbar-slider {
                        background: #a6a5a5;
                        width: 1px;

                        &:before {
                            content:"";
                            width: 7px;
                            height: 7px;
                            border-radius: 100%;
                            position: absolute;
                            right: -3px;
                            bottom: 0;
                            margin: auto;
                            background-color: #a6a5a5;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: -800%;
                            background-color: #a6a5a5;
                            width: 1px;
                            height: 800%;
                            left: 0;
                            right: 0;
                            margin: auto;

                        }

                    }

                    .lb-v-scrollbar {
                        border: none;
                    }
                }
            }
        }

        .steps {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: stretch;
            height: 165px;

            .num_step {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                line-height: 1;
                font-size: 1.2rem;
                color: #8b8b8b;

                span {
                    margin-bottom: 7px;
                }

                &:before {
                    display: block;
                    margin-bottom: 5px;
                    font-size: 2.65em;
                    color: #474747;
                    content: attr(data-step);
                }

                &:after {
                    display: block;
                    width: 1px;
                    height: 7px;
                    content: '';
                }

                &.actif {
                    color: #221E1F;

                    &:after {
                        background-color: $black;
                    }
                }
            }
        }

        .step {
            display: none;
            min-width: 624px;

            .infos {
                .map {
                    width: 624px;
                    height: 255px;
                }

                .form_submit {
                    position: relative;
                    float: right;

                    .button.secondary.dark {
                        width: auto;
                        line-height: 2.35;
                        font-size: 1.285rem;
                        color: $black;
                        padding: 0 2.5em;
                    }

                    .button.loader {
                        line-height: 2.35;
                        background-color: $white;

                        &:before {
                            background-image: url("../svg/loader_grey.svg");
                        }
                    }
                }
            }

            &#step1 {
                display: block;

                .infos {
                    display: flex;

                    .bookinstore_container_img {
                        width: 200px;
                        margin-right: 60px;

                        img {
                            vertical-align: top;
                            width: 100%;
                            height: auto;
                        }
                    }

                    .product_name {
                        font-size: 1.21285rem;
                        color: #221E1F;
                    }

                    .price {
                        font-size: 1.7rem;
                        color: #221E1F;
                    }

                    .form_submit {
                        margin-top: 105px;
                    }
                }
            }

            &#step2 .form_submit {
                margin-top: 20px;
            }

            &#step3 {
                .infos {
                    display: flex;

                    .bookinstore_container_img {
                        width: 200px;
                        margin-right: 90px;
                    }

                    .bookinstore_form_client {
                        width: 305px;

                        #form_infos_bookinstore {
                            margin-bottom: 30px;
                        }

                        .input_container {
                            margin-bottom: 17px;
                        }

                        .mandatory_notice {
                            margin-bottom: 23px;
                            font-family: Arial;
                            font-size: 11px;
                            color: #989898;
                        }

                        .nb {
                            line-height: 1.6;
                            margin-bottom: 12px;
                            font-size: 1.05rem;
                            color: $black;
                        }
                    }
                }


            }
        }
    }

    &#sendwishlistbox {
        padding: 3.5rem 4.5rem 3rem;
        width: 657px;
        max-width: 100%;
        overflow-y: auto;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        height: 850px;
        max-height: inherit;
        transform: inherit;

        @media screen and (max-width: 1560px) {
            padding: 2rem 2.5rem 1.5rem;
        }

        @media screen and (max-width: 1360px) {
            padding: 1.5rem 2rem 1rem;
        }

        @media screen and (max-width: 1160px) {
            padding: 1rem 2rem 1rem;
        }

        @media screen and (max-width: 820px) {
            position: absolute;
        }

        @media screen and (max-height: 820px) {
            height: 95vh;
        }

        .popup_title {
            text-align: center;
            margin: 0 0 1.2rem;
            p {
                &.title {
                    font-family: $secondaryFont;
                    font-size: 1rem;
                    line-height: 1.3;
                    color: $primaryColor;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 0;
                    margin: 0 0 .8rem;
                }

                &.subtitle {
                    font-family: $primaryFont;
                    font-size: 1rem;
                    line-height: 1.3;
                    letter-spacing: 2px;
                    color: $primaryColor;
                }
            }
        }

        .form_title {
            font-family: $secondaryFont;
            font-size: 0.8125rem;
            line-height: 1.3;
            color: $primaryColor;
            letter-spacing: 0.046875rem;
            text-transform: uppercase;
            font-weight: 600;
        }

        .plusieur_add {
            font-family: $primaryFont;
            font-size: 13 / 16 * 1rem;
            line-height: 1.3;
            color: $primaryColor;
            margin: .3rem 0 0;
        }

        label.plusieur_add {
            p {
                font-family: $secondaryFont;
                font-size: 14 / 16 * 1rem;
                line-height: 1;
                color: $primaryColor;
                margin: 2px 0 0;
            }
        }

        .g-recaptcha {
            margin: 3rem 0 0;
        }

        input[type=text].inputFormSite,
        textarea.inputFormSite {
            font-size: 14 / 16 * 1rem;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: 400;
            letter-spacing: .5px;
            padding: 1rem;
            &::-webkit-input-placeholder {
                color: #919191;
                font-size: 14 / 16 * 1rem;
                font-style: normal;
                font-family: Arial, sans-serif;
            }
            &::-moz-placeholder {
                color: #919191;
                font-size: 14 / 16 * 1rem;
                font-style: normal;
                font-family: Arial, sans-serif;
            }
            &:-moz-placeholder {
                color: #919191;
                font-size: 14 / 16 * 1rem;
                font-style: normal;
                font-family: Arial, sans-serif;
            }
            &:-ms-input-placeholder {
                color: #919191;
                font-size: 14 / 16 * 1rem;
                font-style: normal;
                font-family: Arial, sans-serif;
            }

        }

        textarea {
            resize: none;
            overflow: auto;
        }

        #sendfriend_form_buttons {
            max-width: 230px;
            margin: 1rem auto 0;

            .loader{
                &.loading{
                    z-index: 2;
                }
            }
        }
    }

    &#e-resaBox {
        background: $bgColor2;
        width: 836px;
        height: auto;
        min-height: 630px;
        transform: none; /*BLURS FONTS*/
        left: 0;
        right: 0;
        top: calc(50% - 300px);
        margin: auto;
        overflow-y: auto;

        .w-checkbox-input .w-input-element+.w-input-label, .w-radio-input .w-input-element+.w-input-label {
            font-family: $primaryFont !important;
            font-size: .75rem;
        }

        .w-eresa-form  {
            .mandatory {
                font-family: Arial;
                font-size: 11px;
                color: #989898;
            }
        }

        #content_e-resaBox{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .popup_header {
                width: 80%;
                max-width: 100%;
            }

            .popup_title{
                font-family: $secondaryFont;
                font-size: 40px;
                letter-spacing: -0.025em;
                line-height: 27px;
                color: $darkColor;
                margin: 2rem 0 !important;
                text-align: center;
                text-transform: none;
                font-weight: 600;
            }
            .popup_desc {
                font-family: $primaryFont;
                font-size: 13px;
                line-height: 21px;
                letter-spacing: 0.05em;
                text-align: center;
                color: $darkColor;
                font-weight: 500;
                margin-bottom: 30px;
                display: block !important;
            }

            .steps_num {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 330px;
                max-width: 100%;
                margin: 1rem 0;

                @media only screen and (max-width: 1280px) {
                    width: 90%;
                }


                .step_num {
                    font-family: $primaryFont;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 1;
                    color: $darkColor;
                    letter-spacing: 0.05em;
                    text-transform: capitalize;
                    position: relative;

                    &:first-letter {
                        text-transform: uppercase;
                    }

                    &:after {
                        content: "";
                        display: block;
                        background: rgba($darkColor, .7);
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        bottom: -0.1rem;
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                    }

                    &.actif{
                        color: $darkColor;
                        font-weight: 600;

                        &:after{
                            opacity: 1;
                        }
                    }
                }
            }

            .step {
                display: none;
                width: 680px;
                margin: 0 auto;
                &.loading {
                    .step {
                        opacity: 0;
                    }
                    background: url('../img/Ellipsis-1.8s-40px.gif') center center no-repeat;
                }
                &.actif {
                    display: block;
                    .step_content {
                        opacity: 1;
                    }
                }
                .step_content {
                    opacity: 0;
                    transition: opacity 0.2s ease-in;


                    .eresa_container_img {
                        display: flex;
                        justify-content: center;
                        width: 90px;
                        min-width: 80px;
                        height: auto;
                        overflow: hidden;

                        img {
                            width: 120%;
                            height: auto;
                        }
                    }

                    .eresa_pdt_description {
                        margin-left: 2rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .product_name,
                        .product_subname {
                            font-family: $secondaryFont;
                            text-transform: uppercase;
                        }

                        .product_name {
                            font-size: 24 / 16 * 1rem;
                            line-height: 1.3;
                            font-weight: 600;
                        }

                        .product_subname {
                            font-size: 14 / 16 * 1rem;
                            line-height: 1.3;
                            margin: 0.25rem 0;
                        }

                        .item_price_wrapper {
                            margin: 0.25rem 0;

                            .item_price {
                                display: block;
                            }

                            .pricetag {
                                font-family: $secondaryFont;
                                font-size: 18 / 16 * 1rem;
                                line-height: 1.3;
                                color: #272525;

                                & + .pricetag{
                                    font-size: 13 / 16 * 1rem;
                                    color: #acabab;
                                    margin-left: 0.5rem;

                                    &:after{
                                        background: #acabab;
                                    }
                                }
                            }
                        }
                    }

                    .product_info{
                        .size-color-wrap {
                            display: flex;
                            flex-direction: column-reverse;
                        }

                        .ligne_form{
                            width: 100%;
                            margin: 0.25rem 0;

                            .prod_listes{
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                width: 100%;

                                .titre_couleur,
                                .titre_taille {
                                    font-family: $secondaryFont;
                                    font-size: 1rem;
                                    line-height: 1.3;
                                    text-transform: none;
                                    margin-right: 10px;
                                    font-weight: 300;

                                    &:after {
                                        content: ":";
                                    }
                                }

                                .choices_list{
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;

                                    .form_itm {
                                        label {
                                            font-family: $secondaryFont;
                                            font-weight: 600;
                                            font-size: 1rem;
                                            line-height: 1;

                                            &:before,
                                            &:after{
                                                display: none;
                                            }
                                        }

                                        [type=radio]:checked+label,
                                        [type=radio]:not(:checked)+label{
                                            margin: 0;
                                        }

                                        [type=radio]:checked+label:after,
                                        [type=radio]:not(:checked)+label:after{
                                            display: none;
                                        }

                                        &.color {
                                            margin-right: 0.5rem;
                                            label {
                                                display: none;
                                            }

                                            &:after {
                                                content: attr(data-color);
                                                text-transform: capitalize;
                                            }
                                        }

                                        &.size {
                                            margin-right: .5rem;
                                            label {
                                                color: #242424;
                                                margin-left: 0 0 0 15px;
                                                position: relative;
                                            }

                                            [type=radio]:checked,
                                            [type=radio]:not(:checked) {
                                                &.disabled{
                                                    & + label{
                                                        color: #acabab;
                                                    }
                                                }

                                                & + .no_stock{
                                                    color: #acabab;
                                                }
                                            }

                                            [type=radio]+label {
                                                position: relative;
                                                border: 1px solid transparent;
                                                padding: 1px;
                                                min-width: 20px;
                                                height: 20px;

                                                span {
                                                    width: 100%;
                                                    text-align: center;
                                                }
                                            }
                                            [type=radio]:not(:checked)+label {
                                                opacity: 0.6;
                                            }
                                            [type=radio]:checked+label {
                                                opacity: 1;
                                                border-color: $primaryColor;
                                                /* &:before {
                                                    opacity: 1;
                                                } */
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .store {
                    .store_info {
                        color: $secondaryColor;

                        .distance_store {
                            font-size: 0.8em;
                            display: none;
                        }

                        .name_store {
                            font-family: $primaryFont;
                            font-size: 13px;
                            line-height: 1.3;
                            text-transform: none;
                            color: $darkColor;
                            margin-bottom: 1rem;
                            font-weight: 700;
                            letter-spacing: .5px;
                        }

                        .adresse_store,
                        .phone_store{
                            font-family: $primaryFont;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 1.4;
                            color:  $darkColor;
                        }

                        .adresse_store strong {
                            font-weight: 700;
                        }

                        .adresse_store,
                        .phone_store {
                            line-height: 1.8;
                            letter-spacing: .5px;
                        }

                        .js_have_horaire_store {
                            display: none;
                            margin-top: 0.8em;
                            font-family: $primaryFont;
                            font-weight: 500;
                            font-size: .875rem;
                            letter-spacing: 0;
                            line-height: 1.3;
                            text-transform: none;
                            text-decoration: underline;
                            text-underline-position: under;
                            color:  $darkColor;
                            position: relative;

                            &.actif {
                                display: inline-block;
                            }
                        }
                        .schedule_store {
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: 14 / 16 * 1rem;
                            line-height: 1.3;
                            height: 0;
                            overflow: hidden;
                            margin-top: 0.2em;
                            color:  $darkColor;
                            transition: height 0.3s ease-in;
                            &.actif {
                                height: auto;
                            }
                        }

                        .opening_until {
                            display: none!important;
                            font-weight: 600;
                            color:  $darkColor;
                            font-size: .782rem;
                            position: relative;
                            margin-top: .8em;
                            font-family: $secondaryFont;
                            line-height: 1;
                            padding-left: 1rem;



                            .txt_store_open {
                                color: green;
                                text-transform: uppercase;
                                letter-spacing: .15rem;

                                &:before {
                                    content: "·";
                                    position: absolute;
                                    font-size: 3.5rem;
                                    line-height: 0;
                                    top: 19.5%;
                                    transform: translateY(-50%);
                                    left: 0;
                                    color: #009a00;
                                }
                            }

                            .txt_store_closed {
                                color: red;
                                text-transform: uppercase;
                                letter-spacing: .145rem;

                                 &:before {
                                    content: "·";
                                    position: absolute;
                                    font-size: 3.5rem;
                                    line-height: 0;
                                    top: 19.5%;
                                    transform: translateY(-50%);
                                    left: 0;
                                    color: red;
                                 }
                            }
                        }
                    }
                }

                .js_store_report {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    padding: 0 15px;
                    font-family: $secondaryFont;
                    font-weight: 400;
                    font-size: 1rem;
                    text-align: center;
                }
                &#step1 {
                    .step_content {
                        display: flex;
                        max-width: 90%;
                        padding: 2% 10%;
                        margin: 0 auto;
                        background: #f5f5f5;
                    }

                    .infos {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin: 2rem 0;

                        .form_submit {
                            width: 100%;
                            max-width: initial;

                            .button {
                                max-width: 290px;
                                margin: 2rem auto 0;
                            }
                        }
                    }
                }
                &#step2 {

                    .step_content {
                        margin: 1rem 0 2rem;
                    }
                    .w-input-container.country {
                        display: none;
                    }

                    .w-input-container.adresse {
                        margin: 0;

                        .w-address-input {
                            position: relative;

                            &:after {
                                position: absolute;
                                content: "";
                                background: url('../img/maps/marker.png') no-repeat 50% 50%;
                                background-size: 60% auto;
                                width: 24px;
                                height: 32px;
                                right: .5rem;
                                top: 0;
                                bottom: 0;
                                margin: auto 0;

                                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                    /* IE10+ */
                                    background-size: 58% auto;
                                }
                            }
                        }
                    }

                    .lb-v-scrollbar {
                        width: 7px;
                        right: -3px;

                        &:before {
                            content:"";
                            width: 1px;
                            height: 100%;
                            position: absolute;
                            right: 5px;
                            bottom: 0;
                            background-color:#e6e6e6;
                        }
                    }

                    .lb-v-scrollbar-slider {
                        background: $primaryColor;
                        width: 1px;

                        &:before {
                            content:"";
                            width: 7px;
                            height: 7px;
                            border-radius: 100%;
                            position: absolute;
                            right: -3px;
                            bottom: 0;
                            margin: auto;
                            background-color:$primaryColor;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            background-color: $primaryColor;
                            width: 1px;
                            left: 0;
                            right: 0;
                            margin: auto;

                        }

                    }

                    .lb-v-scrollbar {
                        border: none;
                    }

                    .os-scrollbar.os-scrollbar-vertical {
                        width: 16px;
                        .os-scrollbar-track.os-scrollbar-track-off {
                            width: 1px;
                            background-color: #e6e6e6;

                            .os-scrollbar-handle {
                                width: 1px;
                                margin: 0 auto;
                                left: 0;
                                right: 0;
                                background-color: $black;

                                &:before {
                                    background-color: $black;
                                    border-radius: 50%;
                                    width: 7px;
                                    height: 7px;
                                    top: auto;
                                    left: -3px;
                                    right: auto;
                                }

                                &:after{
                                    content: " ";
                                    display: block;
                                    background: #242424;
                                    width: 1px;
                                    height: 600px;
                                    position: absolute;
                                    left: 50%;
                                    bottom: 0;
                                }
                            }
                        }
                    }

                    .store_locator{
                        height: 265px;
                        width: 691px;
                        overflow-y: auto;
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity 0.3s ease-in;


                        &.loaded {
                            opacity: 1;
                            visibility: visible;
                        }
                        .store {
                            display: flex;
                            align-items: center;
                            padding: 20px 15px 20px 0;
                            margin-right: 2rem;
                            border-top: 1px solid #989898;
                            .store_info {
                                width: 70%;
                                margin-right: 2%;
                            }
                        }
                        .form_submit {
                            flex-wrap: wrap;
                            justify-content: normal;
                            margin-top: 0;
                            width: 240px;
                            height: 50px;

                            button {
                                background-color: #272526;
                                line-height: 50px;
                                span {
                                    color: #FFF;
                                }
                            }

                            &:hover {
                                button {
                                    background-color: $secondaryColor;

                                    span {
                                        color: #272526;
                                    }
                                }
                            }
                            .quantity_store {
                                display: none;
                                margin-top: 0.4em;
                                text-align: center;
                                font-size: 11px;

                                &.actif {
                                    display: block;
                                    color: #33c844;
                                }
                                &.no_stock {
                                    display: block;
                                    color: red;
                                }
                            }
                        }
                    }
                }

                &#step3 {
                    overflow: hidden;

                    #form_eresa {
                        .w-form-line {
                            text-align: center;
                            margin: .5rem auto 1.25rem;
                            align-items: start;

                            &.w-submit {
                               margin: 2.25rem auto 2.75rem;
                            }


                            &.utilisation_condition {
                                margin: 21px 0 0 0;
                                justify-content: flex-start;

                                span {
                                    a {
                                        font-family: Arial;
                                        font-size: .8125rem;
                                    }
                                }

                                .w-input-note {
                                    padding-top: .5rem;
                                    text-align: left !important;
                                }
                            }

                            &.w-submit {
                                .w-submit-button {
                                    height: 50px;
                                }
                            }

                            .w-input-container {
                                .w-tel-input {
                                    .selected-flag {
                                        background-color: $bgColor2;
                                        margin-left: 2px;
                                    }

                                    ~ span.errormsgadd {
                                        display: flex;
                                        text-align: left;
                                        color: $fadeTxt;
                                        font-size: 12px;
                                        font-weight: 400;
                                        margin-top: 2px;
                                    }
                                }

                                .w-input-label {
                                    span {
                                        font-size: 13px;
                                        color: #989898;
                                        font-family: Arial;
                                    }
                                }

                            }

                            .w-checkbox-input {
                                .w-input-element:checked {
                                    &+.w-input-label::before {
                                        box-shadow: inset 0 0 0 0.125em $bgColor2;
                                    }
                                }
                            }

                            .w-input-element:checked+.w-input-label::before {
                                box-shadow: inset 0 0 0 0.125em $bgColor2;
                            }

                            .w-input-label::before {
                                height: 16px;
                                width: 16px;
                                box-shadow: inset 0 0 0 16px $bgColor2;
                            }

                            .checkbox-group .w-input-label::before {
                                width: 28px;
                            }

                            .w-input-note {
                                font-size: .75rem;
                                margin: .0333em 0 .66667em;
                                text-align: left;
                                padding-top: .2rem;
                            }
                            
                            &.optin > label{
                                color: #242424;
                                text-align: center;
                                justify-content: center;
                                font-size: .925rem;
                            }
                            &.optin {
                                margin-bottom: 0;
                                display: flex;

                                label {
                                    font-size: 13px;
                                    font-family: Arial;
                                    color: $darkColor;
                                    letter-spacing: 0;
                                    position: relative;
                                }

                                & > label {
                                    position: relative;
                                    padding-left: 25px;

                                    &:before {
                                        content: 'i';
                                        font-size: 11px;
                                        font-family: "Times New Roman", Times, serif;
                                        width: 18px;
                                        height: 18px;
                                        line-height: 18px;
                                        text-align: center;
                                        background-color: $darkColor;
                                        border-radius: 100%;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto;
                                        color: #FFF;
                                    }

                                }

                                .checkbox-group {
                                    display: flex;
                                    margin-top: 0;

                                    .w-input-label {
                                        span {
                                            font-size: 13px;
                                            font-family: Arial;
                                            color: $darkColor;
                                            letter-spacing: 0;
                                        }
                                    }

                                    .w-input-container {
                                        &:last-child {
                                            margin-left: 35px;
                                        }
                                    }
                                }

                            }
                        }
                        .w-form-line.utilisation_condition .w-radio-group span {
                            display: block;
                        }

                        .infos{
                            color:#989898;
                            max-width: 668px;
                            font-family: Arial;
                            font-style: italic;
                        }
                    }
                }

                &#step4 {

                    .step_content {
                        .confirm {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;

                            .confirm_info {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: $primaryFont;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 13px;
                                letter-spacing: 0.325px;
                                color: #242424;
                                margin-bottom: 35px;
                                width: 100%;
                                text-align: center;
                            }

                            .eresa_recap{
                                display: flex;
                                align-items: flex-start;
                                justify-content: space-between;
                                width: 100%;
                                margin-top: 1.5rem;

                                .eresa_choices{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    width: 46%;
                                    max-width: 275px;

                                    .eresa_prod{
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        margin-bottom: 2rem;

                                        img{
                                            width: 123px;
                                            height: auto;
                                            margin-right: .2rem;
                                        }

                                        .eresa_prod_detail{
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                            justify-content: flex-start;
                                            width: calc(100% - 79px);
                                            padding-top: 0.2rem;
                                            margin-left: .5rem;
                                            font-weight: 500;

                                            .prod_title {
                                                font-family: $primaryFont;
                                                font-size: 13px;
                                                letter-spacing: 0.05em;
                                                line-height: 1;
                                                color:#242424;
                                                margin-bottom: 10px;

                                                .name{
                                                    font-family: $primaryFont;
                                                    font-size: 11px;
                                                    letter-spacing: 0.15em;
                                                    line-height: 1;
                                                    text-transform: none;
                                                    margin-top: 5px;
                                                }
                                            }

                                            .item_price{
                                                display: flex;
                                                flex-direction: row-reverse;
                                                align-items: center;
                                                justify-content: flex-end;
                                                width: 100%;
                                                margin-bottom: 1.5rem;

                                                .pricetag{
                                                    font-family: $primaryFont;
                                                    font-size: 13px;
                                                    line-height: 1;
                                                    color: $darkColor;
                                                    font-weight: 700;
                                                    letter-spacing: 0.15em;

                                                    & + .pricetag{
                                                        font-size: 11px;
                                                        margin-right: 7px;
                                                        color: #989898;
                                                        display: none;

                                                        &:after{
                                                            background: $darkColor;
                                                        }
                                                    }
                                                }
                                            }

                                            .item_taille_color_container{
                                                display: flex;
                                                flex-direction: column;
                                                flex-wrap: nowrap;
                                                .titre_couleur,
                                                .titre_taille{
                                                    font-family: $primaryFont;
                                                    font-size: 12px;
                                                    line-height: 1;
                                                    letter-spacing: 0.01em;
                                                    color: #242424;
                                                    margin: 0.35rem 1rem 0 0;

                                                    strong {
                                                        font-family: $primaryFont;
                                                    }
                                                    span {
                                                        margin-left: 0.5rem;
                                                    }
                                                }

                                                .titre_couleur{
                                                    margin-bottom: 2px;

                                                    strong {
                                                        font-weight: 500;
                                                    }

                                                }

                                                .titre_taille {
                                                    margin-top: 0;

                                                    strong {
                                                        font-weight: 500;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    #js_eresa_store_choice{
                                        .store{
                                            font-size: 0.8em;
                                            .form_submit, .opening_until {
                                                display: none;
                                            }
                                        }
                                    }
                                }

                                .eresa_procedure{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    width:  calc(54% - 1rem);
                                    margin-left: 1rem;

                                    .eresa_procedure_title{
                                        font-family: $primaryFont;
                                        font-size: 11px;
                                        font-weight: 700;
                                        line-height: 1.3;
                                        color: $darkColor;
                                        text-transform: uppercase;
                                        width: 100%;
                                        white-space: normal;
                                        letter-spacing: 0.1em;
                                        margin-bottom: .75rem;
                                    }

                                    .eresa_procedure_content{
                                        font-family: $primaryFont;
                                        font-weight: 500;
                                        font-size: 13px;
                                        line-height: 21px;
                                        color: $darkColor;
                                        margin-bottom: 2rem;
                                        width: 100%;
                                        white-space: normal;
                                        letter-spacing: 0.05em;
                                    }

                                    .form_submit{
                                        margin: 10px 0 0;
                                    }
                                }
                            }
                        }
                        .error {
                            text-align: center;
                            color: #d51f1f;
                        }
                    }

                     .form_submit{
                        max-width: 240px !important;
                        margin: .5rem auto 2.75rem;

                        .button {
                            background-color: #272525 !important;
                            color: #fff !important;

                            &:hover {
                                background-color: $secondaryColor !important;
                                color: #272525 !important;
                            }
                        }
                    }

                    &.actif {
                        .step_content {
                            .confirm {
                                .confirm_info {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    margin: auto;
                                    top: 9rem;
                                }
                            }
                        }
                    }
                }

                .form_submit{
                    max-width: 290px;
                    margin: 2rem auto 0;
                }
            }
        }

        #form_eresa{
            max-width: 675px;
            margin: 0 auto;

            .infos{
                font-family: "Arial";
                font-weight: 400;
                font-size: 11px;
                line-height: 14px;
                color: #242424;
                width: 100%;
            }

            .w-form-line{
                &.optin{
                    display: block;
                    margin-top: 15px;
                    margin-bottom: 8px;

                    > label{
                        display: flex;
                        font-family: $primaryFont;
                        font-weight: 400;
                        font-size: 0.8rem;
                        line-height: 1.3;
                        color: #242424;
                        margin: 0 10px 0 0;
                        position: relative;
                    }

                    .checkbox-group {
                        margin-top: 12px;
                        display: flex;

                        .w-form-line{
                            margin-bottom: 1rem;
                            width: 260px;
                            max-width: 100%;

                            > span {
                                font-family: $primaryFont;
                                font-weight: 400;
                                font-size: .8rem;
                                line-height: 1.3;
                                color: #242424;
                                margin: 0 1.3rem 0 0;

                                &:after {
                                    content: ":";
                                }

                                .w-input-label{
                                    &:before{
                                        border-radius: 0;
                                    }

                                    span {
                                        font-weight: 400;
                                        font-size: 14 / 16 * 1rem;
                                        color: $primaryColor;
                                        white-space: nowrap;
                                    }
                                }
                            }

                            &:first-child {
                                > span {
                                    margin: 0 .75rem 0 0;
                                }
                            }
                        }
                    }
                }

                &.utilisation_condition{
                    justify-content: center;
                    .w-input-label{
                        span{
                            font-family: 'Arial', sans-serif;
                            font-weight: 400;
                            font-size: 13 / 16 * 1rem;
                            line-height: 1.3;
                            color: #242424;
                            margin-left: 15px;
                        }
                    }
                    span {
                        position: relative;
                    }
                    a {
                        font-family: $secondaryFont;
                        font-weight: 400;
                        font-size: .875rem;
                        color: #272525;
                        white-space: nowrap;
                        line-height: 1.3;
                        text-decoration: underline;
                    }

                    .w-radio-input .w-input-element+.w-input-label::before,
                    .w-radio-input .w-input-element+.w-input-label::after {
                        border-radius: 0;
                    }

                    .w-input-note {
                        text-align: center !important;
                    }
                }

                &.w-submit{
                    max-width: 240px;
                    height: 50px;
                    margin: 0 auto;
                }
            }
        }
    }

    &#selection-offer {
        width: 908px;
        height: 860px;
        padding: 65px 75px;
        box-sizing: border-box;

        @media (max-height: 800px) {
            height: 700px;
            padding: 20px;
        }

        .selection-offer-header {
            margin: 1.75rem auto 0;
            display: flex;
            flex-direction: column;

            @media (max-height: 800px) {
                margin: 0 auto;
            }

            .selection-offer-title {
                display: inline;
                font-size: 40px;
                letter-spacing: -0.05em;
                font-family: $secondaryFont;
                font-weight: 600;
                color: $black;
                letter-spacing: .5px;

                @media (max-height: 800px) {
                    margin: 0 auto;
                }

                &+p {
                    font-family: $primaryFont;
                    letter-spacing: 2px;
                    font-weight: 500;
                    font-size: 1.1rem;
                    display: block;

                    span#nb_prod_selection_offerte {
                        font-weight: 500;
                    }
                }
            }
        }

        #selection-offer-content {
            width: 620px;
            max-height: 500px;
            padding: 0;
            margin: 1.5rem auto;

            @media (max-height: 800px) {
                margin: 1rem auto;
            }

            #wrap_lion {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                overflow: auto;

                .js-form-selection-offer {
                    position: relative;
                    width: 44%;
                    padding: 1.5rem;
                    box-sizing: border-box;
                    border: 1px solid #ddd;
                    margin: 1rem auto;
                    opacity: 1;
                    padding: 0;

                    @media (max-height: 800px) {
                        min-height: 455px;
                    }

                    input {
                        &+div {
                            overflow: hidden;
                            padding: 1.5rem;
                            cursor: pointer;
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                        }
                    }


                    p.titleOfferedProd {
                        font-size: 12px;
                        letter-spacing: 0.1em;
                        font-family: $primaryFont;
                        font-weight: 400;
                        margin: 1rem;
                        opacity: .5;
                        position: absolute;
                        bottom: 20%;

                        span {
                            font-size: 12px;
                            letter-spacing: 0.1em;
                            font-family: $primaryFont;
                            font-weight: 400;
                        }
                    }

                    .color_list, .size_list {

                        height: 38px;
                        padding-top: 2px;
                        opacity: .5;


                        .prevColour,
                        .nextColour,
                        .prevSize,
                        .nextSize {
                            background-color: $bgColor2;
                            width: 32px;
                            height: 32px;

                            &:before {
                                content:'';
                                border-width: 0 !important;
                                background: $bgColor2 url(../svg/ariane.svg) center center no-repeat;
                                background-size: contain;
                                width: 14px;
                                height: 14px;
                                transform: none;
                                top: 10px;
                                box-sizing: border-box;

                            }
                        }

                        .prevColour,
                        .prevSize {
                            &:before {
                                background: $bgColor2 url(../svg/prev_btn.svg) center center no-repeat;
                                background-size: contain;
                            }
                        }

                        width: 100%;

                        .swiper-wrapper {
                            .form_itm {
                                margin: 0 5px 0 0;
                                width: 32px;
                                height: 32px;
                                border-radius: 100%;
                                position: relative;

                                input[type=radio]{
                                    &+label {
                                        box-shadow: inherit !important;
                                        width: 12px;
                                        height: 12px;
                                        position: relative;
                                        text-align: center;

                                        &:before {
                                            content:'';
                                            position: absolute;
                                            top: -12px;
                                            left: -12px;
                                            border: 1px solid transparent;
                                            width: 32px;
                                            height: 32px;
                                            display: block !important;
                                            background-color: transparent;
                                        }

                                        &:hover {
                                            font-weight: 400;
                                        }
                                    }

                                    &:checked {
                                        &+label {
                                            font-weight: 400;
                                            &:before {
                                                border-color: #ccc;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.noSlides {

                            .swiper-wrapper {
                                justify-content: center;

                                .form_itm {
                                    margin: 0 5px 0 0;
                                }

                            }

                            .prevColour,
                            .nextColour,
                            .prevSize,
                            .nextSize {
                                display: none;
                            }

                        }
                    }

                    .size_list {
                        .swiper-wrapper {
                            .form_itm {
                                input[type=radio]+label{

                                    span {

                                    }

                                    &:before {
                                        left: -7px;
                                    }
                                }
                            }
                        }
                    }

                    &.actif, &:hover {
                        border-color: #333;

                        .titleOfferedProd,
                        .color_list, .size_list {
                            opacity: 1;
                        }
                    }

                    .color_list {
                        position: absolute;
                        bottom: 13%;
                        z-index: -1;
                    }
                }
            }
        }

        #errorLabel {
            height: auto;
            margin-bottom: .5rem;
        }

        .selection-offer-submit {
            width: 100%;
            max-width: 230px;
            margin: auto;

            button {
                width: 100%;
                max-width: 230px;
            }
        }
    }

    /* CART SIZE INFO */
    &#cartSizeInfoLightbox {
        width: 658px;
        height: 340px;
        padding: 4rem 2.5rem;
        
        /* On change le display en flex pour faciliter l'affichage */

        .content {
            text-align: center;
        }

        .close_pop {
            background-image: url('../img/close.png');
            background-size: auto;
            background-repeat: no-repeat;
            cursor: pointer;
        }

        p.title {
            font-size: 16px;
            font-family: $secondaryFont;
            font-size: 1.1rem;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        p.doubt_size_txt {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: none;
            margin-bottom: 15px;
            color: black;

            a.address {
                font-style: normal;
                font-family: $primaryFont;
                font-weight: 900;
            }
        }

        .form_submit {
            max-width: 250px;
            margin: 20px auto 0;

            .button.cartSizeInfoBtn {
                background-color: black;
                color: white;
                &+.loader {
                    &:before {
                        background-color: white !important;
                    }
                    span {
                        color: white !important;
                    }
                } 
            }
        }
    }
}
/*lookbook guide taille pop up*/
.category{
    #content_achat_express{
    .lightbox#sizeguideBox{
        width:100%;
        max-width: 900px;
        height: 630px;
            .tablesWrapper{
                .guide_wrapper{
                    width: 100%;
                    max-width:834px;
                    height:310px;

                    .sizeguide_content{
                        .right_side_content{
                            margin-left: 7vw;
                        }
                    }
                }
            }
        }
    }
}

/* Code promo page panier */
.cart.step_1 #modboxpromo {

    .close_modal {
        -webkit-mask-position: 66.33333% 66.33333%;
        mask-position: 66.33333% 66.33333%;
        width: 10px;
        height: 18px;
    }
}

#modbox {
    .popup_title {
        font-weight: bold;
        font-size: 1.75rem;
        font-family: $primaryFont;
    }
}

/*Category wishlist pop up*/
.category {
    #abox.box_wishlist {
        height: 295px;

        &.connected {
            height: 119px;
        }
    }
}