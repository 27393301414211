body.body_login {

	#copyright {
		margin-top: 120px;
	}

	.main_wrapper {
		width: 100%;

		& > div {
			padding: 0;
			max-width: 100%;
		}

		.wrapper_bandeau_connexion {
			display: none;
		}

		#popup_savoir_plus {

			#title_popup_savoir_plus {
				font-family: $primaryFont;
			}

		}

		.bandeau_container {
			display: none;
		}

		.newlogin_page {
			padding-top: 0;
			margin-bottom: 450px;

			.wrapper_bandeau_connexion {
				display: block;
				width: 100%;

				img {
					position: absolute;
    				width: 1920px;
					height: auto;
    				z-index: 0;
					max-width: 100%;
				}
			}

			.login_wrapper {
				background-color: $white;
				width: 972px;
				text-align: center;
				height: 850px;
				border-bottom-left-radius: 100% 100%;
				border-bottom-right-radius: 100% 100%;
				position: absolute;
				top: 20%;
				left: 50%;
				-webkit-transform: translatex(-50%);
				transform: translatex(-50%);
				z-index: 1;
				padding-top: 11px;

				@include ie() {
					width: 950px;
				}

				@media screen and (max-width: 1440px){
					top: 22%;
				}

				@media screen and (max-width: 1366px){
					top: 19%;
				}

				@media screen and (max-width: 1199px){
					width: 76%;
					height: 780px;
				}
				@media screen and (max-width: 1024px){
					top: 22%;
					width: calc(100% - 64px);
					height: auto;
					min-height: 730px;
				}

				.login_bloc {
					display: block;
					width: 100%;
					height: auto;

					&:first-of-type {
						border: none;
					}

					&:last-of-type {
						padding-top: 1.5rem;

						.subtitle {
							display: none;
						}
					}

					.title {

						&.my_account {

							h2 {
								font-family: $secondaryFont;
								font-size: 65px;
								text-transform: none;
								letter-spacing: 0;
								margin-bottom: 0;

								@media only screen and (max-width: 1680px) {
								        font-size: 60px;
								}
								@media only screen and (max-width: 1520px) {
					                    font-size: 55px;
					            }
								@media only screen and (max-width: 1370px) {
								    font-size: 50px;
								}
							}

							p.subtitle {
								font-family: $primaryFont;
								font-size: 1.1rem;
								text-transform: uppercase;
								letter-spacing: 2px;
								display: block;

								@media only screen and (max-width: 1680px) {
								        font-size: .95rem;
								}


								@media only screen and (max-width: 1370px) {
								    font-size: .85rem;
								}
							}
						}

						&:not(.my_account) {
							margin-bottom: 1rem;
						}

						h4 {
							font-family: $primaryFont;
							font-size: .96rem;
							letter-spacing: 1px;
							font-weight: bold;
							margin-bottom: 1.5rem;

							@media only screen and (max-width: 1680px) {
							        font-size: .875rem;
							}


							@media only screen and (max-width: 1370px) {
							    font-size: .75rem;
							}
						}
					}

					.w-oneclick-connect {
						justify-content: center;

						.w-social-btn {
							width: 2.315rem;
							height: 2.315rem;

							&+.w-social-btn {
								margin-left: 0;
							}

							&.fb-connect {
								order: 2;
								margin-left: 1.25rem;
							}
						}

                        #googleConnect {
                            width: 2.315rem;
                            height: 2.315rem;
                            margin: 0;
                            background: $reassuBg;

                            .g_id_signin {
                                div:first-child {
                                    div:first-child {
                                        div:first-child {
                                            width: 2.315rem;
                                            height: 2.315rem;
                                        }
                                    }
                                }

                                iframe[id^="gsi"] {
                                    height: 2.315rem !important;
                                    width: 2.315rem !important;
                                    margin: 0 !important;
                                }
                            }
                        }
                    }

					.login_form_wrapper {
						margin: 0 auto;

						.title {

							@media screen and (max-width: 1199px){
								max-width: 70%;
							}

							h3 {
								text-transform: uppercase;
								margin: 2.2rem 0 1.2rem;
								letter-spacing: 1.5px;
								font-size: 1rem;
								font-family: $primaryFont;
								font-weight: bold;

								@media only screen and (max-width: 1680px) {
								        font-size: .95rem;
								}


								@media only screen and (max-width: 1370px) {
								    font-size: .85rem;
								}
							}

							p.subtitle {
								font-size: .8rem;
								letter-spacing: .5px;
								font-weight: 500;
							}

							&:not(.my_account) {
								margin: 0 auto 1rem;
							}
						}

						.w-newlogin-form, .w-lostpass-form {
							width: 60%;
							position: relative;
							margin: 0 auto;
							border-bottom: 1px solid $grey;
							padding-bottom: 5px;

							.deja_compte {
								margin: 1rem 0 1.5rem;
								line-height: 1.5;
							}

							input[type=email].w-input-element {
								border-color: $grey;
							}

							.w-fieldset-label {
								font-family: $primaryFont;
								font-size: .8rem;
								letter-spacing: .5px;
								font-weight: 500;
							}

							.w-lines-group .optin_container.column {
								flex-direction: row;
								max-width: 390px;
								margin: 0 auto;

								.w-form-line {

									.w-line-label {
										color: $black;
										font-size: 1rem;
										font-family: $primaryFont;
										font-size: .8rem;
										letter-spacing: .5px;
										font-weight: 500;
										min-width: 70px;

										@media only screen and (max-width: 1680px) {
										        font-size: .95rem;
										}


										@media only screen and (max-width: 1370px) {
										    font-size: .85rem;
										}
									}

									.w-radio-input+.w-radio-input {
										margin-left: 1rem;
									}

								}

								.knowMoreOptin {
									position: absolute;
									bottom: -2.5rem;

									@include ie() {
										transform: translateX(-50%);
										left: 50%;
									}

									a {
										font-size: .8rem;
										letter-spacing: 1px;
										font-weight: 500;
										color: $black;
									}
								}
							}

							.w-submit {
								margin-top: 1rem;
								margin-bottom: 1rem;

								.w-submit-button {
									max-width: 300px;

									span {
										font-size: .8rem;
										letter-spacing: 1px;
										font-weight: 500;
									}
								}
							}

							.w-pwd-reset {
								font-size: .8rem;
								letter-spacing: 1px;
								font-weight: 500;
								color: $black;
							}

							p.mdpSentEmail {
								font-size: .8rem;
								letter-spacing: .5px;
								font-weight: 500;
							}

							.deja_compte {
								line-height: 1.8;
								margin-bottom: 1rem;
								font-size: .7rem;
							}
						}
					}

				}
			}

		}
	}

	//Recettage titre pwdfgt
	.nav_moncompte{
		.title{
			display: block!important;
			text-transform: uppercase;
			margin: 50px 0 1.2rem;
			letter-spacing: 1.5px;
			font-size: 1rem;
			font-family: $primaryFont;
			font-weight: bold;
			text-align: center;
		}
	}

	.wrapper_password{
		width: 100%;
		max-width: 350px;
		margin: 0 auto;
	}
}
body.customer{
	#site_global_wrap > div{
		max-width: 100%;
	}

	&.body_login .main_wrapper .newlogin_page .login_wrapper .login_bloc .login_form_wrapper #js-signUpForm.w-newlogin-form .deja_compte {
		line-height: 1.5;
	}
}

body.customer,
body.newsletter{
	form button{
	    height: 3.1rem !important;
	    &.w-visibility-toggle,
	    &.selected-flag {
	    	height: 1.5rem !important;
	    }
	}
	.form_submit{
		.button{
		    height: 3.18rem !important;

			&.secondary{
				span{
					font-weight: 600;
					font-size: 0.85rem;
					color: lighten($black, 30%);
				}
			}
		}
	}
}
body.customer,
body.newsletter{
	.right-container{
		display: none !important;
	}
}
.wrapper_moncompte {
	// Mes informations
	.nav_moncompte{
		h1.title{
			display: none;
		}
		h2.account_name{
			margin-top: 1rem;
			width: 100%;
			text-align: center;
			font-weight: 700;
			span{
				font-family: $secondaryFont;
			    font-size: 2.65rem;
			    font-weight: 700;
			    letter-spacing: -1.6px;


			    @media only screen and (max-width: 1680px) {
				        font-size: 2rem;
				}


				@media only screen and (max-width: 1370px) {
				    font-size: 1.75rem;
				}
			}
		}
	}
    .left-container{
		display: flex;
	    flex-direction: column;
	    align-items: flex-start;
	    justify-content: flex-start;
        width: 100%;
        background-color: $bgColor;
		margin-bottom: 10rem;
		@media screen and (max-width: 1317px){
			padding: 0 3%;
		}
    }
    .right-container{
    	display: none;
    }
    .wrapper_menu_compte {
		display: flex;
		justify-content: space-between;
		width: 80.1rem;
		max-width: 100%;
		margin: 0 auto;
		margin: 1.55rem auto 3.5rem;
		justify-content: center;
    	.item {
    		a{

				font-size: 0.75rem;
		    	font-weight: 600;
				color: $textForm;
		    	text-decoration: none;
				letter-spacing: 1px;
				text-transform: capitalize;
			    margin: 0 1.45rem;

			    @media only screen and (max-width: 1024px) {
			    	font-size: 0.65rem;
			    	margin: 0 1rem;
			    	letter-spacing: 0;
			    }

    		}

    		&.actif a {
				border-bottom: 1px solid #a09fa0;
				padding-bottom: .1rem;
		    	font-weight: 700;
    		}
    	}
	}
	.wrapper_moncompte_main {
		width: 100%;
		margin: 0 auto;
		div.intro{
			text-align: center;
			font-weight: 600;
			font-size: 0.75rem;
			letter-spacing: 1px;
		}
		.form_submit{
			margin: 2.4rem 0 2.5rem;
		}
		.wrapper_form{
			max-width: 80.1rem;
			margin: 0 auto;
			form{
				padding-bottom: 9rem;
				.w-form-line{
					label{
						input{
							border: 1px solid $grey !important;
    						border-radius: 0;
						    font-size: 0.825rem;
    						color: black;
    						font-family: $primaryFont;
						}
						p{
							&:before{
								border-color: $grey;
							    width: 1.15rem;
							    height: 1.15rem;
							}
							span{
							    font-size: 0.825rem;
								color: grey;
								font-family: $primaryFont;
							}
						}
					}
					&:first-of-type p span{
						font-weight: 500;
					}

					.w-radio-group{
						label p span{
							font-family: $avenirFont;
						    font-size: .84rem!important;
						    letter-spacing: -0.5px;
						    font-weight: 500;
						    color: darken($grey, 50%);
						}
					}

                    &.w-has-tel .errormsgadd {
                        font-size: 12px;
                        font-weight: 400px;
                        color: $fadeTxt;
                    }
                }

                .w-input.w-tel-input .selected-flag {
                    background-color: transparent;
                }

                p.w-input-label span {
                    font-size: 0.7rem !important;
                }
			}
		}
		&.wrapper_content_mes_infos {
		    margin-top: 2rem;
			form[name="login"] {
				.w-form-line.w-submit {
					margin-top: 3rem;
				}
			}
			.mes_info {
				.checkbox_container {
					margin: 0 0 1.2rem 0;
					.customChecker {
						span{
							text-transform: uppercase;
							font-size: 10px;
							font-weight: 400;
							letter-spacing: 1px;
							line-height: 16px;
						}
					}
				}
				.customChecker.changeSubscribe{display: none;}
				.formRow:nth-child(4) {
					.img_error,
					.valid_form{
						right: -2rem;
					}
				}
			}
			.w-input-container input{
			    height: 2.95rem;
			}
		}
	}
	form .w-form-line.w-submit{
		max-width: 100%;
		width: 100% !important;
		display: flex;
		justify-content: flex-start;
		.form_btn{
		    margin: 0 auto;
		    margin-top: 1.5rem;
			position: relative;
		}
		.form_btn,
		.form_btn button{
			width: 100%;
	        max-width: 18.55rem;
		    @media screen and (min-width:0\0) and (min-resolution: 72dpi) {
		    	margin: 0;
			}
		    button + button:nth-child(2n+1){
		    	display: none;
		    }
		    span{
		    	font-size: 0.83rem;
			    font-weight: 500;
			    letter-spacing: 1.5px;
		    }
		}
	}
	p.update_success{
	    margin-top: -1rem;
	    margin-bottom: 1rem;
	    margin-left: 1.5rem;
	    color: green;
	    max-width: 80.1rem;
	    width: 100%;
    	margin: 0 auto 1rem;
	}

	//Adresse
	.wrapper_mes_adresses{
		width: 100%;
		max-width: 85.3rem;
	    margin: 0 auto;
        margin-top: 1.7rem;
        transition: padding 0.2s ease-in-out;
        @media screen and (max-width: 1440px){
        	padding-left: 2%;
        	padding-right: 2%;
        }
        @media screen and (max-width: 1366px){
        	padding-left: 3%;
        	padding-right: 3%;
        }
		.conteneur_adresse{
			text-align: center;
			display: flex;
			flex-wrap: wrap;
			margin-top: 1.45rem;
			justify-content: flex-start;
			.adresse{
				background-color: $white;
				width: 27.3%;
				font-family: $primaryFont;
				font-size: 0.75rem;
				letter-spacing: 1px;
				line-height: 1.37rem;
				font-weight: 600;
				padding: 2.8% 0 2.3%;
				margin-top: 5%;
				margin-left: 3%;
				margin-right: 3%;

				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					margin-top: 0;
				}
				.addr_label{
					text-transform: uppercase;
				    font-size: 0.969rem;
				    font-weight: 600;
				    letter-spacing: 1.5px;
    				margin-bottom: 0.3rem;
				}
				.addr_titre{
					font-family: $secondaryFont;
					font-size: 1.9rem;
				    font-weight: 700;
				    text-transform: initial;
				    letter-spacing: 0;
				    margin-bottom: 0.6rem;
				}
				.addr_type{
				    padding: 0 0 2.5%;
				}
				.infos{
					.addr_line{

					}
				}
			}
			.modlinks_wrapper{
			    margin-top: 1.3rem;
				a{
					font-weight: 600;
					text-decoration: underline;
				}
			}
		}
		.form_submit{
			width: 100%;
			margin-top: 5rem;
			margin-bottom: 6rem;
			a.button{
				width: 100%;
				max-width: 18.8rem;
				background-color: $bgColor;
				margin: 0 auto;
			    border: 1px solid grey;
			    color: black;
			    font-weight: 600;
			    font-size: 0.83rem;
			    letter-spacing: 1px;
			}
		}
		#adresseForm{
			.choix_type_adresse{
				display: flex;
			    margin-bottom: 2rem;
				.type_adresse{
					padding-left: 1.745rem;
					text-transform: uppercase;
				    font-size: 0.765rem;
				    font-weight: 500;
				    letter-spacing: 1.5px;
				    &.actif{
				    	font-weight: 700;
				    }
				    &:first-child{
				    	padding: 0;
				    }
				}
			}
			.addrCivility{
				.w-radio-input .w-input-element+.w-input-label::before{
				    width: 1rem;
				    height: 1rem;
				}
				.w-checkbox-input .w-input-element:checked+.w-input-label::before,
				.w-radio-input .w-input-element:checked+.w-input-label::before {
				    box-shadow: inset 0 0 0 0.2em #fff;
				}
				p span{
					color: $black;
				    font-size: 0.85rem;
				    font-weight: 400;
				}
			}
			input{
				border: 1px solid darken($white, 35%);
				border-radius: 0;
			}
			legend.w-group-label{
				font-family: $primaryFont;
				font-weight: 600;
			    font-size: .8rem;
    			line-height: 1.3;
			}
			button.selected-flag{
				background-color: $bgColor;
				left: 2px;
			}
			img.valid_form,
			img.img_error {
			    top: 50%;
			}
			a.noaddr{
				top: 45%;
			}
			.w-input-container{
				label.w-dropdown{
				    height: 3.1rem;
				}
			}
		}
	}
	// Commande
	.wrapper_mes_commandes{
		width: 80.1rem;
		max-width: 100%;
		margin: 0 auto;
		.wrapper_menu_tab {

			.filtres_commandes {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				p.intro {
					width: 100%;
					font-family: $primaryFont;
					font-size: 0.8rem;
					letter-spacing: 1px;
					color: lighten($black,30%);
					line-height: 1.9;
					font-weight: 600;
					margin-bottom: 2.2rem;
					text-align: center;
				}

				.filter_select {
					width: 21.65rem;
					height: 3.2rem;
					border: 1px solid $black;
					position: relative;
					margin-bottom: 1.5rem;

					select {
						position: relative;
						z-index: 2;
						font-family: $primaryFont;
						text-transform: uppercase;
						font-weight: 600;
						font-size: 0.85rem;
						letter-spacing: 1px;
						background: transparent;
						text-indent: 1.55rem;
						-webkit-appearance: none;
						-moz-appearance: none;
						&::-ms-expand {
							display: none;
						}
					}

					&:after {
						content: "";
						width: 23px;
						height: 23px;
						background-image: url('../img/arrow_commande.jpg');
						background-size: 100%;
						background-repeat: no-repeat;
						background-position: center;
						position: absolute;
						right: 7%;
						top: 50%;
						z-index: 1;
						transform: translateY(-50%);
					}
				}
			}
		}
		#orders_by_type{
			margin-top: 2.4rem;
			table{
				thead tr{
					background: $greenbg;
				    height: 2.8rem;
				    th{
				    	color: white;
					    text-transform: uppercase;
					    font-weight: 600;
					    letter-spacing: 1.1px;
					    font-size: 0.75rem;
    					text-align: left;
    					text-indent: 0.75rem;
    					&.montant{
    						text-align: center;
    					}

    					@media only screen and (max-width: 1024px) {
    						font-size: 0.65rem;
    						letter-spacing: 0px;
    					}
				    }
				}
				tbody{
					tr.rollover{
					    height: 5.4rem;
					    td{
							text-indent: 0;
							padding-left: 0.75rem;
					    	height: 5.4rem;
					    	&:first-child{
					    		font-weight: 700;
					    	}

							&:nth-child(1),
							&:nth-child(2),
							&:nth-child(3),
							&:nth-child(6){
								padding-left: 0;

								span,
								a{
									text-align: center;
								}
							}

						    span{
						    	display: block;
						    	height: 5.4rem;
    							padding-top: 1.4rem;

								@media only screen and (max-width: 1024px) {
									font-size: 11px;
								}
						    }
						    p.price_tag{
						    	height: 5.4rem;
    							padding-top: 1.4rem;
    							text-align: center;
						    	span{
							    	display: inline;
							    	height: auto;
    								text-align: center;
							    }
						    }
							.facture-bloc + .facture-bloc{
								margin-top: 0.5rem;
							}
						    a.bill,
						    .link_detail,
							.refund{
						    	text-decoration: underline;
						    }
						    a.btn_detail{
						    	display: block;
						    	width: 100%;
						    	font-weight: 500;
						    	text-decoration: underline;

						    	&:first-letter{
						    		text-transform: uppercase;
						    	}
						    }
							&.last.link_detail{
								a.btn_detail{
									padding-right: 0.75rem;
									text-align: right;
								}
							}
						    &.etat{
						    	text-indent: 0;
					    	    padding-left: 0.75rem;
					    	    font-weight: 700;
						    }
					    }
					}
				}
			}
		}
		.filtres_commandes{
			.filter_select{
				text-align: center;
				display: inline-block;
			}
		}
		.com_onglet{
			display: flex;
			align-items: center;
	        margin-left: 6.08%;

			#com_onglet{
				display: flex;
				li{
				    padding-right: 1.9rem;
					span{
						font-weight: 700;
						letter-spacing: 1px;
					}
				}
			}
		}
		table.table_mon_compte{
			tbody:nth-child(2n+1){
				tr{
					background: white;
					& + tr{
						height: 0;
						td .message_detail{
							padding-bottom: 2rem;
						}
					}
				}
			}
			td.nothing{
				font-weight: 700;
    			letter-spacing: 1px;
    		    font-size: 12px;
    		 	padding-top: 1rem;

    		 	@media only screen and (max-width: 1680px) {
				        font-size: 11px;
				}


				@media only screen and (max-width: 1370px) {
				    font-size: 10px;
				}
			}
		}
	}
	.wrapper_moncompte_main{
		width: 1430px;
		max-width: 100%;
		padding: 0 32px;

		.wrapper_left{
			.wrapper_infos_cmd,
			.wrapper_delivery_addr,
			.cmd_items{
				width: 	100%;
				background: $white;
				padding: 1.1%;
				p{
					font-family: $primaryFont;
					font-size: 0.8rem;
					text-align: center;
					font-weight: 600;
					span{
						font-weight: 500;
					}
				}
				.cmd_txt{
					font-family: $secondaryFont;
					font-size: 2rem;

					@media only screen and (max-width: 1680px) {
					        font-size: 1.5rem;
					}


					@media only screen and (max-width: 1370px) {
					    font-size: 1.65rem;
					}
				}
				.cmd_number{
				    font-size: .97rem;
				    font-weight: 600;
				    letter-spacing: 1.5px;
				    margin-top: 0.29rem;
				    margin-bottom: 1.1rem;
				}
				.cmd_status{
					margin-top: 0.15rem;
				}
			}
		}
	}

	//Commande detail
	.wrapper_content_cmd_detail{
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
	    margin-bottom: 7.5rem;
		padding: 0 1.5rem;
		.detailCmdTop{
		    width: 100%;
		    padding: 1.25rem 0 2.35rem;
		    font-weight: 600;
		    letter-spacing: 1px;
		    a:before{
		    	content: "";
		    	display: inline-block;
		    	height: 13px;
		    	width: 71px;
		    	background-image: url('../img/arrow_ariane.jpg');
		    	background-repeat: no-repeat;
		    	background-size: 100%;
		    	background-position: center;
		    	margin-right: 0.65rem;
			    position: relative;
			    top: 1px;
		    }
		}
		.wrapper_left{
		    width: 60%;

			@include queriesWshop(1400px, max) {
				width: 65%;
			}
			#commande.order_detail_head{
	    		margin: 0 auto;
			    display: flex;
			    justify-content: space-between;
		        margin-bottom: 5rem;
			}
			.wrapper_infos_cmd{
			    min-height: 11.6rem;
			    max-width: 23.25rem;
				width: 45%;

				.cmd_txt{
				    margin-top: 0.5rem;
				    font-size: 1.9rem;

				    @media only screen and (max-width: 1680px) {
					        font-size: 1.75rem;
					}


					@media only screen and (max-width: 1370px) {
					    font-size: 1.65rem;
					}
				}
				.cmd_date{
	    			margin-bottom: 0.4rem;
				}
				.num_suivi{
					margin-top: 0.3rem;
				}
			}
			.wrapper_delivery_addr{
			    min-height: 11.8rem;
				width: 45%;

			    .addr_title{
				    margin-top: 1rem;
				    font-size: 1.9rem;
				    font-family: $secondaryFont;

				    @media only screen and (max-width: 1680px) {
					        font-size: 1.75rem;
					}


					@media only screen and (max-width: 1370px) {
					    font-size: 1.65rem;
					}
			    }
			    .addr_title_2{
				    font-size: .97rem;
				    font-weight: 600;
				    letter-spacing: 1.5px;
				    margin-top: .29rem;
				    margin-bottom: 1.1rem;
				    text-transform: uppercase;
			    }
			    .addr_name{
				    font-size: .8rem;
				    text-align: center;
				    font-weight: 600;
			    }
			    .addr_address{
    				margin: 0.35rem 0;
			    }
			}
			div.cmd_items{
				width: 100%;
			    padding: 0;
			    background: transparent;

			    .product-link{
					width: 14.05rem;
					margin: 0 4rem 2rem 0;
    				float: left;

					&:nth-of-type(3n) {
						margin-right: 0;
					}

					@include queriesWshop(1400px, max) {
						margin-right: 2rem;
					}

					@include queriesWshop(1200px, max) {
						margin-right: 4rem;

						&:nth-of-type(3n) {
							margin-right: 4rem;
						}

						&:nth-of-type(2n) {
							margin-right: 0;
						}
					}

					.cart_thumb_holder{
						img{
						    width: 100%;
						    height: auto;
						}
					}
					.cart_detail_box{
						font-family: $primaryFont;
						font-weight: 600;
						text-align: left;
						letter-spacing: 1px;
						p,
						span{
							font-family: $primaryFont;
							font-weight: 600;
							text-align: left;
						}
						.cart_product{
							margin-top: 0.65rem;
							.sstitre{
								display: inline;
							}
							.sub_title{
								display: inline;
							}
							.wrapper_price{
							    margin-top: 0.9rem;
    							margin-bottom: 1.359rem;
							}
							.sub_couleur{
							    margin-top: 0.2rem;
							    margin-bottom: 0.2rem;
							}
						}

                        .cart_donation {
                            .sub_qte {
                                display: none;
                            }
                        }
					}
			    }
			}
			table.resume_commande {
				tr{
					td{
						display: none;
						&.ss_total_label,
						&.last.ss_total_value{
							display: block;
							font-size: .8rem;
						    font-weight: 500;
						    font-family: $primaryFont;
						    letter-spacing: .5px;
						    padding-left: 20px;
						    margin-top: .72rem;
						}
						&.last.ss_total_value{
							font-weight: 600;
						    font-size: .75rem;
					        margin-top: 0;
						    .price_tag{
						    	margin-top: 0.4rem;
						    }
						}
					}
					&.trait{
						td.img_services{
							display: block;
						    max-width: 222px;
    						width: 100%;
						}
					}
				}
			}
		}
		.wrapper_right{
			width: 40%;

			@include queriesWshop(1400px, max) {
				width: 35%;
			}
			.cmd_detail_btm{
				.wrap_total_commande{
					background-color: $white;
				    padding: 1% 1% 3.5%;
				    width: 100%;
				    max-width: 23.4rem;
				    margin-left: 13.5%;
				    p.titre{
				    	font-family: $secondaryFont;
					    margin-top: 1.05rem;
					    font-size: 1.9rem;
					    display: block;
					    text-align: center;
						font-weight: 700;

						@media only screen and (max-width: 1680px) {
						        font-size: 1.75rem;
						}


						@media only screen and (max-width: 1370px) {
						    font-size: 1.65rem;
						}

					    span{
					    	display: block;
				    		font-family: $primaryFont;
						    line-height: 1.8;
						    font-size: 1rem;
				    		text-transform: uppercase;
				    		font-weight: 600;

				    		@media only screen and (max-width: 1680px) {
							        font-size: .95rem;
							}


							@media only screen and (max-width: 1370px) {
							    font-size: .85rem;
							}
					    }
				    }
				    .wrapper_total{
				    	padding-left: 2.18rem;
    					padding-right: 2.18rem;
    					margin-top: 0.75rem;
				    	div.clearfix{
						    font-size: .78rem;
						    font-weight: 600;
						    letter-spacing: 1.5px;
						    margin-top: 0.29rem;
    						margin-bottom: 0.7rem;
						    display: flex;
						    label{
						    	width: 60%;
						    }
						    .montants{
						    	width: 40%;
						    	text-align: right;
						    	p{
						    		text-align: right;
						    	}
						    }
						    &:last-of-type{
						    	position: relative;
    							margin-top: 1.3rem;
						    	&:before{
						    		content: "";
						    		width: 100%;
						    		height: 1px;
						    		background-color: $black;
						    		position: absolute;
						    		top: 0;
						    		left: 0;
						    	}
						    	label,
						    	.montants{
    								margin-top: 1.45rem;
								    font-size: 0.8rem;
								    font-weight: 500;
								    font-family: $primaryFont;
							    }
						    }
				    	}
				    }
				}
				.wrapper_bottom_cmd{
				    width: 100%;
				    max-width: 23.4rem;
					margin-left: 13.5%;
					text-align: center;

					p {
				    	font-weight: 600;
					    line-height: 2;
					    margin-top: 1.51rem;
					    margin-bottom: 1.65rem;
					    color: lighten($black, 20%);

						&.titre {
							font-weight: bold;
							margin-top: 2rem;
							margin-bottom: 0;
							font-size: .8rem;
						}

						&.expiredRetractation {
							margin-top: 0.5rem;
						}

						&.intro {
							width: 80%;
							margin: 1.51rem auto 1.65rem;
						}
				    }

					.content a {
						text-decoration: underline;
						font-weight: 600;
					}

					.refund_not_free {
						margin-top: 1rem;
						font-size: .8rem;
						font-weight: 600;
						line-height: 1.5;
					}

				    #liste_motif_retour{
					    width: 100%;
					    max-width: 18.82rem;
					    margin: 0 auto;
				    	.btn_container a{
						    background-color: transparent;
						    border: 1px solid $black;
						    color: $black;
						    letter-spacing: 1px;
						    font-weight: 600;
    						height: 51px;
    						transition:
    							background-color 0.2s ease-in-out,
    							color 0.2s ease-in-out;
    						&:hover{
    							background-color: $black;
    							color: $white;
    						}
							span {
								font-weight: 600;
							}
				    	}
				    }

					#comment_retourner_cmd {

						#printPdf {

							.content {
								margin: 1.5rem auto;
								max-width: 80%;
								font-size: .8rem;
								font-weight: 600;
								line-height: 1.8;
							}

							.retour_numero {
								display: none;
							}

							#btnPrintPdf {
								margin: 0 auto;
								flex-direction: column;
								align-items: center;

								.loader.loading {
									background: url(../svg/three_dots.svg) $black no-repeat center center;
									background-size: 15%;
									transition: inherit;
								}

								.btn_container {
									margin-bottom: 1rem;

									a.button.dark {
										border: 1px solid $black;
										background: transparent;
										margin-left: 0 !important;

										span {
											color: $black;
											font-weight: 600;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	// Message
	.wrapper_moncompte_main.content_messages{
	    width: 100%;
        margin: 1.2rem auto 5.4rem;

		.form_submit{
			a.button{
			    width: 18.75rem;
			    margin: 0 auto;
				span{
				    font-size: 0.84rem;
				    letter-spacing: 1.6px;
				    font-weight: 500;
				    color: $white;
				}
			}
		}
		.wrapper_message{
			thead tr{
				background-color: $tertiaryColor;
				height: 2.8rem;
				td{
					font-weight: 600;
				    font-size: .75rem;
				    letter-spacing: 1px;
					color: $white;
					text-transform: uppercase;
				}
			}
			tbody{
				tr{
					td{
						font-family: $primaryFont;
						font-weight: 500;
						&.enveloppe{
							padding-left: 1rem;
						}
					}
					.btn_ouvrir_message{
						padding-right: 1rem;
						text-decoration: underline;
						cursor: pointer;
					}
					&.unread td{
						font-weight: 600;
					}
					&:first-child{
						height: 3.8rem;
					}
					.wrapper_message_detail{
						.message_detail{
	    					padding-bottom: 2rem;

							.w-reply-form {
								display: flex;
								flex-direction: column;

								div[id^=msg_conf] {
									color: green;
									order: 3;
    								text-align: center;
								}

								.w-form-line {
									order: 0;

									&.w-submit {
										order: 4;
									}
								}

								.file-input-row {
									order: 1;
								}

								.file-list {
									order: 2;
									.rowList {
										position: relative;
										line-height: 2;
										display: inline !important;

										.uploadedFile {
											display: inline !important;
										}

										.closePj {
											position: absolute;
											background: url('../img/close.png') no-repeat center center;
											top: .2rem;
											right: -1.5rem;
											width: 9px;
											height: 9px !important;
										}
									}
								}
							}

							.infos_message {
								line-height: 1.5;
    							margin-bottom: 2rem;
							}

							label {
								color: $fadeTxt;
							}

							& > div{
								padding-left: 2.35rem;
							}

							.message_details_holder {
								margin-top: 1rem;
							}

							.message_detail_pj {
								margin-top: 1rem;
								display: flex;

								p.f_left a {
									text-decoration: underline;
								}
							}
						}
						form .file-input-row .w-form-line{
							label.ajout_pj_label{
								background: $black;
								display: inline-block;
								cursor: pointer;
								font-weight: 500;
								color: $white;
								text-transform: uppercase;
								position: relative;
								height: 45px;
								text-align: center;
								line-height: 45px;
								font-size: .8rem;
								min-width: 250px;
								padding-left: 5px;
								margin-bottom: 1.5rem;
								font-family: $primaryFont;
								letter-spacing: 1.5px;
							}
							input{
								opacity: 0;
								display: none;
							}
						}
						#btnSendResponse{
						    display: flex;
						    justify-content: center;
						}
					}
				}
				&:nth-child(2n+1){
					tr{
						background: white;
						& + tr{
							height: 0;
							td .message_detail{
								padding-bottom: 2rem;
							}
						}
					}
				}
			}
		}
	}


	//others
	@media screen and (min-width:0\0) and (min-resolution: 72dpi) {
		.w-contact-form .file-input-row label.w-loader,
		.w-submit-button.w-loader, button.w-submit-button.w-loader{
		    background: #3c3c3c url(../img/three_dots.gif) center no-repeat;
	        background-size: auto 100%;
		}
	}
	img.valid_form,
	img.img_error{
	    width: 1.75rem;
	    height: 1.75rem;
	    position: absolute;
	    top: calc(50% - (1.75rem / 2));
	    right: 0.5rem;
	}
	&.not_connected{
		form.w-newspreferences-form{
			margin-top: 5rem;
			.form_btn button:hover{
				span{
					color: black;
				}
			}
		}
	}
}

//Préference Newsletter
body.newsletter{
	.wrapper_moncompte_main{
		form{
			max-width: 80.1rem;
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			
			input{
				border: 1px solid lighten($black, 30%) !important;
				border-radius: 0 !important;
			}
			.w-form-line{
			    margin-bottom: 0.67rem;
				&:first-of-type{
			    	margin-bottom: 0.65rem;
					order: 0;

			    	p.w-input-label span{
						font-size: .725rem;
						letter-spacing: 0;
						text-transform: none;
						text-transform: unset;
					    font-style: italic;
					    color: $grey;
					}
				}

				&:nth-of-type(2) {
					order: 1;
				}

				&:nth-of-type(3) {
					order: 2;
				}

				&:nth-of-type(4) {
					order: 4;
				}

				&:nth-of-type(5) {
					order: 3;
				}

				.w-input-label{
					&:before{
					    width: 1.05rem;
					    height: 1.05rem;
					    position: relative;
					    top: -13px;
				        border: 1px solid $grey;
					}
					span{
						text-transform: uppercase;
						font-family: $primaryFont;
						color: $black;
						font-size: 0.96rem;
						letter-spacing: 1px;
						span{
							text-transform: unset;
							text-transform: none;
							font-size: 0.75rem;
							letter-spacing: 0.2px;
							color: $black;
							margin-top: 0.725rem;
						}
					}
				}
				.w-submit-button{
					background-color: $black;
					span{
						letter-spacing: 2px;
						color: $white;
					}
					&:hover{
						background-color: $secondaryColor;
						span{
							color: $black;
						}
					}
				}
			}

			.w-input.w-email-input.w-nonempty{
    			margin-bottom: 0.3rem;
			}
		}
	}
	form .w-form-line.w-submit{
		max-width: 100%;
		width: 100% !important;
		display: flex;
		justify-content: flex-start;
		order: 5;

		.form_btn{
			position: relative;
		}
		.form_btn,
		.form_btn button{
			width: 100%;
		    max-width: 18.75rem;
		    @media screen and (min-width:0\0) and (min-resolution: 72dpi) {
		    	margin: 0;
			}
		    button + button:nth-child(2n+1){
		    	display: none;
		    }
		}
	}
}

/* Wishlist */

body.wishlist{
	.wrapper_menu_compte{
    	margin: 2.5rem auto 4.55rem;
	}
	.right-container{
		display: none;
	}
	.wrapper_wishlist{
	    width: 100%;
	    min-width: 1000px;
	    max-width: 80.12rem;
	    margin: 0 auto;
	    @media screen and (min-width:0\0) and (min-resolution: 72dpi) {
	    	max-width: 79.95rem;
		}

    	@media only screen and (max-width: 1120px) {
    		min-width: inherit;
    		width: 100%;
    		max-width: 100%;
    	}
	    div.intro{
	    	font-family: $primaryFont;
		    font-size: .8rem;
		    letter-spacing: 1px;
		    color: #4d4d4d;
		    line-height: 1.9;
		    font-weight: 600;
    		margin-bottom: 4.663rem;
        	text-align: center;
		    #nrArticlesSelected{
    			text-align: center;
    			margin-top: 0.25rem;
			    font-size: 0.96rem;
			    text-transform: uppercase;
			    font-weight: 700;
			    color: black;
			    letter-spacing: 1.8px;
		    }
	    }
	    .wishlist_container{
	    	display: flex;
	    	.w-left-bloc{
				width: 72.4%;
				display: block;
	    	}
	    	.w-right-bloc{
	    		width: 27.6%;
	    		display: block;

	    		.wrapper_totaux{
    			    display: flex;
    				justify-content: flex-end;
	    		}
	    	}
	    }
	    .wish_liste_product{
		    display: flex;
		    flex-wrap: wrap;
		    margin-bottom: 2rem;
		    width: 100%;

			img{
				image-rendering: -webkit-optimize-contrast;
			}
			.product_ctn{
				width: 100%;
				max-width: 16.9rem;
				height: auto;
				position: relative;
				border: 0;
		    	margin-bottom: 2rem;
				border: 1px solid #9e9e9e;
		    	@media screen and (max-width: 1280px){
					max-width: 14rem;
		    	}
		    	@media screen and (max-width: 1080px){
					max-width: 12rem;
		    	}

		    	@media only screen and (max-width: 1024px) {
	    			margin: 0 1rem 2rem 0 !important;
	    		}

				&:nth-child(3n+2) {
					margin: 0 calc((100% - 50.7rem)/2) 2rem;

		    		@media only screen and (max-width: 1024px) {
		    			margin: 0 1rem 2rem 0 !important;
		    		}
				}
				&.checked{
					border: 1px solid $black;
				}
				.product_check{
					position: absolute;
					top: 0;
					left: 50%;
	    			transform: translate(-50%, -50%);
				}
				.wrapper_product{
					padding-bottom: 1.2rem;
					.photo_product{
	    				padding: 8.5% 8.5% 3%;
	    				.wrapper_img{
	    					position: relative;
	    					.report.error{
	    						position: absolute;
	    						width: 100%;
	    						height: 100%;
	    						top: 0;
	    						left: 0;
	    						display: flex;
	    						justify-content: center;
	    						align-items: center;
	    					}
	    				}
					}
				}
				.product_desc{
    				padding: 0 8.5%;
					div{
						p,
						span{
							font-size: 0.78rem;
							font-weight: 500;
							font-family: $primaryFont;
							letter-spacing: 1px;
						}
					}
					.wrap_txt{
						line-height: 1.75;
					}
					.bottom_content_price{
						margin-top: 0.425rem;
					}
					.wrapper_price{
						p,
						span{
							font-weight: 700;
						}
						.bloc_price{
							width: 100%;
							.old_price{
								color: $grey;
								position: relative;
								display: inline;
								&:after{
									content: "";
									position: absolute;
									top: 50%;
									left: 0;
									height: 1px;
									width: 100%;
									background-color: $grey;
								}
							}
						}
					}
					.wrapper_details{
    					margin-top: 1.225rem;
    					p{
    						line-height: 1.5;
    					}
					}
					.cta-wrapper{
						margin-top: 1.05rem;

						.cta_add_to_basket {
							cursor: pointer;
						}

						div {
							text-decoration: underline;
							line-height: 2;
						}
					}
				}
			}
	    	@media screen and (max-width: 1280px){
				.product_ctn:nth-child(3n+2){
					margin: 0 calc((100% - 46rem)/2) 2rem;
				}
	    	}

	    	@media screen and (max-width: 1180px){
				.product_ctn:nth-child(3n+2){
					margin: 0 calc((100% - 43rem)/2) 2rem;
				}
	    	}
	    	@media screen and (max-width: 1080px){
				.product_ctn:nth-child(3n+2){
					margin: 0 calc((100% - 37rem)/2) 2rem;
				}
	    	}
		}
		.wrapper_btn_add{
		    display: flex;
		    justify-content: flex-end;
		    flex-wrap: wrap;
	    	width: 18.75rem;
	    	max-width: 100%;
	    	@media screen and (max-width: 1366px){
		    	width: 17rem;
	    	}
		    @media screen and (max-width: 1280px){
		    	width: 16rem;
	    	}
		    @media screen and (max-width: 1080px){
		    	width: 15rem;
	    	}
			.form_submit{
			    width: 100%;
		        height: 50px;
			    margin-bottom: 0.88rem;
			    position: relative;
			    top: -2px;
			    border: 1px solid $black;
			    a{
			    	background-color: $black;
			    	span{
				    	font-size: 0.785rem;
				    	font-family: $primaryFont;
				    	font-weight: 600;
				    	color: $white;
				    	padding: 0 .5rem;
				    }
			    }
			    &:hover a{
			    	background-color: $secondaryColor;
			    	span{
			    		color: $black;
			    	}
			    }

			    &.share_wl {
			    	.mail_share {
			    		border: none;
			    		text-align: center;

			    		span {
			    			display: flex;
						    justify-content: center;
						    align-items: center;
						    width: 100%;
						    height: 100%;
			    		}
			    	}
			    }
			}
		}
	}
	#abox{
	    height: 20rem;
		&.connected{
		    height: 10rem;
		}
		.close_pop.close{
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			span{
				position: absolute;
				right: 20px;
				top: 20px;
			}
		}
	}
	#sendwishlistbox{
    	padding: 4rem 4.5rem 3rem;

    	@media only screen and (max-width: 1660px) {
    		padding: 3rem 4rem 2.5rem;
    	}

    	@media only screen and (max-width: 1560px) {
    		padding: 2.5rem 3.5rem 2.25rem;
    	}

    	@media only screen and (max-width: 1360px) {
    		padding: 2rem 3rem 2rem;
    	}

    	@media only screen and (max-width: 1260px) {
    		padding: 1.25rem 2rem 1.25rem;
    	}

    	@media only screen and (max-width: 1160px) {
    		padding: 1rem 2rem 1rem;
    	}

    	background-color: $bgColor;
    	[type=checkbox]:checked+label:before,
    	[type=checkbox]:not(:checked)+label:before {
		    background: #fbf4ec;
			border-color: #000000;
		}
		.close_pop{
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			span{
				display: block;
				position: relative;
				right: 0.8rem;
				top: 0.8rem;
				width: 12px;
				height: 12px;
				background-size: 100%;
				@media screen and (min-width:0\0) and (min-resolution: 72dpi) {
					top: 0.2rem;
					right: 0.4rem;
				}
			}
		}
		.popup_title{
    		margin: 0 0 1.8rem;
		}
		.form_title,
		tr, td, div, p{
			font-family: $primaryFont !important;
		}
		.title{
			font-family: $secondaryFont !important;
		    font-size: 2.45rem !important;
		    text-transform: unset !important;
		    text-transform: none !important;
		    margin-bottom: 0 !important;

		    @media only screen and (max-width: 1680px) {
			        font-size: 2rem !important;
			}
			@media only screen and (max-width: 1370px) {
			    font-size: 1.85rem !important;
			}
		}
		.subtitle{
		    text-transform: uppercase;
		    font-weight: 600;
		    font-size: 0.97rem;
		    margin-top: 0.1rem;
		}
		#wishlist_form_to,
		#wishlist_form_froms{
			table tr{
				td{
					font-family: $primaryFont !important;
				}
			}
			table input,
			table textarea{
				background: #fbf4ec !important;
			}
		}
		#second_wishlist_form_to input,
		#second_wishlist_form_froms input,
		#fourth_wishlist_form_froms input,
		textarea#sendWishListToMessage{
			border: 1px solid darken($grey, 35%);
    		padding: 0.9rem 1rem;
			&::placeholder{
				font-size: 0.8rem;
			}
		}
		#sendWishListToMessage{
    		height: 7.2rem;
		}
		p.plusieur_add,
		#sendFriendCopy + label p{
		    font-size: 0.66rem;
		    color: black;
		    font-weight: 500;
		    text-align: center;
		    word-spacing: 2px;
		    letter-spacing: 0.3px;
		}
		p.plusieur_add{
		    margin-top: 0.45rem;
    		margin-bottom: 1.5rem;
		}
		#sendFriendCopy + label p{
		    font-size: 0.8rem;
		}
		#g-recaptcha{
		    margin: 0.7rem 0 0;
		    div > iframe{
    			/*transform: scale(0.795);*/
		    }
		}
		#sendfriend_form_buttons{
			.button.secondary.dark{
			    height: 3.15rem;
			    margin-top: 2.25rem;
			    display: block;
			}

            .loader{
                &.loading{
                    z-index: 2;
                }
            }
		}
	}
}

/* parrainage */

body.parrainage_index {

	#bloc_no_parrainage {
		background: url('../img/parrainage.jpg') no-repeat;
        background-size: contain;
        width: 100%;
		height: 810px;
		margin-bottom: 270px;

		@supports (-webkit-hyphens:none) {
			padding-top: 60px;
			margin-top: -60px;
		}

		#breadcrumbs {
			margin: 0 ;

			.breadcrumbs {
				display: none;
			}
		}

		.wrapper_parrainage {
			text-align: center;
            background: $white;
            height: 850px;
            border-bottom-left-radius: 100% 100%;
            border-bottom-right-radius: 100% 100%;
            padding-top: 65px;
            width: 972px;
            text-align: center;
            margin: 80px auto 0;

            @media only screen and (max-width: 1023px) {
            		width: calc(100% - 64px);
            		padding: 40px 25px 0;
            }

			.content_parrainage {

				.content_titre {
					font-family: $secondaryFont;
                    font-size: 65px;
                    margin-bottom: 1.5rem;
					font-weight: bold;

					@media only screen and (max-width: 1680px) {
					        font-size: 60px;
					}

					@media only screen and (max-width: 1370px) {
					    font-size: 50px;
					}

					h1 {
						font-family: $secondaryFont;
                    	font-size: 4rem;
                    	font-weight: bold;

                    	@media only screen and (max-width: 1680px) {
					        font-size: 60px;
						}

						@media only screen and (max-width: 1370px) {
						    font-size: 50px;
						}
					}
				}

				p.txt {
					font-weight: 500;
                    letter-spacing: 1px;
                    font-size: .8rem;
				}

				.wrapper_offert {
					display: flex;
					max-width: 850px;
					margin: 6rem auto 0;
					justify-content: space-around;

					.wrapper_offert_number {
						position: relative;

						span.sign {
							content: "";
							position: absolute;
							background: $tertiaryColor;
							border-radius: 50%;
							color: $white;
							width: 50px;
							height: 50px;
							top: -3rem;
							left: 50%;
							transform: translateX(-50%);
							font-size: 1.8rem;
							font-family: $secondaryFont;
							line-height: 50px;

							@media only screen and (max-width: 1680px) {
							    font-size: 1.65rem;
							}

							@media only screen and (max-width: 1370px) {
							    font-size: 1.45rem;;
							}
						}
					}

					.wrapper_offert_desc {
						margin-top: 1.5rem;

						.offert {
							display: none;
						}

						.texte {
							font-size: .8rem;
							font-weight: 600;
							line-height: 1.6;
							max-width: 75%;
							margin: 0 auto;
							letter-spacing: 1px;
						}
					}
				}

				.texte_bottom {
					color: $fadeTxt;
					max-width: 715px;
					margin: 0 auto;
					text-align: center;
					margin: 2rem auto 0;
					line-height: 1.8;
					font-size: .65rem;
					letter-spacing: 1px;
				}

				.btn_parrainage_connect a {
					width: 375px;
					height: 50px;
					background: $black;
					color: $white;
					line-height: 50px;
					display: block;
					margin: 3rem auto 0;
					font-size: .8rem;
					text-transform: uppercase;
					letter-spacing: 1px;
					font-weight: 600;
				}
			}
		}
	}

	#wrapper_moncompte h2.account_name{
	    margin-top: 1rem;
	    width: 100%;
	    text-align: center;
	    font-family: $secondaryFont;
	    font-size: 2.62rem;
	    font-weight: 700;
	    letter-spacing: -1.6px;

	    @media only screen and (max-width: 1680px) {
		        font-size: 2.25rem;
		}

		@media only screen and (max-width: 1370px) {
		    font-size: 2rem;
		}
	}
	.wrapper_content_parrainage{
		.content_parrainage{
			margin-top: 4.8rem;
			.intro{
				span{
					display: block;
				    font-size: 0.81rem;
				    letter-spacing: 1px;
				    font-weight: 500;
				    margin-top: 1rem;
				    margin-bottom: 1.7rem;
    				line-height: 1.65;
					&.title{
					    text-transform: uppercase;
					    font-weight: 700;
					    font-size: 1rem;
					    letter-spacing: 1px;
				    	margin-top: 0.5rem;

				    	@media only screen and (max-width: 1680px) {
						        font-size: .9rem;
						}


						@media only screen and (max-width: 1370px) {
						    font-size: .85rem;
						}
					}
				}
			}
		}
		.intro_parrainage{
			font-size: 0.81rem;
			letter-spacing: 1px;
			font-weight: 600;
			padding: 0 5.2%;
		}
		#parrainage_form{
			padding: 0 5.2%;
			margin-top: 1.5rem;
			input{
			    border: 1px solid darken($grey, 20%);
			}
			.bloc_parrainage_form{
				margin-bottom: 1.255rem;
				.w-form-line.column{
					display: flex;
				    flex-direction: row;
			        justify-content: space-between;
					margin-bottom: 2.5625rem;
					.w-input-container{
					    width: 32%;
					}
				}
			}
			#add_friend{
				width: 100%;
				max-width: 100%;
				display: block;
				button{
					background-color: transparent;
					height: auto;
					width: 100%;
					max-width: 100%;
					text-align: right;
					color: lighten($black, 25%);
					border: none;
					span{
						display: block;
						width: 100%;
						max-width: 100%;
						text-decoration: underline;
						font-size: 0.78rem;
						text-transform: unset;
						text-transform: none;
					    font-weight: 600;
					    letter-spacing: 1px;
					}
				}
			}
			#send_invite{
				opacity: 0;
			    height: 50px;
			    width: 18.75rem;
			    margin: 0 auto;
    			margin-top: 1.25rem;
    			transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

				@include ie() {
					top: 73%;
				}

			    span{
				    font-size: .83rem;
				    font-weight: 500;
				    letter-spacing: 1.5px;
			    }
			    &:hover{
			    	background-color: $secondaryColor;
			    	span{
			    		color: $black;
			    	}
			    }
			}
			.elem_parrainage{
				position: relative;
				.bloc_parrainage_textarea{
					.label_parrainage{
					    font-size: 0.81rem;
					    letter-spacing: 1px;
					    font-weight: 600;
    					margin-bottom: 1.3rem;
					}
				}
				.w-form-line{
					textarea.w-input-element{
    					height: 8.15rem;
					    border: 1px solid darken($grey, 20%);
					    padding-top: 0.75rem;
					    line-height: 1.45;
					}
					.w-input-label{
						display: none;
					}
				}
				.w-submit-button.w-loader{
					opacity: 0;
				    top: unset;
				    bottom: 0;
				    left: 50%;
				    transform: translateX(-50%);
				}
			}
		}
		#parrainage_error,
		#parrainage_ok{
			color: red;
    		padding: 0 5.2%;
    		text-align: center;
		}
		#parrainage_ok{
			color: green;
		}
		.wrapper_bons_achats{
			text-align: center;
    		margin-top: 4.8rem;
			.title_intro{
			    text-transform: uppercase;
			    font-weight: 700;
			    font-size: 1rem;
			    letter-spacing: 1px;

			    @media only screen and (max-width: 1680px) {
				        font-size: .9rem;
				}

				@media only screen and (max-width: 1370px) {
				    font-size: .85rem;
				}
			}
			p.intro{
			    font-size: 0.81rem;
			    letter-spacing: 1px;
			    font-weight: 600;
			    margin-top: 2.1rem;
			    margin-bottom: 2rem;
			}
			#bloc_tableau_parrainage{
			    width: 100%;
			    max-width: 80rem;
			    margin: 0 auto;
    			margin-bottom: 10rem;
				table{
					thead tr{
					    height: 2.8rem;
						background-color: $greenbg;
						th{
						    font-size: 0.81rem;
						    letter-spacing: 1px;
						    font-weight: 600;
						    margin-top: 2.1rem;
						    margin-bottom: 2rem;
						    text-transform: uppercase;
						    color: $white;

						    @media only screen and (max-width: 1024px) {
						    	font-size: 0.65rem;
						    	letter-spacing: 0;
						    }
						}
					}
					tbody{
						tr{
					    	height: 3.8rem;
					    	&:nth-child(2n+2){
					    		background: $secondaryColor;
					    	}
							td{
							    font-size: 0.81rem;
							    letter-spacing: 1px;
							    font-weight: 600;
							    color: $black;
							    img{
							    	width: 16px;
							    	height: 16px;
							    }
							}
						}
					}
				}
				#right_navigation_parrain{
					display: none;
				}
			}
		}
	}
}

body.order {
	.lightbox.popup_numero_retour{
		width: 50%;
    	padding: 4.4rem 4rem;
		background: $secondaryColor;

		p.texte_num_retour{
			font-size: 2rem;
			font-weight: 600;
			text-align: center;
			font-family: $secondaryFont;
		    margin-bottom: 1.45rem;

		    @media only screen and (max-width: 1680px) {
			        font-size: 1.85rem;
			}

			@media only screen and (max-width: 1370px) {
			    font-size: 1.75rem;
			}
		}
		div.recommandation{
			font-size: 0.8rem;
			font-weight: 600;
			text-align: center;
			margin-bottom: 1.4rem;
		}
		.imgtxt_wrapper{
			display: flex;
			align-items: center;
			width: 100%;
			border: 1px solid $darkTxt;
			padding: 1.5rem;
			.imgWrapper{
				display: flex;
				align-items: center;
    			margin-right: 1rem;
				img{
					width: 7.6rem;
					height: auto;
				}
			}
			.formWrapper{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				p{
					display: block;
					width: 100%;
					font-weight: 600;
					line-height: 1.7;
					&.pricetag{
						font-size: 0.8rem;
					    margin-bottom: 0.9rem;
					    margin-top: 0.3rem;
					}
				}
			}
		}
		.selectWrapper.js-select-motif {
			margin: 1.5rem 0 2rem;

			.date_select {
				font-weight: 600;
				display: flex;
				width: 100%;
				min-height: 2rem;
				position: relative;

				&:first-of-type select {
					width: 35px;
				}

				span {
					margin-right: 1rem;
				}
				select {
					border: none;
					border-bottom: 1px solid $black;
					background: transparent url('../img/compte-arrow.jpg') no-repeat 100% center;
					width: 70%;

					&:focus {
						outline: none !important;
					}
				}
			}
		}

		.divBtnPrintPdf {
			clear: left;
			width: 230px;
			margin: 0 auto;
			height: 50px;
		}
	}

	form {
		height: 60vh;

		.productPriceCtn {
			width: 46%;
			float: left;
			margin: 1rem;

			&.none {
				opacity: 0.5;
				pointer-events: none;
			}

			@include queriesWshop(1400px, max) {
				width: 97%;
				margin: 0;
			}
		}

		button {
			width: 230px;
			height: 50px;
			margin: 0 auto;
		}
	}
}

/*------------------
    Newsletter
------------------*/

.newsletter{
    #contents_wrapper #wrapper_moncompte .bandeau_top_compte {
        display: none;
    }
    .mon_compte_left.wrapper_menu_compte {
        display: none;
    }
    .newsletter{
        margin-top: 4.5rem;
        .titre.newsletter_add {
        display: none;
        }
    }
    .txtdesabo {
        font-size: 13px;
        line-height: 1.3;
        margin-bottom: 2rem;
        font-weight: 500;

        @media only screen and (max-width: 1680px) {
		        font-size: 12px;
		}


		@media only screen and (max-width: 1370px) {
		    font-size: 11px;
		}
    }

    #subscriptionForm {

        .newsletter_option {
            [type="checkbox"]{
                &+ label {
                    display: block;
                    padding-left: 3rem;

                    &::before{
                        position: absolute;
                        width: 25px;
                        height: 13px;
                        background: #cacaca;
                        display: block;
                        border-radius: 10px;
                        box-shadow: none;
                        left: 0;
                        top: 5px;
                        border: 0;
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 13px;
                        height: 13px;
                        background: #fff;
                        border-radius: 13px;
                        transition: all .2s ease-out;
                        position: absolute;
                        top: 5px;
                        left: 1px;
                        box-shadow: 0 2px 5px 0 rgba(0,0,0,.5);
                    }

                    strong {
                        color: #cacaca;
                    }
                }
                &:checked + label {
                    &::before {
                        background: $primaryColor;
                    }

                    &::after {
                        left: 25px;
                        transform: translateX(-100%);
                    }

                    strong {
                        color: $primaryColor;
                    }
                }
            }
        }

        .newsletter_option label{
            display: inline-block;
            strong {
                padding-left: 1rem;
                color: #313131;
                font-weight: 400 !important;
                display: inline-block;
            }
        }
    }
}

#subscriptionForm div:first-child{
    margin-bottom: 25px !important;
}

#btn_subscription {
    background-color: #333331;
}

.form_item {
    margin-bottom: 2rem;
}
.wrapper_moncompte {
    .content_newsletter {
        margin: 0 auto;
		padding-bottom: 5.5rem;
        .title{
            display: none;
        }

        .txtdesabo {
            margin-top: 2rem;
            font-weight: 500;
            line-height: 1.3;
        }

        .w-newspreferences-form{
            margin-top: 1.5rem;
            .w-checkbox-input {
                .w-input-element {
                    &+ .w-input-label {
                        &::before{
                            width: 23px;
                            height: 13px;
                            background: #cacaca;
                            display: block;
                            border-radius: 10px;
                            position: relative;
                            box-shadow: none;
                        }

                        &::after {
                            content: '';
                            display: block;
                            width: 13px;
                            height: 13px;
                            background: #fff;
                            border-radius: 13px;
                            transition: all .2s ease-out;
                            position: absolute;
                            top: 1px;
                            left: 1px;
                            box-shadow: 0 2px 5px 0 rgba(0,0,0,.5);
                        }

                        .multiline {
                        	transition: color 0.15s ease-in-out;
                            color: #cacaca;
                            span{
                            	transition: color 0.15s ease-in-out;
                            	color: #cacaca;
                            }
                        }
                    }
                    &:checked + .w-input-label {
                        &::before {
                            background: $primaryColor;
                        }

                        &::after {
                            left: 23px;
                            transform: translateX(-100%);
                        }

                        .multiline {
                            color: $primaryColor;
                            span{
                            	color: $primaryColor;
                            }
                        }
                    }
                }
            }
            .w-form-line:nth-of-type(2){
            	.w-checkbox-input {
	                .w-input-element {
	                    &+ .w-input-label {
	                        &::after {
	                            top: 2px;
	                        }
	                    }
	                }
	            }
            }

            .w-input-container {

                .w-checkbox-input {
                    height: auto;
                }
            }

            .w-input-label {
                font-size: .8rem;
                line-height: 1;
                color: $primaryColor;

                &:before{
                    border-color: $reassuBg;
                    border: solid 1px;
                }

                .multiline {
                    margin: 0 0 0 1.3rem;
                    position: relative;
                    top: 2px;

                    strong {
                        font-family: $primaryFont;
                        letter-spacing: 1px;
                        margin: 0 0 3px;
                    }

                    span {
                        font-family: $primaryFont;
                        letter-spacing: normal;
                    }
                }
            }
        }
    }
    &.not_connected{
        .update_success{
        	margin: 5rem auto -3rem;
        }
    }
}

#clientForm{
	.w-form-line.w-submit{
		.form_btn .button,
		.form_btn span{
			font-size: 0.8rem;
		}
	}
}