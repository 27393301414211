/**
/// Mixin for flex rows implementation, mostly used in forms
/// @param  $column [class used for each column]
/// @param  $gutter [desired gutter size]
/// Exemple :
.gridContaier {
    @include flexGrid('.gridItm', 8px);
}
*/
@mixin flexGrid($column, $gutter) {
    display: flex;
    align-items: stretch;

    @content;

    #{$column} {
        flex: 1;

        + #{$column} {
            margin-left: $gutter;
        }
    }
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea,
select {
    width: 100%;
    height: 100%;
    font-size: .9rem;
    padding: 0 .5rem;
    font-family: "Arial", sans-serif;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
    border: 1px solid #e4e4e4;

    &.inputErr {
        border-color: red!important;
    }
}

button,
input,
label,
textarea {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
}

button,
input,
textarea {
    background-color: inherit;
    border-radius: 0;
    box-sizing: border-box;

    &:active,
    &:focus {
        outline: none;
    }
}

input,
select,
textarea {
    font-family: Arial, sans-serif;
}

textarea {
    padding: .5rem;
}

input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

select::-ms-expand {
    display: none;
}
/******************************************************************************/
/* INPUT CHECBOXES */
/******************************************************************************/
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;

    + label {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        line-height: 1;

        &:before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border: 1px solid #e4e4e4;
            background: $white;
            margin: 0 0.5rem 0 0;
            border-radius: 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            background: $btnColor;
            width: 12px;
            height: 12px;
        }
    }
}

[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
}

[type="checkbox"]:checked + label:after {
    opacity: 1;
    width: 12px;
    height: 12px;
}
/******************************************************************************/
/* INPUT RADIOS */
/******************************************************************************/
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;

    + label {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        line-height: 1;
        cursor: pointer;
        margin: 0 1rem 0 0;

        &:before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border: 1px solid #e4e4e4;
            background: $white;
            margin: 0 0.5rem 0 0;
            border-radius:50%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            background: $btnColor;
            border-radius:50%;
        }
    }

    &.inputErr{
        + label {
            &:before {
                border: 1px solid red;
            }
        }
    }
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
}

[type="radio"]:checked + label:after {
    opacity: 1;
    width: 10px;
    height: 10px;
    background: $btnColor;
}
/******************************************************************************/
/* SELECTS */
/******************************************************************************/
.date_select {
    position: relative;
    width: 80px;
    min-height: 3rem;
    display: inline-block;
    margin: 0 .4rem 0 0;

    select {
        appearance: none;
        border: 1px solid #e4e4e4;
        font-family: $primaryFont;
        font-size: 12px;
        background: $white url(../img/compte-arrow.jpg) no-repeat 92% center;

        &::-ms-expand {
            display: none;
        }

        &.inputErr {
            border-color: red;
        }


    }

    &.country {
        width: 100%;
    }
}
/******************************************************************************/
/* PHONE DROPDOWN */
/******************************************************************************/
.intl-tel-input {
    height: 100%;
}
/******************************************************************************/
/* INPUT PLACEHOLDERS */
/******************************************************************************/
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: sans-serif;
    font-size: 12px;
    color: #999999;
    font-style: italic;
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-family: sans-serif;
    font-size: 12px;
    color: #999999;
    font-style: italic;
}

:-ms-input-placeholder {
    /* IE 10+ */
    font-family: sans-serif;
    font-size: 12px;
    color: #999999;
    font-style: italic;
}

:-moz-placeholder {
    /* Firefox 18- */
    font-family: sans-serif;
    font-size: 12px;
    color: #999999;
    font-style: italic;
}

.img_error,
.valid_form {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
}

.form_submit,
.input_container,
.btn_container {
    position: relative;
}

.form_submit,
.btn_container {
    width: 100%;
    height: 50px;

    button,
    .button,
    .loader {
        display: block;
        width: 100%;
        height: 100%;
        font-family: $primaryFont;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.4px;
        text-decoration: none;
        text-transform: uppercase;

        @media only screen and (max-width: 1370px) {
            font-size: 13px;
            letter-spacing: 1.3px;
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-family: $primaryFont;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 1.4px;
            text-decoration: none;
            text-transform: uppercase;

            @media only screen and (max-width: 1370px) {
                font-size: 13px;
                letter-spacing: 1.3px;
            }
        }
    }

    button,
    .button {
        background: #fbf4ec;
        border: 1px solid $black;
        color: $black;
        cursor: pointer;
        transition: all .2s ease-out;

        + .loader {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            background: $btnColor;
            border: 1px solid $btnColor;
            color: $white;
            cursor: default;

            span:before {
                content: '';
                display: block;
                width: 2em;
                height: 2em;
                background: url(../svg/three_dots.svg) no-repeat 0 0;
                background-size: contain;
            }
        }

        + .loader {
            background: url(../svg/three_dots.svg) $darkColor no-repeat center center !important;

            @media screen and (min-width:0\0) {
                background: url(../img/three_dots.gif) $darkColor no-repeat center center !important;
            }

            background-size: 15% !important;
            transition: inherit;
            position: absolute;
            left: 0px;
            top: 0px;
            margin: auto;

            &::before {
                display: none;
            }
            span {
                opacity: 0;
            }
        }


        &.dark {
            position: relative;
            background: $btnColor;
            color: $white;
            overflow: hidden;
            border: 0 none;

            span{
                position: relative;
                z-index: 2;
            }

            &:before{
                content: " ";
                display: block;
                background: darken($btnColor, 5%);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                opacity: 0;
                transition: all 0.5s ease-in-out;
            }

            + .loader {
                background: url(../svg/three_dots.svg) $btnColor no-repeat center center !important;
                @media screen and (min-width:0\0) {
                    background: url(../img/three_dots.gif) $btnColor no-repeat center center !important;
                    background-size: 15% !important;
                }
                background-size: 15% !important;
                transition: inherit;
                &::before {
                    display: none;
                }
                span {
                    opacity: 0;
                }
            }

            &:hover {
                background: $black;
                color: $white;
            }
        }

        &:hover {
            background: $btnColor;
            color: $white;
        }
    }

    &.loading, .btn_submit.loading  {
        .button {
            display: none;
        }
        .loader {
            display: block;
            z-index: 3;
        }
    }
}

.formRow {
    margin: 0 0 .5rem 0;
    @include flexGrid('.input_container', 1%);

    &.deliveryDate {
        .input_container {
            flex: initial;
            width: 49.5%;
        }
    }

    .noaddr {
        display: none;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        color: red;
        font-size: 14px;
        font-weight: 400;
        font-family: $primaryFont;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

body.bandeauDelivery{
    .noaddr {
        right: 1rem;
        top: 24%;
    }
}

/******************************************************************************/
/* Captcha Google */
/******************************************************************************/
.g-recaptcha {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
}

//COOKIES
#cookiesLightbox {
    font-family: $primaryFont;
    background-color: $bgColor2;
    padding: 30px;
    z-index: 16000003;

    &.notAccepted {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    section {
        font-size: 13px;
        line-height: 21px;
        letter-spacing: 0.05em;
        display: inline-block;
        width: auto;
        margin: 0;

        h3, .h3Title {
            font-family: $primaryFont;
            font-size: 18px;
            font-weight: 900;
            letter-spacing: 0.1em;
            margin-bottom: .5rem;
        }

        ul {
            li {
                .cookiesh4 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #000;
                    font-family: $primaryFont;
                }

                &:first-child .cookiesh4 {
                    color: #a9a9a9;
                }
            }

            #cookiePref02,
            #cookiePref03 {
                .icon {
                    position: relative;
                    top: 0;
                    display: inline-block !important;
                    margin-left: 0 !important;
                }
            }
        }
    }

    .cookiesLightbox_container {
        display: grid;
        max-width: 92%;

        @media only screen and (max-width: 1370px) {
            max-width: none;
        }

        .cookiesLightbox_content {
            max-width: 82%;

            @media only screen and (max-width: 1370px) {
                max-width: 81%;
            }
        }

        .cookiesLightbox_preferences {
            grid-row: 3;
            max-width: 990px;

            .cookiesInfos {
                width: max-content;
                max-width: 100%;

                .w-input-container {
                    margin-bottom: auto;
    
                    .w-input-label {
                        &::after {
                            top: 3px;
                        }
                        .multiline {
                            padding-right: 1.75rem;
                        }
                    }
                }

                @media only screen and (max-width: 1670px) {
                    width: fit-content;
                    max-width: 83%;
                }
            }
        }

        .cookiesLightbox_accept_btn {
            align-self: center;
            grid-row: 1 / 4;
            grid-column: 4;
            width: 172px;
            margin-left: 1.8rem;  

            @media only screen and (min-width: 1370px) {
                span {
                    font-size: 13px;
                    letter-spacing: 1.3px;
                }
                width: 230px;
            }
            
            #js_cookie_accept {
                &:not(:hover), 
                & + .w-loader {
                    background-color: $primaryColor;
                    color: #fff;
                }

                &:hover {
                    background-color: $secondaryColor;
                    color: $primaryColor;
                }

                & + .w-loader { 
                    span:before {
                        background-color: $secondaryColor;
                    }
                }
            }

            button {
                height: 48px;
            }  
        }

        .cookiesLightbox_refuse_btn {
            align-self: center;
            grid-column: 3;
            grid-row: 1 / 4;
            width: 172px;

            @media only screen and (min-width: 1370px) {
                width: 230px;
            }

            #js_cookie_refuse {
                & + .w-loader {
                    span {
                        display: inline-grid;
                        font-size: 0;
        
                        &:before {
                            content: "";
                            mask-image: url(../svg/three_dots.svg);
                            mask-repeat: no-repeat;
                            mask-size: 60%;
                            width: 3.5rem;
                            height: 1rem;
                            mask-position: center;
                        }
                    }
                }
            }
            
            button {
                color: $black;
                height: 48px;
                background-color: $secondaryColor;
                
                &:hover {
                    color: $white !important;
                    background-color: $primaryColor !important;
                }
            }              
        }

        .cookiesLightbox_custom_btn {
            margin: 0;
            grid-row: 2;

            .toggleCookiePrefs {
                width: auto;
                height: auto;
                background: none;
                border: none;
                color: $black;
                font-weight: 300;
                text-transform: none;
                font-size: 13px;
                letter-spacing: .05em;
            }

            .w-loader.loading {
                display: none;
            }
        }
    }
}

/**********************
FORMULAIRE SATISFACTION
**********************/
.satisfaction_main_wrapper {
    padding: 2rem;
    margin: 0;

    .title_satisfaction {
        font-family: $secondaryFont;
        font-weight: 900;
        font-size: 1.85rem;
        text-transform: uppercase;
        letter-spacing: .02em;
        margin: 2rem 0;
        text-align: center;
    }

    .satisfaction_page_content {
        font-size: 1rem;
        font-family: $primaryFont;
        font-weight: 400;
        color: $primaryColor;

        .page_satisfaction_title {
            width: 800px;
            max-width: 100%;
            margin: auto;

            #satisfaction_intro {
                margin: 0 0 1rem 0;

                h3 {
                    font-weight: 900;
                    font-size: 1.25rem;
                    text-transform: uppercase;
                    margin-bottom: 1.5rem;
                    text-align: center;
                }

                div {
                    p {
                        line-height: 1.1;
                        margin-bottom: 1rem;
                        text-align: center;
                    }
                }
            }
            .cadre_question {
                width: 800px;
                max-width: 100%;
                margin-bottom: 24px;
                td {
                    height: 38px;
                }

                .satisfaction_answer_holder, .wrapper_list {
                    margin: auto;

                    tbody {
                        tr {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            td {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;

                                label {
                                    font-size: .75rem;
                                }

                                &.labelForm {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    .customChecker {
                                        margin-left: 1rem;
                                        display: flex;
                                        align-items: flex-end;
                                        position: relative;
                                        cursor: pointer;

                                        label {
                                            position: relative;
                                            padding-left: 22px;

                                            &:before {
                                                content: '';
                                                position: absolute;
                                                left: 0;
                                                top: -1px;
                                                margin: auto;
                                                border: 1px solid rgba($primaryColor,.5);
                                                width: 12px;
                                                height: 12px;
                                                border-radius: 100%;

                                            }

                                            &:after {
                                                content: '';
                                                position: absolute;
                                                left: 2px;
                                                top: 1px;
                                                margin: auto;
                                                border: 1px solid rgba($primaryColor,1);
                                                width: 8px;
                                                height: 8px;
                                                background-color: $primaryColor;
                                                border-radius: 100%;
                                                opacity: 0;
                                                transition: opacity ease-in-out .3s;

                                            }

                                            &.first_label {
                                                min-width: 5rem;
                                            }
                                        }

                                        input {
                                            opacity: 0;
                                            display: none;

                                            &:checked + label {
                                                &:before {
                                                    content: '';
                                                    position: absolute;
                                                    left: 0;
                                                    top: -1px;
                                                    margin: auto;
                                                    border: 1px solid rgba($primaryColor,1);
                                                    width: 12px;
                                                    height: 12px;
                                                    border-radius: 100%;

                                                }

                                                &:after {
                                                    opacity: 1;

                                                }
                                            }
                                        }


                                    }

                                    &.smileyform {
                                        justify-content: space-between;
                                        width: 100%;
                                        max-width: 443px;
                                        position: relative;
                                        left: 10px;

                                        img {
                                            width: 20px;
                                            height: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .explication_range_slider {
                    text-align: center;
                    font-style: italic;
                    font-size: 12px;
                }

                #slider_recommandation,
                #slider_serviceclient {
                    position: relative;
                    top: 4px;
                    left: 8px;
                    width: 420px;
                    height: 37px;
                    border: none;
                    background: none;
                    cursor: pointer;
                }

                &.satisfaction_title_11 tr td.graduationForm,
                &.satisfaction_title_08 tr td.graduationForm {
                    position: relative;
                    float: left;
                    left: 40px;
                    width: 486px;
                    height: 35px;
                    background-repeat: no-repeat;
                }



                .satisfaction_smiley {
                    position: relative;
                    &.libmiddle {
                        position: relative;
                    }
                    &.libright {
                        position: relative;
                    }
                }
                #slider_recommandation a,
                #slider_serviceclient a {
                    width: 30px;
                    height: 37px;
                    margin-left: -13px;
                    background-color: transparent;
                    border-radius: 0;
                    border: none;
                    cursor: pointer;
                }
                .ui-state-default,
                .ui-widget-content .ui-state-default {
                    border: 0;
                    background: #fff 50% 50% repeat-x;
                    font-weight: bold;
                    color: #020202;
                    outline: none;
                    border: 1px solid #bcc2c7;
                }
                .ui-slider-horizontal .ui-slider-handle {
                    top: -3px;
                }
                .ui-slider .ui-slider-handle {
                    position: absolute;
                    z-index: 2;
                    width: 1px;
                    height: 1px;
                    cursor: pointer;
                }

            }

            /* ** ERREUR ** */

            #mes_err_fieldset {
                display: none;
                width: 640px;
                margin: 20px auto;
                font-size: 12px;
                color: #000;
                letter-spacing: 1px;
                padding: 10px;
            }

            #satisfactionForm .labelErr {
                color: red !important;
            }
            #mes_err {
                width: 730px;
                margin: 10px auto 20px 0px;
                padding: 5px 0;
                color: red;
                border: 0;
                font-size: 1rem;
            }
            .erreur_txt {
                font-size: 1rem;
                color: red;
                line-height: 18px;
                height: auto;
                padding: 9px 0;
            }
            ul.erreur_tab.satisfaction {
                padding-top: 14px;
            }
            .erreur_tab.satisfaction li {
                list-style: none;
                color: #f00;
                background: transparent url(../img/picto_panneau.png) no-repeat left 2px;
                text-indent: 26px;
                line-height: 25px;
            }
            table.cadre_question.satisfaction_title_08 {
                display: none;
            }
            .bandeau_top_compte.page_satisfaction_title {
                min-height: 487px;
            }

            /* ** FIN ERREUR ** */

            #satisfactionForm {
                margin-top: 3.5rem;

                .subtitles_questionnaire {
                    width: 100%;
                    margin: 0 auto 1rem;
                    text-align: center;
                    height: 36px;
                    background: transparent url(../img/filet_question_satisfaction.png) repeat-x left center;
                    display: inline-block;

                    span {
                        background-color: #FFF;
                        padding: 8px 50px;
                        font-weight: 700;
                        font-size: .75rem;
                        text-transform: uppercase;
                        font-family: $primaryFont;
                        font-weight: 600;
                        position: relative;
                        top: .5rem;
                        border: 1px solid rgba(0,0,0,.1);
                    }
                }

                #valider_question {
                    .form_submit {
                        width: 240px;
                        margin: 1rem auto 3rem;

                    }
                }
            }
        }
    }
}