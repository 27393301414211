/***@  Css of all lafiancee textes pages   **/
#appContainer {
	.main_wrapper {
		.wrapper_content_sat {
			padding-bottom: 95px;

			h1.title {
				font-size:4.0625rem;
				font-weight: 600;
				letter-spacing: -2px;
				padding-top: 23px;
				padding-bottom: 64px;
				text-align: center;
				font-family: $secondaryFont;

				@media only screen and (max-width: 1680px) {
				    font-size: 60px;
				}
				@media only screen and (max-width: 1520px) {
				    font-size: 55px;
				}
				@media only screen and (max-width: 1370px) {
				    font-size: 50px;
				}

			}
			.content_sat {
				width: 65% !important;
				margin: auto;

				.row {
					padding-bottom: 50px;

					.content {
						line-height: 19px;
						text-align: justify;
						h2 {
							padding-bottom: 25px;
							text-transform: uppercase;
							letter-spacing: 1.65px;
							font-family: $primaryFont;
							font-size: 16px;
							font-weight: 600;
							text-align: center;
						}

						h3 {
							padding-bottom: 5px;
							letter-spacing: .04em;
							font-size: 15px;
							font-weight: bold;
							text-align: center;
						}

						a {
							text-decoration: underline;
						}

						ul {
							margin-left: 20px;
							li {
								list-style: disc;
								padding-right: 2px;
							}
						}

						.content_inside{
							line-height: 2;
							font-size: 13.5px;
						}
					}
				}
			}
		}
	}
}

/***@   lafiancee cgv page **/
.order_cgv {
	#appContainer {
		.page_wrapper {
			.main_wrapper {
				.wrapper_content_sat {
					.content_sat {
						.frais_port {
							display:none!important;
						}
					}
				}
			}
		}
	}
}
/***@   lafiancee  mention-legales page **/
.footer_legalmentions {
	#appContainer {
		.page_wrapper {
			.main_wrapper {
				.wrapper_content_sat {
					h1,
					h2 {
						text-align: center;
					}
					.content_sat {
						.row {
					        width: 32.5%;
    						margin: 0 auto;
							.content {
								line-height: 19px;
								text-align: left;
								h2{
									text-align: center;
								}
							}
						}
						#row_bloc_3{
					        width: 100%;
					        display: flex;
							justify-content: center;
							h2{
							    text-align: center;
							}
						}
					}
				}
			}
		}
	}
}

 /****@  lafiancee plan-du-site page  ***/
 .footer_sitemap {
 	#appContainer {
 		.page_wrapper {
 			.main_wrapper {
 				padding-bottom: 160px;

 				.page_satellite {
 					width: 100%;
 					margin: auto;
 					max-width: 1480px;

 					@media only screen and (max-width: 1520px) {
 						padding: 0 20px;
 					}


 					h2.satellite_title {
 						font-size: 3.75rem;
 						padding-top: 100px;
 						padding-bottom: 70px;
 						letter-spacing: 1px;
 						font-weight: 600;
 						text-align: center;
 						font-family: $secondaryFont;
 					}

 					.satellite_content {
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;
                        justify-content: space-around;

                        @media only screen and (max-width: 1180px) {
                            display: flex;
                            flex-wrap: wrap;
                            grid-gap: 20px;
                        }

                        @media only screen and (max-width: 850px) {
                            flex-wrap: wrap;
                            justify-content: flex-start;
                        }

 						.sitemap_section {
 							display: flex;
 							flex-direction: column;
 							width: auto;
 							margin-right: 1rem;

							 @media only screen and (max-width: 850px) {
								width: 25%;
								margin-bottom: 25px;
							 }

 							h3.big_title_charte {
 								padding-bottom: 5px;
 								display: inline-block;
 								width: auto;

 								span {
 									position: relative;
 									padding-bottom: 25px;
 									text-transform: uppercase;
 									letter-spacing: .1em;
 									font-family: $primaryFont;
 									font-size: 14px;
 									font-weight: 600;
 									display: inline-block;
 									padding-bottom: 2px;
 									border-bottom: 2px solid $darkColor;

									 @media only screen and (max-width: 850px) {
										font-size: 12px;
										letter-spacing: 0.6px;
									 }
 								}
 							}

 							dl.new_sitemap_border {
 								padding-top: 25px;
 								width: 100%;

 								dt.new_sitemap_subcategory {
 									letter-spacing: .1em;
 									font-family: $primaryFont;
 									font-size: 14px;
 									padding-bottom: 6px;

									 @media only screen and (max-width: 850px) {
										font-size: 12px;
										letter-spacing: 0.6px;
									 }
 								}

 								dd {
 									ul.site_map_ul {
 										margin-left: 25px;
 										li {
 											list-style: disc;
 											padding-bottom: 6px;
 										}
 									}
 								}
 							}

 						}
 					}
 				}
 			}
 		}
 	}
 }


 /****@  lafiancee cookies page  ***/

.misc_cookiesinfo {
    #appContainer {
        .main_wrapper {
            .wrapper_content_sat {
                h1.title {
                    text-transform: lowercase;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    #cookiesLightbox {
        &.displayCookies {
            display: flex!important;
        }

        &.hideCookies {
            display: none!important;
        }

        .w-form-line.w-submit button.w-loader.hideLoader {
            z-index: -1;
            opacity: 0;
        }
    }
}