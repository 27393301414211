/* HOMEPAGE */

body {
    &.homepage {
        #site_global_wrap {
            .home-module {
                position: relative;
                z-index: 5;
                width: 100%;
                max-width: 100%;
                box-sizing: border-box;

                @media only screen and (max-width: 1023px) {
                    max-width: 100vw !important;
                }

                &.cover-module {
                    position: relative;
                    margin-bottom: 80px;
                    overflow: hidden;

                     #img_video {
                        display: none;
                        opacity: 0;
                     }

                    @media only screen and (max-width: 1680px) {
                        margin-bottom: 60px;
                        /*max-height: calc(100vh - 175px);*/

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .home-module-body {
                        .home-columns-container {
                            position: relative;
                            .home-column {
                                &.boxText {
                                    max-width: 100%;
                                    text-align: left;
                                    position: absolute;
                                    z-index: 5;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;

                                    .home-module-contents {
                                        width: 100%;
                                        max-width: 550px;
                                        height: 15rem;
                                        display: inline-block;
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 0;
                                        margin: auto;

                                        &.align-left {
                                            right: auto;
                                            left: 5%;
                                        }

                                        &.align-right {
                                            right: 22.3%;
                                            left: auto;
                                        }

                                        &.align-bottom {
                                            top: auto;
                                            bottom: 18%;
                                        }

                                        &.align-top {
                                            top: 20.5%;
                                            bottom: auto;
                                        }

                                        &.align-center {
                                            left: 0;
                                            right: 0;
                                        }

                                        &.align-middle {
                                            top: 0;
                                            bottom: 0;
                                        }
                                    }

                                    .home-module-title {
                                        font-family: $secondaryFont;
                                        text-transform: none;
                                        font-size: 154px;
                                        line-height: 100px;
                                        font-weight: 600;
                                        letter-spacing: -0.025em;
                                        padding-left: 7.65rem;
                                        font-style: italic;
                                        white-space: nowrap;

                                        @media only screen and (max-width: 1680px) {
                                            font-size: 135px;
                                            line-height: 110px;
                                        }

                                        @media only screen and (max-width: 1370px) {
                                            font-size: 80px;
                                            line-height: 75px;
                                        }

                                        @media only screen and (max-width: 1360px) {
                                            font-size: 75px;
                                            line-height: 70px;
                                        }
                                    }

                                    .home-module-subtitle {
                                        font-family: $secondaryFont;
                                        text-transform: none;
                                        letter-spacing: -4px;
                                        font-size: 120px;
                                        font-weight: 600;
                                        margin-top: -10px;
                                        line-height: 1;
                                        white-space: nowrap;

                                        @media only screen and (max-width: 1680px) {
                                            font-size: 110px;
                                            line-height: 100px;
                                        }

                                        @media only screen and (max-width: 1370px) {
                                            font-size: 90px;
                                            line-height: 85px;
                                        }

                                        @media only screen and (max-width: 1360px) {
                                            font-size: 75px;
                                            line-height: 1;
                                        }
                                    }

                                    .home-module-pretitle {
                                        font-family: $primaryFont;
                                        letter-spacing: 0.1em;
                                        font-size: 18px;
                                        font-weight: 200;
                                        margin-top: .75rem;
                                        white-space: nowrap;

                                        @media only screen and (max-width: 1370px) {
                                            font-size: 17px;
                                        }

                                        @media only screen and (max-width: 1360px) {
                                            font-size: 16px;
                                            line-height: 1;
                                        }
                                    }

                                    .home-module-text {
                                        font-family: $primaryFont;
                                        letter-spacing: 0.075em;
                                        font-size: 18px;
                                        font-weight: 600;
                                        line-height: 1.75;

                                        @media only screen and (max-width: 1370px) {
                                            font-size: 17px;
                                        }

                                        @media only screen and (max-width: 1360px) {
                                            font-size: 16px;
                                            line-height: 1;
                                        }
                                    }

                                    .home-module-cta {
                                        margin-top: -11px;
                                        width: auto;
                                        display: inline-block;
                                        height: 50px;
                                        padding: 0;
                                        line-height: 50px;
                                        font-size: 19px;
                                        font-family: $primaryFont;
                                        letter-spacing: 1px;

                                        @media only screen and (max-width: 1680px) {
                                            font-size: 18px;
                                        }

                                        @media only screen and (max-width: 1370px) {
                                            font-size: 17px;
                                        }

                                        @media only screen and (max-width: 1360px) {
                                            font-size: 16px;
                                            line-height: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .swiper-slide {
                        overflow: hidden;
                        display: block;  


                        a {
                            width: 100%;
                            height: 100%;
                            display: block;  
                        }

                        .slide_video {
                            display: block;
                            width: 100%;
                            position: relative;
                            background-color: $primaryColor;

                            a {
                                display: block;
                                width: 100%;
                                height: 0;
                                padding-bottom: calc((876 / 1920) * 100%);
                                position: relative;

                                iframe {
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    background: #fbf4ec;
                                    width: 100%;
                                    height: 100%;
                                }

                                .block_redirect {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .cta_video_redirect, .block_redirect {
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                cursor: pointer;
                                z-index: 5;
                            }
                        }
                    }

                    .coverslide_nav_bg {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 918px;
                        height: 136px;
                        max-width: 55vw;
                        margin: auto;
                        z-index: 6;

                        @media only screen and (max-width: 1580px) {
                            max-width: 60vw;
                        }

                        @media only screen and (max-width: 1380px) {
                            height: 110px;
                            max-width: 65vw;
                        }

                        @media only screen and (max-width: 1260px) {
                            max-width: 70vw;
                        }

                        @media only screen and (max-width: 1160px) {
                            max-width: 75vw;
                        }

                        &:before {
                            width: 918px;
                            min-width: 100%;
                            max-width: 55vw;
                            margin: auto;
                            height: 500px;
                            background: #FFF;
                            border-radius: 100%;
                            content: '';
                            position: absolute;
                            left: 0;
                            right: 0;
                            margin: auto;
                            z-index: -1;

                            @media only screen and (max-width: 1580px) {
                                max-width: 60vw;
                            }

                            @media only screen and (max-width: 1380px) {
                                max-width: 65vw;
                            }

                            @media only screen and (max-width: 1260px) {
                                max-width: 70vw;
                            }

                            @media only screen and (max-width: 1160px) {
                                max-width: 75vw;
                            }

                        }

                        .swiper_btn_container {
                            width: 225px;
                            height: 100%;
                            margin: auto;
                            position: relative;

                            .swiper-button-next,
                            .swiper-button-prev {
                                width: 71px;

                                &:focus, &:focus-visible {
                                    border: none;
                                }
                            }

                            .swiper-button-prev {
                                left: 0;
                                right: inherit;
                                transform: rotate(180deg);
                            }

                            .swiper-pagination {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                font-size: 14px;
                                letter-spacing: .1em;
                                font-family: $primaryFont;
                                text-align: center;
                                width: 50px;
                                color: $darkColor;
                                height: 14px;
                                font-weight: 400;
                            }
                        }
                    }
                }

                &.push-product-module {
                    width: 100%;
                    max-width: 1680px;
                    padding-bottom: 150px;
                    margin: auto;
                    position: relative;
                    padding-top: 100px;
                    margin-bottom: 130px;
                    box-sizing: border-box;

                    @media only screen and (max-width: 1370px) {
                        margin-bottom: 70px;
                        padding-bottom: 50px;
                    }

                    @media only screen and (max-width: 1024px) {
                        max-width: 100vw;
                        margin: 0 0 100px 0;
                    }

                    &:before {
                        content:'';
                        position: absolute;
                        width: 1280px;
                        background-color: #FFF;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        height: 100%;
                        max-width: 76vw;
                        border-radius: 0 0 50% 50%;

                        @media only screen and (max-width: 1640px) {
                                height: 100%;
                        }

                        @media only screen and (max-width: 1460px) {
                                height: 90%;
                        }

                        @media only screen and (max-width: 1370px) {
                                height: 90%;
                        }

                        @media only screen and (max-width: 1190px) {
                                height: 90%;
                        }

                        @media only screen and (max-width: 1080px) {
                                height: 85%;
                        }

                        @media only screen and (max-width: 1024px) {
                                height: 95%;
                        }

                        @media only screen and (max-width: 960px) {
                                height: 90%;
                        }

                        @media only screen and (max-width: 850px) {
                                height: 85%;
                        }
                    }

                    @media only screen and (max-width: 1720px) {
                        padding: 7.9% 32px 0 32px;
                    }

                    @media only screen and (max-width: 1460px) {
                        margin-bottom: 0;
                    }

                    @media only screen and (max-width: 1370px) {
                        padding: 4.9% 32px 100px 32px;
                    }

                    @media only screen and (max-width: 1300px) {
                        padding: 4.9% 32px 50px 32px;
                    }

                    @media only screen and (max-width: 1160px) {
                        padding: 4.9% 32px 0px 32px;

                        .pushProductHomeWrapper {
                            .wrap_rolloverproduit {
                                top: calc(100% - 190px) !important;
                            }
                        }
                    }

                    @media only screen and (max-width: 1024px) {
                        margin: 0 0 50px 0;
                    }

                    @media only screen and (max-width: 1023px) {
                        padding: 7.9% 10px 0 10px;
                    }

                    .pushProductHomeWrapper_main {
                        width: 100%;
                        max-width: 1680px;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        height: 825px;
                        max-height: 825px;
                        position: relative;
                        z-index: 5;

                        @media only screen and (max-width: 1370px) {
                            height: 700px;
                            max-height: 700px;
                        }

                         @media only screen and (max-width: 1024px) {
                            height: 600px;
                            max-height: 600px;
                         }

                        .home-module-link {
                            display: block;

                            img {
                                width: 100%;
                                height: 100%;
                            }

                            &.module_1 {
                                max-width: 461px;
                                width: 27.44%;
                                margin-bottom: 80px;
                                align-self: flex-end;
                            }
                            &.module_2 {
                                max-width: 300px;
                                align-self: flex-end;
                                width: 17.857%;
                            }
                            &.module_3 {
                                max-width: 460px;
                                width: 27.3809%;
                                align-self: flex-start;
                            }

                            span.ill_img{
                                opacity: 0;

                                &.imgLoaded {
                                    opacity: 1;
                                }
                            }
                        }

                        .pushProductHomeWrapper {
                            max-width: 600px;
                            width: 35.714%;
                            margin: 0 4.76%;
                            min-height: 400px;

                            @media only screen and (max-width: 1440px) {
                                width: 30.714%;

                                .wrap_rolloverproduit {
                                    .bloc_add_color {
                                        span {
                                            display: none !important;
                                        }
                                    } 
                                }
                            }

                            .item {
                                position: relative;

                                .ecalt_rect_place, .eclat_rect {
                                    position: absolute;
                                    top: 15px;
                                    left: 15px;
                                    z-index: 5;

                                    img {
                                        width: auto;
                                        height: 22px;
                                    }

                                    @media only screen and (max-width: 1380px) {
                                        top: 10px;
                                        left: 10px;
                                    }
                                }

                                .eclat_gondole_home, .eclat_rond {
                                    position: absolute;
                                    bottom: 125px;
                                    right: 24px;
                                    z-index: 5;

                                    img {
                                        width: auto;
                                        height: 22px;
                                    }
                                }

                                .productVisualMulti {
                                    z-index: 6;
                                    max-height: 772px;

                                    &.no_stock {
                                       /*opacity: .3; */
                                       opacity: 1;

                                       .swiper-slide, .swiper-slide-off, .swiper-slide-on {
                                            img {
                                                opacity: .3; 
                                            }
                                       }
                                    }
                                }

                                .bloc_descrip {
                                    &.no_stock {
                                        position: relative;
                                    }
                                }

                                .wrap_rolloverproduit {
                                    z-index: 6;
                                    &.no_stock {
                                        opacity: 1 !important;
                                        z-index: 5 !important;
                                        position: initial !important;


                                            .expressAlertStock, .productSizeFieldset {
                                                /*display: none !important;*/
                                            }

                                        .no_stock_wrap, &+.no_stock_wrap {
                                            text-align: center;
                                            position: absolute;
                                            width: 100%;
                                            bottom: 0;
                                            top: 0;
                                            margin: auto;
                                            height: 5rem;
                                            z-index: 5;
                                            text-transform: uppercase;
                                            line-height: 1.5;
                                            padding: 1rem;
                                            display: block !important;
                                            transition: all .35s ease-in-out;
                                            background-color: inherit !important;

                                            small {
                                                font-size: .65rem;
                                                text-transform: none;
                                            }
                                        }
                                    }

                                    .btnAddBasketWrapper {
                                        position: relative;
                                        padding: 1rem;

                                        .alert_stock {
                                            padding:  .75rem 0;
                                            max-width: 90%;
                                            margin: auto;
                                            text-align: center;
                                        }
                                        .bloc_add_alert_form {
                                            position: relative;
                                            max-width: 90%;
                                            margin: .5rem auto;

                                            .w-form-line {
                                                .w-input-container {
                                                    .w-input {
                                                        .w-input-element {

                                                        }
                                                    }
                                                }
                                            }

                                            .form_submit {
                                                max-width: 110px;
                                                width: 100%;
                                                height: 46px;
                                                position: absolute;
                                                right: 1px;
                                                top: 0px;
                                                bottom: 0;
                                                margin: auto;
                                                padding: 0 1rem 1rem;
                                                background-color: #FFF;

                                                button {
                                                    background-color: inherit;
                                                    border: none;
                                                    font-size: 0;
                                                    display: inline-block;
                                                    width: 100%;
                                                    height: 46px;
                                                    background-size: contain;
                                                    background-repeat: no-repeat;
                                                    background-position: center center;
                                                    background-image: url(../svg/arrow_btn_black.svg);
                                                    background-color: transparent;
                                                    border: none;

                                                    span {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }

                                        bloc_add_alert_confirmation {
                                            padding: 1rem;
                                        }

                                        .alert_return {
                                            position: absolute;
                                            top: 10px;
                                            right: 10px;
                                        }
                                    }
                                }

                                &:hover {
                                    .wrap_rolloverproduit {
                                        .no_stock_wrap, &+.no_stock_wrap {
                                            opacity: 1 !important;
                                        }
                                    }
                                }
                            }

                            .block_lnk {
                                display: block;
                                width: 100%;

                                .wrapper_description {
                                    display: flex;
                                    flex-direction: column;
                                    margin-top: 11px;

                                    .name {
                                        width: calc(100% - 130px);
                                        order: 1;
                                        margin-bottom: 5px;

                                        .item_title, h2 {
                                            font-size: 13px;
                                            font-family: $primaryFont;
                                            font-weight: 200;
                                            display: inline-flex;
                                            color: $darkColor;
                                            position: relative;
                                            margin-bottom: 5px;
                                        }
                                    }
                                    .item_price {
                                        order: 2;
                                        width: 100%;

                                        &+.item_price {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .productSwiper  {
                                

                                .productBigPictures {
                                    width: 100%;

                                    .swiper-slide-off {
                                        width: 100%;
                                    }

                                    .swiper-slide{
                                        .block_lnk{
                                            display: block;
                                            overflow: hidden;
                                        }
                                    }
                                }

                                .rollover_rayonsNext,
                                .rollover_rayonsPrev {
                                    opacity: 0; 
                                    margin: auto 0;
                                    height: 35px;
                                    transition: opacity 0.35s ease-in-out;
                                    z-index: 50;
                                    cursor: pointer;
                                }

                                .rollover_rayonsNext {
                                    right: 16px;
                                }

                                .rollover_rayonsPrev {
                                    left: 16px;
                                }

                                &:hover {
                                    .rollover_rayonsNext,
                                    .rollover_rayonsPrev {
                                        opacity: 1;

                                        &.swiper-button-disabled {
                                            opacity: .35;
                                            cursor: auto;
                                            pointer-events: none;
                                        }

                                    }
                                }
                            }


                            .productColorFieldset,
                            .productSizeFieldset{
                                .prod_listes{
                                    .titre_choices_list{
                                        display: none;
                                    }
                                }

                                .choices_list{
                                    [type=radio]:checked+label:before, 
                                    [type=radio]:not(:checked)+label:before,
                                    [type=radio]:checked+label:after, 
                                    [type=radio]:not(:checked)+label:after{
                                        display: none;
                                    }
                                }
                            }

                            .productColorFieldset {
                                height: 3rem;
                                overflow-y: auto;
                                overflow-x: hidden;
                            }

                            .wrap_rolloverproduit {
                                position: absolute;
                                bottom: inherit;
                                top: calc(100% - 169px);
                                opacity: 1;
                                z-index: 5;
                                background-color: transparent;
                                width: 100%;

                                @media only screen and (max-width: 1360px) {
                                    top: calc(100% - 174px);
                                }

                                @media only screen and (max-width: 1024px) {
                                    top: calc(100% - 149px);
                                }

                                @media only screen and (max-width: 768px) {
                                    top: calc(100% - 242px);
                                }

                                .productSizeFieldset, .btnAddBasketWrapper, .bloc_add_color  {
                                    opacity: 0;

                                }

                                .bloc_add_color {
                                    @media only screen and (max-width: 1160px) {
                                        span {
                                            display: none!important;
                                            font-size: 0;
                                        }
                                    }
                                }

                                .productColorFieldset {
                                    position: absolute;
                                    top: 100%;
                                    right: 3px;
                                    margin-top: 10px;
                                    width: 120px;

                                    @media only screen and (max-width: 1370px) {
                                        top: calc(100% + 18px);
                                    }

                                    .prod_listes  {
                                        .choices_list{
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
                                            flex-wrap: wrap;
                                            width: 125px;
                                            max-width: 100%;

                                            .simplebar-wrapper {
                                                .simplebar-content {
                                                    display: flex;
                                                    flex-wrap: wrap;
                                                    justify-content: flex-end;
                                                }
                                            }

                                            .form_itm.color{

                                                margin-left: 7px;
                                                margin-bottom: 5px;

                                                [type=radio]:checked,
                                                [type=radio]:not(:checked){
                                                    & + label{
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 18px;
                                                        height: 18px;
                                                        border-radius: 50%;
                                                        margin: 0;
                                                        border: solid 1px rgba(0,0,0,0.075);
                                                        background-size: contain;
                                                        transition: all 0.4s ease-in-out;

                                                        &[title="Blanc"],
                                                        &[title="White"],
                                                        &[title="Ecru"]{
                                                            border: solid 1px #dddddd;
                                                        }
                                                    }
                                                }

                                                [type=radio]:checked{
                                                    & + label{
                                                        border: solid 1px #000000;

                                                        &[title="Blanc"],
                                                        &[title="White"],
                                                        &[title="Ecru"]{
                                                            border: solid 1px #000000;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .productErrorFieldset {
                                    position: absolute;
                                    top: 0.5rem;
                                    left: 2.5rem;
                                    font-size: 0.65rem;
                                    font-style: italic;
                                    color: red;
                                }

                                .productSizeFieldset{
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    /*background-color: rgba(255, 255, 255, 0.9);*/
                                    width: 100%;
                                    min-height: 116px;
                                    /*position: absolute;
                                    left: 0;
                                    bottom: 0;*/
                                    padding: 24px 32px;
                                    z-index: 2;

                                    @media only screen and (max-width: 1370px) {
                                        padding: 15px 10px;
                                    }

                                    .productErrorFieldset{
                                        position: absolute;
                                        top: 0.5rem;
                                        left: 2.5rem;
                                        font-size: 0.65rem;
                                        font-style: italic;
                                        color: red;
                                    }

                                    .choices_list{
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        flex-wrap: wrap;
                                        width: 100%;
                                        max-width: 235px;

                                        .form_itm.size{
                                            @media only screen and (max-width: 820px) {
                                                &:nth-of-type(n+6){
                                                    margin-top: 8px;
                                                } 
                                            }

                                            &:nth-of-type(n+7){
                                                margin-top: 8px;
                                            }

                                            &:not(:last-of-type){
                                                margin-right: 8px;
                                            }

                                            [type=radio]:checked,
                                            [type=radio]:not(:checked){
                                                & + label{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    width: 30px;
                                                    height: 30px;
                                                    position: relative;
                                                    font-family: $primaryFont;
                                                    font-weight: 400;
                                                    font-size: 11px;
                                                    letter-spacing: 0.55px;
                                                    margin-right: 0;

                                                    &:before{
                                                        content: " ";
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        background-color: transparent;
                                                        width: 100%;
                                                        height: 100%;
                                                        border: solid 1px $primaryColor;
                                                        border-radius: 50%;
                                                        position: absolute;
                                                        left: 50%;
                                                        top: 50%;
                                                        margin-left: -16px;
                                                        margin-top: -16px;
                                                        margin-right: 0;
                                                        opacity: 0;
                                                        transition: all 0.4s ease-in-out;
                                                    }
                                                }

                                                &.disabled{
                                                    & + label{
                                                        color: #cccccc;
                                                    }
                                                }
                                            }

                                            [type=radio]:checked{
                                                & + label{
                                                    &:before{
                                                        opacity: 1;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                form {

                                    .form_submit{
                                        &.bloc_add_color{
                                            position: absolute;
                                            right: 32px;
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;
                                            height: auto;
                                            max-width: 160px;

                                            @media only screen and (max-width: 1370px) {
                                                right: 15px;
                                                 max-width: 130px;
                                            }

                                            .button{
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-end;
                                                background: transparent;
                                                border: none;
                                                @extend .textStyles;
                                                text-transform: none;

                                                span{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    @extend .textStyles;
                                                    text-transform: none;
                                                    width: 100%;
                                                    font-size: 13px !important;

                                                    @media only screen and (max-width: 1370px) {
                                                        font-size: 11px !important;
                                                    }
                                                }

                                                &:before{
                                                    display: none;
                                                }

                                                &:after{
                                                    content: " ";
                                                    display: block;
                                                    background: url('../svg/icones.svg');
                                                    background-repeat: no-repeat;
                                                    background-size: 800%;
                                                    background-position: 27% 26%;
                                                    width: 20px;
                                                    height: 25px;
                                                    z-index: 2;

                                                    /* FOR IE :( */
                                                    @media screen and (min-width:0\0) {
                                                        /*background-position: 27% 32%;*/
                                                    }
                                                }

                                                &.loader {
                                                    opacity: 0 !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .wrap_rolloverproduit {
                                    background-color: rgba(255,255,255,.9);

                                    .productSizeFieldset, .btnAddBasketWrapper, .bloc_add_color  {
                                        opacity: 1;
                                    }
                                }
                            }

                        }
                    }


                    .button_and_pagination {
                        position: absolute;
                        bottom: 18.25%;
                        right: 40px;
                        width: 110px;
                        display: none;

                        .swiper-pushProduct-button {
                            position: absolute;
                            right: 0;
                            bottom: 3px;
                            top: inherit;
                            left: inherit;
                        }

                        @media only screen and (max-width: 1360px) {
                            bottom: 24.25%;
                        }
                    }
                }

                &.image-image-image-text {
                    padding-bottom: 150px;

                    @media only screen and (max-width: 1740px) {
                       padding: 0 32px;
                       padding-bottom: 150px;
                    }


                    @media only screen and (max-width: 1370px) {
                        padding-bottom: 80px;
                    }

                    .image-image-image-wrapper {
                        width: 100%;
                        max-width: 1680px;
                        margin: auto;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .home-module-title {
                                font-size: 65px;
                                font-weight: 600;
                                font-family: $secondaryFont;
                                line-height: 1;
                                color: $darkColor;
                                letter-spacing: -0.025em;
                                margin-bottom: 80px;
                                text-align: center;
                                width: 100%;

                                @media only screen and (max-width: 1680px) {
                                    font-size: 60px;
                                }

                                @media only screen and (max-width: 1580px) {
                                    font-size: 55px;
                                    margin-bottom: 70px;
                                }

                                @media only screen and (max-width: 1480px) {
                                    font-size: 50px;
                                    margin-bottom: 70px;
                                }

                                @media only screen and (max-width: 1370px) {
                                    font-size: 45px;
                                    margin-bottom: 50px;

                                }

                        }

                        p {
                            width: 1280px;
                            max-width: 76.19%;
                            padding: 0 32px;
                            margin: 80px auto 0;
                            line-height: 21px;
                            text-align: justify;
                            letter-spacing: 0.05em;

                        }

                        .home-column {
                            &:nth-of-type(1) {
                                width: 24.16%;
                            }
                            &:nth-of-type(2) {
                                width: 42.14%;
                            }
                            &:nth-of-type(3) {
                                width: 24.16%;
                            }

                            @media only screen and (max-width: 1370px) {
                                &:nth-of-type(1) {
                                width: 22.16%;
                                }
                                &:nth-of-type(2) {
                                    width: 38.5%;
                                }
                                &:nth-of-type(3) {
                                    width: 22.16%;
                                }
                            }
                        }
                    }
                }

                &.image-image-image {
                    padding: 80px 0 150px;
                    position: relative;
                    background: #31574a;
                    z-index: 0;

                    @media only screen and (max-width: 1740px) {
                       padding: 80px 32px 150px;
                    }

                    @media only screen and (max-width: 1370px) {
                        padding-bottom: 80px;
                    }

                    &:before {
                        content:'';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background: $bgColor2;
                        height: 56.64%;
                        width: 100%;
                        z-index: -1;
                    }

                    .image-image-image-wrapper {

                        width: 100%;
                        max-width: 1680px;
                        margin: auto;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .home-column {
                            width: 506px;
                            position: relative;

                            .home-module-title {
                                color: #FFF;
                                font-size: 50px;
                                font-family: $secondaryFont;
                                letter-spacing: -0.025em;
                                position: absolute;
                                top: 10.5%;
                                width: 100%;
                                margin: auto;
                                text-align: center;

                                @media only screen and (max-width: 1680px) {
                                    font-size: 48px;
                                }


                                @media only screen and (max-width: 1370px) {
                                    font-size: 45px;
                                }

                                @media only screen and (max-width: 1360px) {
                                    font-size: 40px;
                                }

                                @media only screen and (max-width: 1160px) {
                                    font-size: 37px;
                                }
                            }

                            @media only screen and (max-width: 1650px) {
                                max-width: 30.119%;
                            }

                            .home-module-cta {
                                font-size: 14px;
                                font-family: $primaryFont;
                                letter-spacing: 0.05em;
                                position: relative;
                                padding-right: 90px;
                                float: right;
                                margin-top: 20px;

                                @media only screen and (max-width: 1680px) {
                                    font-size: 13px;
                                }

                                @media only screen and (max-width: 1370px) {
                                    font-size: 12px;
                                }

                                @media only screen and (max-width: 1360px) {
                                    font-size: 11px;
                                }

                                &:before {
                                    content:'';
                                    width: 70px;
                                    background: url(../svg/arrow_btn_black.svg) center center no-repeat;
                                    background-size: contain;
                                    position: absolute;
                                    height: 9px;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                    right: 0;
                                }

                                &::first-letter {
                                    text-transform: uppercase;
                                }

                            }

                        }
                    }
                }

                &.slider-module {
                    padding: 80px 0 150px;
                    background: #FFF url(../img/fp_bg.jpg) center bottom no-repeat;
                    margin-bottom: 150px;
                    

                    @media only screen and (max-width: 1760px) {
                        padding: 80px 20px 80px;
                    }

                    @media only screen and (max-width: 1370px) {
                        padding: 40px 20px 40px;
                        margin-bottom: 80px;
                    }

                    .home-columns-container {
                        width: 100%;
                        max-width: 1680px;
                        margin: auto;
                        position: relative;

                        .home-column {
                            .home_title {
                                .home-module-title {
                                    font-size: 65px;
                                    font-weight: 600;
                                    font-family: $secondaryFont;
                                    line-height: 1;
                                    color: $darkColor;
                                    letter-spacing: -0.025em;
                                    margin-bottom: 80px;
                                    text-align: center;

                                    span {
                                        font-size: 65px;
                                    }

                                    @media only screen and (max-width: 1580px) {
                                        font-size: 50px;
                                        span {
                                            font-size: 50px;
                                        }
                                    }
                                    
                                    @media only screen and (max-width: 1370px) {
                                        font-size: 45px;
                                        margin-bottom: 40px;

                                        span {
                                            font-size: 45px;
                                        }
                                    }

                                }
                            }

                            .block_gondole {
                                #tete_gondole {
                                    #productAssociationSwiper {
                                        opacity: 0;
                                        .content-wrapper {
                                            .item.swiper-slide {
                                                position: relative;
                                                max-width: inherit;
                                                width: 359px;

                                                @media only screen and (max-width: 1680px) {
                                                    width: 310px;
                                                }

                                                @media only screen and (max-width: 1370px) {
                                                    width: 270px;
                                                }

                                                 @media only screen and (max-width: 1300px) {
                                                    width: 400px;
                                                 }

                                                 .productVisualMulti {
                                                    &.no_stock {
                                                        img {
                                                            opacity: .3;
                                                        }
                                                    }
                                                 }

                                                .wrap_video {
                                                    position: relative;
                                                    height: 100%;

                                                    iframe {
                                                        max-width: 100%;
                                                        position: relative;
                                                        top: 0;
                                                        left: 0;
                                                    }

                                                    img {
                                                        opacity: 0;
                                                    }
                                                }

                                                &.reload_slide_colorchange {
                                                    margin-right: 55px;

                                                    @media only screen and (max-width: 1370px) {
                                                       width: 359px;
                                                    }
                                                }

                                                .ecalt_rect_place {
                                                    position: absolute;
                                                    top: 15px;
                                                    left: 15px;
                                                    z-index: 5;

                                                    img {
                                                        width: auto;
                                                        height: 22px;
                                                    }

                                                    @media only screen and (max-width: 1380px) {
                                                        top: 10px;
                                                        left: 10px;
                                                    }
                                                }

                                                .eclat_gondole_home {

                                                    position: absolute;
                                                    bottom: 90px;
                                                    right: 10px;
                                                    z-index: 5;

                                                    img {
                                                        width: auto;
                                                        height: 22px;

                                                        @media only screen and (max-width: 1370px) {
                                                            
                                                        }
                                                    }
                                                }

                                                .no_stock_wrap {
                                                    text-align: center;
                                                    position: absolute;
                                                    width: 100%;
                                                    bottom: 40%;
                                                    top: auto;
                                                    margin: auto;
                                                    z-index: 1;
                                                    height: 7rem;
                                                    text-transform: uppercase;
                                                    line-height: 1.5;
                                                    padding: 1rem 0.25rem;
                                                    display: block !important;
                                                    transition: all .35s ease-in-out;

                                                    @media only screen and (max-width: 1370px) {
                                                        bottom: 45%;
                                                    }

                                                    small {
                                                        font-size: .65rem;
                                                        text-transform: none;
                                                    }
                                                }

                                                .wrap_rolloverproduit {
                                                    z-index: 5;
                                                    opacity: 1;
                                                    bottom: 79px;
                                                    background-color: transparent;
                                                    transition: all ease-in-out .5s;

                                                    /* FOR IE :( */
                                                    @media screen and (min-width:0\0) {
                                                        background-position: 27% 26%;
                                                    }

                                                    .productSizeFieldset, .bloc_add_color, .btnAddBasketWrapper {
                                                        opacity: 0;
                                                    }

                                                    .productSizeFieldset{
                                                        .productErrorFieldset{
                                                            position: absolute;
                                                            top: 0.5rem;
                                                            left: 2.5rem;
                                                            font-size: 0.65rem;
                                                            font-style: italic;
                                                            color: red;
                                                        }
                                                    }

                                                    form {
                                                        .form_submit.bloc_add_color {
                                                            .button {
                                                                span {
                                                                }
                                                            }
                                                        }

                                                        .rollover_left {
                                                            .productColorFieldset{
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: flex-end;
                                                                flex-wrap: wrap;
                                                                width: 130px;
                                                                max-width: 100%;
                                                                position: absolute;
                                                                top: calc(100% + 15px);
                                                                right: 0;

                                                                .prod_listes{
                                                                    .titre_choices_list{
                                                                        display: none;
                                                                    }
                                                                }

                                                                .choices_list{
                                                                    [type=radio]:checked+label:before, 
                                                                    [type=radio]:not(:checked)+label:before,
                                                                    [type=radio]:checked+label:after, 
                                                                    [type=radio]:not(:checked)+label:after{
                                                                        display: none;
                                                                    }
                                                                }       
                                                               
                                                                .choices_list{
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: flex-end;
                                                                    flex-wrap: wrap;
                                                                    max-width: 100%;
                                                                    width: 130px;
                                                                    max-height: 46px;
                                                                    overflow-x: hidden;
                                                                    overflow-y: auto;
                                                                    padding-right: .5rem;
                            
                                                                    .simplebar-content {
                                                                        display: flex;
                                                                        flex-wrap: wrap;
                                                                        justify-content: flex-end;
                                                                    }

                                                                    @media only screen and (max-width: 1370px) {
                                                                        width: 100px;
                                                                    }

                                                                    .form_itm.color{
                                                                        margin: 0 0 5px 8px;

                                                                        [type=radio]:checked,
                                                                        [type=radio]:not(:checked){
                                                                            & + label{
                                                                                display: flex;
                                                                                align-items: center;
                                                                                justify-content: center;
                                                                                width: 18px;
                                                                                height: 18px;
                                                                                border-radius: 50%;
                                                                                margin: 0;
                                                                                border: solid 1px rgba(0,0,0,0.075);
                                                                                transition: all 0.4s ease-in-out;
                                                                                background-size: contain;

                                                                                &[title="Blanc"],
                                                                                &[title="White"],
                                                                                &[title="Ecru"]{
                                                                                    border: solid 1px #dddddd;
                                                                                }
                                                                            }
                                                                        }

                                                                        [type=radio]:checked{
                                                                            & + label{
                                                                                border: solid 1px #000000;

                                                                                &[title="Blanc"],
                                                                                &[title="White"],
                                                                                &[title="Ecru"]{
                                                                                    border: solid 1px #000000;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .bloc_add_color {
                                                            .button {
                                                                &:after {
                                                                   /* FOR IE :( */
                                                                    @media screen and (min-width:0\0) {
                                                                        background-position: 27% 26%;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .wrapper_description {
                                                    width: calc(100% - 130px);
                                                    min-height: 65px;
                                                    justify-content: flex-start;

                                                    @media only screen and (max-width: 1370px) {
                                                        width: calc(100% - 100px);
                                                    }

                                                    .name {
                                                        width: 100%;

                                                        .item_price {
                                                            width: 100%;
                                                        }
                                                    }
                                                }

                                                .rollover_rayonsNext,
                                                .rollover_rayonsPrev {
                                                    opacity: 0; 
                                                    margin: auto 0;
                                                    height: 35px;
                                                    transition: opacity 0.35s ease-in-out;
                                                    z-index: 30;
                                                    cursor: pointer;
                                                    position: absolute;

                                                    @media only screen and (max-width: 1380px) {
                                                        max-width: 45px; 
                                                    }

                                                }

                                                .rollover_rayonsNext {
                                                    right: 16px;
                                                }

                                                .rollover_rayonsPrev {
                                                    left: 16px;
                                                }


                                                &:hover{
                                                    .wrap_rolloverproduit {
                                                        background-color: rgba(255,255,255,.9);
                                                        .productSizeFieldset, .bloc_add_color, .btnAddBasketWrapper {
                                                            opacity: 1;
                                                        }
                                                    }

                                                    .rollover_rayonsNext,
                                                    .rollover_rayonsPrev {
                                                        opacity: 1;

                                                        &.swiper-button-disabled {
                                                            opacity: .35;
                                                            cursor: auto;
                                                            pointer-events: none;
                                                        }

                                                    }

                                                    .no_stock_wrap {
                                                        z-index: -1;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .swiper-button-next.swiper-button-disabled,
                                    .swiper-button-prev.swiper-button-disabled {
                                        opacity: 0 !important;
                                    }

                                    &.no_nav_big {
                                        .swiper-button-prev.btn_prv,
                                        .swiper-button-next.btn_nxt {
                                            @media only screen and (min-width: 1361px) {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.text-module {
                    margin: 80px 0;
                    text-align: center;

                    @media only screen and (max-width: 1740px) {
                       padding: 0 32px;
                    }

                    @media only screen and (max-width: 1360px) {
                        margin: 60px 0;
                    }

                    .home-module-text {
                        width: 100%;
                        max-width: 1680px;
                        margin: auto;
                        font-size: 65px;
                        font-family: $secondaryFont;
                        font-weight: 600;
                        letter-spacing: -0.025em;

                        @media only screen and (max-width: 1580px) {
                            font-size: 55px;
                        }


                        @media only screen and (max-width: 1360px) {
                            font-size: 45px;
                        }

                    }
                }

                &.image-image-image-video-text {
                    padding-bottom: 80px;
                    position: relative;


                    @media only screen and (max-width: 1740px) {
                       padding: 0 32px 80px;
                    }

                    &:before {
                        content:'';
                        position: absolute;
                        height: 23.43%;
                        width: 100%;
                        left: 0;
                        bottom: 0;
                        background-color: #FFF;
                        z-index: -1;
                    }

                    .image-image-image-wrapper {
                        width: 100%;
                        max-width: 1680px;
                        margin: auto;
                        display: flex;
                        flex-wrap: wrap;

                        .video_link{
                            display: block;
                            position: relative;
                            width: 100%;
                            height: 0;
                            padding-bottom: calc((550 / 980) * 100%);

                            iframe {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        @media only screen and (max-width: 1360px) {
                            max-width: 960px;
                        }

                        .home-module-title {
                            font-size: 65px;
                            font-weight: 600;
                            font-family: $secondaryFont;
                            line-height: 1;
                            color: $darkColor;
                            letter-spacing: -0.025em;
                            margin-bottom: 80px;
                            text-align: center;
                            width: 100%;

                            @media only screen and (max-width: 1680px) {
                                    font-size: 60px;
                            }


                            @media only screen and (max-width: 1370px) {
                                font-size: 50px;
                                margin-bottom: 40px;
                            }
                        }

                        .home-column {
                            margin-bottom: 2rem;

                            p {
                                font-size: 13px;
                                line-height: 21px;
                                margin-top: 30px;
                                text-align: justify;
                            }

                            &:nth-of-type(1) {
                                width: 58.333%;
                                margin-right: 4.75%;
                                overflow-x: hidden;
                                overflow-y: visible;

                                .home-module-cta {
                                    float: right;

                                    &::first-letter {
                                        text-transform: uppercase;
                                    }
                                }

                                @media only screen and (max-width: 1360px) {
                                    width: 52%;
                                    margin-right: 4.15%;
                                }

                            }

                            &:nth-of-type(2) {
                                width: 32.2%;

                                .home-module-title {
                                    text-align: left;
                                    margin-top: 80px;
                                    margin-bottom: 80px;

                                    @media only screen and (max-width: 1580px) {
                                    font-size: 55px;
                                    }

                                    @media only screen and (max-width: 1370px) {
                                        margin-top: 40px;
                                        margin-bottom: 40px;
                                        font-size: 45px;
                                    }
                                }

                                @media only screen and (max-width: 1360px) {
                                    width: 43.75%;
                                }
                            }
                            &:nth-of-type(3) {
                                width: 46.428%;
                                padding: 0 4.76%;
                                margin-top: -5.25%;

                                @media only screen and (max-width: 1440px) {
                                    margin-top: -3%;
                                }

                                @media only screen and (max-width: 1370px) {
                                    margin-top: -5%;
                                }

                                @media only screen and (max-width: 1340px) {
                                    margin-top: 0%;
                                }

                            }
                            &:nth-of-type(4) {
                                width: 53.57%;
                            }

                            .home-module-cta {
                                font-size: 14px;
                                font-family: $primaryFont;
                                letter-spacing: 0.05em;
                                position: relative;
                                padding-right: 90px;
                                float: left;
                                margin-top: 25px;

                                /*@media only screen and (max-width: 1680px) {
                                    font-size: 13px;
                                }

                                @media only screen and (max-width: 1370px) {
                                    font-size: 12px;
                                }

                                @media only screen and (max-width: 1360px) {
                                    font-size: 11px;
                                }*/

                                &:before {
                                    content:'';
                                    width: 70px;
                                    background: url(../svg/arrow_btn_black.svg) center center no-repeat;
                                    background-size: contain;
                                    position: absolute;
                                    height: 9px;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                    right: 0;
                                }

                                &::first-letter {
                                    text-transform: uppercase;
                                }

                            }
                        }


                    }

                }

                &.social-module {
                    background-color: $bgColor2 !important;
                    padding: 7.813vw 0;
                    position: relative;

                    .home-module-body {
                        width: 100%;
                        max-width: 66.667vw;
                        margin: auto;

                        /*@media only screen and (max-width: 1360px) {
                            max-width: 960px;
                        }*/
                    }

                    .home-columns-container {
                        .home-column {

                            &.column_1 {
                                margin-top:  20px;

                                .home-module-title {
                                    font-size: 18px;
                                    text-transform: uppercase;
                                    letter-spacing: 0.1em;
                                    color: $darkColor;
                                    line-height: 25px;
                                }
                            }

                            &.column_2 {
                                .social-media-container {
                                    #sliderHomeInsta {
                                        .swiper-wrapper {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            flex-direction: column;
                                            height: 33.333vw;
                                            flex-wrap: wrap;

                                            /*@media only screen and (max-width: 1360px) {
                                                height: 475px;
                                            }*/

                                            .swiper-slide {

                                                display: inline-flex;
                                                height: auto;
                                                

                                                a:not(.text) {
                                                    display: block;
                                                    background-size: cover;
                                                }

                                                &:nth-of-type(1) {
                                                    width: 11.979vw;
                                                    height: 11.979vw;
                                                    justify-content: flex-end;
                                                    margin-right: 4.115vw;
                                                    align-self: flex-end;

                                                    a {
                                                        width: 11.979vw;
                                                        height: 11.979vw; 
                                                    }

                                                    /*@media only screen and (max-width: 1360px) {
                                                        width: 170px;
                                                        height: 170px;
                                                        margin-right: 40px;

                                                        a {
                                                            width: 170px;
                                                            height: 170px; 
                                                        }
                                                    }

                                                    @media only screen and (max-width: 1023px) {
                                                        width: 125px;
                                                        height: 125px; 

                                                        a {
                                                            width: 125px;
                                                            height: 125px;
                                                        }
                                                    }

                                                    @media only screen and (max-width: 850px) {
                                                        width: 100px;
                                                        height: 100px; 

                                                        a {
                                                            width: 100px;
                                                            height: 100px;
                                                        }
                                                    }*/
                                                }

                                                &:nth-of-type(2) {
                                                    width: 17.344vw;
                                                    margin-right: 4.115vw;

                                                    a {
                                                        width: 17.344vw;
                                                        height: 17.344vw; 
                                                    }

                                                    /*@media only screen and (max-width: 1360px) {
                                                        margin-right: 40px;
                                                         width: 265px;

                                                        a {
                                                            width: 265px;
                                                            height: 265px; 
                                                        }
                                                    }

                                                    @media only screen and (max-width: 1023px) {
                                                        width: 200px;

                                                        a {
                                                            width: 200px;
                                                            height: 200px; 
                                                        }
                                                    }

                                                    @media only screen and (max-width: 850px) {
                                                        width: 160px;

                                                        a {
                                                            width: 160px;
                                                            height: 160px; 
                                                        }
                                                    }*/
                                                }

                                                &:nth-of-type(3) {
                                                    width: 23.854vw;
                                                    margin-right: 4.115vw;
                                                    height: 100%;
                                                    display: flex;
                                                    align-items: center;

                                                    a {
                                                        width: 23.854vw;
                                                        height: 23.854vw; 
                                                    }

                                                    /*@media only screen and (max-width: 1360px) {
                                                        margin-right: 40px;
                                                        width: 350px;
                                                        margin-top: 1rem;

                                                        a {
                                                            width: 350px;
                                                            height: 350px; 
                                                        }
                                                    }

                                                    @media only screen and (max-width: 1023px) {
                                                        width: 280px;

                                                        a {
                                                            width: 280px;
                                                            height: 280px; 
                                                        }
                                                    }

                                                     @media only screen and (max-width: 850px) {
                                                        width: 240px;

                                                        a {
                                                            width: 240px;
                                                            height: 240px; 
                                                        }
                                                    }*/
                                                }

                                                &:nth-of-type(4) {
                                                    width: 17.344vw;

                                                    a {
                                                        width: 17.344vw;
                                                        height: 17.344vw; 
                                                    }

                                                    /*@media only screen and (max-width: 1360px) {
                                                         width: 265px;

                                                        a {
                                                            width: 265px;
                                                            height: 265px; 
                                                        }
                                                    }

                                                    @media only screen and (max-width: 1023px) {
                                                        width: 200px;

                                                        a {
                                                            width: 200px;
                                                            height: 200px; 
                                                        }
                                                    }

                                                    @media only screen and (max-width: 850px) {
                                                        width: 160px;

                                                        a {
                                                            width: 160px;
                                                            height: 160px; 
                                                        }
                                                    }*/
                                                }
                                                &:nth-of-type(5) {
                                                    width: 11.979vw;

                                                    a {
                                                        width: 11.979vw;
                                                        height: 11.979vw; 
                                                    }

                                                    /*@media only screen and (max-width: 1360px) {
                                                        width: 170px;
                                                        height: 170px;

                                                        a {
                                                            width: 170px;
                                                            height: 170px; 
                                                        }
                                                    }

                                                    @media only screen and (max-width: 1023px) {
                                                        width: 125px;
                                                        height: 125px; 

                                                        a {
                                                            width: 125px;
                                                            height: 125px;
                                                        }
                                                    }

                                                    @media only screen and (max-width: 850px) {
                                                        width: 100px;
                                                        height: 100px; 

                                                        a {
                                                            width: 100px;
                                                            height: 100px;
                                                        }
                                                    }*/
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* Section after cover module */
                /*
                &.four-images {
                    
                    position: relative;
                    min-height: 1000px;

                    &:before {
                        content:'';
                        position: absolute;
                        width: 1280px;
                        margin: auto;
                        left: 0;
                        right: 0;
                        top: 0;
                        height: 566px;
                        background-color: #FFF;
                    }

                    &:after {
                        content:'';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 1280px;
                        height: 900px;
                        background-color: #FFF;
                        z-index: -1;
                        border-radius: 100%;
                    }
                }*/

                .home_title {

                    .home-module-title {
                        span {
                            font-size: 65px;
                            font-weight: 600;
                            font-family: $secondaryFont;
                            line-height: 1;
                            margin-bottom: 90px;
                            color: $darkColor;
                            letter-spacing: -0.025em;

                            @media only screen and (max-width: 1360px) {
                                font-size: 45px;

                            }

                        }
                    }
                }
            }
        }
    }

    .swiper-button-next,
    .rollover_rayonsNext,
    .swiper-button-prev,
    .rollover_rayonsPrev {
        width: 71px;
        background: url(../svg/arrow_btn_black.svg) center center no-repeat;
        background-size: contain;
        position: absolute;
        height: 9px;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
    }

    .swiper-button-prev,
    .rollover_rayonsPrev {
        left: 0;
        right: inherit;
        transform: rotate(180deg);
    }

    .wrapper_description {
        .item_name {
            font-size: 13px;
            font-family: $primaryFont;
            font-weight: 200;
            display: inline-flex;
            color: $darkColor;
            position: relative;
        }

        .item_feat {
            font-size: 13px;
            font-family: $primaryFont;
            font-weight: 200;
            display: inline-flex;
            color: $darkColor;
        }

        .item_price {
            font-size: 16px;
            font-family: $primaryFont;
            font-weight: 900;
            display: inline-flex;
            color: $darkColor;
        }
    }

    
}
