.satellite_histoire {
    img {
        width: auto;
        max-width: 100%;
        height: auto;
    }

    .titleIntro {
        text-align: center;
        width: 1060px;
        max-width: 100%;
        margin: 0 auto;

        .title {
            font-family: $secondaryFont;
            font-size: 65px;
            line-height: 1;
            margin: 0 0 3rem;

            @media only screen and (max-width: 1680px) {
                    font-size: 60px;
            }

            @media only screen and (max-width: 1370px) {
                font-size: 50px;
            }
        }

        .intro {
            font-family: $primaryFont;
            font-size: 20 / 16 * 1rem;
            line-height: 1.5;
            margin: 2rem 0;

            @media screen and (max-width: 1680px) {
                font-size: 1rem;
            }

            @media only screen and (max-width: 1370px) {
                font-size: .95rem;
            }

            @media screen and (max-width: 1024px) {
                font-size: .9rem;
                width: 75%;
                margin: 2rem auto;
            }
        }

        .logo {
            margin: 4rem 0;

            @media screen and (max-width: 1024px) {
                margin: 2rem 0;
            }

            img {
                width: 40%;
                max-width: 100%;

                @media screen and (max-width: 1024px) {
                    width: 300px;
                }
            }

            p {
                font-family: $zoojaFont;
                font-size: 54 / 16 * 1rem;
                margin: 1rem 0 0;

                @media screen and (max-width: 1500px) {
                    font-size: 45 / 16 * 1rem;
                }
            }
        }
    }

    .menu-lamarque-histoire {
        margin: 0 0 6.5rem;

        @media screen and (max-width: 1366px) {
            margin: 0 0 3.5rem;
        }

        @media screen and (max-width: 1024px) {
            margin: 0 0 2.5rem;
        }

        &.fixed {
            position: fixed;
            top: 0;
            left: 0;
            background-color: $secondaryColor;
            z-index: 9;
            width: 100%;
        }

        ul {
            display: flex;
            justify-content: center;

            li {
                padding: 1rem;

                @media screen and (max-width: 1366px) {
                    padding: 1rem 0.5rem;
                }

                &.active {
                    a {
                        text-decoration: underline;
                        font-weight: 600;
                    }
                }

                a {
                    font-family: $primaryFont;
                    font-size: 13 / 16 * 1rem;
                    line-height: 1;
                    letter-spacing: .5px;

                    @media screen and (max-width: 1366px) {
                        font-size: 12 / 16 * 1rem;
                    }

                    @media screen and (max-width: 1024px) {
                        font-size: 11 / 16 * 1rem;
                    }

                }
            }
        }
    }

    
    .content_wrapper_inside {
        position: relative;

        &.has-background {
            background-color: $white;
        }

        .bloc-wrap {
            display: flex;
            flex-wrap: wrap;
            width: 1600px;
            max-width: 100%;
            margin: 0 auto;

            @media screen and (max-width: 1680px) {
                padding: 0 2rem;
            }
    
            .bloctext {
    
                h2 {
                    font-family: $primaryFont;
                    font-size: 48 / 19 * 1rem;
                    line-height: 1;
                    font-weight: 500;
                    margin: 0 0 1rem;

                    @media screen and (max-width: 1500px) {
                        font-size: 32 / 16 * 1rem;
                    }

                    @media screen and (max-width: 1024px) {
                        font-size: 24 / 16 * 1rem;
                    }
    
                    span {
                        font-family: $zoojaFont;
                        font-size: 70 / 16 * 1rem;
                        position: relative;
                        top: 0.3rem;

                        @media screen and (max-width: 1500px) {
                            font-size: 56 / 16 * 1rem;
                        }

                        @media screen and (max-width: 1024px) {
                            font-size: 38 / 16 * 1rem;
                            top: 0.2rem;
                        }
                    }
                }
    
                p {
                    font-family: $primaryFont;
                    font-size: 13 / 16 * 1rem;
                    line-height: 1.7;
                    text-align: justify;
                    
                    @media screen and (max-width: 1500px) {
                        font-size: 12 / 16 * 1rem;
                    }

                    @media screen and (max-width: 1024px) {
                        font-size: 10 / 16 * 1rem;
                    }
                }
            }

            .img-wrap {
                position: relative;
            }
            
            .info-text {               
                font-family: $zoojaFont;
                display: block;
            }
    
            &.text-right {
                .bloc-left {
                    width: 55%;

                    @media screen and (max-width: 1500px) {
                        width: 45%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-left: 2rem;
                    }
                }
        
                .bloc-right {
                    width: 45%;

                    @media screen and (max-width: 1500px) {
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
    
            &.text-center {
                .bloc-left {
                    width: 38%;

                    @media screen and (max-width: 1500px) {
                        padding-left: 2rem;
                    }

                    @media screen and (max-width: 1280px) {
                        width: 35%;
                    }
                }

                .bloc-center {
                    width: 40%;

                    @media screen and (max-width: 1280px) {
                        width: 45%;
                    }
                }

                .bloc-right {
                    width: 22%;

                    @media screen and (max-width: 1500px) {
                        padding-right: 2rem;
                    }

                    @media screen and (max-width: 1280px) {
                        width: 20%;
                    }
                }
            }

            &.text-50 {
                .bloc-left {
                    width: 50%;

                    @media screen and (max-width: 1500px) {
                        padding-left: 2rem;
                        padding-right: 1rem;
                    }
                }
        
                .bloc-right {
                    width: 50%;

                    @media screen and (max-width: 1500px) {
                        padding-left: 2rem;
                    }
                }
            }
    
        }

    }
    
}


.satellite_histoire .content_wrapper_inside {

    &.section-histoire-voyage {
        max-height: 830px;

        &:before {
            content: '';
            width: 100%;
            position: absolute;
            background: url('../img/histoire/floral_1.png') no-repeat 50% 50%;
            background-size: cover;
            height: 559px;
            bottom: 100%;
            left: 0;
            right: 0;
            z-index: -1;

            @media screen and (max-width: 1366px) {
                height: 75%;
            }

            @media screen and (max-width: 1024px) {
                height: 50%;
            }
        }

        p {
            font-style: italic;
        }

        .info-text {
            text-align: right;
            margin: 1rem 0;
            font-size: 40 / 16 * 1rem;
            
            @media screen and (max-width: 1500px) {
                font-size: 28 / 16 * 1rem;
            }

            @media screen and (max-width: 1024px) {
                font-size: 22 / 16 * 1rem;
            }
        }

        .bloc-left {
            padding-right: 1rem;

            .img-wrap {
                margin-top: -4rem;

                @media screen and (max-width: 1500px) {
                    margin-top: -1.5rem;
                    margin-bottom: -1.5rem;
                }
            }
        }

        .bloc-right {
            padding-left: 3rem;

            @media screen and (max-width: 1500px) {
                padding-left: 2rem;
            }

            .bloctext {
                margin-top: 3.5rem;
                width: 85%;

                @media screen and (max-width: 1500px) {
                    margin-top: 0;
                    width: 95%;
                }

                @media screen and (max-width: 1366px) {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }

                @media screen and (max-width: 1024px) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.section-imprimes-inspires {
        margin: 10rem 0 10rem;

        @media screen and (max-width: 1366px) {
            margin: 7rem 0 7rem;
        }

        @media screen and (max-width: 1024px) {
            margin: 5rem 0 5rem;
        }

        .info-text {
            margin: .5rem 0 .5rem auto;
            width: 68%;
            font-size: 30 / 16 * 1rem;
            
            @media screen and (max-width: 1500px) {
                font-size: 25 / 16 * 1rem;
            }

            @media screen and (max-width: 1024px) {
                font-size: 18 / 16 * 1rem;
            }
        }

        .bloc-left {
            padding-right: 1rem;

            .img-wrap {
                margin: 4rem 0 0;
            }
        }

        .bloc-center {
            padding-left: 1rem;

            .bloctext {
                margin: 11rem 0 0;
                width: 90%;

                @media screen and (max-width: 1366px) {
                    margin: 8rem 0 0;
                }

                @media screen and (max-width: 1280px) {
                    margin: 7rem 0 0;
                    width: 93%;
                }

                @media screen and (max-width: 1024px) {
                    margin: 5rem 0 0;
                }
            }

            .img-wrap {
                margin: 3.5rem 0 0;
                text-align: right;

                @media screen and (max-width: 1024px) {
                    margin: 2rem 0 0;
                }

                &:before {
                    content: '';
                    width: 765px;
                    position: absolute;
                    background: url('../img/histoire/floral_2.png') no-repeat 50% 50%;
                    background-size: contain;
                    height: 520px;
                    bottom: -20%;
                    right: -33%;
                    z-index: -1;

                    @media screen and (max-width: 1500px) {
                        width: 100%;
                        bottom: -32%;
                        right: -25%;
                    }

                    @media screen and (max-width: 1280px) {
                        width: 90%;
                    }

                    @media screen and (max-width: 1024px) {
                        width: 100%;
                        bottom: -70%;
                        right: -28%;
                    }
                }

                img {
                    margin-right: -3rem;

                    @media screen and (max-width: 1500px) {
                        max-width: 340px;
                    }

                    @media screen and (max-width: 1280px) {
                        max-width: 290px;
                    }

                    @media screen and (max-width: 1024px) {
                        max-width: 260px;
                    }
                }
            }
        }

        .bloc-right {
            padding-left: 1rem;

            .img-wrap {

                &::before {
                    content: '';
                    position: absolute;
                    top: 7rem;
                    left: -110px;
                    background: url('../img/histoire/fleche.png') no-repeat 50% 50%;
                    width: 90px;
                    height: 72px;

                    @media screen and (max-width: 1500px) {
                        background-size: 80% auto;
                    }

                    @media screen and (max-width: 1366px) {
                        background-size: 65% auto;
                        top: 3rem;
                    }

                    @media screen and (max-width: 1280px) {
                        top: 2rem;
                    }

                    @media screen and (max-width: 1024px) {
                        background-size: 60% auto;
                        left: -90px;
                    }
                }
            }
        }
    }

    &.section-matieres {
        margin: 5rem 0 21rem;
        max-height: 690px;

        @media screen and (max-width: 1366px) {
            max-height: 630px;
            margin: 5rem 0 13rem;
        }

        @media screen and (max-width: 1024px) {
            max-height: 500px;
            margin: 5rem 0 11rem;
        }

        &:before {
            content: '';
            width: 100%;
            position: absolute;
            background: url('../img/histoire/floral_3.png') no-repeat 0 50%;
            background-size: contain;
            height: 730px;
            top: 0;
            left: 0;

            @media screen and (max-width: 1500px) {
                height: 100%;
                width: 37%;
                background-size: cover;
            }

            @media screen and (max-width: 1024px) {
                width: 30%;;
            }
        }

        .bloc-wrap {
            position: relative;
            z-index: 1;
        }

        .bloc-wrap.text-right .bloc-left {
            @media screen and (max-width: 1500px) {
                justify-content: flex-start;
                padding-left: 3rem;
            }
        }

        .bloc-left {
            padding: 0 2rem 0 4rem;

            @media screen and (max-width: 1500px) {
                padding: 0 0 0 2rem;
                justify-content: flex-start;
            }

            .img-wrap {
                margin: 3rem 0 0;

                &::before {
                    content: '';
                    position: absolute;
                    top: 5%;
                    right: 0;
                    background: url('../img/histoire/brush_3.png') no-repeat 50% 50%;
                    background-size: 80% auto;
                    width: 170px;
                    height: 140px;
                    z-index: 0;

                    @media screen and (max-width: 1280px) {
                        background-size: 75% auto;
                        right: -2%;
                    }

                    @media screen and (max-width: 1024px) {
                        background-size: 60% auto;
                        right: -6%;
                        top: 1%;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 19%;
                    right: -7rem;
                    background: url('../img/histoire/fleche.png') no-repeat 50% 50%;
                    width: 90px;
                    height: 72px;
                    transform: rotate(170deg);
                    
                    @media screen and (max-width: 1500px) {
                        background-size: 80% auto;
                        bottom: 15%;
                        right: -15%;
                    }

                    @media screen and (max-width: 1366px) {
                        background-size: 65% auto;
                        bottom: 10%;
                        right: -17%;
                    }

                    @media screen and (max-width: 1024px) {
                        background-size: 60% auto;
                        bottom: 15%;
                        right: -18%;
                    }
                }

                img, .info-text {
                    position: relative;
                    z-index: 1;
                }

                .info-text {
                    margin: 0.5rem 0 0;
                }
            }
        }

        .bloc-right {
            padding-left: 2rem;

            @media screen and (max-width: 1500px) {
                padding-left: 4rem;
            }

            .bloctext {
                margin-top: 3rem;
                width: 90%;
            }

            .img-wrap {
                text-align: center;
                width: 100%;
                margin: 4rem 0 0;
                width: 90%;

                @media screen and (max-width: 1024px) {
                    margin: 2rem 0 0;
                }

                img {
                    width: 55%;
                }
            }
        }

        .info-text {
            font-size: 30 / 16 * 1rem;
            text-align: right;

            @media screen and (max-width: 1500px) {
                font-size: 25 / 16 * 1rem;
            }

            @media screen and (max-width: 1024px) {
                font-size: 18 / 16 * 1rem;
            }
        }
    }

    &.section-responsable {
        margin: 5rem 0 18rem;

        @media screen and (max-width: 1366px) {
            margin: 5rem 0 12rem;
        }


        @media screen and (max-width: 1024px) {
            margin: 5rem 0 10rem;
        }

        .bloc-left {
            .bloctext {
                width: 75%;
                padding-bottom: 3rem;
            }

            .info-text {
                margin-top: -7%;
                font-size: 30 / 16 * 1rem;
                width: 54%;
                text-align: right;

                @media screen and (max-width: 1500px) {
                    width: 60%;
                    font-size: 25 / 16 * 1rem;
                }

                @media screen and (max-width: 1024px) {
                    font-size: 18 / 16 * 1rem;
                }
            }
        }

        .bloc-right {
            position: relative;
            padding-left: 1rem;

            &:before,
            &:after {
                content: '';
                position: absolute;
            }

            &:before {
                bottom: -15%;
                left: -18%;
                background: url('../img/histoire/floral_4.png') no-repeat 50% 50%;
                background-size: contain;
                width: 100%;
                height: 950px;
                z-index: -1;

                @media screen and (max-width: 1366px) {
                    height: 100%;
                    bottom: 0;
                }

                @media screen and (max-width: 1024px) {
                    bottom: 3%;
                }
            }

            &:after {
                background: url('../img/histoire/brush.png') no-repeat 85% 50%;
                background-size: contain;
                width: 80%;
                height: 22px;
                bottom: 0;

                @media screen and (max-width: 1500px) {
                    bottom: 4%;
                }

                @media screen and (max-width: 1366px) {
                    background-size: 45% auto;
                    bottom: 13%;
                }

                @media screen and (max-width: 1024px) {
                    background-size: 55% auto;
                    bottom: 10%;
                }
            }

            .bloctext,
            .img-wrap {
                width: 80%;

                @media screen and (max-width: 1500px) {
                    width: 85%;
                }
            }


            .bloctext {
                padding-top: 1rem;
            }
        }
    }

    &.section-fiancees {
        margin: 5rem 0 10rem;

        @media screen and (max-width: 1366px) {
            margin: 5rem 0 8rem;
        }

        @media screen and (max-width: 1024px) {
            margin: 5rem 0 6rem;
        }

        &:before {
            content: '';
            width: 100%;
            position: absolute;
            background: url('../img/histoire/floral_5.png') no-repeat 100% 100%;
            background-size: contain;
            height: 282px;
            bottom: 0;
            right: 0;

            @media screen and (max-width: 1366px) {
                background-size: 40% auto;
                height: 100%;
            }
        }

        .bloc-wrap {
            position: relative;
        }

        .bloc-wrap.text-50 .bloc-left {
            @media screen and (max-width: 1500px) {
                padding-left: 4rem;
            }

            @media screen and (max-width: 1024px) {
                padding-left: 3rem;
            }
        }

        .bloc-left {
            padding-left: 2rem;
            padding-right: 1rem;

            .img-wrap {
                margin: -3rem 0 0;
                padding-bottom: 3rem;
            }
        }

        .bloc-right {
            padding-left: 2rem;

            .bloctext {
                margin: 8rem 0 0;
                width: 85%;

                @media screen and (max-width: 1366px) {
                    margin: 4rem 0 0;
                }

                h2 span {
                    &:before {
                        content: '';
                        width: 78px;
                        position: absolute;
                        background: url('../img/histoire/highlight.png') no-repeat 0% 0%;
                        background-size: contain;
                        height: 38px;
                        top: -1rem;
                        right: -3rem;

                        @media screen and (max-width: 1366px) {
                            background-size: 60% auto;
                            top: -0.5rem;
                            right: -4rem;
                        }
                    }
                }
            }
        }
    }

    &.section-optimistes {
        margin: 5rem 0 10rem;

        @media screen and (max-width: 1366px) {
            margin: 5rem 0 8rem;
        }

        @media screen and (max-width: 1024px) {
            margin: 5rem 0 6rem;
        }

        &:before {
            content: '';
            width: 507px;
            position: absolute;
            background: url('../img/histoire/floral_6.png') no-repeat 0 50%;
            background-size: contain;
            height: 100%;
            bottom: -24%;
            left: 0;
            z-index: -1;
        }

        .bloc-wrap.text-50 .bloc-left {
            @media screen and (max-width: 1500px) {
                padding-left: 4rem;
            }

            @media screen and (max-width: 1024px) {
                padding-left: 3rem;
            }
        }

        .bloc-left {
            padding-left: 2rem;
            padding-right: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .bloctext {
                width: 85%;
                position: relative;

                @media screen and (max-width: 1024px) {
                    width: 95%;
                }

                &:before {
                    content: '';
                    width: 341px;
                    position: absolute;
                    background: url('../img/histoire/brush_2.png') no-repeat 0% 0%;
                    background-size: contain;
                    height: 61px;
                    bottom: -6rem;
                    right: -6rem;
                    z-index: -1;

                    @media screen and (max-width: 1366px) {
                        background-size: 90% auto;
                    }

                    @media screen and (max-width: 1024px) {
                        background-size: 80% auto;
                    }
                }
            }
        }

        .bloc-right {
            padding-left: 2rem;
            padding-right: 4rem;
        }
    }
}