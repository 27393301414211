#block_news {
    &.signup-images-module {
        .home-module-body {
            .home-columns-container {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center top;
                padding-top: 80px;
                position: relative;
         
                .home-column {

                    width: 100%;
                    max-width: 970px;
                    margin: auto;
                    padding: 125px 60px;
                    margin-bottom: 150px;
                    min-height: 850px;
                    text-align: center;
                    position: relative;
                    z-index: 5;
                    overflow: hidden;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 970px;
                        background-color: #fff;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        height: 51%;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        width: 970px;
                        z-index: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        height: 850px;
                        background-color: #fff;
                        border-radius: 100%;
                        z-index: -1;
                    }

                    .home-module-title {
                        max-width: 500px;
                        font-size: 65px;
                        line-height: 75px;
                        font-family: $secondaryFont;
                        margin: 0 auto 1.25rem;
                        position: relative;
                        z-index: 5;

                        @media only screen and (max-width: 1680px) {
                                font-size: 60px;
                                line-height: 70px;
                        }

                        @media only screen and (max-width: 1370px) {
                            font-size: 50px;
                            line-height: 60px;
                        }

                    }

                    .home-module-text {
                        font-size: 13px;
                        line-height: 21px;
                        font-family: $primaryFont;
                        font-weight: 500;
                        letter-spacing: 0.05em;
                        position: relative;
                        z-index: 5;

                        @media only screen and (max-width: 1680px) {
                                font-size: 12px;
                                line-height: 18px;
                        }

                        @media only screen and (max-width: 1370px) {
                            font-size: 11px;
                            line-height: 17px;
                        }

                    }

                    form {
                        width: 590px;
                        margin: .75rem auto 0;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        position: relative;
                        z-index: 5;

                        .w-form-line {
                            margin-bottom: 0;

                            input {
                                border-radius: 0;
                                border-color: #989898;
                            }

                            &.w-submit {
                                margin: 45px auto 0;
                                width: 100%;
                                max-width: 300px;
                                position: relative;
                                top: inherit;
                                right: inherit;
                                bottom: inherit;
                                left: inherit;

                                .w-btn-wrapper {
                                    width: 100%;

                                    button {
                                        width: 100%;
                                        height: 50px;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    } 
}